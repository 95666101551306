import classNames from 'classnames';

export const IconMapMarker = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 22 31"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    {...props}
  >
    {props.title && <title>{props.title}</title>}
    <path d="M21.2858 11.2835C21.2858 19.3311 14.4286 30.1406 11.0001 30.1406C6.71436 29.2835 0.714355 19.3311 0.714355 11.2835C0.714355 3.2359 4.37265 0.140625 11.0001 0.140625C17.6275 0.140625 21.2858 3.2359 21.2858 11.2835Z" />
    <circle cx="11" cy="10.4319" r="5.14286" fill="white" />
  </svg>
);
