import { Group, Image, Text } from '@mantine/core';
import { GreenRectangle } from '@icons';
import classes from './HomepageCards.module.scss';

const HomepageCards: React.FC = () => {
  return (
    <div className={classes.wrapper}>
      <Group className={classes.heading}>
        <Text component="h2" mb={0} className={classes.pinkText}>
          We've got you covered
        </Text>
      </Group>
      <Group className={classes.greenRectangle}>
        <GreenRectangle size={30} className={classes.greenRectangle} />
      </Group>
      <Group className={classes.alignCards}>
        <div className={classes.cardFlex}>
          <Image
            src="/drawnLogos/shieldLogo.png"
            alt="vetted suppliers logo"
            width={107}
            height={63}
            fit="contain"
            className={classes.logoImage}
          />
          <Text component="h3" mb="sm" className={classes.cardHeading}>
            Vetted Suppliers
          </Text>
          <Text className={classes.description}>
            We vet and hand-pick all our suppliers to ensure only the best options are available for
            you. Enjoy from a variety of activities and book with ease any day, at any time.
          </Text>
        </div>
        <div className={classes.cardFlex}>
          <Image
            src="/drawnLogos/sunLogo.png"
            alt="peace of mind logo"
            width={107}
            height={63}
            fit="contain"
            className={classes.logoImage}
          />
          <Text component="h3" mb="sm" className={classes.cardHeading}>
            Peace of Mind
          </Text>
          <Text className={classes.description}>
            Our team of experts work closely with your chosen activity supplier to help you access
            the best sessions they have to offer. You focus on your children, we'll handle the rest.
          </Text>
        </div>
        <div className={classes.cardFlex}>
          <Image
            src="/drawnLogos/smileyFaceLogo.png"
            alt="flexibility first logo"
            width={107}
            height={63}
            fit="contain"
            className={classes.logoImage}
          />
          <Text component="h3" mb="sm" className={classes.cardHeading}>
            Flexibility First
          </Text>
          <Text className={classes.description}>
            Whether it’s to make memories for your kids, better manage your time, or to simply make
            last minute arrangements, we are an extra pair of hands to help you in unexpected times.
          </Text>
        </div>
      </Group>
    </div>
  );
};

export default HomepageCards;
