import { CloudinaryImage } from 'components/ui';
import { useRef } from 'react';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import classes from './ImageCarousel.module.scss';

interface IImageCarouselProps {
  allImageIds: string[];
  activityName?: string;
  supplierName: string;
  trackEvent: () => void;
  isActivityDetails?: boolean;
}

const ImageCarousel: React.FC<IImageCarouselProps> = ({
  allImageIds,
  activityName,
  supplierName,
  trackEvent,
  isActivityDetails = false,
}: IImageCarouselProps) => {
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  return (
    <div>
      <Carousel
        maw="100%"
        mx="auto"
        withIndicators
        height="100%"
        classNames={{
          controls: classes.controls,
          root: classes.root,
          indicator: classes.indicator,
        }}
        loop={true}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        onNextSlide={() => trackEvent()}
        onPreviousSlide={() => trackEvent()}
        nextControlProps={{
          title: 'next slide',
        }}
        previousControlProps={{
          title: 'previous slide',
        }}
      >
        {allImageIds.map((imageId, index) => (
          <Carousel.Slide key={imageId}>
            <CloudinaryImage
              id={imageId}
              alt={`${activityName}-${supplierName}-${index}`}
              isActivityDetails={isActivityDetails}
            />
          </Carousel.Slide>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
