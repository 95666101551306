import classNames from 'classnames';

const PebbleLogoWithText = ({ className = '' }) => (
  <svg
    className={classNames('icon', className)}
    width="167"
    height="47"
    viewBox="0 0 167 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1211 20.7478C16.1211 20.7478 17.7893 19.3053 21.6168 19.2461C25.4442 19.1869 29.6381 19.7965 31.1456 21.381C32.6532 22.9656 31.7042 24.3016 31.7042 24.3016C31.7042 24.3016 30.997 25.4956 29.6261 25.8063C28.2551 26.117 26.6785 26.4573 24.5208 26.4706C22.3631 26.4839 17.8104 26.2398 16.7788 25.3595C15.7472 24.4792 15.058 23.7557 15.0625 22.7703C15.067 21.785 16.1211 20.7478 16.1211 20.7478Z"
      fill="#FA7BBF"
    />
    <path
      d="M19.0183 12.6034C20.1047 11.9086 21.3748 11.5442 22.67 11.5559C24.7992 11.5929 25.4915 12.0102 25.4915 12.0102C25.4915 12.0102 26.9239 12.8875 27.3098 13C27.6957 13.1124 28.3744 13.3447 28.6132 14.0267C28.8519 14.7088 29.1898 15.4767 28.6552 15.9324C28.1207 16.3881 27.951 16.6337 26.7453 16.9503C25.7327 17.1982 24.6868 17.2876 23.6461 17.2151C22.8397 17.1781 20.9358 16.9858 20.2736 16.9843C19.6114 16.9828 18.3801 16.2801 18.227 15.453C18.0738 14.6259 18.1008 12.994 19.0183 12.6034Z"
      fill="#ABEE56"
    />
    <path
      d="M13.8216 31.5515C13.8216 31.5515 14.5724 29.9832 17.3067 29.0807C19.5133 28.3676 21.8669 28.215 24.1493 28.6369C25.801 28.9254 28.262 29.2879 30.6795 30.1341C33.097 30.9804 33.7502 31.4923 33.7337 32.6493C33.7172 33.8063 32.5219 34.7207 32.1541 34.8686C30.7833 35.5037 29.2916 35.8456 27.777 35.8717C25.2815 35.9457 19.6492 36.0197 18.6912 35.9487C17.7332 35.8777 15.3878 35.8007 14.4493 34.5771C13.5108 33.3536 13.3622 32.2913 13.8216 31.5515Z"
      fill="#548CED"
    />
    <path
      d="M44.6445 12.7276C44.6445 12.4136 44.7711 12.1126 44.9964 11.8906C45.2216 11.6686 45.5272 11.5439 45.8458 11.5439H54.7845C60.7126 11.5439 64.5521 14.5637 64.5521 19.6813V19.7479C64.5521 25.6394 60.2742 28.4401 54.4467 28.4401H51.1793V34.7769C51.1793 35.0908 51.0527 35.3919 50.8274 35.6139C50.6022 35.8358 50.2966 35.9605 49.978 35.9605H45.8458C45.5272 35.9605 45.2216 35.8358 44.9964 35.6139C44.7711 35.3919 44.6445 35.0908 44.6445 34.7769V12.7276ZM54.313 23.389C56.6029 23.389 58.0519 22.161 58.0519 20.3353V19.9743C58.0519 17.9828 56.6029 17.249 54.2785 17.249H52.3805C52.0619 17.249 51.7564 17.3737 51.5311 17.5957C51.3058 17.8176 51.1793 18.1187 51.1793 18.4326V22.2054C51.1793 22.5193 51.3058 22.8204 51.5311 23.0423C51.7564 23.2643 52.0619 23.389 52.3805 23.389H54.313Z"
      fill="#548CED"
    />
    <path
      d="M65.2227 26.7701V26.7036C65.2227 21.4586 69.0291 17.3101 74.4182 17.3101C80.6841 17.3101 83.5776 21.8581 83.5776 27.1341V27.2451C83.5776 27.559 83.4511 27.86 83.2258 28.082C83.0005 28.304 82.695 28.4287 82.3764 28.4287H71.3535C71.858 30.3876 73.3055 31.3877 75.2951 31.3877C76.5519 31.3877 77.5669 31.0149 78.5985 30.2041C78.8142 30.032 79.0838 29.9389 79.3614 29.9405C79.6389 29.9421 79.9074 30.0384 80.121 30.213L81.9064 31.6718C82.0361 31.7775 82.1418 31.9089 82.2167 32.0575C82.2916 32.206 82.334 32.3684 82.341 32.5342C82.3481 32.6999 82.3198 32.8652 82.2578 33.0195C82.1959 33.1737 82.1017 33.3134 81.9815 33.4295C80.2937 35.0673 78.0219 36.1044 74.9842 36.1044C69.2648 36.0956 65.2227 32.3124 65.2227 26.7701ZM76.8957 25.2092C76.9873 25.2092 77.0777 25.1884 77.16 25.1487C77.2422 25.1089 77.3141 25.0511 77.3702 24.9797C77.4263 24.9083 77.4651 24.8252 77.4835 24.7367C77.502 24.6483 77.4997 24.5569 77.4768 24.4695C77.0579 22.9115 75.9422 21.9543 74.4182 21.9543C72.9601 21.9543 71.9046 22.9012 71.4301 24.4473C71.4037 24.5356 71.3985 24.6287 71.4151 24.7193C71.4316 24.8099 71.4694 24.8954 71.5254 24.9691C71.5814 25.0428 71.654 25.1026 71.7376 25.1438C71.8211 25.1849 71.9132 25.2063 72.0067 25.2063L76.8957 25.2092Z"
      fill="#548CED"
    />
    <path
      d="M91.5919 33.4735V34.777C91.5919 35.0909 91.4654 35.392 91.2401 35.6139C91.0148 35.8359 90.7093 35.9606 90.3907 35.9606H86.3966C86.078 35.9606 85.7724 35.8359 85.5471 35.6139C85.3219 35.392 85.1953 35.0909 85.1953 34.777V12.6197C85.1953 12.3057 85.3219 12.0047 85.5471 11.7827C85.7724 11.5607 86.078 11.436 86.3966 11.436H90.3907C90.7093 11.436 91.0148 11.5607 91.2401 11.7827C91.4654 12.0047 91.5919 12.3057 91.5919 12.6197V20.0986C92.8382 18.6043 94.3863 17.3097 97.1161 17.3097C101.46 17.3097 105.199 20.8606 105.199 26.6026V26.6692C105.199 32.5104 101.46 36.0627 97.1492 36.0627C94.3908 36.0627 92.7751 34.8347 91.5919 33.4735ZM98.9345 26.7032V26.6367C98.9345 24.2472 97.2498 22.5547 95.1956 22.5547C93.107 22.5547 91.4568 24.2472 91.4568 26.6367V26.7032C91.4568 29.0927 93.1085 30.7852 95.1956 30.7852C97.2543 30.7852 98.939 29.1252 98.939 26.7032H98.9345Z"
      fill="#548CED"
    />
    <path
      d="M113.218 33.4735V34.777C113.218 35.0909 113.091 35.392 112.866 35.6139C112.641 35.8359 112.335 35.9606 112.017 35.9606H108.025C107.707 35.9606 107.401 35.8359 107.176 35.6139C106.951 35.392 106.824 35.0909 106.824 34.777V12.6197C106.824 12.3057 106.951 12.0047 107.176 11.7827C107.401 11.5607 107.707 11.436 108.025 11.436H112.017C112.335 11.436 112.641 11.5607 112.866 11.7827C113.091 12.0047 113.218 12.3057 113.218 12.6197V20.0986C114.464 18.6043 116.014 17.3097 118.742 17.3097C123.088 17.3097 126.826 20.8606 126.826 26.6026V26.6692C126.826 32.5104 123.088 36.0627 118.775 36.0627C116.02 36.0627 114.403 34.8347 113.218 33.4735ZM120.56 26.7032V26.6367C120.56 24.2472 118.877 22.5547 116.822 22.5547C114.733 22.5547 113.083 24.2472 113.083 26.6367V26.7032C113.083 29.0927 114.734 30.7852 116.822 30.7852C118.883 30.7852 120.566 29.1252 120.566 26.7032H120.56Z"
      fill="#548CED"
    />
    <path
      d="M129.666 11.436H133.638C133.957 11.436 134.262 11.5607 134.487 11.7827C134.713 12.0047 134.839 12.3057 134.839 12.6197V34.777C134.839 35.0909 134.713 35.392 134.487 35.6139C134.262 35.8359 133.957 35.9606 133.638 35.9606H129.674C129.355 35.9606 129.05 35.8359 128.824 35.6139C128.599 35.392 128.473 35.0909 128.473 34.777V12.6197C128.473 12.307 128.598 12.0071 128.822 11.7853C129.045 11.5636 129.349 11.438 129.666 11.436Z"
      fill="#548CED"
    />
    <path
      d="M136.578 26.7701V26.7036C136.578 21.4586 140.386 17.3101 145.775 17.3101C152.04 17.3101 154.935 21.8581 154.935 27.1341V27.2451C154.935 27.559 154.808 27.86 154.583 28.082C154.357 28.304 154.052 28.4287 153.733 28.4287H142.707C143.213 30.3876 144.659 31.3877 146.649 31.3877C147.907 31.3877 148.921 31.0149 149.961 30.2041C150.177 30.0324 150.446 29.9395 150.724 29.9411C151.001 29.9427 151.269 30.0388 151.483 30.213L153.269 31.6718C153.399 31.7775 153.505 31.9089 153.58 32.0575C153.655 32.206 153.697 32.3684 153.704 32.5342C153.711 32.6999 153.683 32.8652 153.621 33.0195C153.559 33.1737 153.465 33.3134 153.344 33.4295C151.657 35.0673 149.383 36.1044 146.346 36.1044C140.62 36.0956 136.578 32.3124 136.578 26.7701ZM148.401 25.2092C148.493 25.2094 148.584 25.1888 148.666 25.1491C148.749 25.1094 148.821 25.0517 148.877 24.9802C148.933 24.9088 148.972 24.8256 148.991 24.7371C149.009 24.6486 149.007 24.557 148.984 24.4695C148.564 22.9115 147.448 21.9543 145.925 21.9543C144.466 21.9543 143.412 22.9012 142.936 24.4473C142.909 24.5357 142.904 24.629 142.921 24.7197C142.937 24.8104 142.975 24.896 143.031 24.9697C143.088 25.0434 143.16 25.1032 143.244 25.1443C143.328 25.1853 143.42 25.2066 143.514 25.2063L148.401 25.2092Z"
      fill="#548CED"
    />
  </svg>
);

export default PebbleLogoWithText;
