import { Button, Chip, Flex, Group, Text, useMantineTheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
import { getWeekdayBtnLabel, sortSelectedDaysOfWeek, weekdays, weekends } from './utils';
import { useMediaQuery } from '@mantine/hooks';
import { trackAction, Actions } from 'utils/amplitude';
import ResetSearchFilterButton from 'components/ui/ResetSearchFilterButtons/ResetSearchFilterButton';
import { SearchBarDropdownEnum, DaysOfWeekEnum } from 'enums';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { useRouter } from 'next/router';
dayjs.extend(isBetween);
import classes from './ScheduleFilterContent.module.scss';
import classNames from 'classnames';

const ScheduleFilterContent: React.FC = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const URLpathname = getURLpathnameParam(useRouter().pathname);
  const {
    startFilterDate,
    endFilterDate,
    setDateRange,
    daysOfWeek,
    selectedDays,
    setSelectedDays,
    setModifiedFilter,
  } = useSavedSearchValues();

  const weekdaysSelected = JSON.stringify(selectedDays) === JSON.stringify(weekdays);
  const weekendsSelected = JSON.stringify(selectedDays) === JSON.stringify(weekends);

  const deselectDayOfWeek = (value: string) => {
    // return early when attempting to deselect the only selected day
    if (selectedDays.length === 1 && selectedDays[0] === value) {
      return;
    }

    const filteredDaysOfWeek = selectedDays.filter((day) => {
      return day !== value;
    });
    setSelectedDays(filteredDaysOfWeek);
  };

  const handleDayOfWeekSelect = (value: string) => {
    setDateRange([null, null]);
    if (selectedDays.length === 7) {
      setSelectedDays([value]);
    } else {
      const alreadySelected = selectedDays.find((dayOfWeek) => {
        return dayOfWeek === value;
      });
      if (alreadySelected) {
        deselectDayOfWeek(value);
      } else {
        setSelectedDays((prev: string[]) => sortSelectedDaysOfWeek([value, ...prev]));
      }
    }
  };

  return (
    <>
      {!isMobile && (
        <Flex justify={isMobile ? 'flex-end' : 'space-between'} align="center" mb="sm">
          <Text
            c={theme.colors.blue[8]}
            size="md"
            style={{
              fontWeight: 700,
            }}
          >
            When
          </Text>
          <ResetSearchFilterButton filterType={SearchBarDropdownEnum.when} />
        </Flex>
      )}
      <Chip.Group>
        <Group gap={0} justify="space-between">
          <Chip
            type="radio"
            variant="outline"
            checked={selectedDays.length === 7}
            onClick={() => {
              setDateRange([null, null]);
              setSelectedDays(daysOfWeek);
              setModifiedFilter(true);
              trackAction(Actions.WHEN_FILTER_ALL_DAYS);
            }}
            classNames={{
              label: classes.handlingAllWeekdaysBtn,
              iconWrapper: classes.chipIcon,
            }}
          >
            All days
          </Chip>
          <Chip
            type="radio"
            variant="outline"
            checked={weekdaysSelected}
            onClick={() => {
              setDateRange([null, null]);
              setSelectedDays(weekdays);
              setModifiedFilter(true);
              trackAction(Actions.WHEN_FILTER_WEEKDAYS);
            }}
            classNames={{ label: classes.handlingAllWeekdaysBtn, iconWrapper: classes.chipIcon }}
          >
            Weekdays
          </Chip>
          <Chip
            type="radio"
            variant="outline"
            checked={weekendsSelected}
            onClick={() => {
              setDateRange([null, null]);
              setSelectedDays(weekends);
              setModifiedFilter(true);
              trackAction(Actions.WHEN_FILTER_WEEKENDS);
            }}
            classNames={{ label: classes.handlingAllWeekdaysBtn, iconWrapper: classes.chipIcon }}
          >
            Weekends
          </Chip>
        </Group>
      </Chip.Group>
      <Text className={classes.subheading}>Select days of the week</Text>
      <Group justify="space-between" gap={0}>
        {Object.values(daysOfWeek).map((item, index) => {
          return (
            <Button
              variant="outline"
              key={index}
              value={item}
              className={classNames(classes.dayOfWeekButton, {
                [classes.dayOfWeekButtonSelected]:
                  selectedDays.length !== 7 &&
                  selectedDays.find((dayOfTheWeek) => dayOfTheWeek === item.toUpperCase()),
                [classes.allButtonSelected]: selectedDays.length === 7,
              })}
              onClick={() => {
                handleDayOfWeekSelect(item);
                setModifiedFilter(true);
                trackAction(Actions.DAY_SELECT, { dayOfTheWeek: item, URLpathname });
              }}
            >
              {getWeekdayBtnLabel(item)}
            </Button>
          );
        })}
      </Group>
      <Text className={classes.subheading}>Or select a date/range of dates</Text>
      <Group justify="center">
        <DatePicker
          className={classes.calendarBase}
          type="range"
          allowSingleDateInRange
          value={[startFilterDate, endFilterDate]}
          onChange={(e) => {
            setDateRange(e);
            setSelectedDays([]);
            setModifiedFilter(true);
            trackAction(Actions.DATE_SELECT_SCHEDULE, { URLpathname });
          }}
          minDate={new Date()}
          maxLevel="month"
          size={'md'}
          hideOutsideDates
          withCellSpacing={false}
          classNames={{
            calendarHeader: classes.calendarHeader,
            calendarHeaderLevel: classes.calendarHeaderLevel,
            calendarHeaderControl: classes.calendarControl,
            monthThead: classes.calendarHeader,
            day: classes.dayCell,
            weekdaysRow: classes.weekdaysRow,
            weekday: classNames(classes.weekdays, {
              [classes.highlightMonday]: selectedDays.includes(DaysOfWeekEnum.Mondays),
              [classes.highlightTuesday]: selectedDays.includes(DaysOfWeekEnum.Tuesdays),
              [classes.highlightWednesday]: selectedDays.includes(DaysOfWeekEnum.Wednesdays),
              [classes.highlightThursday]: selectedDays.includes(DaysOfWeekEnum.Thursdays),
              [classes.highlightFriday]: selectedDays.includes(DaysOfWeekEnum.Fridays),
              [classes.highlightSaturday]: selectedDays.includes(DaysOfWeekEnum.Saturdays),
              [classes.highlightSunday]: selectedDays.includes(DaysOfWeekEnum.Sundays),
            }),
          }}
          onNextMonth={() => trackAction(Actions.SEARCH_NEXT_MONTH)}
          onPreviousMonth={() => trackAction(Actions.SEARCH_PREVIOUS_MONTH)}
          data-testid="schedule-filter-calendar"
          getDayProps={(date) => {
            if (
              startFilterDate &&
              !endFilterDate &&
              dayjs(date).isAfter(startFilterDate) &&
              dayjs(date).isBefore(dayjs(startFilterDate).add(100, 'day'))
            ) {
              return {
                style: () => ({
                  backgroundColor: '#445690',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: `${theme.colors.blue[1]} !important`,
                    color: `${theme.colors.blue[8]} !important`,
                  },
                }),
              };
            }

            return {};
          }}
        />
      </Group>
    </>
  );
};

export default ScheduleFilterContent;
