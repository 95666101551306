import { ActivitySearchResult } from 'types';
import { ActivityTimeFullEnum } from 'enums';

export const getTimeText = (times: ActivitySearchResult['times']) => {
  if (times === ActivityTimeFullEnum.Afternoons) {
    return 'PM';
  } else if (times === ActivityTimeFullEnum.Mornings) {
    return 'AM';
  } else if (times === ActivityTimeFullEnum.AllDay) {
    return 'AM/PM';
  } else {
    return times;
  }
};
