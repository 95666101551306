import { ActionIcon, Badge, Flex, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { useMediaQuery } from '@mantine/hooks';
import { KeyboardEvent } from 'react';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { Actions, trackAction } from 'utils/amplitude';
import ResetSearchFilterButton from 'components/ui/ResetSearchFilterButtons/ResetSearchFilterButton';
import { SearchBarDropdownEnum } from 'enums';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { useRouter } from 'next/router';
import classes from './ActivityFilterContent.module.scss';
import { Plus, X } from '@phosphor-icons/react';

const ActivityFilterContent = () => {
  const theme = useMantineTheme();

  const {
    activitySearchTerms,
    setActivitySearchTerms,
    searchFilterTerm,
    setSearchTerm,
    setModifiedFilter,
  } = useSavedSearchValues();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const URLpathname = getURLpathnameParam(useRouter().pathname);

  const handleAddSearchTerm = () => {
    setModifiedFilter(true);

    if (activitySearchTerms && activitySearchTerms.length > 0) {
      setActivitySearchTerms([...activitySearchTerms, searchFilterTerm]);
    } else {
      setActivitySearchTerms([searchFilterTerm]);
    }

    setSearchTerm('');
  };

  const deleteSearchTerm = (searchTermToDelete: string) => {
    if (!activitySearchTerms) return;

    const filteredTerms = activitySearchTerms.filter((term: string) => term !== searchTermToDelete);

    if (filteredTerms.length === 0) {
      setActivitySearchTerms(null);
    } else {
      setActivitySearchTerms(filteredTerms);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (searchFilterTerm.length === 0) return;

    if (event.key === 'Enter') {
      handleAddSearchTerm();
      trackAction(Actions.SEARCH_TERM_ENTER, { URLpathname, searchTerm: searchFilterTerm });
    }
  };

  return (
    <>
      {!isMobile && (
        <Flex justify="space-between" align="center">
          <Text
            c={theme.colors.blue[8]}
            mb="xs"
            style={{
              fontWeight: 700,
            }}
          >
            Activity
          </Text>
          <ResetSearchFilterButton filterType={SearchBarDropdownEnum.activity} />
        </Flex>
      )}

      <Text color={theme.colors.blue[8]} size="xs" lh="18px" mb="xs">
        Describe what you are looking for here. Click + to add multiple search terms.
      </Text>
      <Flex mb="sm" align="center">
        <TextInput
          data-cy="activity-search-term-input"
          classNames={{ input: classes.activityTextInput }}
          w="100%"
          placeholder="Activity name, provider or type"
          value={searchFilterTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
        <ActionIcon
          mr="sm"
          ml="xs"
          variant="filled"
          radius="xl"
          size="lg"
          title="add"
          className={classes.search}
          onClick={() => {
            handleAddSearchTerm();
            trackAction(Actions.SEARCH_TERM_ENTER, { URLpathname, searchTerm: searchFilterTerm });
          }}
          disabled={activitySearchTerms?.length === 5 || searchFilterTerm.length === 0}
        >
          <Plus size={18} weight="bold" />
        </ActionIcon>
      </Flex>
      <Flex className={classes.labelsWrapper}>
        {activitySearchTerms &&
          activitySearchTerms.map((label: string) => (
            <Badge
              w="fit-content"
              onClick={() => {
                setModifiedFilter(true);
                deleteSearchTerm(label);
                trackAction(Actions.SEARCH_TERM_DELETE, { URLpathname });
              }}
              key={label}
              m={4}
              className={classes.badgeLabel}
              rightSection={
                <ActionIcon title="cross" ml={2} variant="subtle">
                  <X size={10} weight="bold" color={theme.colors.blue[8]} />
                </ActionIcon>
              }
            >
              {capitalizeFirstLetter(label)}
            </Badge>
          ))}
      </Flex>
    </>
  );
};

export default ActivityFilterContent;
