import { FilterOptions, FiltersQuery } from 'types';
import { FilterOption } from 'interfaces';
import {
  DaysOfWeekEnum,
  DistanceEnum,
  TimeEnum,
  MinAgeRangeEnum,
  MaxAgeRangeEnum,
  AgeFilterTypeEnum,
  ValidFilterKeysEnum,
} from 'enums';
import { ParsedUrlQuery, parse } from 'querystring';
import getEnumKeyByEnumValue from 'utils/getEnumKeyByEnumValue';

type UpdateCallback = (value: FiltersQuery) => void;

const getOptionsFromEnum = <T extends { [index: string]: string }>(
  optionsEnum: T,
  defaultLabel?: string,
): FilterOption[] => {
  const entries = Object.entries(optionsEnum);
  return entries.map(([key, value]) => {
    return {
      label: key,
      value: value as FilterOption['value'],
      ...(defaultLabel === key && {
        default: true,
      }),
    };
  });
};

const getDefaultOption = (label: string): FilterOption => {
  return {
    label,
    value: '',
    default: true,
  };
};

export const getAgeFilters = (param?: string): FilterOption[] => {
  //removes "No max age" filter option for allAgeFilters
  const allAgeFilterOptions = getOptionsFromEnum(MaxAgeRangeEnum).slice(1);
  const allAgeFilters = [getDefaultOption('All ages'), ...allAgeFilterOptions];
  if (param === AgeFilterTypeEnum.MIN) {
    const minAgeFilters = [...getOptionsFromEnum(MinAgeRangeEnum, 'No min age')];
    return minAgeFilters;
  } else if (param === AgeFilterTypeEnum.MAX) {
    const maxAgeFilters = [...getOptionsFromEnum(MaxAgeRangeEnum, 'No max age')];
    return maxAgeFilters;
  }
  return allAgeFilters;
};

const getDayOfTheWeekFilters = (includeAllDays: boolean): FilterOption[] => {
  return includeAllDays
    ? [getDefaultOption('All days'), ...getOptionsFromEnum(DaysOfWeekEnum)]
    : [...getOptionsFromEnum(DaysOfWeekEnum)];
};

const getDistanceFilters = (): FilterOption[] => {
  return [...getOptionsFromEnum(DistanceEnum, '5 miles')];
};

const getTimeFilters = (): FilterOption[] => {
  return [getDefaultOption('Anytime'), ...getOptionsFromEnum(TimeEnum)];
};

export const filterOptionsWithAllDaysAndTime: FilterOptions = {
  age: getAgeFilters(),
  dayOfTheWeek: getDayOfTheWeekFilters(true),
  distance: getDistanceFilters(),
  time: getTimeFilters(),
};

export const filterOptionsWithAllDays: FilterOptions = {
  age: getAgeFilters(),
  dayOfTheWeek: getDayOfTheWeekFilters(true),
  distance: getDistanceFilters(),
};

export const getDefaultSelection = (options: FilterOption[]): FilterOption | undefined => {
  return options.find((option) => option.default);
};

export const getSelectedOption = (
  value: string | string[] | undefined,
  options: FilterOption[],
  defaultOption: FilterOption | undefined,
) => {
  const hasSelectedValue = Boolean(value);

  if (hasSelectedValue) {
    return options.find((option) => option.value === value);
  }

  return defaultOption;
};

export const handleSelection = (
  value: string | string[],
  name: string,
  onUpdate: UpdateCallback,
) => {
  if (name === 'age' && typeof value === 'string') {
    const [ageStart, ageEnd] = value.split('-');
    onUpdate({
      ageStart,
      ageEnd,
    });
  } else if (name === 'dayOfTheWeek') {
    onUpdate({
      [name]: value,
    });
  } else if (name === 'provider') {
    onUpdate({
      suppliers: value,
    });
  } else if (name === 'locationWithCoordinates') {
    const filters = {
      seLat: value[0],
      seLng: value[1],
      nwLat: value[2],
      nwLng: value[3],
      location: value[4],
    };
    onUpdate(filters);
  } else {
    onUpdate({
      [name]: value,
    });
  }
};

export const getValue = (
  name: keyof FiltersQuery,
  selectedFilters: FiltersQuery,
): string | string[] | undefined => {
  if (name in selectedFilters) {
    return selectedFilters[name];
  }
  return '';
};

export const formatMonthToYears = (ageInMonths: number) => {
  return Math.round(
    ageInMonths === 217 || ageInMonths === 1201 ? ageInMonths / 12 : ageInMonths / 12 - 1,
  );
};

export const categoryGroups = {
  group1: [
    'Arts & Crafts',
    'Dance & Drama',
    'Holiday Camp',
    'Sport',
    'Tutoring',
    'Childcare',
    'Wraparound Childcare',
  ],
  group2: ['Educational', 'Language', 'Music', 'STEM'],
  group3: ['Play'],
  group4: ['First Aid', 'Prenatal', 'Yoga'],
  group5: ['Health & Wellbeing', 'Parent & Baby/Toddler'],
};

export const getAgeRangeLabel = (
  searchbarLabel: boolean = false,
  ageStart?: string,
  ageEnd?: string,
  category?: string,
): string | null => {
  const minAgeValue = Number(ageStart);
  const maxAgeValue = Number(ageEnd);
  const ageStartLabel = getEnumKeyByEnumValue(MinAgeRangeEnum, ageStart || '');
  const ageEndLabel = getEnumKeyByEnumValue(MaxAgeRangeEnum, ageEnd || '');

  // Handles exception for 'No min age' label with 0 value
  const minAgeInMonthsLabel = minAgeValue === 0 ? minAgeValue : minAgeValue + 1;

  if ((!ageStart || ageStart === '0') && (!ageEnd || ageEnd === '1201')) {
    return null;
  }

  // If start and end age the same, display value for search bar and SEO titles
  if (ageStartLabel === ageEndLabel) {
    return searchbarLabel && ageStartLabel
      ? ageStartLabel
      : `aged ${ageStartLabel?.slice(0, -1)}s old`;
  }

  // Seo title - don't show age label to categories in group 4 and 5 except if Parent & Baby/Toddler
  if (category && category !== 'Parent & Baby/Toddler') {
    for (const [groupName, categories] of Object.entries(categoryGroups)) {
      if (categories.includes(category) && (groupName === 'group4' || groupName === 'group5')) {
        return '';
      }
    }
  }

  // Age start or age end display for search bar and SEO titles
  if (ageStart && (!ageEnd || ageEnd === '1201')) {
    const noMinAgeLabel = ageStartLabel === 'No min age';
    if (searchbarLabel) {
      return `From ${noMinAgeLabel ? '0 mths' : ageStartLabel}`;
    } else {
      return `${noMinAgeLabel ? 'aged 0 months or older' : `aged ${ageStartLabel} or older`}`;
    }
  }

  if ((!ageStart || ageStart === '0') && ageEnd && !searchbarLabel) {
    const noMaxAgeLabel = ageEndLabel === 'No max age';
    return `${noMaxAgeLabel ? 'aged up to 100 years old' : `aged up to ${ageEndLabel} old`}`;
  }

  // Age range display for search bar and SEO titles
  if (minAgeValue > 19 && maxAgeValue > 19) {
    return searchbarLabel
      ? `${Math.round(minAgeValue / 12) || 0}-${formatMonthToYears(maxAgeValue)}yrs`
      : `aged ${Math.round(minAgeValue / 12) || 0}-${formatMonthToYears(maxAgeValue)} years old`;
  }

  if (minAgeValue <= 19 && maxAgeValue <= 19) {
    return searchbarLabel
      ? `${minAgeInMonthsLabel || 0}-${maxAgeValue - 1}mths`
      : `aged ${minAgeInMonthsLabel || 0}-${maxAgeValue - 1} months old`;
  }

  return searchbarLabel
    ? `${minAgeInMonthsLabel}m - ${formatMonthToYears(maxAgeValue)}yrs`
    : `aged ${minAgeInMonthsLabel} months - ${formatMonthToYears(maxAgeValue)} years old`;
};

export const getHashQuery = (asPath: string): ParsedUrlQuery => parse(asPath.split('#')[1]);

export const getValidFiltersFromQuery = (
  query: ParsedUrlQuery,
  asPath: string,
  defaultRadius?: string,
): FiltersQuery => {
  const hash = getHashQuery(asPath);
  const rawFilters = {
    distance: hash.distance || defaultRadius,
    ...query,
    ...hash,
  };

  return Object.entries(rawFilters).reduce((existing, [key, value]) => {
    if (key in ValidFilterKeysEnum) {
      return {
        ...existing,
        [key]: value,
      };
    }
    return existing;
  }, {});
};

export const getAllFromQueryExceptFilters = (query: ParsedUrlQuery): FiltersQuery => {
  return Object.entries(query).reduce((existing, [key, value]) => {
    if (key in ValidFilterKeysEnum) {
      return existing;
    }

    return {
      ...existing,
      [key]: value,
    };
  }, {});
};

export const getDisabledAgeOption = (
  value: string,
  param: AgeFilterTypeEnum,
  selectedOppositeAge: string | undefined,
): boolean => {
  if (param === AgeFilterTypeEnum.MAX && selectedOppositeAge && value) {
    return Number(value) - 1 < Number(selectedOppositeAge);
  }
  if (param === AgeFilterTypeEnum.MIN && selectedOppositeAge && value) {
    return Number(value) + 1 > Number(selectedOppositeAge);
  }
  return false;
};
