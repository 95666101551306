import classNames from 'classnames';

export const Enquire = ({
  className = '',
  color = 'currentColor',
  size = 30,
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <rect x="2" y="2.5" width="19" height="19" rx="3" fill="#D5E1FF" />
    <path
      d="M8.864 16.5V8.04H14.744V9.492H10.652V11.472H14.456V12.936H10.652V15.048H14.744V16.5H8.864Z"
      fill="#4378FF"
    />
  </svg>
);
