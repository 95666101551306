import classNames from 'classnames';

export const SingleSession = ({
  className = '',
  size = 16,
  color = '#4378FF',
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="singleBookingIcon"
    {...props}
  >
    {title && <title>{title}</title>}
    <path
      fill={color}
      d="M13.09 2.152a.5.5 0 0 1 .5.5v1.5a.5.5 0 0 1-.5.5H2.947a.5.5 0 0 1-.5-.5v-1.5a.5.5 0 0 1 .5-.5h10.145Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M12.89 2.852H3.147v1.1h9.745v-1.1Zm.7-.2a.5.5 0 0 0-.5-.5H2.947a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 .5.5h10.145a.5.5 0 0 0 .5-.5v-1.5Z"
      clipRule="evenodd"
    />
    <rect
      width="14.8"
      height="14.8"
      x="15.4"
      y=".6"
      stroke={color}
      strokeWidth="1.2"
      rx="1.4"
      transform="rotate(90 15.4 .6)"
    />
  </svg>
);
