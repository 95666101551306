import {
  trackAction as track,
  initializeAmplitude,
  trackPageView,
} from '../../../../../shared/utils/amplitude';
import { Actions } from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackAction = (action: Actions, data?: any) => {
  const localStorageSupplierId =
    typeof window !== 'undefined' ? window.sessionStorage.getItem('supplierId') : '';
  const localStorageActivityId =
    typeof window !== 'undefined' ? window.sessionStorage.getItem('activityId') : '';

  const dataObject = {
    ...data,
    ...(localStorageSupplierId &&
      !data?.supplierId && {
        supplierId: JSON.parse(localStorageSupplierId),
      }),
    ...(localStorageActivityId &&
      !data?.activityId && {
        activityId: JSON.parse(localStorageActivityId),
      }),
  };

  track<Actions>(action, dataObject);
};

export { trackAction, initializeAmplitude, trackPageView, Actions };
