import { gql } from '@apollo/client';

const GetFranchiseBySlug = gql`
  query getFranchiseBySlug($franchiseSlug: String!) {
    marketplaceFranchiseBySlug(franchiseSlug: $franchiseSlug) {
      id
      slug
      name
      franchiseAgeRange
      cloudinaryImageId
      additionalImageIds
      operatingArea
      operatingAreaMapLink
      description
      aboutActivity1
      activityName1
      aboutActivity2
      activityName2
      aboutActivity3
      activityName3
      aboutActivity4
      activityName4
      aboutActivity5
      activityName5
      onlineDetails
      categories {
        id
        name
      }
      facebook
      instagram
      twitter
      suppliers {
        id
        slug
        name
        activitiesLocation
        activityLocation {
          id
          addressLine1
          addressLine2
          city
          postCode
          longitude
          latitude
        }
        phoneNumber
        email
        ageRange
        reviewsEnabled
        location {
          id
          latitude
          longitude
          addressLine1
          postCode
          city
        }
      }
    }
  }
`;

export default GetFranchiseBySlug;
