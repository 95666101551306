import { Box, Text, Flex, Card, Image, Badge, useMantineTheme } from '@mantine/core';
import { ActivitySearchResult } from 'types';
import { ActivityCardVariantEnum, SocialProofLabelEnum, ActivityTypeEnum } from 'enums';
import Link from 'next/link';
import { Actions, trackAction } from 'utils/amplitude';
import { CloudinaryImage } from 'components/ui';
import { useMemo } from 'react';
import getSocialProofBadge from 'utils/getSocialProofBadge';
import { ChildFace } from '@icons';
import DaysOfWeekDisplayLine from 'components/ui/DaysOfWeekDisplayLine/DaysOfWeekDisplayLine';
import { getTimeText } from 'pages/activities/[category]/[location]/utils';
import dayjs from 'dayjs';
import classes from './NewActivityCard.module.scss';
import classNames from 'classnames';
import { Lightning } from '@phosphor-icons/react';

interface INewActivityCardProps {
  activity: ActivitySearchResult;
  variant?: ActivityCardVariantEnum;
}

const ActivityImage = ({
  activity,
  isFullSize,
}: {
  activity: ActivitySearchResult;
  isFullSize: boolean;
}) => {
  return (
    <div className={classNames(classes.imageBorderRadius, { [classes.minCardImage]: !isFullSize })}>
      {activity?.cloudinaryImageId ? (
        <CloudinaryImage
          id={activity.cloudinaryImageId}
          alt={`${activity.name} - ${activity.supplierName}`}
          width={isFullSize ? 240 : 48}
          height={isFullSize ? 133 : 48}
          showGradient={isFullSize}
        />
      ) : (
        <Image
          src="/pebble_placeholder_image.png"
          width={isFullSize ? '100%' : 48}
          height={isFullSize ? 133 : 48}
          fit="cover"
          alt="Pattern with shapes and lines"
          className={classNames({ [classes.image]: isFullSize })}
        />
      )}
    </div>
  );
};

const DateInformation = ({
  activity,
  isFullSize,
}: {
  activity: ActivitySearchResult;
  isFullSize: boolean;
}) => {
  const getNextSessionDate = () => {
    const { activityType, nextSessionDate, subscriptionStart } = activity;

    if (activityType === ActivityTypeEnum.SUBSCRIPTION) {
      const isDeferredDateAfter = dayjs(subscriptionStart).isAfter(dayjs(nextSessionDate));

      return subscriptionStart && isDeferredDateAfter ? subscriptionStart : nextSessionDate;
    }

    return nextSessionDate;
  };

  return (
    <>
      <Flex
        direction="row"
        justify="start"
        align="center"
        data-cy="activity-date-time"
        className={classes.dateInfo}
      >
        <Text>NEXT: {dayjs(getNextSessionDate()).format('ddd, DD MMM').toUpperCase()}</Text>
        <Text px={4}>•</Text>
        <Text px={2}>{getTimeText(activity.times)}</Text>
      </Flex>
      {activity.weekdays && (
        <Box pt={isFullSize ? 8 : 6}>
          <DaysOfWeekDisplayLine weekdays={activity.weekdays} selectedLimeBg fullWidth />
        </Box>
      )}
    </>
  );
};

const NewActivityCard: React.FC<INewActivityCardProps> = ({
  activity,
  variant = ActivityCardVariantEnum.FULL,
}: INewActivityCardProps) => {
  const theme = useMantineTheme();
  const AdpLink = `/activity/${activity.slug}`;

  const isSoldOut = !activity.anySpotsLeft;

  const isFullSize = variant === ActivityCardVariantEnum.FULL;

  const hasSocialProof =
    activity.socialProof?.justAdded ||
    activity.socialProof?.limitedSpots ||
    activity.socialProof?.popular;

  const socialProofBadge = useMemo(() => {
    if (isSoldOut || hasSocialProof) {
      return getSocialProofBadge(activity.socialProof, isSoldOut);
    } else {
      return null;
    }
  }, [activity.socialProof, isSoldOut, hasSocialProof]);

  return (
    <div
      className={classNames({
        [classes.cardWrapperFull]: isFullSize,
        [classes.cardWrapperMin]: !isFullSize,
      })}
    >
      {socialProofBadge && (
        <Badge
          className={classNames(classes.badge, {
            [classes.limitedSpotsBadge]: socialProofBadge === SocialProofLabelEnum.limitedSpots,
            [classes.popularBadge]: socialProofBadge === SocialProofLabelEnum.popular,
            [classes.justAddedBadge]: socialProofBadge === SocialProofLabelEnum.justAdded,
          })}
        >
          {socialProofBadge}
        </Badge>
      )}
      <Link href={AdpLink} passHref title={activity.name} legacyBehavior>
        <Card
          p={0}
          h={isFullSize ? 342 : 'auto'}
          component="a"
          className={classNames(classes.card, {
            [classes.defaultCard]: !activity?.cloudinaryImageId,
            [classes.fullSizeCard]: isFullSize,
            [classes.minifiedCard]: !isFullSize,
          })}
          title={activity.name}
          aria-label={activity.name}
          onClick={() => {
            const event =
              variant === ActivityCardVariantEnum.MIN
                ? Actions.PROVIDER_CARD_ACTIVITY
                : Actions.SUPPLIER_MINIMAL_ACTIVITY_CARD;
            trackAction(event, {
              supplierId: activity.supplierId,
              activityId: activity.id,
              ...(socialProofBadge && {
                socialProofBadge,
              }),
              activityLocation: activity.isOnline ? 'online' : 'in-person',
              activityType: activity.activityType,
              activityName: activity.name,
            });
          }}
        >
          {isFullSize && (
            <div className={classes.cardHeader}>
              <ActivityImage activity={activity} isFullSize={isFullSize} />
            </div>
          )}
          <Box className={classes.cardBody}>
            {!isFullSize && (
              <Flex>
                <ActivityImage activity={activity} isFullSize={isFullSize} />
                <Flex direction={'column'} ml={12} w={'100%'}>
                  <DateInformation activity={activity} isFullSize={isFullSize} />
                </Flex>
              </Flex>
            )}
            {isFullSize && <DateInformation activity={activity} isFullSize={isFullSize} />}
            <Flex h={isFullSize ? 50 : 'auto'} my={isFullSize ? 0 : 8} align="center">
              <Text
                size="md"
                lineClamp={2}
                fw={700}
                className={classes.activityTitle}
                data-cy="activity-name"
              >
                {activity.name}
              </Text>
            </Flex>
            <Flex
              className={classNames({ [classes.fixedSectionWrapper]: isFullSize })}
              direction={isFullSize ? 'column' : 'row'}
              justify={isFullSize ? 'space-evenly' : 'space-between'}
            >
              <Flex direction="row" align="center" ml={-4} data-cy="activity-ageRange">
                <ChildFace color={theme.colors.gray[6]} size={26} />
                <Text
                  size="xs"
                  color={theme.colors.gray[6]}
                  fw={600}
                  ml={isFullSize ? 4 : 0}
                  mt={2}
                >
                  {activity.ageRange}
                </Text>
              </Flex>
              {isFullSize && <Text size="xs">{activity.priceRange}</Text>}

              {isSoldOut ? (
                <Flex
                  className={classNames(classes.bookPseudoButton, {
                    [classes.waitlistPseudoButton]: isSoldOut,
                  })}
                  justify="center"
                  align="center"
                >
                  <Text fw={700} size={'sm'} ml={4}>
                    Waitlist
                  </Text>
                </Flex>
              ) : (
                <Flex className={classes.bookPseudoButton} justify="center" align="center">
                  <Lightning size={16} weight="fill" />
                  <Text fw={700} size={'sm'} ml={4}>
                    Book
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>
        </Card>
      </Link>
    </div>
  );
};

export default NewActivityCard;
