import { createContext, useState } from 'react';

type MapMarkerContextType = {
  selectedMarker: [number, number] | null;
  setSelectedMarker: React.Dispatch<React.SetStateAction<[number, number] | null>>;
};

export const MapMarkerContext = createContext<MapMarkerContextType | undefined>(undefined);

export const MarkerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedMarker, setSelectedMarker] = useState<[number, number] | null>(null);

  return (
    <MapMarkerContext.Provider value={{ selectedMarker, setSelectedMarker }}>
      {children}
    </MapMarkerContext.Provider>
  );
};
