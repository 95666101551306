import Router from 'next/router';
import { useEffect, useState } from 'react';

const ACTIVITIES_PATH = '/activities';

export const usePageLoading = () => {
  const [isPageLoading, setIsPageLoading] = useState(false);

  useEffect(() => {
    const routeEventStart = (path: string) => {
      if (Router.pathname.startsWith(ACTIVITIES_PATH) && path.startsWith(ACTIVITIES_PATH)) {
        // If already on /activities, we are only updating filters etc so do not show loading state.
        return;
      }
      setIsPageLoading(true);
    };
    const routeEventEnd = () => {
      setIsPageLoading(false);
    };

    Router.events.on('routeChangeStart', routeEventStart);
    Router.events.on('routeChangeComplete', routeEventEnd);
    Router.events.on('routeChangeError', routeEventEnd);
    return () => {
      Router.events.off('routeChangeStart', routeEventStart);
      Router.events.off('routeChangeComplete', routeEventEnd);
      Router.events.off('routeChangeError', routeEventEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isPageLoading };
};
