import { ActionIcon, Drawer, Modal } from '@mantine/core';
import classes from './SubsSchedule.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import SubsScheduleContent from './SubsScheduleContent/SubsScheduleContent';
import { X } from '@phosphor-icons/react';
interface ISubsScheduleProps {
  opened: boolean;
  onClose(): void;
  subName: string;
  subDescription: string;
  offDates: string[];
  weekdays: string[];
  startDate: string | null;
}

const SubsSchedule: React.FC<ISubsScheduleProps> = ({
  opened,
  onClose,
  subName,
  subDescription,
  offDates,
  weekdays,
  startDate,
}: ISubsScheduleProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)', true);
  return (
    <>
      {isMobile ? (
        <Drawer
          withCloseButton={false}
          opened={opened}
          onClose={onClose}
          classNames={{
            content: classes.drawerContent,
            body: classes.drawerBody,
            header: classes.header,
          }}
          position="bottom"
          radius={0}
        >
          <ActionIcon
            title="Close"
            onClick={onClose}
            className={classes.drawerCloseButton}
            variant="filled"
          >
            <X weight="bold" size={22} />
          </ActionIcon>
          <SubsScheduleContent
            subName={subName}
            subDescription={subDescription}
            offDates={offDates}
            weekdays={weekdays}
            startDate={startDate}
          />
        </Drawer>
      ) : (
        <Modal
          opened={opened}
          onClose={onClose}
          withCloseButton={false}
          centered
          size={967}
          padding={0}
          classNames={{
            content: classes.modalContent,
            body: classes.modalBody,
          }}
        >
          <ActionIcon
            title="Close"
            onClick={onClose}
            className={classes.modalCloseButton}
            variant="filled"
          >
            <X weight="bold" size={18} />
          </ActionIcon>
          <SubsScheduleContent
            subName={subName}
            subDescription={subDescription}
            offDates={offDates}
            weekdays={weekdays}
            startDate={startDate}
          />
        </Modal>
      )}
    </>
  );
};

export default SubsSchedule;
