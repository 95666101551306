import { gql } from '@apollo/client';

const BasketApplyPromotion = gql`
  mutation basketApplyPromotion($input: BasketApplyPromotionInputType!) {
    basketApplyPromotion(input: $input) {
      id
      finalAmount
      discount
    }
  }
`;

export default BasketApplyPromotion;
