export enum IntercomEvents {
  UNPUBLISH_ACTIVITY = 'Unpublish activity',
  EMAIL_ATTENDEES = 'Emails attendees',
  EXPORT_BOOKINGS = 'Exports bookings',
  EXPORT_FINANCES = 'Exports finances',
  BOOKING_FILTER_INTERACT = 'Bookings filter interaction',
  MARK_ATTENDANCE = 'Marks attendence',
  IFRAME_GENERATOR = 'Iframe generator',
  VIEW_REVIEWS = 'View reviews',
  GENERATE_IFRAME = 'Generate iframe',
  SAVE_CONSENT_DOC = 'Saves consent document',
  SAVE_SUPPLIER_PAGE = 'Create supplier page',
  ENABLE_FURTHER_INFO_CAPTURE = 'Turn on further information capture',
  DISABLE_FURTHER_INFO_CAPTURE = 'Turn off further information capture',
  HELP_CENTRE = 'Help centre',
  CONTACT_SUPPORT = 'Contact support',
  CLOSE_ACCOUNT = 'Close account',
  STRIPE_CTA = 'Stripe CTA clicked',
  BLOCK_SINGLE_ACTIVITY = 'Block/single clicked',
  SUBSCRIPTION_ACTIVITY = 'Subscription clicked',
  CLONE_ACTIVITY = 'Clone activity',
}
