import { gql } from '@apollo/client';

const GetFranchiseSlugById = gql`
  query getFranchiseSlugById($franchiseId: UUID!) {
    marketplaceFranchise(franchiseId: $franchiseId) {
      id
      slug
    }
  }
`;

export default GetFranchiseSlugById;
