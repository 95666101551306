import { gql } from '@apollo/client';

const SupplierPageQuery = gql`
  query supplierPageQuery($supplierId: UUID!, $limit: Int, $isOnline: Boolean) {
    marketplaceSupplier(supplierId: $supplierId) {
      id
      name
      cloudinaryImageId
      additionalImageIds
      aboutUs
      website
      ageRange
      location {
        addressLine1
        addressLine2
        city
        postCode
        country
        latitude
        longitude
      }
      activitiesLocation
      facebook
      instagram
      twitter
      categories {
        name
        id
        slug
      }
    }
    marketplaceActivities(supplier: $supplierId, limit: $limit, isOnline: $isOnline) {
      items {
        id
        slug
        name
        cloudinaryImageId
        supplierName
        location
        shortLocation
        ageRange
        activityType
        bookingType
        times
        weekdays
        dateRange
        supplierId
        isOnline
        categories {
          id
          name
        }
        anySpotsLeft
        isInPast
        eligibleChildren {
          id
          fullName
        }
        longitude
        latitude
        distanceInMiles
      }
    }
  }
`;

export default SupplierPageQuery;
