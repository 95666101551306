import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import React from 'react';
import { DESKTOP_FIC_FORM_WIDTH } from '../FurtherInformationForm';

interface ProgressBarProps {
  totalQuestions: number;
  answeredQuestions: number;
  precheckout?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalQuestions,
  answeredQuestions,
  precheckout,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const startingProgress = 4;

  const progress = Math.max((answeredQuestions / totalQuestions) * 100, startingProgress);

  const getBarPosition = () => {
    if (precheckout) {
      return 'sticky';
    } else {
      return 'fixed';
    }
  };

  return (
    <div
      style={{
        position: getBarPosition(),
        top: isMobile ? 75 : 97,
        zIndex: precheckout ? 10 : 100,
        padding: '16px',
        borderRadius: theme.radius.sm,
        color: '#fff',
        backgroundColor: theme.colors.blue[8],
        opacity: 0.85,
        fontWeight: 700,
        fontSize: '18px',
        overflow: 'hidden',
        width: precheckout
          ? '100%'
          : isMobile
          ? `calc(100% - ${theme.spacing.lg} - ${theme.spacing.lg})`
          : DESKTOP_FIC_FORM_WIDTH,
        margin: 'auto',
      }}
    >
      <span>
        {answeredQuestions} / {totalQuestions} Questions Completed
      </span>

      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '12px',
          width: `${progress}%`,
          backgroundColor: theme.colors.lime[8],
          transition: 'width 0.3s ease-in-out',
          borderRadius: theme.radius.sm,
        }}
      />
    </div>
  );
};

export default ProgressBar;
