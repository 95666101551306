import { gql } from '@apollo/client';

const GetActivityBookingsByIds = gql`
  query GetActivityBookingsByIds($activityBookingIds: [UUID]) {
    activityBookingsByIds(activityBookingIds: $activityBookingIds) {
      id
      basket {
        id
      }
    }
  }
`;

export default GetActivityBookingsByIds;
