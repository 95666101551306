import { ActionIcon, Card, Divider, Group, Text } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './InactiveHomepageSearchBarDesktop.module.scss';
import { MagnifyingGlass } from '@phosphor-icons/react';

const InactiveHomepageSearchBarDesktop = () => {
  const { activeDesktopFilter, setActiveDesktopFilter } = useSavedSearchValues();

  return (
    <Card
      data-cy="inactive-search-bar"
      className={activeDesktopFilter ? classes.cardHidden : classes.card}
      p={'0.5rem'}
      onClick={() => {
        setActiveDesktopFilter(!activeDesktopFilter);
        trackAction(Actions.SEARCH_BAR_SELECT);
      }}
    >
      <Group gap={'xl'}>
        <Text className={classes.filterLabel} pl="xl">
          Location
        </Text>
        <Divider orientation="vertical" />
        <Text className={classes.filterLabel}>Age</Text>
        <Divider orientation="vertical" />
        <Text className={classes.filterLabel}>When</Text>
        <Divider orientation="vertical" />
        <Text className={classes.filterLabel}>Activity</Text>
        <ActionIcon
          variant="filled"
          radius="xl"
          size="md"
          title="Search"
          className={classes.search}
        >
          <MagnifyingGlass size={18} weight="bold" />
        </ActionIcon>
      </Group>
    </Card>
  );
};

export default InactiveHomepageSearchBarDesktop;
