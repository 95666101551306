import { gql } from '@apollo/client';

const BasketSelectAddons = gql`
  mutation basketSelectAddons($input: BasketSelectAddonsInputType!) {
    basketSelectAddons(input: $input) {
      id
      status
    }
  }
`;

export default BasketSelectAddons;
