import classNames from 'classnames';

export const ClusterPin = ({ className = '', color = 'currentColor', size = 40, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    {...props}
  >
    {props.title && <title>{props.title}</title>}
    <rect width="40" height="40" rx="20" fill={color} fillOpacity="0.2" />
    <rect x="4" y="4" width="32" height="32" rx="16" fill={color} />
    <text x="20" y="21" dominantBaseline="middle" textAnchor="middle" fill="white" fontWeight={700}>
      {props.pointCount}
    </text>
  </svg>
);
