const POSTCODE_API = 'https://api.postcodes.io/postcodes';
const POSTCODE_REGEX = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) {0,1}[0-9][A-Za-z]{2})$/;
const normalize = async (postCodeString) => {
    const postcodeData = await fetch(`${POSTCODE_API}/${postCodeString}`).then((response) => response.json());
    return postcodeData?.result?.postcode ?? postCodeString;
};
const byQuery = async (query) => {
    const postcodeData = await fetch(`${POSTCODE_API}?q=${query}`).then((response) => response.json());
    return (postcodeData.result?.map(({ postcode, latitude, longitude }) => ({
        postcode,
        latitude,
        longitude,
    })) || []);
};
const isValid = (string) => {
    return POSTCODE_REGEX.test(string);
};
const getPostcodeByCoordinates = async (mapAreaCenter) => {
    return await fetch(`https://api.postcodes.io/postcodes?lon=${mapAreaCenter.lng}&lat=${mapAreaCenter.lat}&limit=1&radius=1000`)
        .then((response) => response.json())
        .then((data) => data.result);
};
export default { byQuery, isValid, normalize, getPostcodeByCoordinates };
