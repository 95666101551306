import { gql } from '@apollo/client';

const getGuardian = gql`
  query guardian {
    guardian {
      id
      fullName
    }
  }
`;

export default getGuardian;
