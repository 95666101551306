import { FC } from 'react';
import { Anchor, Box, Breadcrumbs, Text } from '@mantine/core';
import Link from 'next/link';
import classes from './ActivityBreadcrumbs.module.scss';

interface IActivityBreadcrumbItemProps {
  title: string;
  href?: string;
  nofollow?: boolean;
}

const ActivityBreadcrumbItem: FC<IActivityBreadcrumbItemProps> = ({ title, href, nofollow }) => {
  if (href) {
    return (
      <Box className={classes.item}>
        <Link href={href} passHref legacyBehavior>
          <Anchor
            rel={nofollow ? 'nofollow' : undefined}
            className={classes.itemLink}
            title={title}
          >
            {title}
          </Anchor>
        </Link>
      </Box>
    );
  }
  return (
    <Box className={classes.item}>
      <Text className={classes.itemText} title={title}>
        {title}
      </Text>
    </Box>
  );
};

interface IActivityBreadcrumbsProps {
  items: IActivityBreadcrumbItemProps[];
}

const ActivityBreadcrumbs: FC<IActivityBreadcrumbsProps> = ({ items }) => {
  return (
    <Breadcrumbs classNames={{ root: classes.root, separator: classes.separator }}>
      {items.map((item, index) => (
        <ActivityBreadcrumbItem
          title={item.title}
          href={item.href}
          nofollow={item.nofollow}
          key={index}
        />
      ))}
    </Breadcrumbs>
  );
};

export default ActivityBreadcrumbs;
