import { DateAvailabilityType } from 'types';
import { AvailabilityEnum } from 'enums';
import { Collapse, Anchor, List, useMantineTheme, Text, Grid, Flex } from '@mantine/core';
import { useState } from 'react';
import { getDayAndMonth, getFilteredDatesWithAvailability, groupDatesByWeek } from '../utils';
import { trackAction, Actions } from 'utils/amplitude';
import { useMediaQuery } from '@mantine/hooks';
import classes from './BlockBookingDates.module.scss';
import classNames from 'classnames';
import { CaretDown, Check, ClockClockwise, X } from '@phosphor-icons/react';

interface IBlockBookingDates {
  blockDates: DateAvailabilityType[];
  startTime: string;
  endTime: string;
  alwaysOpen?: boolean;
  hidePastDates?: boolean;
  showDatesByWeek?: boolean;
  hideTimes?: boolean;
}

const BlockBookingDates: React.FC<IBlockBookingDates> = ({
  blockDates,
  startTime,
  endTime,
  alwaysOpen,
  hidePastDates,
  showDatesByWeek = false,
  hideTimes = false,
}) => {
  const [open, setOpen] = useState(alwaysOpen || false);
  const theme = useMantineTheme();
  const label = open ? 'View less' : 'View remaining dates';
  const isMobile = useMediaQuery('(max-width: 480px)', true);
  const datesWithAvailability = getFilteredDatesWithAvailability(blockDates || []);

  const datesToDisplay = hidePastDates
    ? datesWithAvailability.filter((date) => date.availability !== AvailabilityEnum.IN_PAST)
    : datesWithAvailability;

  const getSessionInstances = (sessionBlockDates: DateAvailabilityType[]) => {
    const filtered = sessionBlockDates.filter((date) => !date.isInPast && date.spotsLeft > 0);

    const sessionInstances = filtered.length;

    return sessionInstances > 0 ? ` (${sessionInstances})` : '';
  };

  const showDate = (blockDateStart: string, blockDateEnd: string) => {
    if (blockDateStart !== startTime || blockDateEnd !== endTime) {
      return true;
    }
    return false;
  };

  const getDateCols = (dateString: string, availability?: AvailabilityEnum) => {
    const dateFormat = isMobile ? 'ddd D MMM' : 'ddd D MMMM';
    const [dayOfWeek, dayOfMonth, month] = getDayAndMonth(dateString, dateFormat).split(' ');

    return (
      <>
        <Grid.Col
          className={classNames(classes.gridCol, {
            [classes.pastDate]: availability === AvailabilityEnum.IN_PAST,
          })}
          span={{ base: 3 }}
        >
          {dayOfWeek}
        </Grid.Col>
        <Grid.Col
          className={classNames(classes.dayOfMonth, classes.gridCol, {
            [classes.pastDate]: availability === AvailabilityEnum.IN_PAST,
          })}
          span={isMobile ? 2 : 3}
        >
          {dayOfMonth}
        </Grid.Col>
        <Grid.Col
          className={classNames({
            [classes.pastDate]: availability === AvailabilityEnum.IN_PAST,
          })}
          span={{ base: 2 }}
          miw={'fit-content'}
          ml={isMobile ? 6 : 0}
        >
          {month}
        </Grid.Col>
      </>
    );
  };

  const getDateListItem = (blockDate: DateAvailabilityType) => (
    <List.Item
      key={blockDate.date}
      aria-label={`${blockDate.date} ${
        showDate(blockDate.startTime, blockDate.endTime) && !hideTimes
          ? `${blockDate.startTime} - ${blockDate.endTime}`
          : ''
      }`}
      className={classNames(classes.listItem, {
        [classes.past]: blockDate.availability === AvailabilityEnum.IN_PAST,
        [classes.soldOut]: blockDate.availability === AvailabilityEnum.SOLD_OUT,
        [classes.available]: blockDate.availability === AvailabilityEnum.AVAILABLE,
      })}
    >
      <Grid columns={24} className={classes.dateGridRoot} gutter={0}>
        <Grid.Col span={isMobile ? 2 : 3} className={classes.gridCol}>
          {blockDate.availability === AvailabilityEnum.SOLD_OUT && (
            <X weight="bold" color={theme.colors.red[6]} size={15} />
          )}
          {blockDate.availability === AvailabilityEnum.AVAILABLE && (
            <Check size={15} weight="bold" color={theme.colors.lime[6]} />
          )}
        </Grid.Col>

        {getDateCols(blockDate.date, blockDate.availability)}

        <Grid.Col span={{ base: 9 }} offset={4} className={classes.gridCol}>
          <div className={classes.dateAndTime}>
            {showDate(blockDate.startTime, blockDate.endTime) && !hideTimes && (
              <Flex className={classes.timeVaryBadge}>
                <Text>
                  {blockDate.startTime} - {blockDate.endTime}
                </Text>
                <ClockClockwise
                  className={classes.clockIcon}
                  size={15}
                  color={theme.colors.blue[8]}
                />
              </Flex>
            )}
          </div>
        </Grid.Col>
      </Grid>
    </List.Item>
  );

  if (showDatesByWeek) {
    const datesGroupedByWeek = groupDatesByWeek(datesToDisplay);
    return (
      <>
        <Collapse in={open} className={classes.wrapper}>
          {datesGroupedByWeek.map((week, index) => (
            <List
              key={`group-${week[0].date}`}
              listStyleType="none"
              classNames={{ root: classes.listRoot, itemWrapper: classes.itemWrapper }}
            >
              <List.Item className={classes.weekLabel} key={JSON.stringify(week)}>
                Week {index + 1}
              </List.Item>
              {week.map(getDateListItem)}
            </List>
          ))}
        </Collapse>
        {!alwaysOpen && (
          <Anchor
            component="button"
            onClick={() => {
              setOpen((o) => !o);
              trackAction(Actions.VIEW_DATES_DETAILS);
            }}
            className={classNames(classes.viewButton, { [classes.open]: open })}
            w={'100%'}
          >
            <Flex align="center" justify={'space-between'}>
              <Text fw={600} size="sm">
                {label} {getSessionInstances(blockDates)}
              </Text>

              <CaretDown size={24} weight="bold" />
            </Flex>
          </Anchor>
        )}
      </>
    );
  }

  return (
    <>
      <Collapse in={open} className={classes.wrapper}>
        <List
          listStyleType="none"
          classNames={{ root: classes.listRoot, itemWrapper: classes.itemWrapper }}
        >
          {datesToDisplay.map(getDateListItem)}
        </List>
      </Collapse>
      {!alwaysOpen && (
        <Anchor
          component="button"
          onClick={() => {
            setOpen((o) => !o);
            trackAction(Actions.VIEW_DATES_DETAILS);
          }}
          className={classNames(classes.viewButton, { [classes.open]: open })}
          w="100%"
        >
          <Flex align="center" justify={'space-between'}>
            <Text fw={600} size="sm">
              {label} {getSessionInstances(blockDates)}{' '}
            </Text>
            <CaretDown size={24} weight="bold" />
          </Flex>
        </Anchor>
      )}
    </>
  );
};

export default BlockBookingDates;
