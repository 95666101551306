import classNames from 'classnames';

export const SubscriptionsFill = ({
  className = '',
  color = 'currentColor',
  size = 24,
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    // Workaround as SVG as extra whitespace.
    style={{
      marginLeft: 0,
      marginRight: 0,
    }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
      <path
        fill={color}
        d="M17.555 18.496a8.623 8.623 0 01-5.558 2.047c-4.75 0-8.64-3.866-8.64-8.64v-.783h1.44a.628.628 0 00.556-.329.676.676 0 00-.026-.657L3.231 6.876c-.228-.38-.834-.38-1.087 0L.098 10.109a.677.677 0 00-.025.657.628.628 0 00.556.329h1.44v.808c0 5.457 4.446 9.903 9.902 9.903 2.35 0 4.624-.834 6.392-2.35a.642.642 0 00.076-.91c-.202-.252-.607-.277-.884-.05zM23.365 12.711c.227 0 .455.127.556.329a.848.848 0 01.025.657l-2.097 3.258c-.1.203-.328.304-.53.304s-.404-.127-.53-.304l-2.098-3.258a.677.677 0 01-.025-.657.628.628 0 01.556-.329h1.44v-.783c0-4.774-3.89-8.64-8.64-8.64a8.622 8.622 0 00-5.558 2.047.642.642 0 01-.91-.076.642.642 0 01.077-.91A9.827 9.827 0 0112.022 2c5.457 0 9.903 4.446 9.903 9.903v.808h1.44z"
      ></path>
      <path
        fill={color}
        d="M17.2 11.903a5.197 5.197 0 00-5.203-5.204 5.197 5.197 0 00-5.204 5.204 5.197 5.197 0 005.204 5.204 5.197 5.197 0 005.204-5.204zm-9.119 0a3.919 3.919 0 013.916-3.916c2.147 0 3.915 1.769 3.915 3.916 0 2.147-1.768 3.916-3.915 3.916a3.919 3.919 0 01-3.916-3.916z"
      ></path>
      <path
        fill={color}
        fillRule="evenodd"
        d="M17 12a5 5 0 11-10 0 5 5 0 0110 0zm-7.655-.177a.55.55 0 01.776.051l1.065 1.217 2.624-2.625a.55.55 0 11.778.778l-2.998 2.998a.553.553 0 01-.11.085.55.55 0 01-.743-.078l-1.443-1.65a.55.55 0 01.051-.776z"
        clipRule="evenodd"
      ></path>
  </svg>
);
