import { CloudinaryImage } from 'components/ui';
import { Flex, Stack, Image } from '@mantine/core';
import classes from './ImageCollage.module.scss';
import classNames from 'classnames';

interface IImageCollageProps {
  imageIds: (string | undefined)[];
  altText: string;
}

const ImageCollage: React.FC<IImageCollageProps> = ({ imageIds, altText }) => {
  const images = imageIds.filter(Boolean) as string[];
  const isSingleImage = images.length === 1;

  return (
    <Flex className={classes.container}>
      <div
        className={classNames(classes.image1, {
          [classes.singleImage]: isSingleImage,
        })}
      >
        {images[0] ? (
          <CloudinaryImage id={images[0]} alt={altText} height={432} width={768} />
        ) : (
          <Image
            src="../../pebble_placeholder_image.png"
            width="100%"
            height={186}
            fit="cover"
            alt="Pattern with shapes and lines"
          />
        )}
      </div>
      {images[1] && (
        <Stack className={classes.imageStack}>
          <div>
            <CloudinaryImage
              id={images[1]}
              alt={altText}
              width={92}
              height={images[2] ? 96 : 196}
            />
          </div>
          {images[2] && (
            <div>
              <CloudinaryImage id={images[2]} alt={altText} width={92} height={96} />
            </div>
          )}
        </Stack>
      )}
    </Flex>
  );
};

export default ImageCollage;
