import classNames from 'classnames';

export const Details = ({ className = '', color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3588 17.758C21.7389 17.758 22.0874 17.7874 22.4677 17.8465V8.20239H16.7969C16.3218 8.20239 15.9732 7.84854 15.9732 7.43563L15.973 2.12695H4.69473C4.21961 2.12695 3.87109 2.4808 3.87109 2.89371V23.6271C3.87109 24.0694 4.25119 24.3938 4.69473 24.3938H14.9909C14.9593 24.1873 14.9593 23.9809 14.9593 23.745C14.9275 20.4715 17.8105 17.758 21.3588 17.758L21.3588 17.758ZM8.46477 6.63929H12.5199C12.995 6.63929 13.3435 6.99314 13.3435 7.40605C13.3435 7.84836 12.9634 8.17281 12.5199 8.17281L8.46477 8.17301C7.98966 8.17301 7.64114 7.81916 7.64114 7.40626C7.64114 6.99316 8.02123 6.63934 8.46477 6.63934V6.63929ZM8.46477 10.7682H17.8422C18.3174 10.7682 18.6659 11.122 18.6659 11.5349C18.6659 11.948 18.3174 12.3018 17.8422 12.3018H8.46477C7.98966 12.3018 7.64114 11.948 7.64114 11.5351C7.64114 11.1222 8.02123 10.7682 8.46477 10.7682ZM8.46477 14.8972H17.8422C18.3174 14.8972 18.6659 15.2511 18.6659 15.664C18.6659 16.0769 18.3174 16.4309 17.8422 16.4309H8.46477C7.98966 16.4309 7.64114 16.0771 7.64114 15.6642C7.64114 15.2511 8.02123 14.8972 8.46477 14.8972ZM12.8684 20.5598H8.46477C7.98966 20.5598 7.64114 20.206 7.64114 19.7931C7.64114 19.3802 8.02123 19.0263 8.46477 19.0263L12.8684 19.0261C13.3435 19.0261 13.692 19.38 13.692 19.7929C13.692 20.206 13.3435 20.5598 12.8684 20.5598V20.5598Z"
      fill={color}
    />
    <path
      d="M22.2186 6.50379L17.7834 2.37492C17.7516 2.31588 17.6884 2.28646 17.625 2.25684V6.68074H22.3771C22.3137 6.59227 22.2821 6.53344 22.2186 6.50381L22.2186 6.50379Z"
      fill={color}
    />
    <path
      d="M27.8919 28.4633L25.3258 26.2513C25.8328 25.5435 26.1495 24.6882 26.1495 23.7444C26.1495 21.2965 24.0269 19.291 21.3657 19.291C18.7363 19.291 16.582 21.267 16.582 23.7444C16.582 26.2217 18.7046 28.1977 21.3657 28.1977C22.3478 28.1977 23.2667 27.9027 24.0587 27.4309L26.4347 29.8198C26.4663 29.8492 26.4663 29.8492 26.4982 29.8788C26.9101 30.2327 27.5753 30.2033 27.9556 29.8198C28.3355 29.4365 28.3036 28.8173 27.8919 28.4632L27.8919 28.4633ZM23.3932 24.5409H22.1893V25.6617C22.1893 26.0746 21.8092 26.4285 21.3657 26.4285C20.9222 26.4285 20.542 26.0746 20.542 25.6617V24.5409H19.3381C18.863 24.5409 18.5145 24.1871 18.5145 23.7742C18.5145 23.3613 18.8946 23.0074 19.3381 23.0074H20.542V21.8866C20.542 21.4737 20.9221 21.1199 21.3657 21.1199C21.8092 21.1199 22.1893 21.4737 22.1893 21.8866V23.0074H23.3932C23.8684 23.0074 24.2169 23.3613 24.2169 23.7742C24.2169 24.1868 23.8684 24.5409 23.3932 24.5409Z"
      fill={color}
    />
  </svg>
);
