import { Child, Product } from 'types';
import {
  PreCheckoutBasket,
  PreCheckoutBasketAttendee,
  PreCheckoutBasketTicket,
  CustomQuestionItem,
} from 'interfaces';
import { StepperEnum } from 'enums';
import ageInRange from 'utils/ageInRange';
import { MutableRefObject } from 'react';

type Labels = {
  [key in StepperEnum]?: string;
};

export const getPreviousLabel = (
  activeStep: StepperEnum,
  hasAddOns: boolean,
  preCheckoutFormRequired: boolean,
  isBlockTrialCheckout: boolean,
): string => {
  const labels: Labels = {
    [StepperEnum.CONFIRM_ATTENDEES]: 'Back to Activity Details',
    [StepperEnum.ANSWER_QUESTIONS]: 'Back to Attendees',
    [StepperEnum.SELECT_ADDONS]: preCheckoutFormRequired
      ? 'Back to Questions'
      : 'Back to Attendees',
  };

  if (labels[activeStep]) {
    return labels[activeStep] as string;
  }

  if (hasAddOns && !isBlockTrialCheckout) {
    return 'Back to Add-Ons';
  }

  return preCheckoutFormRequired ? 'Back to Questions' : 'Back to Attendees';
};

export const validateTelephone = (value: string) => {
  const regex = new RegExp(/(^(?=[0-9]{11}$)(0)\d+)|(^(?=[0-9]{10}$)(7)\d+)/);
  return regex.test(value);
};

export const getExistingTelephone = (telephone?: string): string => {
  if (!telephone) {
    return '';
  }
  if (telephone.startsWith('+44')) {
    return telephone.slice(3);
  }
  return telephone;
};

export const isEligibleChild = (childId?: string | null, registeredAttendees?: Child[]) => {
  if (registeredAttendees && childId) {
    return registeredAttendees.some((child) => child.id === childId);
  }
  return false;
};

export const getTicketsWithAttendees = (
  basket: PreCheckoutBasket,
  isSubscription: boolean,
  registeredAttendees?: Child[],
) => {
  const ticketQuantity = basket.tickets.length;
  return basket.tickets.map((ticket) => {
    const subscriptionTrialSelected = basket?.tickets[0].subscriptionTrialSelected || false;

    const existingAttendees: PreCheckoutBasketAttendee[] = ticket.attendees.map((attendee) => {
      if (isEligibleChild(attendee.id, registeredAttendees)) {
        return {
          id: attendee.id,
        };
      }

      return {
        id: attendee.id,
      };
    });

    if (ticketQuantity === existingAttendees.length) {
      return {
        ...ticket,
        attendees: existingAttendees,
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    } else {
      const attendeesArray = new Array(ticket.ticketCapacity - existingAttendees.length).fill({
        id: null,
      });

      return {
        ...ticket,
        attendees: existingAttendees.concat(attendeesArray),
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    }
  });
};

export const filterUniqueByKey = <T,>(
  items: T[],
  keyExtractor: (item: T) => string | number,
): T[] => {
  const uniqueKeysSet = new Set<string | number>();

  return items.filter((item) => {
    const key = keyExtractor(item);

    if (uniqueKeysSet.has(key)) {
      return false;
    }

    uniqueKeysSet.add(key);
    return true;
  });
};

export const findAttendeesOutOfAgeRange = (
  tickets: PreCheckoutBasketTicket[],
  ageMonthsStart: number,
  ageMonthsEnd: number,
  registeredAttendees: Child[] = [],
) => {
  const allAttendees = tickets.flatMap((ticket) => {
    return ticket.attendees.map((attendee) => {
      const matchingAttendee = registeredAttendees.find((_a) => _a.id === attendee.id);
      if (matchingAttendee && !matchingAttendee.isAdult) {
        return matchingAttendee;
      }

      if (!attendee.isAdult) {
        return attendee;
      }

      return undefined;
    });
  });

  const uniqueAttendees = filterUniqueByKey(allAttendees as Child[], (attendee) => attendee?.id);

  return uniqueAttendees.filter(
    (attendee) =>
      attendee?.birthDate && !ageInRange(attendee.birthDate, ageMonthsStart, ageMonthsEnd),
  );
};

export const calculateAgeInYearsAndMonths = (birthDate?: string) => {
  if (!birthDate) return;
  const today = new Date();
  const birthDateObj = new Date(birthDate);

  const yearsDiff = today.getFullYear() - birthDateObj.getFullYear();
  const monthsDiff = today.getMonth() - birthDateObj.getMonth();
  const daysDiff = today.getDate() - birthDateObj.getDate();
  // Subtract one month if not reached the birth day yet this month
  const monthsDiffToDate = daysDiff < 0 ? monthsDiff - 1 : monthsDiff;

  if (monthsDiffToDate < 0) {
    return `${yearsDiff - 1}yrs ${monthsDiffToDate + 12}mths`;
  }

  return `${yearsDiff}yrs ${monthsDiffToDate}mths`;
};

export const formatTelephoneValue = (telephoneNumber: string) => {
  const regex = new RegExp(/^(?=[0-9]{10}$)(7)\d+/);
  const telephonePrefix = '+44';

  if (regex.test(telephoneNumber)) {
    return telephonePrefix + telephoneNumber;
  } else {
    const telephoneNumberWithoutZero = telephoneNumber.substring(1);
    return telephonePrefix + telephoneNumberWithoutZero;
  }
};

export const getSteps = (
  hasAddOns: boolean,
  preCheckoutFormRequired: boolean,
  isBlockTrialCheckout: boolean,
) => {
  const steps = [StepperEnum.CONFIRM_ATTENDEES];
  if (preCheckoutFormRequired) {
    steps.push(StepperEnum.ANSWER_QUESTIONS);
  }
  if (hasAddOns && !isBlockTrialCheckout) {
    steps.push(StepperEnum.SELECT_ADDONS);
  }
  steps.push(StepperEnum.REVIEW);
  return steps;
};

export const addAttendeesToTheForm = (basket: PreCheckoutBasket) => {
  const getAddedAttendee = (question: CustomQuestionItem) => {
    const attendeesAnswerObjects = basket.tickets.flatMap((ticket: PreCheckoutBasketTicket) => {
      return ticket.attendees.map((attendee) => {
        const previousAttendee = question.attendeeAnswers?.find(
          (attendeeAnswer) => attendeeAnswer.attendeeId === attendee?.id,
        );
        return {
          attendeeId: attendee?.id || '',
          fullName: attendee.fullName || '',
          answer: previousAttendee?.answer || previousAttendee?.previousAnswer || '',
        };
      });
    });

    return filterUniqueByKey(attendeesAnswerObjects, (attendee) => attendee.attendeeId);
  };

  return basket.questionAnswers.map((questionItem: CustomQuestionItem) => {
    return {
      ...questionItem,
      bookerAnswer: questionItem.question.isPerAttendee
        ? null
        : questionItem.bookerAnswer || questionItem.previousBookerAnswer || '',
      attendeeAnswers: !questionItem.question.isPerAttendee ? null : getAddedAttendee(questionItem),
    };
  });
};

export const executeScroll = (stepperRef?: MutableRefObject<HTMLDivElement | null>) =>
  stepperRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const getBookedDatesList = (
  product?: Product,
  basketTickets?: PreCheckoutBasketTicket[],
) => {
  if (!product) {
    return null;
  }
  const isBlockBooking = product.allBlockDates && product.allBlockDates.length > 0;
  const getSelectedSingleSessionDate = () => {
    return [
      {
        dayOfTheWeek: product.weekdays,
        startTime: product.startTime,
        endTime: product.endTime,
        spotsLeft: product.spotsLeft,
        date: product.singleSessionDate || '',
        isInPast: false,
      },
    ];
  };

  const getSelectedMultipleSessionDates = () => {
    if (!product.multipleSessionDates) return [];
    const sessionDates = product.multipleSessionDates.flatMap((date) => {
      return [
        {
          dayOfTheWeek: product.weekdays,
          startTime: product.startTime,
          endTime: product.endTime,
          spotsLeft: product.spotsLeft,
          date: date || '',
          isInPast: false,
        },
      ];
    });

    return sessionDates.filter((date) => !date.isInPast || date.spotsLeft !== 0);
  };

  if (isBlockBooking) {
    return product.allBlockDates?.filter((date) => !date.isInPast);
  }
  return basketTickets ? getSelectedMultipleSessionDates() : getSelectedSingleSessionDate();
};

export const getBookedSubscriptionInfo = (product?: Product) => {
  if (!product || !product.subscriptionNextSessionDate) {
    return null;
  }
  return {
    date: product.subscriptionNextSessionDate,
    startTime: product.startTime,
    endTime: product.endTime,
  };
};
