import { ActionIcon, Box, Flex, Modal, Stack, Text, useMantineTheme } from '@mantine/core';
import { PebbleButtonsEnum, SocialProofLabelEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './LeavingModal.module.scss';
import { X } from '@phosphor-icons/react';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleButtonSet } from 'components/ui';
interface ILeavingModalProps {
  opened: boolean;
  onClose(): void;
  onLeaveClick: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  socialProofBadge: SocialProofLabelEnum | null;
  hasOneSpotLeft: boolean;
  preCheckoutPage?: boolean;
}

const LeavingModal: React.FC<ILeavingModalProps> = ({
  opened,
  onClose,
  onLeaveClick,
  onClick,
  socialProofBadge,
  hasOneSpotLeft,
  preCheckoutPage,
}: ILeavingModalProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const getModalMessage = () => {
    if (hasOneSpotLeft) {
      return 'One or more of your selected sessions has just 1 spot left. Book now to secure your place!';
    } else if (socialProofBadge === SocialProofLabelEnum.limitedSpots) {
      return "This activity has limited spots left. Book now and secure yours before it's too late!";
    } else if (socialProofBadge === SocialProofLabelEnum.popular) {
      return 'This activity is popular with others! Hurry and secure your place now!';
    } else {
      return '';
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        centered
        size={640}
        padding={0}
        classNames={{
          content: classes.modalContent,
          body: classes.modalBody,
        }}
      >
        <Flex justify="flex-end">
          <ActionIcon
            title="Close"
            onClick={onClose}
            className={classes.closeButton}
            variant="filled"
          >
            <X size={12} weight="bold" color={theme.colors.gray[6]} />
          </ActionIcon>
        </Flex>
        <Stack pb="xl" px="lg" className={classes.contentWrapper}>
          <Text className={classes.modalTitle}>Are you sure you want to leave?</Text>
          <Box>
            <Text fw={600} pt="lg">
              {preCheckoutPage
                ? `Your ticket selection and basket items will be lost!`
                : `The items in your basket will be removed!`}
            </Text>

            {getModalMessage() && (
              <Text maw={isMobile ? '100%' : '80%'} mx="auto" pt="lg">
                {getModalMessage()}
              </Text>
            )}
          </Box>
          <Flex gap="md" direction={isMobile ? 'column' : 'row'} pt="lg">
            <PebbleButtonSet
              btnVariant={PebbleButtonsEnum.SECONDARY}
              size="md"
              onClick={() => {
                onLeaveClick();
                const trackingAction = preCheckoutPage
                  ? Actions.LEAVING_MODAL_BACK_PRE_CHECKOUT
                  : Actions.LEAVING_MODAL_BACK_ADP;
                trackAction(trackingAction);
              }}
              fullWidth={isMobile}
            >
              Leave anyway
            </PebbleButtonSet>
            <PebbleButtonSet
              btnVariant={PebbleButtonsEnum.PRIMARY}
              size="md"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClose();
                onClick?.(e);
                const trackingAction = preCheckoutPage
                  ? Actions.LEAVING_MODAL_CONTINUE_PRE_CHECKOUT
                  : Actions.LEAVING_MODAL_CONTINUE_ADP;
                trackAction(trackingAction);
              }}
              fullWidth={isMobile}
            >
              {preCheckoutPage ? 'Return to checkout' : 'Continue booking'}
            </PebbleButtonSet>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
};

export default LeavingModal;
