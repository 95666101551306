import { Group, Text } from '@mantine/core';
import { DaysOfWeekEnum } from 'enums';
import classes from './DaysOfWeekDisplayLine.module.scss';
import classNames from 'classnames';

interface IDaysOfWeekDisplayLineProps {
  weekdays: string[];
  selectedLimeBg?: boolean;
  fullWidth?: boolean;
  enlarged?: boolean;
}

const DaysOfWeekDisplayLine: React.FC<IDaysOfWeekDisplayLineProps> = ({
  weekdays,
  selectedLimeBg,
  fullWidth,
  enlarged,
}: IDaysOfWeekDisplayLineProps) => {
  const getWeekdayBtnLabel = (item: string): string => {
    return item.substring(0, 2);
  };

  const isWeekdaySelected = (item: string) =>
    weekdays.includes(item.charAt(0).toUpperCase() + item.slice(1));

  return (
    <Group
      className={classNames({ [classes.fullWidth]: fullWidth })}
      gap={fullWidth ? 0 : 'sm'}
      w={'100%'}
    >
      {Object.keys(DaysOfWeekEnum)
        .slice(0, 7)
        .map((item, index) => {
          return (
            <Text
              key={index}
              className={classNames(classes.daysOfWeek, {
                [classes.dayOfWeekSelected]: isWeekdaySelected(item),
                [classes.limeBg]: selectedLimeBg && isWeekdaySelected(item),
                [classes.enlarged]: enlarged,
              })}
            >
              {getWeekdayBtnLabel(item)}
            </Text>
          );
        })}
    </Group>
  );
};

export default DaysOfWeekDisplayLine;
