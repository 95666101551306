import classNames from 'classnames';

export const GreenRectangle = ({
  className = 'icon',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 45 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="45" height="10" rx="5" fill="#9DE551" />
  </svg>
);
