import { Ref, useEffect, useMemo, useRef, useState } from 'react';
import { useMantineTheme } from '@mantine/core';
import MapGL, { MapRef, Marker, NavigationControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import bbox from '@turf/bbox';
import { points as getPoints } from '@turf/helpers';
import { Activity, ActivitySearchResult } from 'types';
import { ClusterPin } from '@icons';
import { useSupercluster } from 'hooks/useSupercluster';
import { MapPin } from '@phosphor-icons/react';

type ComputedMapValues = {
  zoom: number;
  bounds: mapboxgl.LngLatBounds;
  center: {
    lat: number;
    lng: number;
  };
};

interface ISearchMap {
  markers: Record<string, Activity[] | ActivitySearchResult[]>;
  onMarkerClick?: (key: string, activities: Activity[] | ActivitySearchResult[]) => void;
  onDragEnd?: () => void;
  onChange?: (value: ComputedMapValues | null) => void;
  onClick?: () => void;
  selectedPinKey?: string | null;
  centerLat: number;
  centerLng: number;
  zoom: number;
  showZoom?: boolean;
}

const SearchMap: React.FC<ISearchMap> = ({
  markers,
  onMarkerClick,
  onDragEnd,
  onChange,
  onClick,
  centerLat,
  centerLng,
  zoom,
  selectedPinKey,
  showZoom,
}) => {
  const theme = useMantineTheme();
  const mapRef: Ref<MapRef> = useRef(null);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [viewport, setViewport] = useState({
    latitude: centerLat,
    longitude: centerLng,
    zoom,
  });
  const uniqueCoordinates = useMemo(() => {
    const keys = Object.keys(markers);
    if (keys.length === 0) {
      return undefined;
    }
    return keys.map((key) => key.split(':').map(Number));
  }, [markers]);

  const points = useMemo(() => {
    if (!uniqueCoordinates) {
      return undefined;
    }
    return getPoints(uniqueCoordinates);
  }, [uniqueCoordinates]);

  const bounds = useMemo(() => {
    if (!points) {
      return undefined;
    }
    return bbox(points);
  }, [points]);

  useEffect(() => {
    if (!bounds || !mapLoaded) {
      return;
    }
    const [minLng, minLat, maxLng, maxLat] = bounds;
    mapRef?.current?.fitBounds(
      [
        [minLat, minLng],
        [maxLat, maxLng],
      ],
      { duration: 500, padding: 100 },
    );
  }, [bounds, mapLoaded]);

  const { clusters, supercluster } = useSupercluster({
    points: points?.features || [],
    bounds: bounds,
    zoom: viewport.zoom || 14,
  });

  const Markers = useMemo(() => {
    if (clusters.length > 0) {
      return clusters.map((cluster) => {
        const [latitude, longitude] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;
        const key = `${latitude}:${longitude}`;
        const isSelected = selectedPinKey === key;
        if (isCluster) {
          return (
            <Marker
              latitude={latitude}
              longitude={longitude}
              key={cluster.id}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(Number(cluster.id)),
                  20,
                );
                mapRef?.current?.flyTo({
                  center: [longitude, latitude],
                  zoom: expansionZoom,
                  duration: 500,
                });
              }}
            >
              <ClusterPin pointCount={pointCount} color={theme.colors.pink[5]} />
            </Marker>
          );
        }
        return (
          <Marker
            latitude={latitude}
            longitude={longitude}
            key={key}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              onMarkerClick?.(key, markers[key]);
            }}
          >
            {isSelected ? (
              <MapPin size={42} color={theme.colors.blue[5]} weight="fill" />
            ) : (
              <MapPin size={36} color={theme.colors.pink[5]} weight="fill" />
            )}
          </Marker>
        );
      });
    }
    if (uniqueCoordinates?.length || 0 > 0) {
      return uniqueCoordinates?.map(([lat, lng]) => {
        const key = `${lat}:${lng}`;
        const isSelected = selectedPinKey === key;
        return (
          <Marker
            latitude={lat}
            longitude={lng}
            key={key}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              onMarkerClick?.(key, markers[key]);
            }}
          >
            {isSelected ? (
              <MapPin size={42} color={theme.colors.blue[5]} weight="fill" />
            ) : (
              <MapPin size={36} color={theme.colors.pink[5]} weight="fill" />
            )}
          </Marker>
        );
      });
    }
  }, [markers, onMarkerClick, theme, clusters, supercluster, selectedPinKey, uniqueCoordinates]);

  return (
    <MapGL
      ref={mapRef}
      style={{
        height: '100%',
        width: '100%',
      }}
      mapStyle={process.env.NEXT_PUBLIC_MAPBOX_STYLES_URL}
      initialViewState={viewport}
      // Restrict scrolling to UK.
      maxBounds={[
        [-7.5372, 49.736463],
        [2.099044, 61.326313],
      ]}
      maxZoom={20}
      minZoom={5}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_KEY}
      onDragEnd={() => onDragEnd?.()}
      onLoad={() => setMapLoaded(true)}
      onMove={(e) => {
        setViewport({
          ...e.viewState,
        });
        const data = {
          bounds: e.target.getBounds(),
          center: {
            lat: e.viewState.latitude,
            lng: e.viewState.longitude,
          },
          zoom: e.viewState.zoom,
        };
        onChange?.(data);
      }}
      onClick={() => onClick?.()}
      reuseMaps
    >
      {Markers}
      {showZoom && <NavigationControl />}
    </MapGL>
  );
};

export default SearchMap;
