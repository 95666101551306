import { ActivitySearchResult } from 'types';
import { ActivityBookingTypeEnum } from 'enums';

export const oneOrMoreBookableActivities = (activities?: ActivitySearchResult[] | null) => {
  if (!activities) {
    return false;
  }

  return activities.some((activity) => activity.bookingType === ActivityBookingTypeEnum.INSTANT);
};
