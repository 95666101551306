import { DocumentNode } from 'graphql';
import { Category } from 'types';
import { CategoriesQuery } from 'graphql/queries';

enum Errors {
  NO_CATEGORY_MATCH = "Sorry, that category isn't recognised.",
}

type GetCategoriesResponse = {
  error?: string;
  categories?: Category[];
};

export const getCategories = async (gql: {
  query: (input: { query: DocumentNode }) => Promise<{ data: { categories: [Category] } }>;
}): Promise<GetCategoriesResponse> => {
  try {
    const { data } = await gql.query({
      query: CategoriesQuery,
    });

    return {
      categories: data.categories,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    return {
      error: e.message,
    };
  }
};

type MatchCategoryResponse = {
  categoryId?: string;
  categoryName?: string;
  error?: Errors;
};

export const matchCategoryFromURL = (
  categorySlug: string,
  categoryList: Category[],
): MatchCategoryResponse => {
  const category = categoryList.find(
    ({ slug }: { slug: string }) => slug.toLowerCase() === categorySlug.toLowerCase(),
  );

  if (!category) {
    return {
      error: Errors.NO_CATEGORY_MATCH,
    };
  }

  return {
    categoryId: category.id,
    categoryName: category.name,
  };
};

export const getCategoryParams = (
  categorySlug: string,
  categoryList: Category[],
): Record<string, string[]> => {
  if (typeof categorySlug !== 'string') return {};

  const category = categoryList.find(
    ({ slug }: { slug: string }) => slug.toLowerCase() === categorySlug.toLowerCase(),
  );
  if (category) {
    return {
      categories: [category.id],
    };
  }
  return {};
};
