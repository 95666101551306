import { gql } from '@apollo/client';

const ActivitiesQuery = gql`
  query {
    categories {
      id
      name
      slug
    }
  }
`;

export default ActivitiesQuery;
