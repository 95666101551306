// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Image, Transformation } from 'cloudinary-react';
import { useState } from 'react';
import { Image as MantineImage } from '@mantine/core';
import classes from './CloudinaryImage.module.scss';
import classNames from 'classnames';

interface ICloudinaryImage {
  id: string;
  alt: string;
  width?: number;
  height?: number;
  isActivityDetails?: boolean;
  showGradient?: boolean;
}

const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME || '';

const CloudinaryImage: React.FC<ICloudinaryImage> = ({
  id,
  width,
  height,
  alt,
  isActivityDetails = false,
  showGradient = true,
}) => {
  const [imageError, setImageError] = useState(false);

  if (imageError || !id) {
    return (
      <MantineImage
        src={
          isActivityDetails
            ? '../../../../pebble_placeholder_activity_details.png'
            : '../../pebble_placeholder_image.png'
        }
        width="100%"
        height={height}
        fit="cover"
        alt="Pattern with shapes and lines"
      />
    );
  }
  return (
    <div className={classes.imageWrapper}>
      <Image
        // Onclick needed to prevent TypeError: _a2.clickAllowed is not a function when clicking on activity details image carousel
        onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (isActivityDetails) {
            e.stopPropagation();
          }
        }}
        onError={() => {
          setImageError(true);
        }}
        cloudName={cloudName}
        secure
        publicId={`${id}.jpg`}
        alt={alt}
        width={'100%'}
      >
        {(width || height) && (
          <Transformation width={width} height={height} crop="fill" quality="auto:best" />
        )}
      </Image>

      {showGradient && (
        <div
          className={classNames(classes.gradient, {
            [classes.borderRadiusForListings]: !isActivityDetails,
          })}
        ></div>
      )}
    </div>
  );
};

export default CloudinaryImage;
