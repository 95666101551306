import classNames from 'classnames';

export const Yoga = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4571 5.51249C15.4571 6.89847 14.3336 8.02205 12.9475 8.02205C11.5615 8.02205 10.438 6.89847 10.438 5.51249C10.438 4.1265 11.5615 3.00293 12.9475 3.00293C14.3336 3.00293 15.4571 4.1265 15.4571 5.51249Z"
      fill="white"
    />
    <path
      d="M12.646 8.8584H10.6162C10.0041 8.8584 9.44088 9.19267 9.14768 9.72988L7.58558 12.5917C7.43923 12.8599 7.25683 13.1067 7.0435 13.3253L4.85616 15.5672C4.40248 16.0321 4.38271 16.7594 4.79069 17.2473L4.87804 17.3415C5.37408 17.8255 6.1685 17.8156 6.65243 17.3195L8.75911 15.1601C9.1854 14.7232 9.54996 14.2299 9.84249 13.6941L10.4378 12.6037V15.5506C10.4378 15.696 10.4502 15.8387 10.474 15.9773L12.9495 16.6736L15.4206 15.9786C15.4445 15.8395 15.457 15.6964 15.457 15.5506V12.6042L16.0521 13.6941C16.3446 14.2299 16.7091 14.7232 17.1354 15.1601L19.2421 17.3195C19.726 17.8156 20.5205 17.8255 21.0165 17.3415L21.1039 17.2473C21.5118 16.7594 21.4921 16.0321 21.0384 15.5672L18.851 13.3253C18.6377 13.1067 18.4553 12.8599 18.309 12.5917L16.7469 9.72988C16.4537 9.19267 15.8904 8.8584 15.2784 8.8584H13.2485V8.8763C13.1498 8.86451 13.0494 8.8584 12.9474 8.8584C12.8454 8.8584 12.7448 8.86451 12.646 8.8763V8.8584Z"
      fill="white"
    />
    <path
      d="M9.2467 16.5013L11.4037 17.1074L9.17727 17.7343C8.67912 17.8744 8.27408 18.1836 8.00689 18.5822C7.70564 18.2726 7.57364 17.8153 7.69903 17.3695C7.88664 16.7024 8.57957 16.3137 9.2467 16.5013Z"
      fill="white"
    />
    <path
      d="M15.8146 20.9555C16.4817 21.1431 17.1746 20.7544 17.3622 20.0873C17.3925 19.9797 17.4078 19.8714 17.4093 19.7646L14.4937 20.5841L15.8146 20.9555Z"
      fill="white"
    />
    <path
      d="M16.6511 16.5013L9.40369 18.5395C8.73657 18.7272 8.34784 19.4201 8.53547 20.0872C8.7231 20.7543 9.41599 21.143 10.0831 20.9554L17.3305 18.9172C17.9977 18.7295 18.3864 18.0366 18.1988 17.3695C18.0111 16.7024 17.3182 16.3137 16.6511 16.5013Z"
      fill="white"
    />
  </svg>
);
