import classNames from 'classnames';

export const ReviewsIOIcon = ({ className = '', color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_245_4975)">
      <path
        d="M2.2069 15.6611C2.2069 23.0906 8.23062 29.1144 15.6601 29.1144C23.0908 29.1144 29.1145 23.0906 29.1145 15.6611C29.1145 8.23051 23.0908 2.20679 15.6601 2.20679C8.23062 2.20679 2.2069 8.23051 2.2069 15.6611ZM11.5708 18.0942C11.712 17.6473 11.5553 17.1596 11.179 16.8782C10.1837 16.1345 8.21959 14.668 6.90097 13.6838C6.71007 13.5414 6.63283 13.2931 6.70786 13.0669C6.7829 12.8418 6.99366 12.6895 7.2309 12.6895H12.3443C12.8199 12.6895 13.2425 12.385 13.3914 11.9326C13.7942 10.7155 14.5953 8.29561 15.1272 6.6923C15.2011 6.4672 15.4119 6.31493 15.6502 6.31382C15.8874 6.31382 16.0982 6.4661 16.1743 6.6912C16.7106 8.29561 17.5217 10.7188 17.9288 11.9359C18.0789 12.3861 18.5004 12.6895 18.9749 12.6895C20.1843 12.6895 22.5148 12.6895 24.1004 12.6895C24.3377 12.6895 24.5484 12.8407 24.6234 13.0658C24.6985 13.2909 24.6223 13.5392 24.4326 13.6815C23.1172 14.6724 21.1531 16.1522 20.1589 16.9003C19.7859 17.1817 19.6303 17.6683 19.7716 18.1152C20.1556 19.329 20.9258 21.7621 21.4466 23.4051C21.5195 23.6358 21.4345 23.8862 21.237 24.0242C21.0406 24.1621 20.7757 24.1566 20.5848 24.0098C19.2629 22.9958 17.333 21.5138 16.3222 20.7381C15.925 20.4335 15.3721 20.4346 14.976 20.7403C13.9741 21.5149 12.0706 22.9869 10.7608 23.9999C10.5699 24.1478 10.3051 24.1544 10.1076 24.0164C9.90897 23.8774 9.824 23.6269 9.89683 23.3974C10.4177 21.7489 11.1868 19.3113 11.5708 18.0942Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_245_4975">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
