export const formatPenceToPounds = (priceInPence: number) => {
  const priceInPounds = priceInPence / 100;
  // const isWhole = Number.isInteger(priceInPounds);

  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return priceInPounds.toLocaleString('en-GB', opts);
};
