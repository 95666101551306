import type { NextPage, GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { GraphQLClient } from '@pebble/common';
import { ActivitySearchResult } from 'types';
import { Supplier } from 'interfaces';
import { GetSupplierBySlug, GetSupplierSlugByIdQuery } from 'graphql/queries';
import SupplierDetails from 'components/SupplierDetails';
import { getImageUrl } from 'utils/getImageUrl';
import { useContext, useEffect, useState } from 'react';
import { isValidUUID } from 'utils/isValidUUID';
import { buildUrlAndQuery } from 'utils/buildUrlAndQuery';
import { Overlay } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { trackPageView } from 'utils/amplitude';
import { AmplitudeContext, AmplitudeContextType } from 'context/AmplitudeContext';

interface ISupplierRouteProps {
  supplierDetails: Supplier;
  supplierActivities: ActivitySearchResult[];
  canonicalUrl: string;
}

const SupplierRoute: NextPage<ISupplierRouteProps> = ({ supplierDetails, canonicalUrl }) => {
  const Router = useRouter();
  const supplierImage =
    getImageUrl(supplierDetails?.cloudinaryImageId || '') || supplierDetails?.imageUrl;

  const [hasReviews, setHasReviews] = useState(false);

  const { ready } = useContext(AmplitudeContext) as AmplitudeContextType;

  const { activeSearchBarDropDown, activeDesktopFilter } = useSavedSearchValues();

  useEffect(() => {
    const getReviewCount = async () => {
      await fetch(
        `https://api.reviews.io/product/rating-batch?store=${process.env.NEXT_PUBLIC_REVIEWS_STORE_KEY}&sku=${supplierDetails.id}`,
      )
        .then((response) => response.json())
        .then(([data]) => {
          setHasReviews(data.num_ratings > 0);
        })
        .catch(() => {
          setHasReviews(false);
        });
    };
    getReviewCount();
  }, [supplierDetails.id]);

  const pageTitle = `${supplierDetails.seoPageTitle} | Pebble`;

  useEffect(() => {
    if (ready) {
      trackPageView(Router.pathname, {
        supplierId: supplierDetails.id,
        details: 'supplier-profile',
      });
    }
  }, [Router.pathname, supplierDetails.id, ready]);

  return (
    <>
      <NextSeo
        title={pageTitle}
        description={supplierDetails.seoPageDescription}
        openGraph={{
          title: pageTitle,
          description: supplierDetails.seoPageDescription,
          site_name: 'Pebble Activities',
          ...(supplierImage && {
            images: [
              {
                width: 400,
                height: 225,
                url: supplierImage,
                alt: supplierDetails.seoPageDescription,
              },
            ],
          }),
        }}
        twitter={{
          handle: '@Bookpebbleuk',
          site: '@Bookpebbleuk',
          cardType: 'summary_large_image',
        }}
        canonical={canonicalUrl}
      />
      <SupplierDetails supplier={supplierDetails} goBack={Router.back} hasReviews={hasReviews} />
      {activeSearchBarDropDown && activeDesktopFilter && (
        <Overlay opacity={0.7} color="#000" zIndex={2} h={'100%'} />
      )}
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { slug, ...restOfQuery } = context.query;

  try {
    // 1. If the user uses the old url => /supplier/[uuid],
    // redirect to new url  => /supplier/[slug]
    if (isValidUUID(slug as string)) {
      const { data } = await GraphQLClient.query({
        query: GetSupplierSlugByIdQuery,
        variables: { supplierId: slug },
        fetchPolicy: 'network-only',
      });

      const { slug: supplierSlug } = data.marketplaceSupplier;

      // Issue redirect
      return {
        redirect: {
          destination: buildUrlAndQuery(`/supplier/${supplierSlug}`, restOfQuery),
          permanent: true, // 308 Permanent
        },
      };
    }

    const { data } = await GraphQLClient.query({
      query: GetSupplierBySlug,
      variables: {
        supplierSlug: slug,
        fetchPolicy: 'network-only',
      },
    });

    // 2. if the user access the supplier using an older [slug],
    // redirect the user to the latest [slug]
    const { slug: supplierSlug } = data.marketplaceSupplierBySlug;
    if (slug !== supplierSlug) {
      // Issue redirect
      return {
        redirect: {
          destination: buildUrlAndQuery(`/supplier/${supplierSlug}`, restOfQuery),
          permanent: true, // 308 Permanent
        },
      };
    }

    return {
      props: {
        supplierDetails: data.marketplaceSupplierBySlug,
        canonicalUrl: `${process.env.NEXT_PUBLIC_CANONICAL_BASE_URL}/supplier/${supplierSlug}`,
      },
    };
  } catch (e) {
    console.error(JSON.stringify(e));
    return { notFound: true };
  }
};

export default SupplierRoute;
