import Script from 'next/script';
import classes from './Reviews.module.scss';
import classNames from 'classnames';
interface ICompanyRatingsBar {
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const CompanyRatingsBar: React.FC<ICompanyRatingsBar> = ({ onClick, className }) => {
  return (
    <>
      <div
        className={classNames('reviews-io-rating-bar-widget', classes.companyRatingsBar, className)}
        widget-id={process.env.NEXT_PUBLIC_REVIEWS_COMPANY_RATINGS_WIDGET_ID}
        data-store-name={process.env.NEXT_PUBLIC_REVIEWS_STORE_KEY}
        lang="en"
        onClick={(e) => onClick?.(e)}
      ></div>
      <Script src="https://widget.reviews.io/modern-widgets/rating-bar.js"></Script>
    </>
  );
};

export default CompanyRatingsBar;
