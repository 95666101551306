import classNames from 'classnames';

export const AttendanceUnknown = ({ className = '', color = '#7F839B', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.0688 8.73333C13.8223 8.73333 15.2438 7.33795 15.2438 5.61667C15.2438 3.89538 13.8223 2.5 12.0688 2.5C10.3153 2.5 8.8938 3.89538 8.8938 5.61667C8.8938 7.33795 10.3153 8.73333 12.0688 8.73333Z"
      fill={color}
    />
    <path
      d="M18.9188 12.9749L16.0688 10.0999C15.5438 9.5749 14.8188 9.2749 14.0688 9.2749H10.0938C9.3438 9.2749 8.6188 9.5749 8.0938 10.0999L5.1938 12.9749C4.6688 13.4999 4.6688 14.3999 5.1938 14.8999C5.7188 15.4749 6.5938 15.4499 7.1438 14.8999L8.8938 13.1499V21.1249C8.8938 21.8749 9.4938 22.4999 10.2688 22.4999C11.0188 22.4999 11.6438 21.8749 11.6438 21.1249V18.1249C11.6438 17.8999 11.8438 17.6999 12.0688 17.6999C12.2938 17.6999 12.4938 17.8999 12.4938 18.1249V21.1249C12.4938 21.8749 13.0938 22.4999 13.8688 22.4999C14.6188 22.4999 15.2438 21.8749 15.2438 21.1249V13.1749L16.9938 14.9249C17.5188 15.4499 18.3938 15.4749 18.9438 14.9249C19.4688 14.3999 19.4688 13.4999 18.9188 12.9749Z"
      fill={color}
    />
  </svg>
);
