const PebbleLogoDarkBlue = () => (
  <svg width="89" height="94" viewBox="0 0 89 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_26787)">
      <g clipPath="url(#clip1_1_26787)">
        <path
          d="M29.7678 57.0876C29.7678 57.0876 30.7036 55.0852 34.1115 53.9329C36.8618 53.0224 39.7952 52.8275 42.6399 53.3662C44.6985 53.7345 47.7659 54.1974 50.779 55.2779C53.7921 56.3585 54.6062 57.0121 54.5856 58.4893C54.565 59.9666 53.0753 61.134 52.6168 61.3229C50.9083 62.1339 49.0491 62.5704 47.1614 62.6037C44.051 62.6982 37.0311 62.7926 35.8371 62.7019C34.643 62.6113 31.7198 62.513 30.5501 60.9508C29.3804 59.3885 29.1952 58.0322 29.7678 57.0876Z"
          fill="#0E206F"
        />
        <path
          d="M32.6349 43.2937C32.6349 43.2937 34.7141 41.4519 39.4845 41.3763C44.2549 41.3008 49.482 42.0791 51.3609 44.1022C53.2399 46.1254 52.0571 47.8313 52.0571 47.8313C52.0571 47.8313 51.1757 49.3557 49.467 49.7524C47.7583 50.1491 45.7933 50.5836 43.1039 50.6006C40.4146 50.6176 34.7403 50.3059 33.4546 49.1819C32.1689 48.058 31.3098 47.1342 31.3155 45.8761C31.3211 44.618 32.6349 43.2937 32.6349 43.2937Z"
          fill="#0E206F"
        />
        <path
          d="M36.2468 32.8944C37.6009 32.0072 39.1839 31.5421 40.7982 31.557C43.452 31.6042 44.3147 32.1369 44.3147 32.1369C44.3147 32.1369 46.1001 33.2572 46.5811 33.4007C47.0621 33.5443 47.908 33.8409 48.2056 34.7117C48.5031 35.5826 48.9242 36.563 48.258 37.1449C47.5917 37.7267 47.3802 38.0403 45.8774 38.4445C44.6154 38.7611 43.3119 38.8752 42.0147 38.7827C41.0097 38.7354 38.6366 38.4899 37.8113 38.488C36.986 38.4861 35.4514 37.5888 35.2605 36.5328C35.0696 35.4768 35.1033 33.3932 36.2468 32.8944Z"
          fill="#0E206F"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_26787">
        <rect width="233" height="94" fill="white" />
      </clipPath>
      <clipPath id="clip1_1_26787">
        <rect width="233" height="94" fill="white" transform="translate(0 -0.0754395)" />
      </clipPath>
    </defs>
  </svg>
);

export default PebbleLogoDarkBlue;
