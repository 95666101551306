import { gql } from '@apollo/client';

const CreateTrialActivityBookingCancellation = gql`
  mutation createTrialActivityBookingCancellation(
    $bookingId: UUID
    $bookingTicketSessionId: UUID!
    $cancelledByCustomer: Boolean = false
    $reason: ActivityBookingCancellationEnum!
    $reasonDetails: String
  ) {
    createTrialActivityBookingCancellation(
      bookingId: $bookingId
      bookingTicketSessionId: $bookingTicketSessionId
      cancelledByCustomer: $cancelledByCustomer
      reason: $reason
      reasonDetails: $reasonDetails
    ) {
      cancelledActivityBooking
    }
  }
`;

export default CreateTrialActivityBookingCancellation;
