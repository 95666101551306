import { PreCheckoutBasketTicket } from 'interfaces';

// renames (adds number) repetitive instances of the same tickets
const getRenamedTickets = (tickets: PreCheckoutBasketTicket[]): PreCheckoutBasketTicket[] => {
  const ticketsGroupedById: Record<string, PreCheckoutBasketTicket[]> = tickets.reduce(
    (accum: Record<string, PreCheckoutBasketTicket[]>, ticket) => {
      const existing = accum[ticket.ticket] || [];
      return {
        ...accum,
        [ticket.ticket]: [...existing, ticket],
      };
    },
    {},
  );
  return Object.values(ticketsGroupedById).flatMap((groupedTickets) => {
    return groupedTickets.map((ticket, index) =>
      index === 0 ? ticket : { ...ticket, ticketName: `${ticket.ticketName} ${index + 1}` },
    );
  });
};

export default getRenamedTickets;
