import { AppShellProps, AppShell } from '@mantine/core';
import classes from './ResponsiveContainer.module.scss';

const ResponsiveContainer: React.FC<AppShellProps> = (props) => {
  return (
    <AppShell {...props} className={classes.wrapper}>
      {props.children}
    </AppShell>
  );
};

export default ResponsiveContainer;
