import {
  Accordion,
  Box,
  Center,
  Divider,
  Flex,
  Grid,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import CheckoutTicketAccordionCard from 'components/CheckoutDetails/CheckoutAttendees/CheckoutTicketAccordionCard/CheckoutTicketAccordionCard';
import { PreCheckoutBasketAttendee, PreCheckoutBasketTicket } from 'interfaces';
import classes from './CheckoutAttendees.module.scss';
import { Actions, trackAction } from 'utils/amplitude';
import AboutYouSection from './AboutYouSection/AboutYouSection';
import AgeCheckModal from 'components/AgeCheckModal/AgeCheckModal';
import {
  executeScroll,
  findAttendeesOutOfAgeRange,
  formatTelephoneValue,
  getExistingTelephone,
  getTicketsWithAttendees,
  validateTelephone,
} from '../CheckoutDetailsUtils';
import { CaretDown, XCircle } from '@phosphor-icons/react';
import { Fragment, useMemo } from 'react';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import { isntEmpty, validateEmail, validateFullName } from '@pebble/common';
import {
  CheckoutAttendeesFormProvider,
  useCheckoutAttendeesForm,
} from 'context/CheckoutFormContext';
import getRenamedTickets from 'utils/getRenamedTickets';
import { Pebbles } from '@ui';
import SelectedSessionsList from './SelectedSessionsList/SelectedSessionsList';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import { PebbleButtonsEnum, StepperEnum } from 'enums';
import { detect } from 'detect-browser';
import { PebbleButtonSet } from 'components/ui';

interface ICheckoutAttendeesProps {
  userToken?: string;
  supplierMarketingConsentGiven: boolean;
}

const CheckoutAttendees: React.FC<ICheckoutAttendeesProps> = ({
  userToken,
}: ICheckoutAttendeesProps) => {
  const {
    isSubscription,
    setActiveStep,
    showAgeCheckModal,
    setShowAgeCheckModal,
    accordionOpenId,
    setAccordionOpenId,
    basket,
    steps,
    activeStep,
    erroredAccordions,
    isLoggedIn,
    basketConfirmAttendeesMutation,
    basketMutationLoading,
    scrollRef,
    eligibleAttendees,
  } = useCheckoutDetailsContext();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const browser = detect();

  const { activity, tickets: basketTickets } = basket;

  const getLoggedInAttendees = (attendees: PreCheckoutBasketAttendee[]) => {
    return attendees.map(({ id, birthDate, fullName, isAdult, isNew }) => ({
      ...(!isNew && id
        ? { id }
        : {
            birthDate: isAdult ? null : birthDate,
            fullName,
            isAdult,
            id,
            isNew,
          }),
    }));
  };

  const getGuestAttendees = (attendees: PreCheckoutBasketAttendee[]) => {
    const selectedGuests = attendees.map(({ id }) =>
      eligibleAttendees.find((attendee) => attendee.id === id),
    );
    return selectedGuests.map((child) => ({
      fullName: child?.fullName,
      birthDate: child?.birthDate,
      isAdult: child?.isAdult,
    }));
  };

  const checkoutAttendeesForm = useCheckoutAttendeesForm({
    initialValues: {
      fullName: basket?.guest?.fullName || '',
      email: basket?.guest?.email || '',

      telephone: getExistingTelephone(basket?.guest?.phoneNumber),
      tickets: getTicketsWithAttendees(basket, isSubscription, eligibleAttendees),
    },
    validate: {
      email: (value) =>
        validateEmail(value) || isLoggedIn ? null : 'Field must be a valid email.',
      fullName: (value) =>
        validateFullName(value) || isLoggedIn ? null : 'Please enter your first and last name.',
      telephone: (value) =>
        validateTelephone(value) || isLoggedIn ? null : 'Please enter a valid telephone number.',
      tickets: {
        attendees: {
          id: (value) => (value && isntEmpty(value) ? null : 'Please select an attendee.'),
        },
      },
    },
  });

  // renames (adds number) repetitive instances of the same tickets
  const renamedTickets: PreCheckoutBasketTicket[] = getRenamedTickets(basketTickets);

  const saveAttendeesChanges = async () => {
    const formattedTickets = checkoutAttendeesForm.values.tickets.map((ticket) => {
      const { ticket: ticketId, attendees, subscriptionTrialSelected, id: uniqueId } = ticket;
      const formattedAttendees = isLoggedIn
        ? getLoggedInAttendees(attendees)
        : getGuestAttendees(attendees);
      return {
        id: uniqueId,
        ticket: ticketId,
        attendees: formattedAttendees,
        ...(isSubscription && {
          subscriptionTrialSelected,
        }),
      };
    });

    await basketConfirmAttendeesMutation({
      variables: {
        input: {
          id: basket.id,
          tickets: formattedTickets,
          ...(!isLoggedIn && {
            guest: {
              fullName: checkoutAttendeesForm.values.fullName,
              phoneNumber: formatTelephoneValue(checkoutAttendeesForm.values.telephone),
              email: checkoutAttendeesForm.values.email,
            },
          }),
        },
      },
    });
  };

  const attendeesOutOfAgeRange = findAttendeesOutOfAgeRange(
    checkoutAttendeesForm.values.tickets,
    activity.ageMonthsStart,
    activity.ageMonthsEnd,
    eligibleAttendees,
  );

  const allAgesInRange = useMemo(() => {
    return attendeesOutOfAgeRange.length === 0;
  }, [attendeesOutOfAgeRange]);

  const handleNextClick = async () => {
    const { hasErrors } = checkoutAttendeesForm.validate();

    if (!hasErrors && allAgesInRange) {
      await saveAttendeesChanges();
      executeScroll(scrollRef);

      setActiveStep(steps[steps.indexOf(activeStep) + 1]);

      const nextStep = steps[steps.indexOf(activeStep) + 1];
      switch (nextStep) {
        case StepperEnum.ANSWER_QUESTIONS:
          return trackAction(Actions.CHECKOUT_NEXT_BOOKING_QUESTIONS);
        case StepperEnum.SELECT_ADDONS:
          return trackAction(Actions.CHECKOUT_NEXT_ADDONS);
        default:
          return trackAction(Actions.CHECKOUT_NEXT_REVIEWPAY);
      }
    } else {
      if (erroredAccordions.length > 0) {
        setAccordionOpenId(erroredAccordions[0]);
      } else if (!hasErrors && !allAgesInRange) {
        setShowAgeCheckModal(true);
        trackAction(Actions.AGE_RANGE_WARNING);
      }
    }
  };

  if (basketMutationLoading) {
    return (
      <Center style={{ height: '60vh' }}>
        <Pebbles />
      </Center>
    );
  }

  const classAccordionItems = (renamedFilteredTickets: PreCheckoutBasketTicket[]) => {
    return renamedFilteredTickets.map((_ticket, index) => {
      const ticketIndex = basketTickets.findIndex((basketTicket) => basketTicket.id === _ticket.id);

      return (
        <Fragment key={_ticket.id}>
          <Accordion.Panel>
            <Grid>
              <Grid.Col span={isMobile ? 12 : 4}>
                <SelectedSessionsList
                  product={_ticket?.product}
                  basketTickets={renamedFilteredTickets}
                />
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 8}>
                <Accordion
                  value={accordionOpenId}
                  onChange={setAccordionOpenId}
                  chevron={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
                  classNames={{
                    content: classes.ticketAccordionContent,
                    chevron: classes.ticketAccordionChevron,
                  }}
                >
                  <CheckoutTicketAccordionCard
                    key={`${_ticket.ticketName}-${_ticket.id}`}
                    id={`tickets.${ticketIndex}`}
                    ticketId={_ticket.id}
                    ticketName={_ticket.ticketName}
                    ticketIndex={ticketIndex}
                    subscriptionTrialInfo={{
                      trialType: activity.subscriptionTrialType,
                      trialLength: activity.subscriptionTrialSessionCount,
                      trialCost: activity.subscriptionTrialPrice,
                    }}
                    checkoutValue={_ticket.product?.checkoutValue}
                  />
                </Accordion>
              </Grid.Col>
            </Grid>
            {renamedFilteredTickets.length !== index + 1 && (
              <Divider mt="lg" mb="xs" variant="dashed" c={theme.colors.gray[4]} />
            )}
          </Accordion.Panel>
        </Fragment>
      );
    });
  };

  return (
    <CheckoutAttendeesFormProvider form={checkoutAttendeesForm}>
      <Box className={classes.attendeeStepWrapper}>
        {!userToken && <AboutYouSection form={checkoutAttendeesForm} />}

        <Flex justify="space-between" mt={!userToken ? 'xl' : '0px'} align="center">
          <Title order={3} className={classes.title}>
            About your attendee(s)
          </Title>
        </Flex>
        {basket?.classes ? (
          <>
            <Accordion
              defaultValue={basket.classes?.[0].id}
              className={classNames({
                [classes.samsungFix]: browser?.name === 'samsung' && isMobile,
              })}
              chevron={<CaretDown size={24} weight="bold" color={theme.colors.blue[8]} />}
              classNames={{ chevron: classes.classAccordionChevron }}
            >
              {basket.classes.map((basketClass) => {
                const filteredTickets = basketTickets.filter(
                  (basketTicket: PreCheckoutBasketTicket) =>
                    basketTicket?.classId === basketClass.id,
                );
                const renamedFilteredTickets: PreCheckoutBasketTicket[] =
                  getRenamedTickets(filteredTickets);
                const erroredAccordion = erroredAccordions.some(
                  (erroredAccordionKey) =>
                    checkoutAttendeesForm.getInputProps(erroredAccordionKey).value.classId ===
                    basketClass.id,
                );

                return (
                  <Accordion.Item
                    key={basketClass.id}
                    value={basketClass.id}
                    className={classNames(classes.classCard, {
                      [classes.accordionError]: erroredAccordion,
                    })}
                  >
                    <Accordion.Control>
                      <Flex align={'center'} gap={8}>
                        <Text fw={700} size="xl" c={theme.colors.blue[8]}>
                          {basketClass.name}
                        </Text>
                        {erroredAccordion && (
                          <Flex align={'center'} gap={8}>
                            <XCircle size={32} color={theme.colors.red[5]} weight="fill" />
                          </Flex>
                        )}
                      </Flex>
                    </Accordion.Control>
                    {classAccordionItems(renamedFilteredTickets)}
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </>
        ) : (
          <Accordion
            value={accordionOpenId}
            onChange={setAccordionOpenId}
            chevron={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
            className={classNames({
              [classes.samsungFix]: browser?.name === 'samsung' && isMobile,
            })}
          >
            {renamedTickets.map((_ticket, ticketIndex) => {
              return (
                <CheckoutTicketAccordionCard
                  key={`${_ticket.ticketName}-${_ticket.id}`}
                  id={`tickets.${ticketIndex}`}
                  ticketId={_ticket.id}
                  ticketName={_ticket.ticketName}
                  ticketIndex={ticketIndex}
                  subscriptionTrialInfo={{
                    trialType: activity.subscriptionTrialType,
                    trialLength: activity.subscriptionTrialSessionCount,
                    trialCost: activity.subscriptionTrialPrice,
                  }}
                />
              );
            })}
          </Accordion>
        )}
        <AgeCheckModal
          opened={showAgeCheckModal}
          onClose={() => {
            setShowAgeCheckModal(false);
          }}
          handleConfirmAttendees={async () => {
            await saveAttendeesChanges();
            executeScroll(scrollRef);
            setActiveStep(steps[steps.indexOf(activeStep) + 1]);
          }}
          attendeesOutOfAgeRange={attendeesOutOfAgeRange}
          activity={activity}
        />
      </Box>
      <PebbleButtonSet
        btnVariant={PebbleButtonsEnum.PRIMARY}
        size="md"
        onClick={handleNextClick}
        fullWidth
        mt={isMobile ? 0 : 'lg'}
        mb={isMobile ? 'sm' : 0}
      >
        Next
      </PebbleButtonSet>
    </CheckoutAttendeesFormProvider>
  );
};

export default CheckoutAttendees;
