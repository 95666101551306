import { Badge } from '@mantine/core';
import { Coins, HandHeart } from '@phosphor-icons/react';
import classNames from 'classnames';
import { BlockTrialTypeEnum } from 'enums';
import { useMemo } from 'react';
import classes from './TrialsOnBlocksBadge.module.scss';

interface ITrialsOnBlocksBadgeProps {
  blockTrialType: BlockTrialTypeEnum;
  blockTrialSessionCount: number;
  hideIcon?: boolean;
  cornerBadge?: boolean;
}

const TrialsOnBlocksBadge: React.FC<ITrialsOnBlocksBadgeProps> = ({
  blockTrialType,
  blockTrialSessionCount,
  hideIcon = false,
  cornerBadge = false,
}) => {
  const isFreeTrial = blockTrialType === BlockTrialTypeEnum.FREE_TRIAL;
  const isPaidTrial = blockTrialType === BlockTrialTypeEnum.PAID_TRIAL;
  const trialBadgeLabel = useMemo(() => {
    if (cornerBadge) {
      return `${isFreeTrial ? 'Free' : 'Paid'} trial available`;
    }
    if (isFreeTrial) {
      return `Free trial x${blockTrialSessionCount}`;
    }
    if (isPaidTrial) {
      return `Paid trial x${blockTrialSessionCount}`;
    }
    return '';
  }, [blockTrialSessionCount, cornerBadge, isFreeTrial, isPaidTrial]);

  const trialBadgeIcon = useMemo(() => {
    if (isFreeTrial) {
      return <HandHeart size={20} />;
    }
    if (isPaidTrial) {
      return <Coins size={20} />;
    }
    return null;
  }, [isFreeTrial, isPaidTrial]);
  return (
    <Badge
      px={4}
      py={0}
      title={trialBadgeLabel}
      classNames={{
        root: classNames(classes.rootBadge, {
          [classes.cornerBadge]: cornerBadge,
          [classes.trialBadge]: isFreeTrial,
          [classes.paidTrialBadge]: isPaidTrial,
        }),
        label: classes.innerBadge,
      }}
      leftSection={hideIcon ? null : trialBadgeIcon}
    >
      {trialBadgeLabel}
    </Badge>
  );
};

export default TrialsOnBlocksBadge;
