import { TextInput, TextInputProps } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './PebbleTextInput.module.scss';
import classNames from 'classnames';

interface IPebbleTextInputProps extends TextInputProps {
  action?:
    | Actions
    | {
        actionName: Actions;
        iframeLocation?: string | null;
      };
  whiteBackground?: boolean;
  labelSizeMd?: boolean;
  fieldError?: boolean;
  boldLabel?: boolean;
  boldInput?: boolean;
  withLeftIcon?: boolean;
  blueRequired?: boolean;
}

export const PebbleTextInput: React.FC<IPebbleTextInputProps> = ({
  onBlur,
  action,
  whiteBackground,
  labelSizeMd,
  fieldError,
  boldLabel,
  boldInput,
  withLeftIcon,
  blueRequired,
  ...props
}) => {
  return (
    <TextInput
      {...props}
      classNames={{
        input: classNames(classes.input, {
          [classes.disabledInput]: props.disabled,
          [classes.inputError]: fieldError,
          [classes.whiteBgInput]: whiteBackground,
          [classes.inputWithLeftIcon]: withLeftIcon,
          [classes.boldInput]: boldInput,
        }),
        label: classNames(classes.label, {
          [classes.boldLabel]: boldLabel,
        }),
        required: classNames({ [classes.blueRequired]: blueRequired }),
        ...props.classNames,
      }}
      onBlur={(e) => {
        onBlur?.(e);
        if (action) {
          if (typeof action === 'string') {
            trackAction(action);
          } else {
            trackAction(action.actionName, { iframeLocation: action.iframeLocation });
          }
        }
      }}
    />
  );
};
