import { useMantineTheme, Spoiler } from '@mantine/core';
import { DateAvailabilityType } from 'types';
import { Schedule } from 'interfaces';
import { useMemo } from 'react';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './SessionDateList.module.scss';
import classNames from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Check, X } from '@phosphor-icons/react';
dayjs.extend(advancedFormat);

interface ISessionDateList {
  dates: DateAvailabilityType[] | Schedule[];
  isWaitlist?: boolean;
  subscriptionInfo: null | {
    date?: string;
    startTime?: string;
    endTime?: string;
  };
  hideEndTimes?: boolean;
}

const SessionDateList: React.FC<ISessionDateList> = ({
  dates,
  subscriptionInfo,
  isWaitlist = false,
  hideEndTimes = false,
}) => {
  const theme = useMantineTheme();

  const splitDates = useMemo(() => {
    const cutOff = 3;
    return [dates.slice(0, cutOff), dates.slice(cutOff, dates.length)];
  }, [dates]);

  const getDateListItem = (date: DateAvailabilityType | Schedule) => {
    const dateObj = dayjs(date.date);
    const dateString = dateObj.format('ddd-Do-MMMM');
    const [weekday, dayOfMonth, month] = dateString.split('-');
    const isSoldOut = date.spotsLeft === 0;

    return (
      <div
        className={classNames(classes.tableRow, {
          [classes.soldOut]: isSoldOut && !isWaitlist,
        })}
        key={date.date}
      >
        <div className={classes.tableCell}>
          {isSoldOut && !isWaitlist ? (
            <X weight="bold" size={24} color={theme.colors.red[6]} />
          ) : (
            <Check weight="bold" size={24} color={theme.colors.lime[6]} />
          )}
        </div>
        <div className={classes.tableCell}>{weekday}</div>
        <div className={classes.tableCell}>{dayOfMonth}</div>
        <div className={classes.tableCell}>{month}</div>
        <div className={classes.tableCell}>-</div>
        <div className={classNames(classes.tableCell, classes.bold)}>
          {hideEndTimes ? date.startTime : `${date.startTime} - ${date.endTime}`}
        </div>
      </div>
    );
  };

  if (subscriptionInfo) {
    const formattedDate = subscriptionInfo?.date
      ? dayjs(subscriptionInfo.date).format('ddd Do MMMM')
      : '';
    return (
      <div className={classes.table}>
        <div className={classes.tableRow}>
          <div className={classes.tableCell}>
            <Check color={theme.colors.lime[6]} size={24} weight="bold" />
          </div>
          <div className={classes.tableCell}>NEXT: {formattedDate}</div>
          <div className={classes.tableCell}>-</div>
          <div className={classNames(classes.tableCell, classes.bold)}>
            {`${subscriptionInfo.startTime} - ${subscriptionInfo.endTime}`}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Spoiler
      maxHeight={splitDates[1].length > 0 ? 100 : 9999}
      showLabel={`+ ${splitDates[1].length} more`}
      hideLabel="See less"
      classNames={{
        control: classes.spoilerControl,
      }}
      onClick={() => {
        trackAction(Actions.VIEW_DATES_DETAILS);
      }}
    >
      <div className={classes.table}>{dates.map(getDateListItem)}</div>
    </Spoiler>
  );
};

export default SessionDateList;
