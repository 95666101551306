import Image from 'next/legacy/image';
import {
  Modal,
  ModalProps,
  Group,
  Text,
  Box,
  ActionIcon,
  rem,
  useMantineTheme,
  Stack,
  Flex,
} from '@mantine/core';
import { PebbleButtonSet, PebbleLogoAllWhite } from 'components/ui';
import classes from './CreateAccountModal.module.scss';
import ListingsActivities from '../../../public/createAccountModalImages/createAccountModalImage.webp';
import { trackAction, Actions } from 'utils/amplitude';
import { useBasket } from 'context/BasketContext';
import { X } from '@phosphor-icons/react';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleButtonsEnum } from 'enums';

interface ICreateAccountModal extends ModalProps {
  onClose(): void;
  handleCreateAccountModalClick(routeName: string, ticketId?: string): void;
}

const CreateAccountModal: React.FC<ICreateAccountModal> = ({
  onClose,
  handleCreateAccountModalClick,
  ...modalProps
}) => {
  const guest = 'guest';
  const login = 'login';
  const register = 'register';

  const {
    setShowCreateAccountModal,
    setShowTicketTypesModal,
    selectedTickets,
    trialsOnBlocksSelected,
  } = useBasket();

  const isLoggedInOptionOnly = trialsOnBlocksSelected;

  const isMobile = useMediaQuery('(max-width: 768px)', true);
  const theme = useMantineTheme();

  return (
    <Modal
      {...modalProps}
      size={875}
      classNames={{
        content: classes.modal,
        body: classes.modalBody,
      }}
      padding={0}
      onClose={onClose}
      centered
      zIndex={202}
    >
      <Group align="stretch" className={classes.container} gap={0}>
        <ActionIcon
          onClick={onClose}
          className={classes.closeButtonWrapper}
          variant={'filled'}
          aria-label="close-button"
        >
          <X
            size={20}
            className={classes.closeButtonIcon}
            weight="bold"
            onClick={() => trackAction(Actions.TICKETS_CLOSE)}
          />
        </ActionIcon>
        {!isMobile && (
          <Stack className={classes.imageWrapper} gap={0} justify="space-between">
            <Box>
              <PebbleLogoAllWhite />
              <>
                <Text fw={700} size={rem(24)} c={'#fff'} my={10} mb="sm">
                  Welcome to Pebble 👋
                </Text>
                <Text fw={600} size={'md'} c={theme.colors.pink[2]}>
                  All your bookings made easy
                </Text>
              </>
            </Box>
            <Box className={classes.listingsImages}>
              <Image src={ListingsActivities} />
            </Box>
          </Stack>
        )}
        <Stack className={classes.modalContent} justify="space-between">
          {isMobile && (
            <Flex justify={'center'} mt="md">
              <PebbleLogoAllWhite />
            </Flex>
          )}
          <Stack align="center" justify="space-between">
            <Text
              size={'md'}
              w="80%"
              c={isMobile ? '#fff' : theme.colors.blue[8]}
              mt={isMobile ? 0 : '10%'}
            >
              For <b>faster checkout</b> next time and to <b>view your bookings</b>
            </Text>

            <PebbleButtonSet
              btnVariant={PebbleButtonsEnum.PRIMARY}
              size="md"
              onClick={() => {
                trackAction(Actions.CREATE_AN_ACCOUNT);
                handleCreateAccountModalClick(register, selectedTickets?.[0]?.ticketId);
              }}
            >
              Create an Account
            </PebbleButtonSet>

            {isLoggedInOptionOnly ? (
              <Text
                fw={700}
                maw={isMobile ? '95%' : '80%'}
                c={isMobile ? '#fff' : theme.colors.blue[8]}
              >
                In order to book onto a trial you must have a Pebble account
              </Text>
            ) : (
              <Stack>
                <Text
                  c={isMobile ? '#fff' : theme.colors.gray[6]}
                  fw={400}
                  size={'md'}
                  my={isMobile ? 'md' : 'xs'}
                >
                  or
                </Text>
                <PebbleButtonSet
                  btnVariant={PebbleButtonsEnum.SECONDARY}
                  aria-label="guest-checkout-button"
                  size="md"
                  variant={'outline'}
                  radius="xl"
                  onClick={() => {
                    trackAction(Actions.GUEST_CHECKOUT_SELECTED);
                    setShowTicketTypesModal(false);
                    setShowCreateAccountModal(false);
                    handleCreateAccountModalClick(guest, selectedTickets?.[0]?.ticketId);
                  }}
                >
                  Continue as Guest
                </PebbleButtonSet>
              </Stack>
            )}
          </Stack>
          <Box mt={isMobile ? 0 : 'lg'} mb={isMobile ? 'xl' : 0}>
            <Text size={'sm'} fw={600} c={isMobile ? '#fff' : theme.colors.blue[8]}>
              Already have an account?
            </Text>

            <PebbleButtonSet
              className={classes.logInButton}
              btnVariant={PebbleButtonsEnum.TERTIARY}
              size="md"
              aria-label="login-button"
              onClick={() => {
                handleCreateAccountModalClick(login, selectedTickets?.[0]?.ticketId);
                trackAction(Actions.MARKETPLACE_LOGIN_SELECTED);
              }}
            >
              Log in
            </PebbleButtonSet>
          </Box>
        </Stack>
      </Group>
    </Modal>
  );
};

export default CreateAccountModal;
