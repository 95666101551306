import { createContext, FC } from 'react';

export interface EmbedContextType {
  isEmbed: boolean;
  iframeTheme: string;
  iframeLocation: string | null;
}

export const EmbedContext = createContext<EmbedContextType | null>(null);

interface IEmbedProvider {
  isEmbed: boolean;
  iframeTheme: string;
  iframeLocation: string | null;
  children?: React.ReactNode;
}

export const EmbedContextProvider: FC<IEmbedProvider> = ({
  isEmbed,
  iframeTheme,
  iframeLocation,
  children,
}) => {
  const value: EmbedContextType = {
    isEmbed,
    iframeTheme,
    iframeLocation,
  };
  return <EmbedContext.Provider value={value}>{children}</EmbedContext.Provider>;
};
