import { gql } from '@apollo/client';

const MarketplaceActivityForGuardian = gql`
  query ($activityId: UUID!, $date: Date) {
    marketplaceActivityForGuardian(activityId: $activityId, date: $date) {
      eligibleChildren {
        id
        fullName
        birthDate
        guardian {
          email
        }
      }
    }
  }
`;

export default MarketplaceActivityForGuardian;
