import React from 'react';
import { Button, Group } from '@mantine/core';
import classNames from 'classnames';
import classes from './YesNoButtons.module.scss';
import { Actions, trackAction } from 'utils/amplitude';

interface YesNoButtonsProps {
  // true = yes, false = no, null = not answered
  value: boolean | null;
  onYes: () => void;
  onNo: () => void;
  questionShortTitle?: string;
  attendeeFullName?: string;
}

const YesNoButtons: React.FC<YesNoButtonsProps> = ({
  value,
  onYes,
  onNo,
  questionShortTitle,
  attendeeFullName,
}: YesNoButtonsProps) => {
  return (
    <Group grow w="full">
      <Button
        data-testid={`${questionShortTitle} ${attendeeFullName ? attendeeFullName : ''} YES answer`}
        onClick={() => {
          onYes();
          if (questionShortTitle === 'Photo/Video consent') {
            trackAction(Actions.PHOTO_CONSENT_YES);
          } else {
            trackAction(Actions.INFO_CHILD_YES);
          }
        }}
        className={classNames(classes.button, {
          [classes.selectedButton]: value,
        })}
      >
        Yes
      </Button>
      <Button
        data-testid={`${questionShortTitle} ${attendeeFullName ? attendeeFullName : ''} NO answer`}
        onClick={() => {
          onNo();
          if (questionShortTitle === 'Photo/Video consent') {
            trackAction(Actions.PHOTO_CONSENT_NO);
          } else {
            trackAction(Actions.INFO_CHILD_NO);
          }
        }}
        className={classNames(classes.button, {
          [classes.selectedButton]: value === false,
        })}
      >
        No
      </Button>
    </Group>
  );
};

export default YesNoButtons;
