import classNames from 'classnames';

export const GreenMedicalConsiderations = ({
  className = '',
  color = 'currentColor',
  size = 24,
  strokeWidth = 2,
  height = 22,
  width = 26,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.92847 7.54535C10.3435 7.54535 10.68 7.8818 10.68 8.29683V10.1755H12.5587C12.9737 10.1755 13.3102 10.512 13.3102 10.927C13.3102 11.3421 12.9737 11.6785 12.5587 11.6785H10.68V13.5572C10.68 13.9723 10.3435 14.3087 9.92847 14.3087C9.51344 14.3087 9.17699 13.9723 9.17699 13.5572V11.6785H7.29828C6.88324 11.6785 6.54679 11.3421 6.54679 10.927C6.54679 10.512 6.88324 10.1755 7.29828 10.1755H9.17699V8.29683C9.17699 7.8818 9.51344 7.54535 9.92847 7.54535Z"
      fill="#0E206F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.67402 0.40625C7.0761 0.40625 6.50267 0.643772 6.07988 1.06656C5.65709 1.48936 5.41957 2.06278 5.41957 2.6607V3.41219H1.66215C0.832081 3.41219 0.15918 4.08509 0.15918 4.91515V16.9389C0.15918 17.769 0.832081 18.4419 1.66215 18.4419H18.1948C19.0249 18.4419 19.6978 17.769 19.6978 16.9389V4.91515C19.6978 4.08509 19.0249 3.41219 18.1948 3.41219H14.4374V2.6607C14.4374 2.06278 14.1999 1.48936 13.7771 1.06656C13.3543 0.643772 12.7808 0.40625 12.1829 0.40625H7.67402ZM12.9344 3.41219V2.6607C12.9344 2.4614 12.8552 2.27025 12.7143 2.12932C12.5734 1.98839 12.3822 1.90922 12.1829 1.90922H7.67402C7.47471 1.90922 7.28357 1.98839 7.14264 2.12932C7.00171 2.27025 6.92254 2.4614 6.92254 2.6607V3.41219H12.9344ZM1.66215 4.91515V16.9389H18.1948V4.91515H1.66215Z"
      fill="#0E206F"
    />
    <ellipse cx="19.4966" cy="15.3748" rx="4.6626" ry="4.51153" fill="#9DE551" />
    <path
      d="M19.6464 9.51185C18.0552 9.51185 16.5291 10.144 15.4039 11.2692C14.2786 12.3944 13.6465 13.9205 13.6465 15.5118C13.6465 17.103 14.2786 18.6291 15.4039 19.7544C16.529 20.8796 18.0552 21.5117 19.6464 21.5117C20.6997 21.5117 21.7343 21.2345 22.6464 20.7079C23.5585 20.1813 24.3159 19.4238 24.8426 18.5117C25.3693 17.5995 25.6464 16.565 25.6464 15.5117C25.6464 14.4585 25.3692 13.4239 24.8426 12.5118C24.316 11.5996 23.5585 10.8422 22.6464 10.3155C21.7342 9.78883 20.6997 9.51172 19.6464 9.51172V9.51185ZM17.8703 12.6443C18.531 12.6469 19.2152 12.9911 19.6464 13.7822C20.7078 11.8347 23.3029 12.5959 23.3029 14.4927C23.3029 16.7155 21.1732 16.9516 19.6464 19.242C18.1196 16.9516 15.9899 16.7155 15.9899 14.4927C15.9899 13.3666 16.9048 12.6406 17.8703 12.6443Z"
      fill="#0E206F"
    />
    <path
      d="M13.5901 20.4601C13.5865 20.4618 13.5827 20.4633 13.5788 20.4642C13.575 20.4652 13.571 20.4657 13.5668 20.4657C13.5541 20.4657 13.544 20.4623 13.5364 20.4553C13.529 20.4481 13.5254 20.4385 13.5254 20.4264C13.5254 20.4141 13.529 20.4045 13.5364 20.3975C13.5439 20.3903 13.5541 20.3867 13.5668 20.3867C13.571 20.3867 13.575 20.3872 13.5788 20.3882C13.5827 20.3893 13.5865 20.3906 13.5901 20.3924V20.4082C13.5865 20.4058 13.5829 20.404 13.5794 20.4028C13.5759 20.4016 13.5723 20.401 13.5683 20.401C13.5613 20.401 13.5558 20.4033 13.5516 20.4079C13.5476 20.4123 13.5457 20.4185 13.5457 20.4264C13.5457 20.4341 13.5476 20.4403 13.5516 20.4449C13.5558 20.4493 13.5613 20.4514 13.5683 20.4514C13.5723 20.4514 13.5759 20.451 13.5794 20.4499C13.5829 20.4487 13.5865 20.4469 13.5901 20.4443V20.4601Z"
      fill="#0E206F"
    />
    <path
      d="M13.6491 20.4232C13.6474 20.4225 13.6459 20.4219 13.6443 20.4215C13.6427 20.4211 13.6411 20.4209 13.6395 20.4209C13.6348 20.4209 13.631 20.4225 13.6285 20.4256C13.6261 20.4286 13.6249 20.4329 13.6249 20.4384V20.4647H13.6064V20.4077H13.6249V20.417C13.6271 20.4133 13.6297 20.4106 13.6327 20.4089C13.6358 20.4071 13.6395 20.4062 13.6437 20.4062H13.6458C13.6465 20.4062 13.6476 20.4064 13.6488 20.4065L13.6491 20.4232Z"
      fill="#0E206F"
    />
    <path
      d="M13.7154 20.4361V20.4411H13.6728C13.6734 20.4455 13.6749 20.4488 13.6776 20.451C13.6801 20.453 13.6838 20.454 13.6886 20.454C13.6925 20.454 13.6965 20.4535 13.7005 20.4525C13.7045 20.4513 13.7086 20.4495 13.7127 20.4471V20.4611C13.7086 20.4629 13.7042 20.4642 13.6999 20.465C13.6955 20.4657 13.6913 20.4662 13.6871 20.4662C13.6767 20.4662 13.6687 20.4636 13.6629 20.4584C13.6571 20.4531 13.6543 20.4456 13.6543 20.4361C13.6543 20.427 13.6571 20.4197 13.6626 20.4143C13.6684 20.4089 13.6761 20.4062 13.6859 20.4062C13.6948 20.4062 13.702 20.4089 13.7074 20.4143C13.7127 20.4197 13.7154 20.427 13.7154 20.4361ZM13.6969 20.4298C13.6969 20.4264 13.6958 20.4238 13.6937 20.4218C13.6916 20.4196 13.689 20.4185 13.6859 20.4185C13.6823 20.4185 13.6794 20.4195 13.6773 20.4215C13.675 20.4235 13.6737 20.4262 13.6731 20.4298L13.6969 20.4298Z"
      fill="#0E206F"
    />
    <path
      d="M13.7554 20.439C13.7516 20.439 13.7487 20.4398 13.7468 20.4411C13.745 20.4423 13.7441 20.4443 13.7441 20.4468C13.7441 20.449 13.7447 20.4508 13.7462 20.4522C13.7477 20.4534 13.7499 20.454 13.7527 20.454C13.7561 20.454 13.759 20.4528 13.7614 20.4504C13.7637 20.448 13.7649 20.4449 13.7649 20.4411V20.439H13.7554ZM13.7834 20.4322V20.4647H13.7649V20.4563C13.7626 20.4598 13.7597 20.4622 13.7566 20.4638C13.7536 20.4654 13.7499 20.4662 13.7456 20.4662C13.7396 20.4662 13.7347 20.4645 13.731 20.4611C13.7274 20.4575 13.7256 20.4531 13.7256 20.4477C13.7256 20.441 13.7278 20.436 13.7324 20.4328C13.737 20.4297 13.7442 20.428 13.7542 20.428H13.7649V20.4268C13.7649 20.4238 13.7637 20.4218 13.7614 20.4206C13.7591 20.4192 13.7557 20.4185 13.7509 20.4185C13.7469 20.4185 13.7432 20.4189 13.7399 20.4197C13.7365 20.4205 13.7334 20.4216 13.7307 20.4229V20.4092C13.7344 20.4083 13.7383 20.4076 13.7423 20.4071C13.7462 20.4065 13.7502 20.4062 13.7542 20.4062C13.7645 20.4062 13.7719 20.4083 13.7766 20.4125C13.7811 20.4165 13.7834 20.4231 13.7834 20.4322Z"
      fill="#0E206F"
    />
    <path
      d="M13.8201 20.3906V20.407H13.8389V20.4198H13.8201V20.4443C13.8201 20.4469 13.8205 20.4487 13.8216 20.4497C13.8228 20.4505 13.8249 20.4508 13.8281 20.4508H13.8374V20.464H13.8219C13.8145 20.464 13.8094 20.4625 13.8064 20.4595C13.8034 20.4565 13.8019 20.4514 13.8019 20.4443V20.4198H13.793V20.407H13.8019V20.3906L13.8201 20.3906Z"
      fill="#0E206F"
    />
    <path
      d="M13.9078 20.4361V20.4411H13.8652C13.8658 20.4455 13.8673 20.4488 13.8699 20.451C13.8725 20.453 13.8762 20.454 13.881 20.454C13.8849 20.454 13.8889 20.4535 13.8929 20.4525C13.8968 20.4513 13.9009 20.4495 13.9051 20.4471V20.4611C13.9009 20.4629 13.8966 20.4642 13.8923 20.465C13.8879 20.4657 13.8836 20.4662 13.8795 20.4662C13.8691 20.4662 13.8611 20.4636 13.8553 20.4584C13.8495 20.4531 13.8467 20.4456 13.8467 20.4361C13.8467 20.427 13.8494 20.4197 13.855 20.4143C13.8608 20.4089 13.8685 20.4062 13.8783 20.4062C13.8872 20.4062 13.8944 20.4089 13.8997 20.4143C13.9051 20.4197 13.9078 20.427 13.9078 20.4361ZM13.8893 20.4298C13.8893 20.4264 13.8882 20.4238 13.886 20.4218C13.884 20.4196 13.8814 20.4185 13.8783 20.4185C13.8747 20.4185 13.8718 20.4195 13.8696 20.4215C13.8674 20.4235 13.8661 20.4262 13.8655 20.4298L13.8893 20.4298Z"
      fill="#0E206F"
    />
    <path
      d="M13.9609 20.4155V20.3848H13.9791V20.4641H13.9609V20.4557C13.9583 20.4591 13.9555 20.4616 13.9526 20.4632C13.9496 20.4647 13.9461 20.4656 13.9421 20.4656C13.935 20.4656 13.9291 20.4628 13.9245 20.4572C13.9201 20.4517 13.918 20.4444 13.918 20.4354C13.918 20.4267 13.9201 20.4196 13.9245 20.414C13.9291 20.4085 13.935 20.4056 13.9421 20.4056C13.9458 20.4056 13.9493 20.4065 13.9523 20.408C13.9554 20.4097 13.9583 20.4121 13.9609 20.4155ZM13.9487 20.4524C13.9526 20.4524 13.9556 20.4511 13.9576 20.4483C13.9598 20.4453 13.9609 20.441 13.9609 20.4354C13.9609 20.4301 13.9598 20.4261 13.9576 20.4232C13.9556 20.4202 13.9526 20.4188 13.9487 20.4188C13.9449 20.4188 13.9419 20.4202 13.9397 20.4232C13.9377 20.4261 13.9368 20.4301 13.9368 20.4354C13.9368 20.441 13.9377 20.4453 13.9397 20.4483C13.9419 20.4511 13.9449 20.4524 13.9487 20.4524Z"
      fill="#0E206F"
    />
    <path
      d="M14.0636 20.4524C14.0676 20.4524 14.0705 20.4511 14.0726 20.4483C14.0747 20.4453 14.0758 20.441 14.0758 20.4354C14.0758 20.4301 14.0747 20.4261 14.0726 20.4232C14.0705 20.4202 14.0676 20.4188 14.0636 20.4188C14.0598 20.4188 14.0568 20.4202 14.0547 20.4232C14.0527 20.4261 14.0517 20.4301 14.0517 20.4354C14.0517 20.441 14.0527 20.4453 14.0547 20.4483C14.0568 20.4511 14.0598 20.4524 14.0636 20.4524ZM14.0517 20.4155C14.0541 20.4121 14.0568 20.4097 14.0597 20.408C14.0629 20.4065 14.0664 20.4056 14.0705 20.4056C14.0774 20.4056 14.0831 20.4085 14.0878 20.414C14.0923 20.4196 14.0946 20.4267 14.0946 20.4354C14.0946 20.4444 14.0923 20.4517 14.0878 20.4572C14.0831 20.4628 14.0774 20.4656 14.0705 20.4656C14.0664 20.4656 14.0629 20.4647 14.0597 20.4632C14.0568 20.4616 14.0541 20.4591 14.0517 20.4557V20.4641H14.0332V20.3848H14.0517V20.4155Z"
      fill="#0E206F"
    />
    <path
      d="M14.1016 20.4082H14.1197L14.1353 20.4467L14.1484 20.4082H14.1666L14.1424 20.4705C14.14 20.4768 14.1372 20.4813 14.1341 20.4839C14.1309 20.4865 14.1266 20.4878 14.1212 20.4878H14.1108V20.4756H14.1165C14.1196 20.4756 14.1219 20.4751 14.1233 20.4741C14.1247 20.473 14.1258 20.4714 14.1266 20.469L14.1272 20.4672L14.1016 20.4082Z"
      fill="#0E206F"
    />
    <path
      d="M14.2602 20.451H14.2295L14.2248 20.4647H14.2051L14.2331 20.3887H14.2567L14.2847 20.4647H14.265L14.2602 20.451ZM14.2343 20.4367H14.2552L14.245 20.4066L14.2343 20.4367Z"
      fill="#0E206F"
    />
    <path d="M14.2959 20.3848H14.3144V20.4641H14.2959V20.3848Z" fill="#0E206F" />
    <path
      d="M14.332 20.4071H14.3505V20.4641H14.332V20.4071ZM14.332 20.3848H14.3505V20.3997H14.332V20.3848Z"
      fill="#0E206F"
    />
    <path
      d="M14.4661 20.4601C14.4625 20.4618 14.4587 20.4633 14.4547 20.4642C14.4509 20.4652 14.447 20.4657 14.4428 20.4657C14.4301 20.4657 14.4199 20.4623 14.4124 20.4553C14.405 20.4481 14.4014 20.4385 14.4014 20.4264C14.4014 20.4141 14.405 20.4045 14.4124 20.3975C14.4199 20.3903 14.4301 20.3867 14.4428 20.3867C14.447 20.3867 14.4509 20.3872 14.4547 20.3882C14.4587 20.3893 14.4625 20.3906 14.4661 20.3924V20.4082C14.4625 20.4058 14.4589 20.404 14.4553 20.4028C14.4519 20.4016 14.4482 20.401 14.4443 20.401C14.4373 20.401 14.4318 20.4033 14.4276 20.4079C14.4236 20.4123 14.4216 20.4185 14.4216 20.4264C14.4216 20.4341 14.4236 20.4403 14.4276 20.4449C14.4318 20.4493 14.4373 20.4514 14.4443 20.4514C14.4482 20.4514 14.4519 20.451 14.4553 20.4499C14.4589 20.4487 14.4625 20.4469 14.4661 20.4443V20.4601Z"
      fill="#0E206F"
    />
    <path
      d="M14.5088 20.4194C14.5048 20.4194 14.5018 20.4209 14.4996 20.4238C14.4976 20.4267 14.4966 20.4307 14.4966 20.4361C14.4966 20.4417 14.4976 20.4459 14.4996 20.4489C14.5018 20.4517 14.5048 20.4531 14.5088 20.4531C14.5128 20.4531 14.5158 20.4517 14.5181 20.4489C14.5202 20.4459 14.5214 20.4417 14.5214 20.4361C14.5214 20.4307 14.5202 20.4267 14.5181 20.4238C14.5158 20.4209 14.5128 20.4194 14.5088 20.4194ZM14.5088 20.4062C14.5188 20.4062 14.5264 20.4089 14.5318 20.4143C14.5373 20.4195 14.5401 20.4267 14.5401 20.4361C14.5401 20.4456 14.5373 20.4531 14.5318 20.4584C14.5264 20.4636 14.5188 20.4662 14.5088 20.4662C14.4991 20.4662 14.4914 20.4636 14.4859 20.4584C14.4803 20.4531 14.4775 20.4456 14.4775 20.4361C14.4775 20.4267 14.4803 20.4195 14.4859 20.4143C14.4914 20.4089 14.4991 20.4062 14.5088 20.4062Z"
      fill="#0E206F"
    />
    <path
      d="M14.5978 20.4095V20.4232C14.5938 20.4217 14.5901 20.4205 14.5865 20.4197C14.5829 20.4189 14.5794 20.4185 14.576 20.4185C14.5726 20.4185 14.5701 20.4189 14.5683 20.4197C14.5666 20.4205 14.5659 20.4218 14.5659 20.4235C14.5659 20.4252 14.5665 20.4264 14.5677 20.4271C14.569 20.4279 14.5714 20.4285 14.5748 20.4289L14.5778 20.4292C14.5871 20.4304 14.5934 20.4324 14.5966 20.4352C14.6 20.4378 14.6017 20.442 14.6017 20.448C14.6017 20.454 14.5994 20.4586 14.5948 20.4617C14.5904 20.4647 14.5838 20.4662 14.5748 20.4662C14.571 20.4662 14.5671 20.4659 14.5629 20.4653C14.5589 20.4647 14.5549 20.4638 14.5507 20.4626V20.4489C14.5543 20.4505 14.5579 20.4518 14.5617 20.4528C14.5657 20.4536 14.5696 20.454 14.5737 20.454C14.5772 20.454 14.5799 20.4535 14.5817 20.4525C14.5835 20.4515 14.5844 20.4501 14.5844 20.4483C14.5844 20.4465 14.5836 20.4452 14.5823 20.4444C14.5811 20.4437 14.5787 20.4431 14.5751 20.4426L14.5719 20.4423C14.5637 20.4414 14.5579 20.4395 14.5546 20.4367C14.5514 20.4339 14.5498 20.4297 14.5498 20.4241C14.5498 20.418 14.5519 20.4136 14.5561 20.4107C14.5602 20.4077 14.5666 20.4062 14.5751 20.4062C14.5785 20.4062 14.582 20.4065 14.5856 20.4071C14.5893 20.4076 14.5934 20.4083 14.5978 20.4095ZM14.5802 20.4647C14.5822 20.4668 14.5836 20.4689 14.5844 20.4706C14.5854 20.4724 14.5859 20.4741 14.5859 20.4757C14.5859 20.4788 14.5848 20.4812 14.5826 20.4829C14.5806 20.4844 14.5774 20.4853 14.5731 20.4853C14.5714 20.4853 14.5698 20.485 14.568 20.4847C14.5664 20.4844 14.5647 20.4841 14.5629 20.4838V20.476C14.5645 20.4766 14.566 20.477 14.5674 20.4772C14.569 20.4774 14.5702 20.4775 14.5713 20.4775C14.5732 20.4775 14.5747 20.4771 14.5757 20.4763C14.5769 20.4757 14.5775 20.4747 14.5775 20.4733C14.5775 20.4723 14.5771 20.4711 14.5763 20.4698C14.5757 20.4683 14.5746 20.4666 14.5731 20.4647H14.5802Z"
      fill="#0E206F"
    />
    <path
      d="M14.6152 20.3848H14.6337V20.428L14.6546 20.4071H14.6758L14.648 20.4331L14.6778 20.4641H14.6558L14.6337 20.4402V20.4641H14.6152L14.6152 20.3848Z"
      fill="#0E206F"
    />
    <path
      d="M14.6855 20.4428V20.4082H14.704V20.4139V20.4255V20.4347C14.704 20.4394 14.7041 20.4427 14.7043 20.4449C14.7045 20.4469 14.7049 20.4483 14.7055 20.449C14.7063 20.4502 14.7073 20.4513 14.7085 20.452C14.7097 20.4526 14.711 20.4529 14.7127 20.4529C14.7164 20.4529 14.7194 20.4514 14.7216 20.4485C14.7238 20.4455 14.7249 20.4414 14.7249 20.4362V20.4082H14.7431V20.4651H14.7249V20.4568C14.7221 20.4602 14.7191 20.4627 14.716 20.4643C14.713 20.4658 14.7095 20.4666 14.7058 20.4666C14.6993 20.4666 14.6942 20.4647 14.6906 20.4607C14.6872 20.4565 14.6855 20.4505 14.6855 20.4428Z"
      fill="#0E206F"
    />
    <path
      d="M14.8183 20.4298V20.4647H14.7998V20.4381C14.7998 20.4332 14.7996 20.4298 14.7995 20.428C14.7993 20.4261 14.7989 20.4247 14.7983 20.4238C14.7977 20.4226 14.7967 20.4218 14.7953 20.4212C14.7941 20.4204 14.7928 20.42 14.7914 20.42C14.7874 20.42 14.7844 20.4215 14.7822 20.4244C14.7802 20.4274 14.7792 20.4315 14.7792 20.4367V20.4647H14.7607V20.4077H14.7792V20.4161C14.7818 20.4127 14.7847 20.4103 14.7879 20.4086C14.791 20.4071 14.7944 20.4062 14.798 20.4062C14.8046 20.4062 14.8095 20.4083 14.8129 20.4125C14.8165 20.4165 14.8183 20.4223 14.8183 20.4298Z"
      fill="#0E206F"
    />
    <path
      d="M13.5666 20.4902V20.5022H13.5567C13.5541 20.5022 13.5523 20.5027 13.5511 20.5037C13.55 20.5047 13.5496 20.5063 13.5496 20.5084V20.5126H13.5654V20.5254H13.5496V20.5695H13.5314V20.5254H13.5225V20.5126H13.5314V20.5084C13.5314 20.5023 13.533 20.4977 13.5365 20.4947C13.5401 20.4917 13.5455 20.4902 13.5529 20.4902L13.5666 20.4902Z"
      fill="#0E206F"
    />
    <path
      d="M13.6169 20.5287C13.6152 20.528 13.6136 20.5274 13.6121 20.5269C13.6104 20.5266 13.6089 20.5263 13.6073 20.5263C13.6025 20.5263 13.5988 20.528 13.5963 20.5311C13.5939 20.5341 13.5927 20.5384 13.5927 20.5439V20.5702H13.5742V20.5132H13.5927V20.5225C13.5949 20.5187 13.5975 20.516 13.6005 20.5144C13.6036 20.5126 13.6073 20.5117 13.6115 20.5117H13.6136C13.6143 20.5117 13.6154 20.5119 13.6166 20.512L13.6169 20.5287Z"
      fill="#0E206F"
    />
    <path
      d="M13.6534 20.5248C13.6493 20.5248 13.6463 20.5263 13.6441 20.5293C13.6421 20.5321 13.6412 20.5362 13.6412 20.5415C13.6412 20.5471 13.6421 20.5514 13.6441 20.5544C13.6463 20.5572 13.6493 20.5585 13.6534 20.5585C13.6573 20.5585 13.6604 20.5572 13.6626 20.5544C13.6648 20.5514 13.6659 20.5471 13.6659 20.5415C13.6659 20.5362 13.6648 20.5321 13.6626 20.5293C13.6604 20.5263 13.6573 20.5248 13.6534 20.5248ZM13.6534 20.5117C13.6633 20.5117 13.671 20.5144 13.6763 20.5198C13.6818 20.525 13.6847 20.5322 13.6847 20.5415C13.6847 20.5511 13.6818 20.5585 13.6763 20.5639C13.671 20.5691 13.6633 20.5716 13.6534 20.5716C13.6436 20.5716 13.6359 20.5691 13.6304 20.5639C13.6248 20.5585 13.6221 20.5511 13.6221 20.5415C13.6221 20.5322 13.6248 20.525 13.6304 20.5198C13.6359 20.5144 13.6436 20.5117 13.6534 20.5117Z"
      fill="#0E206F"
    />
    <path
      d="M13.7523 20.5225C13.7544 20.5191 13.7571 20.5165 13.7603 20.5147C13.7635 20.5128 13.767 20.5117 13.7711 20.5117C13.7774 20.5117 13.7822 20.5138 13.7857 20.518C13.7893 20.522 13.791 20.5277 13.791 20.5353V20.5702H13.7726V20.5403C13.7727 20.54 13.7729 20.5396 13.7729 20.5391V20.5371C13.7729 20.5329 13.7723 20.5299 13.7711 20.5281C13.7699 20.5263 13.7679 20.5254 13.7654 20.5254C13.7618 20.5254 13.759 20.5269 13.7571 20.5299C13.7553 20.5327 13.7544 20.5368 13.7544 20.5421V20.5702H13.7359V20.5403C13.7359 20.534 13.7353 20.5299 13.7341 20.5281C13.7331 20.5263 13.7312 20.5254 13.7284 20.5254C13.725 20.5254 13.7223 20.5269 13.7204 20.5299C13.7186 20.5327 13.7177 20.5368 13.7177 20.5421V20.5702H13.6992V20.5132H13.7177V20.5216C13.7199 20.5182 13.7223 20.5157 13.7252 20.5141C13.7281 20.5125 13.7314 20.5117 13.735 20.5117C13.7389 20.5117 13.7424 20.5128 13.7454 20.5147C13.7484 20.5165 13.7506 20.5191 13.7523 20.5225Z"
      fill="#0E206F"
    />
    <path
      d="M13.865 20.4961V20.5125H13.8838V20.5253H13.865V20.5498C13.865 20.5524 13.8655 20.5542 13.8665 20.5551C13.8677 20.5559 13.8699 20.5563 13.8731 20.5563H13.8823V20.5694H13.8668C13.8594 20.5694 13.8543 20.5679 13.8513 20.565C13.8483 20.562 13.8468 20.5569 13.8468 20.5498V20.5253H13.8379V20.5125H13.8468V20.4961L13.865 20.4961Z"
      fill="#0E206F"
    />
    <path
      d="M13.9521 20.5347V20.5695H13.9336V20.543C13.9336 20.5381 13.9334 20.5347 13.9333 20.5329C13.9331 20.5309 13.9327 20.5295 13.9321 20.5287C13.9315 20.5275 13.9305 20.5266 13.9291 20.526C13.9279 20.5253 13.9266 20.5248 13.9252 20.5248C13.9212 20.5248 13.9182 20.5263 13.916 20.5293C13.914 20.5323 13.913 20.5364 13.913 20.5415V20.5695H13.8945V20.4902H13.913V20.5209C13.9155 20.5176 13.9185 20.5151 13.9217 20.5135C13.9248 20.5119 13.9282 20.5111 13.9318 20.5111C13.9384 20.5111 13.9433 20.5132 13.9467 20.5174C13.9503 20.5214 13.9521 20.5271 13.9521 20.5347Z"
      fill="#0E206F"
    />
    <path
      d="M14.0269 20.5415V20.5466H13.9843C13.9849 20.551 13.9865 20.5543 13.9891 20.5564C13.9916 20.5585 13.9953 20.5594 14.0001 20.5594C14.0041 20.5594 14.008 20.559 14.012 20.5579C14.016 20.5567 14.0201 20.5549 14.0243 20.5526V20.5666C14.0201 20.5684 14.0158 20.5697 14.0114 20.5705C14.007 20.5712 14.0028 20.5716 13.9986 20.5716C13.9883 20.5716 13.9802 20.5691 13.9745 20.5639C13.9687 20.5585 13.9658 20.5511 13.9658 20.5415C13.9658 20.5324 13.9686 20.5251 13.9742 20.5198C13.9799 20.5144 13.9877 20.5117 13.9974 20.5117C14.0064 20.5117 14.0135 20.5144 14.0189 20.5198C14.0243 20.5251 14.0269 20.5324 14.0269 20.5415ZM14.0085 20.5353C14.0085 20.5319 14.0073 20.5292 14.0052 20.5272C14.0032 20.5251 14.0006 20.5239 13.9974 20.5239C13.9938 20.5239 13.9909 20.525 13.9888 20.5269C13.9865 20.5289 13.9852 20.5317 13.9846 20.5353L14.0085 20.5353Z"
      fill="#0E206F"
    />
    <path
      d="M14.0781 20.4941H14.1002L14.1276 20.5463V20.4941H14.1464V20.5702H14.1243L14.0969 20.518V20.5702H14.0781V20.4941Z"
      fill="#0E206F"
    />
    <path
      d="M14.1934 20.5248C14.1894 20.5248 14.1863 20.5263 14.1842 20.5293C14.1822 20.5321 14.1812 20.5362 14.1812 20.5415C14.1812 20.5471 14.1822 20.5514 14.1842 20.5544C14.1863 20.5572 14.1894 20.5585 14.1934 20.5585C14.1974 20.5585 14.2004 20.5572 14.2027 20.5544C14.2048 20.5514 14.2059 20.5471 14.2059 20.5415C14.2059 20.5362 14.2048 20.5321 14.2027 20.5293C14.2004 20.5263 14.1974 20.5248 14.1934 20.5248ZM14.1934 20.5117C14.2033 20.5117 14.211 20.5144 14.2164 20.5198C14.2219 20.525 14.2247 20.5322 14.2247 20.5415C14.2247 20.5511 14.2219 20.5585 14.2164 20.5639C14.211 20.5691 14.2033 20.5716 14.1934 20.5716C14.1836 20.5716 14.176 20.5691 14.1705 20.5639C14.1649 20.5585 14.1621 20.5511 14.1621 20.5415C14.1621 20.5322 14.1649 20.525 14.1705 20.5198C14.176 20.5144 14.1836 20.5117 14.1934 20.5117Z"
      fill="#0E206F"
    />
    <path
      d="M14.2373 20.5483V20.5137H14.2558V20.5193V20.531V20.5402C14.2558 20.5448 14.2559 20.5482 14.2561 20.5503C14.2562 20.5524 14.2567 20.5538 14.2573 20.5545C14.258 20.5557 14.2591 20.5568 14.2603 20.5575C14.2615 20.5581 14.2628 20.5584 14.2644 20.5584C14.2682 20.5584 14.2711 20.5569 14.2734 20.5539C14.2755 20.5509 14.2767 20.5469 14.2767 20.5417V20.5137H14.2948V20.5706H14.2767V20.5623C14.2738 20.5657 14.2708 20.5682 14.2677 20.5697C14.2647 20.5713 14.2613 20.5721 14.2576 20.5721C14.251 20.5721 14.246 20.5702 14.2424 20.5661C14.2389 20.562 14.2373 20.556 14.2373 20.5483Z"
      fill="#0E206F"
    />
    <path
      d="M14.37 20.5353V20.5702H14.3516V20.5436C14.3516 20.5387 14.3514 20.5353 14.3513 20.5335C14.351 20.5315 14.3507 20.5301 14.3501 20.5293C14.3495 20.5281 14.3484 20.5272 14.3471 20.5266C14.3459 20.5259 14.3445 20.5254 14.3432 20.5254C14.3392 20.5254 14.3361 20.5269 14.334 20.5299C14.332 20.5329 14.331 20.537 14.331 20.5421V20.5702H14.3125V20.5132H14.331V20.5216C14.3335 20.5182 14.3364 20.5157 14.3396 20.5141C14.3428 20.5125 14.3462 20.5117 14.3498 20.5117C14.3563 20.5117 14.3612 20.5138 14.3647 20.518C14.3683 20.522 14.37 20.5277 14.37 20.5353Z"
      fill="#0E206F"
    />
    <path
      d="M14.4248 20.4941H14.4573C14.467 20.4941 14.4744 20.4964 14.4797 20.5007C14.4848 20.5049 14.4874 20.511 14.4874 20.5189C14.4874 20.5269 14.4848 20.533 14.4797 20.5374C14.4744 20.5415 14.467 20.5436 14.4573 20.5436H14.4445V20.5702H14.4248L14.4248 20.4941ZM14.4445 20.5085V20.5296H14.4552C14.4589 20.5296 14.4618 20.5287 14.4639 20.5269C14.466 20.525 14.4671 20.5223 14.4671 20.5189C14.4671 20.5155 14.466 20.5129 14.4639 20.5111C14.4618 20.5093 14.4589 20.5085 14.4552 20.5085L14.4445 20.5085Z"
      fill="#0E206F"
    />
    <path
      d="M14.5436 20.5287C14.542 20.528 14.5404 20.5274 14.5388 20.5269C14.5372 20.5266 14.5356 20.5263 14.5341 20.5263C14.5293 20.5263 14.5256 20.528 14.523 20.5311C14.5207 20.5341 14.5195 20.5384 14.5195 20.5439V20.5702H14.501V20.5132H14.5195V20.5225C14.5216 20.5187 14.5242 20.516 14.5272 20.5144C14.5303 20.5126 14.5341 20.5117 14.5382 20.5117H14.5403C14.5411 20.5117 14.5421 20.5119 14.5433 20.512L14.5436 20.5287Z"
      fill="#0E206F"
    />
    <path
      d="M14.5801 20.5248C14.5761 20.5248 14.5731 20.5263 14.5709 20.5293C14.5689 20.5321 14.5679 20.5362 14.5679 20.5415C14.5679 20.5471 14.5689 20.5514 14.5709 20.5544C14.5731 20.5572 14.5761 20.5585 14.5801 20.5585C14.5841 20.5585 14.5871 20.5572 14.5894 20.5544C14.5915 20.5514 14.5927 20.5471 14.5927 20.5415C14.5927 20.5362 14.5915 20.5321 14.5894 20.5293C14.5871 20.5263 14.5841 20.5248 14.5801 20.5248ZM14.5801 20.5117C14.59 20.5117 14.5977 20.5144 14.6031 20.5198C14.6086 20.525 14.6114 20.5322 14.6114 20.5415C14.6114 20.5511 14.6086 20.5585 14.6031 20.5639C14.5977 20.5691 14.59 20.5716 14.5801 20.5716C14.5704 20.5716 14.5627 20.5691 14.5572 20.5639C14.5516 20.5585 14.5488 20.5511 14.5488 20.5415C14.5488 20.5322 14.5516 20.525 14.5572 20.5198C14.5627 20.5144 14.5704 20.5117 14.5801 20.5117Z"
      fill="#0E206F"
    />
    <path
      d="M14.6245 20.5126H14.643V20.5686C14.643 20.5762 14.6411 20.5819 14.6373 20.5859C14.6338 20.5901 14.6285 20.5922 14.6215 20.5922H14.6123V20.58H14.6156C14.6189 20.58 14.6212 20.5792 14.6224 20.5776C14.6238 20.5762 14.6245 20.5732 14.6245 20.5686L14.6245 20.5126ZM14.6245 20.4902H14.643V20.5051H14.6245V20.4902Z"
      fill="#0E206F"
    />
    <path
      d="M14.7183 20.5415V20.5466H14.6757C14.6763 20.551 14.6779 20.5543 14.6805 20.5564C14.683 20.5585 14.6867 20.5594 14.6915 20.5594C14.6955 20.5594 14.6994 20.559 14.7034 20.5579C14.7074 20.5567 14.7115 20.5549 14.7157 20.5526V20.5666C14.7115 20.5684 14.7072 20.5697 14.7028 20.5705C14.6984 20.5712 14.6942 20.5716 14.69 20.5716C14.6797 20.5716 14.6716 20.5691 14.6659 20.5639C14.6601 20.5585 14.6572 20.5511 14.6572 20.5415C14.6572 20.5324 14.66 20.5251 14.6656 20.5198C14.6713 20.5144 14.6791 20.5117 14.6888 20.5117C14.6978 20.5117 14.7049 20.5144 14.7103 20.5198C14.7157 20.5251 14.7183 20.5324 14.7183 20.5415ZM14.6999 20.5353C14.6999 20.5319 14.6987 20.5292 14.6966 20.5272C14.6946 20.5251 14.692 20.5239 14.6888 20.5239C14.6853 20.5239 14.6823 20.525 14.6802 20.5269C14.6779 20.5289 14.6766 20.5317 14.676 20.5353L14.6999 20.5353Z"
      fill="#0E206F"
    />
    <path
      d="M14.7789 20.515V20.5296C14.7765 20.528 14.774 20.5268 14.7714 20.526C14.7688 20.5253 14.7662 20.5248 14.7637 20.5248C14.7585 20.5248 14.7545 20.5263 14.7518 20.5293C14.7489 20.5323 14.7476 20.5364 14.7476 20.5415C14.7476 20.5469 14.7489 20.5511 14.7518 20.5541C14.7545 20.557 14.7585 20.5585 14.7637 20.5585C14.7665 20.5585 14.7691 20.5582 14.7717 20.5573C14.7743 20.5566 14.7767 20.5552 14.7789 20.5535V20.5684C14.7761 20.5696 14.7732 20.5703 14.7703 20.5707C14.7673 20.5713 14.7643 20.5716 14.7613 20.5716C14.7509 20.5716 14.7429 20.5691 14.7372 20.5639C14.7313 20.5585 14.7285 20.5511 14.7285 20.5415C14.7285 20.5322 14.7313 20.525 14.7372 20.5198C14.7429 20.5144 14.7509 20.5117 14.7613 20.5117C14.7643 20.5117 14.7673 20.512 14.7703 20.5126C14.7732 20.5131 14.7761 20.5138 14.7789 20.515Z"
      fill="#0E206F"
    />
    <path
      d="M14.8152 20.4961V20.5125H14.834V20.5253H14.8152V20.5498C14.8152 20.5524 14.8157 20.5542 14.8167 20.5551C14.8179 20.5559 14.8201 20.5563 14.8233 20.5563H14.8325V20.5694H14.817C14.8096 20.5694 14.8045 20.5679 14.8015 20.565C14.7985 20.562 14.797 20.5569 14.797 20.5498V20.5253H14.7881V20.5125H14.797V20.4961L14.8152 20.4961Z"
      fill="#0E206F"
    />
  </svg>
);
