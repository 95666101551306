import Script from 'next/script';
import { useEffect, useState } from 'react';
import classes from './Reviews.module.scss';
import classNames from 'classnames';
interface IRatingsBar {
  supplierId: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  mode?: 'default' | 'minimal' | 'extended';
  hideText?: boolean;
  color?: string;
  className?: string;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ratingSnippet: any;
  }
}

const RatingsBar: React.FC<IRatingsBar> = ({
  supplierId,
  onClick,
  mode = 'default',
  hideText = false,
  color = '#EFC830',
  className,
}) => {
  const [scriptReady, setScriptReady] = useState(false);
  useEffect(() => {
    if (!window?.ratingSnippet) {
      return;
    }
    window.ratingSnippet('ruk_rating_snippet', {
      store: process.env.NEXT_PUBLIC_REVIEWS_STORE_KEY,
      mode,
      color,
      linebreak: false,
      text: hideText ? '' : 'Reviews',
      singularText: hideText ? '' : 'Review',
      lang: 'en',
      usePolaris: false,
      showEmptyStars: true,
    });
  }, [scriptReady, mode, hideText, color]);

  if (!supplierId) {
    return null;
  }
  return (
    <>
      <div
        className={classNames('ruk_rating_snippet', classes.ratingsBar, className)}
        data-sku={supplierId}
        onClick={(e) => onClick?.(e)}
      ></div>
      <Script
        src="https://widget.reviews.io/rating-snippet/dist.js"
        onLoad={() => setScriptReady(true)}
      ></Script>
    </>
  );
};

export default RatingsBar;
