import classNames from 'classnames';

export const ChildAndGuardian = ({
  className = '',
  color = 'currentColor',
  size = 14,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#FB54AE" clipPath="url(#a)">
      <path d="M8.633 1.513C8.093.93 7.338.608 6.505.608c-.837 0-1.594.32-2.132.9-.544.586-.809 1.382-.746 2.242.123 1.697 1.414 3.078 2.878 3.078 1.464 0 2.754-1.38 2.879-3.077.063-.852-.204-1.647-.75-2.238Zm1.785 10.323H1.823c-.2 0-.26-.223-.215-.493A4.345 4.345 0 0 1 3.38 8.476c.855-.617 1.939-.957 3.051-.957 1.113 0 2.196.34 3.052.957a4.344 4.344 0 0 1 1.771 2.866.946.946 0 0 1-.167.734.73.73 0 0 1-.252.209c-.097.049-.417-.449-.417-.449Z" />
    </g>
    <path
      fill="#FB54AE"
      d="M11.677 3.325a2.435 2.435 0 0 0-1.814-.77c-.713 0-1.359.271-1.817.765-.463.5-.689 1.179-.636 1.912.105 1.446 1.206 2.622 2.453 2.622 1.248 0 2.346-1.176 2.453-2.622.054-.726-.173-1.404-.64-1.907Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.863 7.854c1.248 0 2.346-1.176 2.453-2.622.054-.726-.173-1.404-.64-1.907a2.435 2.435 0 0 0-1.813-.77c-.713 0-1.359.271-1.817.765-.463.5-.689 1.179-.636 1.912.105 1.446 1.206 2.622 2.453 2.622Zm1.804-2.67c-.088 1.192-.977 2.02-1.804 2.02-.827 0-1.717-.828-1.804-2.02-.04-.569.134-1.065.464-1.421.327-.353.796-.558 1.34-.558.538 0 1.005.205 1.336.562.335.362.51.858.468 1.417Z"
      clipRule="evenodd"
    />
    <path
      fill="#FB54AE"
      d="M13.288 12.474H6.312a.575.575 0 0 1-.264-.06.623.623 0 0 1-.215-.179.805.805 0 0 1-.143-.625A3.702 3.702 0 0 1 7.2 9.168c.729-.526 1.652-.816 2.6-.816.948 0 1.871.29 2.6.816a3.702 3.702 0 0 1 1.51 2.442.806.806 0 0 1-.142.625.621.621 0 0 1-.215.178.575.575 0 0 1-.265.061Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.312 12.474h6.976a.575.575 0 0 0 .265-.06.621.621 0 0 0 .215-.179.806.806 0 0 0 .142-.625 3.702 3.702 0 0 0-1.51-2.442c-.729-.526-1.652-.816-2.6-.816-.948 0-1.871.29-2.6.816a3.702 3.702 0 0 0-1.51 2.442.805.805 0 0 0 .143.625.623.623 0 0 0 .215.178.575.575 0 0 0 .264.061Zm.028-.65h6.92a.192.192 0 0 0 .008-.107 3.052 3.052 0 0 0-1.248-2.021 3.819 3.819 0 0 0-2.22-.693c-.819 0-1.607.25-2.219.692a3.053 3.053 0 0 0-1.249 2.022.192.192 0 0 0 .008.106Z"
      clipRule="evenodd"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h14.034v12.758H0z" transform="translate(.69 .276)" />
      </clipPath>
    </defs>
  </svg>
);
