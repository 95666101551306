import { Box, Spoiler, Stack, Text, useMantineTheme } from '@mantine/core';
import TrialsOnBlocksBadge from 'components/TrialsOnBlocksBadge/TrialsOnBlocksBadge';
import dayjs from 'dayjs';
import { BlockTrialTypeEnum } from 'enums';
import { AnonymisedBookingTicketSessionsType, AnonymisedBookingTicketType } from 'types';
import { Actions, trackAction } from 'utils/amplitude';
import { getUniqueSessions } from '../Success.utils';
import classes from './SessionsList.module.scss';

interface ISessionsListProps {
  bookedTicket: AnonymisedBookingTicketType;
  hideEndTimes: boolean;
}

const SessionItem: React.FC<{
  session: AnonymisedBookingTicketSessionsType;
  hideEndTimes: boolean;
  postTrialSessions?: boolean;
}> = ({ session, hideEndTimes, postTrialSessions = false }) => {
  const theme = useMantineTheme();
  const dateString = dayjs(session.sessionStart).utc().format('DD MMM');
  const sessionStartTime = dayjs(session.sessionStart).utc().format('HH:mm');
  const sessionEndTime = dayjs(session.sessionEnd).utc().format('HH:mm');
  const timeString = hideEndTimes ? sessionStartTime : `${sessionStartTime} - ${sessionEndTime}`;

  return (
    <div className={classes.sessionDateInfo} key={`${session.id}`}>
      <Text c={postTrialSessions ? theme.colors.gray[5] : theme.colors.blue[8]}>
        {dateString}
        {' - '}
        {timeString}
      </Text>
    </div>
  );
};

const SpoilerContent: React.FC<{
  sessions: AnonymisedBookingTicketSessionsType[];
  hideEndTimes: boolean;
  postTrialSessions?: boolean;
}> = ({ sessions, hideEndTimes, postTrialSessions = false }) => {
  return (
    <Box>
      <Spoiler
        maxHeight={sessions.length > 0 ? 55 : 9999}
        showLabel="View more"
        hideLabel="See less"
        classNames={{ control: classes.spoilerControl }}
        onClick={() => trackAction(Actions.VIEW_DATES_DETAILS)}
      >
        {sessions.map((session, index) => (
          <SessionItem
            key={`${session.id}-${index}`}
            session={session}
            hideEndTimes={hideEndTimes}
            postTrialSessions={postTrialSessions}
          />
        ))}
      </Spoiler>
    </Box>
  );
};

const SessionsList: React.FC<ISessionsListProps> = ({ bookedTicket, hideEndTimes }) => {
  const theme = useMantineTheme();

  const blockTrialType = bookedTicket.blockTrialType || BlockTrialTypeEnum.NO_TRIAL;
  const isBlockTrialCheckout = blockTrialType !== BlockTrialTypeEnum.NO_TRIAL;
  const blockTrialSessionCount = bookedTicket.blockTrialSessionCount || 0;

  const filteredUniquesSessions = getUniqueSessions(bookedTicket.sessions);
  return (
    <>
      {isBlockTrialCheckout ? (
        <>
          <Text size="xs" fw={600} c={theme.colors.gray[6]} mb="xs">
            TRIAL SESSION{blockTrialSessionCount > 1 ? 'S' : ''}:
          </Text>
          <TrialsOnBlocksBadge
            blockTrialType={blockTrialType}
            blockTrialSessionCount={blockTrialSessionCount}
          />
          <Stack gap={0}>
            {filteredUniquesSessions.slice(0, blockTrialSessionCount).map((session) => (
              <SessionItem session={session} hideEndTimes={hideEndTimes} key={session.id} />
            ))}
          </Stack>
          {filteredUniquesSessions.slice(blockTrialSessionCount).length > 0 ? (
            <>
              <Text size="xs" mt="lg" mb="xs" fw={400} c={theme.colors.blue[8]}>
                If you choose to go ahead after the trial session, these are the dates you will
                purchase.
              </Text>
              <SpoilerContent
                sessions={filteredUniquesSessions.slice(blockTrialSessionCount)}
                hideEndTimes={hideEndTimes}
                postTrialSessions={true}
              />
            </>
          ) : (
            <Text size="xs" mt="lg" fw={400} c={theme.colors.blue[8]}>
              There are no sessions beyond the trial dates.
            </Text>
          )}
        </>
      ) : (
        <>
          <Text c={theme.colors.gray[6]} fw={600} size="xs" mb="xs">
            Selected sessions:
          </Text>
          <SpoilerContent sessions={filteredUniquesSessions} hideEndTimes={hideEndTimes} />
        </>
      )}
    </>
  );
};

export default SessionsList;
