import { gql } from '@apollo/client';

const GetSupplierSlugByIdQuery = gql`
  query getSupplierSlugByIdQuery($supplierId: UUID!) {
    marketplaceSupplier(supplierId: $supplierId) {
      id
      slug
    }
  }
`;

export default GetSupplierSlugByIdQuery;
