import {
  Box,
  Grid,
  Group,
  Image,
  Text,
  Title,
  Flex,
  Spoiler,
  Center,
  Loader,
  useMantineTheme,
} from '@mantine/core';
import {
  ActivitiesMap,
  BackButton,
  ReviewsWidget,
  ActivitiesByLocationAccordion,
} from 'components';
import { ActivitySearchResult } from 'types';
import { Supplier } from 'interfaces';
import { VerifiedButton, VerifiedModal } from 'components/VerifiedModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import PebblePromiseBanner from 'components/PebblePromiseBanner/PebblePromiseBanner';
import { CloudinaryImage } from 'components/ui';
import { useMediaQuery, useScrollIntoView } from '@mantine/hooks';
import CategoryIcon from 'components/ui/CategoryIcon/CategoryIcon';
import SocialLinksBanner from 'components/SocialLinksBanner/SocialLinksBanner';
import SupplierMainInfoBanner from 'components/SupplierMainInfoBanner/SupplierMainInfoBanner';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { useLazyQuery, useQuery } from '@apollo/client';
import { ActivitiesQuery } from 'graphql/queries';
import {
  getMatchingSearchVariables,
  hasSearchCriteria,
  getAllActivitiesDistanceVariables,
  getNumberOfVenues,
} from './SupplierDetails.utils';
import ImageCarousel from 'components/ActivityDetails/ImageCarousel/ImageCarousel';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './SupplierDetails.module.scss';
import classNames from 'classnames';
import CloudinaryLogo from 'components/ui/CloudinaryLogo/CloudinaryLogo';
import { getAgeRangeLabel } from 'utils/filters/filters';
import { MarkerProvider } from 'components/ActivitiesMap/useMapMarkerContext';

interface ISupplierDetailsProps {
  supplier: Supplier;
  goBack: () => void;
  hasReviews: boolean;
}

const SupplierDetails: React.FC<ISupplierDetailsProps> = ({ supplier, goBack, hasReviews }) => {
  const theme = useMantineTheme();

  const {
    searchFilterState,
    locationString,
    selectedDatesLabel,
    selectedDaysLabels,
    searchFilterState: { selectedAgeStart, selectedAgeEnd },
  } = useSavedSearchValues();

  const ageRangeLabel = getAgeRangeLabel(true, selectedAgeStart, selectedAgeEnd);

  const {
    cloudinaryImageId,
    additionalImageIds,
    categories,
    facebook,
    twitter,
    instagram,
    averageRating,
    numberOfRatings,
    reviewsEnabled,
  } = supplier;
  const [showVerifiedModal, setShowVerifiedModal] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width: 768px)', true);
  const [spoilerOpened, setSpoilerOpened] = useState<boolean>(false);
  const spoilerControlRef = useRef<HTMLButtonElement>(null);
  const [searchCriteriaActivities, setSearchCriteriaActivities] = useState([]);

  const [logoError, setLogoError] = useState(false);
  const isMobileLogoShown = Boolean(supplier.logoImageId && isMobile && !logoError);

  const { scrollIntoView: scrollToReviews, targetRef: reviewsRef } =
    useScrollIntoView<HTMLDivElement>({
      duration: 750,
      offset: 96,
    });

  const { data, loading } = useQuery(ActivitiesQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      suppliers: [supplier.id],
      isOnline: true,
      limit: 500,
      ...getAllActivitiesDistanceVariables(searchFilterState.coordinates),
    },
  });

  const activities = useMemo<ActivitySearchResult[]>(() => {
    if (!data) {
      return [];
    }
    return data.marketplaceActivities.items;
  }, [data]);

  const variables = useMemo(() => {
    return getMatchingSearchVariables(searchFilterState, supplier.id, categories);
  }, [searchFilterState, supplier.id, categories]);

  const [getActivities] = useLazyQuery(ActivitiesQuery, {
    fetchPolicy: 'cache-first',
    variables,
    onCompleted: (searchData) => {
      setSearchCriteriaActivities(searchData.marketplaceActivities.items);
    },
  });

  const activitiesWithLocation = useMemo(
    () => activities.filter((activity) => !activity.isOnline),
    [activities],
  );

  const activitiesHaveLocation = useMemo(
    () => activitiesWithLocation.length > 0,
    [activitiesWithLocation],
  );

  const numberOfVenues = useMemo(() => {
    return getNumberOfVenues(activities);
  }, [activities]);

  const showReviewsWidgets = supplier?.reviewsEnabled && hasReviews;

  const hasSavedSearch =
    hasSearchCriteria(searchFilterState) && searchCriteriaActivities.length > 0;

  const getSingleImage = () => {
    if (cloudinaryImageId) {
      return (
        <section>
          <div className={classes.imageWrapper}>
            <CloudinaryImage id={cloudinaryImageId} alt={`${supplier.name}`} />
          </div>
        </section>
      );
    } else {
      return (
        <Image
          src={
            isMobile
              ? '../../pebble_placeholder_image.png'
              : '../../../../pebble_placeholder_activity_details.png'
          }
          width="100%"
          height="100%"
          fit="contain"
          alt="Patterns with shapes and lines"
        />
      );
    }
  };

  const allActivitiesOnline = useMemo(
    () => activities.every((activity) => activity.isOnline),
    [activities],
  );

  useEffect(() => {
    if (hasSearchCriteria(searchFilterState)) {
      getActivities();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilterState]);

  const getFilterTags = () => {
    const filteredData = Object.entries(searchFilterState).filter(([key]) =>
      ['selectedDistance'].includes(key),
    );

    const distanceFilter = filteredData[0][1];

    return (
      <>
        <Text className={classes.filterTags}>{locationString}</Text>
        <Text className={classes.filterTags}>
          {distanceFilter} {distanceFilter > 1 ? 'Miles' : 'Mile'}
        </Text>
        <Text className={classes.filterTags}> {ageRangeLabel || 'All ages'}</Text>
        <Text className={classes.filterTags}>
          {selectedDatesLabel()} {selectedDaysLabels()}
        </Text>
      </>
    );
  };

  return (
    <MarkerProvider>
      <div className={classes.fullWidthSectionWhite}>
        <div className={classes.container}>
          <Box>
            <Group justify="left">
              <BackButton onClick={goBack} />
            </Group>
          </Box>

          <div className={classes.desktopHeaderWrapper}>
            <div className={classNames({ [classes.mobileLogoSectionWrapper]: isMobileLogoShown })}>
              <section className={classes.imageWrapper}>
                {additionalImageIds && additionalImageIds?.length > 0 && cloudinaryImageId ? (
                  <ImageCarousel
                    allImageIds={[cloudinaryImageId, ...additionalImageIds]}
                    supplierName={supplier.name}
                    trackEvent={() =>
                      trackAction(Actions.SUPPLIER_CAROUSEL, {
                        supplierId: supplier.id,
                      })
                    }
                  />
                ) : (
                  getSingleImage()
                )}
              </section>
              {isMobileLogoShown && (
                <div data-testid="cloudinary-logo-wrapper">
                  <CloudinaryLogo
                    id={supplier.logoImageId || ''}
                    alt={`${supplier.name} provider logo`}
                    setLogoError={setLogoError}
                  />
                </div>
              )}
            </div>
            <SupplierMainInfoBanner
              allActivitiesOnline={allActivitiesOnline}
              ageRange={supplier.ageRange}
              supplierStarRating={averageRating}
              supplierReviewsTotal={numberOfRatings}
              activitiesWithLocation={numberOfVenues}
              scrollToReviews={scrollToReviews}
              logoImageId={supplier.logoImageId}
              supplierName={supplier.name}
              reviewsEnabled={reviewsEnabled}
            />

            <div className={classes.headerWrapper}>
              <Title className={classes.heading}>{supplier.seoPageTitle}</Title>
              <VerifiedButton onClick={() => setShowVerifiedModal(true)} />
            </div>
          </div>

          <Grid gutter={{ lg: '5.5rem' }} mt="lg">
            <Grid.Col span={{ base: 12, lg: 7 }}>
              {supplier.aboutUs && (
                <section className={classes.section}>
                  <Title className={classes.sectionHeading} mb={isMobile ? 'sm' : 'md'}>
                    About {supplier.name}
                  </Title>
                  <Spoiler
                    maxHeight={isMobile ? 95 : 112}
                    controlRef={spoilerControlRef}
                    showLabel="Read more"
                    hideLabel="Read less"
                    classNames={{
                      root: classNames({
                        [classes.spoilerGradient]: Boolean(
                          !spoilerOpened && spoilerControlRef.current,
                        ),
                      }),
                      control: classes.spoilerControl,
                    }}
                    onClick={(event) => {
                      if ((event.target as HTMLElement).innerText === 'Read more') {
                        trackAction(Actions.SUPPLIER_READ_MORE, { supplierId: supplier.id });
                      }
                      setSpoilerOpened((event.target as HTMLElement).innerText === 'Read more');
                    }}
                  >
                    <div
                      className={classes.sectionContent}
                      dangerouslySetInnerHTML={{ __html: supplier.aboutUs }}
                    />
                  </Spoiler>
                </section>
              )}
              <Flex wrap="wrap" justify="start" align="center" gap="md" className={classes.section}>
                {categories?.length > 0 &&
                  categories.map((category) => {
                    return (
                      <CategoryIcon
                        data-cy="category-badge"
                        category={category}
                        key={category.id}
                        mediumSizeText
                      ></CategoryIcon>
                    );
                  })}
              </Flex>
            </Grid.Col>
            {!isMobile && (
              <Grid.Col span={{ base: 12, lg: 5 }}>
                {activitiesHaveLocation && !isMobile && (
                  <div className={classes.section}>
                    <Title className={classes.sectionHeading}>Where to find us</Title>
                    <Text mb="md" c={theme.colors.gray[6]} size="sm">
                      We have a total of {numberOfVenues} venues
                    </Text>
                    <Box className={classes.mapWrapper}>
                      <ActivitiesMap activities={activitiesWithLocation} supplierId={supplier.id} />
                    </Box>
                  </div>
                )}
              </Grid.Col>
            )}
          </Grid>
        </div>

        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <div className={classes.fullWidthSectionGray}>
            {hasSavedSearch && (
              <>
                <Title
                  pt={34}
                  className={classNames(classes.sectionHeading, classes.accordionSectionTitle)}
                >
                  Matching your search criteria
                </Title>
                <Text
                  mt={0}
                  mb="xs"
                  size={isMobile ? 'xs' : 'sm'}
                  fw={600}
                  c={theme.colors.gray[6]}
                  className={classes.accordionSectionTitle}
                >
                  Ordered by nearest and next available date
                </Text>
                <Box className={classes.filterTagWrapper}>{getFilterTags()}</Box>
                <ActivitiesByLocationAccordion
                  activities={searchCriteriaActivities}
                  supplierId={supplier.id}
                />
              </>
            )}

            {activities.length > 0 && (
              <>
                <Title
                  pt={isMobile && hasSavedSearch ? 26 : 34}
                  pb={isMobile && !hasSavedSearch ? 16 : 24}
                  className={classNames(classes.sectionHeading, classes.accordionSectionTitle)}
                >
                  All upcoming classes
                </Title>
                <ActivitiesByLocationAccordion activities={activities} supplierId={supplier.id} />
              </>
            )}
          </div>
        )}

        <div className={classes.fullWidthSectionWhite}>
          <div className={classes.container}>
            {activitiesHaveLocation && isMobile && (
              <div className={classes.section}>
                <Title className={classes.sectionHeading} mt={40}>
                  Where to find us
                </Title>
                <Text mb="md" color={theme.colors.gray[6]} size="sm">
                  We have a total of {numberOfVenues} venues
                </Text>
                <Box className={classes.mapWrapper}>
                  <ActivitiesMap activities={activitiesWithLocation} supplierId={supplier.id} />
                </Box>
              </div>
            )}

            {showReviewsWidgets && (
              <div ref={reviewsRef}>
                <Title order={3} className={classes.sectionHeader} my="lg">
                  What families are saying about {supplier.name}
                </Title>
                <ReviewsWidget supplierId={supplier.id} reviewsPerPage={4} />
              </div>
            )}

            <PebblePromiseBanner supplierPage={true} />

            <SocialLinksBanner
              facebookLink={facebook}
              instagramLink={instagram}
              twitterLink={twitter}
              supplierName={supplier.name}
              supplierPage
              trackEvent={() =>
                trackAction(Actions.SUPPLIER_SOCIALS, {
                  supplierId: supplier.id,
                })
              }
            />

            <VerifiedModal
              data-cy="modal"
              opened={showVerifiedModal}
              onClose={() => setShowVerifiedModal(false)}
            />
          </div>
        </div>
      </div>
    </MarkerProvider>
  );
};

export default SupplierDetails;
