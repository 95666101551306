import { Stack, Textarea } from '@mantine/core';
import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';
import YesNoButtons from '../YesNoButtons/YesNoButtons';
import YesNoInput from '../YesNoInput/YesNoInput';

interface CustomQuestionCardBookerAnswersProps {
  customQuestion: CustomQuestionItem;
  handleUpdateBookerAnswer: (questionId: string, value: string | null) => void;
  showInput: Record<string, boolean>;
  setShowInput: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

const CustomQuestionCardBookerAnswers: React.FC<CustomQuestionCardBookerAnswersProps> = ({
  customQuestion,
  handleUpdateBookerAnswer,
  showInput,
  setShowInput,
}) => {
  const { question } = customQuestion;

  const getYesNoInputValue = () => {
    if (customQuestion.bookerAnswer === 'YES') {
      return true;
    }
    if (customQuestion.bookerAnswer === 'NO') {
      return false;
    }
    return customQuestion.bookerAnswer ? true : null;
  };

  const showYesNoInput = () => {
    return (
      (customQuestion.bookerAnswer &&
        customQuestion.bookerAnswer !== 'YES' &&
        customQuestion.bookerAnswer !== 'NO') ||
      showInput[customQuestion.id]
    );
  };

  return (
    <Stack>
      {question.type === CustomQuestionTypeEnum.YES_NO && (
        <YesNoButtons
          value={
            customQuestion.bookerAnswer === 'YES'
              ? true
              : customQuestion.bookerAnswer === 'NO'
              ? false
              : null
          }
          onYes={() => handleUpdateBookerAnswer(customQuestion.id, 'YES')}
          onNo={() => handleUpdateBookerAnswer(customQuestion.id, 'NO')}
          questionShortTitle={customQuestion.question.shortTitle}
        />
      )}

      {question.type === CustomQuestionTypeEnum.YES_NO_INPUT && (
        <YesNoInput
          value={getYesNoInputValue()}
          onYes={() => {
            setShowInput((prevState) => ({
              ...prevState,
              [customQuestion.id]: true,
            }));
            handleUpdateBookerAnswer(customQuestion.id, '');
          }}
          onNo={() => {
            setShowInput((prevState) => ({
              ...prevState,
              [customQuestion.id]: false,
            }));

            handleUpdateBookerAnswer(customQuestion.id, 'NO');
          }}
          inputValue={customQuestion.bookerAnswer}
          onInputChange={(value) => handleUpdateBookerAnswer(customQuestion.id, value)}
          showInput={showYesNoInput()}
          questionShortTitle={customQuestion.question.shortTitle}
        />
      )}

      {question.type === CustomQuestionTypeEnum.TEXT_INPUT && (
        <Textarea
          autosize
          minLength={1}
          value={customQuestion.bookerAnswer || ''}
          onChange={(event) => handleUpdateBookerAnswer(customQuestion.id, event.target.value)}
          placeholder="Add information"
          data-testid={`${customQuestion.question.shortTitle} answer input`}
        />
      )}
    </Stack>
  );
};

export default CustomQuestionCardBookerAnswers;
