import classNames from 'classnames';

export const PebblePlainLogo = ({
  className = '',
  color = '#4378FF',
  width = 48,
  height = 48,
  strokeWidth = '3',
  ...props
}) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.44615 40.0261C6.44615 40.0261 7.77895 37.2006 12.633 35.5745C16.5502 34.2898 20.7283 34.0147 24.7802 34.7749C27.7123 35.2947 32.0812 35.9477 36.3729 37.4725C40.6645 38.9972 41.824 39.9195 41.7947 42.004C41.7654 44.0885 39.6435 45.7358 38.9905 46.0024C36.5571 47.1467 33.9089 47.7626 31.2202 47.8097C26.79 47.9429 16.7914 48.0762 15.0907 47.9483C13.39 47.8203 9.22637 47.6817 7.56037 45.4773C5.89437 43.2728 5.63048 41.3589 6.44615 40.0261Z"
      fill="#4378FF"
    />
    <path
      d="M10.5297 20.562C10.5297 20.562 13.4912 17.963 20.2858 17.8564C27.0804 17.7498 34.5254 18.848 37.2017 21.7029C39.8779 24.5577 38.1933 26.9648 38.1933 26.9648C38.1933 26.9648 36.9378 29.1159 34.5041 29.6757C32.0704 30.2354 29.2715 30.8485 25.4411 30.8725C21.6106 30.8965 13.5285 30.4567 11.6972 28.8707C9.86594 27.2846 8.64243 25.9812 8.65043 24.2059C8.65843 22.4306 10.5297 20.562 10.5297 20.562Z"
      fill="#4378FF"
    />
    <path
      d="M15.6748 5.88774C17.6036 4.63581 19.8582 3.97944 22.1576 4.00049C25.9374 4.06713 27.1662 4.81883 27.1662 4.81883C27.1662 4.81883 29.7092 6.39954 30.3943 6.60212C31.0793 6.80471 32.2842 7.22321 32.708 8.45205C33.1318 9.68089 33.7316 11.0643 32.7826 11.8853C31.8337 12.7064 31.5325 13.1488 29.392 13.7193C27.5945 14.166 25.7378 14.327 23.8902 14.1964C22.4588 14.1298 19.0788 13.7833 17.9033 13.7806C16.7277 13.7779 14.5419 12.5118 14.2701 11.0217C13.9982 9.53162 14.0461 6.59146 15.6748 5.88774Z"
      fill="#4378FF"
    />
  </svg>
);
