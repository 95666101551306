import classNames from 'classnames';

export const NoResults = ({ className = 'icon', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 93 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {props.title && <title>{props.title}</title>}
    <path
      d="M10.1981 10.8417L10.2001 10.8397L10.4688 10.5567C16.9347 4.16675 25.6797 0.553837 34.8137 0.500597C43.9502 0.447342 52.7397 3.95996 59.2817 10.2774C62.543 13.4334 65.1443 17.1951 66.9372 21.3473C68.7302 25.4995 69.6796 29.961 69.7313 34.4768C69.7831 38.9925 68.9362 43.4742 67.2389 47.6657C65.5416 51.8571 63.0272 55.6763 59.8392 58.9048C58.1418 60.6223 56.2625 62.1535 54.2338 63.472C47.5957 67.7837 39.6764 69.7346 31.7726 69.0046C23.8706 68.2748 16.4534 64.9088 10.7348 59.4584C4.2737 53.0197 0.603051 44.3383 0.502137 35.2607C0.401203 26.1813 3.8794 17.4203 10.1981 10.8417ZM54.9439 54.1955L54.9475 54.1918C57.5138 51.5955 59.5391 48.5236 60.9076 45.1513C62.2761 41.7789 62.9609 38.1723 62.9228 34.5375C62.8847 30.9027 62.1245 27.311 60.6857 23.9675C59.2468 20.6241 57.1576 17.5945 54.5375 15.0517C51.9174 12.5088 48.8178 10.5025 45.4157 9.14692C42.0136 7.7914 38.3755 7.11319 34.7092 7.15091C27.3052 7.22708 20.2341 10.2143 15.0515 15.457C9.83922 20.6705 6.92948 27.723 6.96385 35.0636C6.99821 42.4047 9.97426 49.4306 15.2358 54.5961C20.4971 59.7613 27.6128 62.6432 35.0172 62.6092C42.4212 62.5752 49.5092 59.6283 54.722 54.4153C54.7222 54.4151 54.7225 54.4148 54.7228 54.4145L54.9439 54.1955Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M61.6366 62.3513L61.6375 62.3505C61.982 62.0075 62.3201 61.6584 62.6517 61.3035C64.5218 59.3019 67.6283 58.9341 69.6316 60.6706L88.5863 77.1005C90.5691 78.8192 90.6668 81.8629 88.7984 83.7052L82.4809 89.9343C80.6419 91.7476 77.6573 91.6443 75.948 89.7083L59.3877 70.9512C57.5983 68.9245 57.9907 65.7397 60.0609 63.8598C60.4861 63.4737 60.9029 63.0783 61.3111 62.6739L61.3111 62.6739L61.6366 62.3513Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M30.1055 44.2499C35.7883 39.5319 38.7546 39.6876 43.653 44.2499"
      stroke={color}
      strokeWidth="3"
    />
    <path
      d="M31.1104 31.3232C31.1104 33.2703 29.6237 34.8008 27.8472 34.8008C26.0707 34.8008 24.584 33.2703 24.584 31.3232C24.584 29.3762 26.0707 27.8457 27.8472 27.8457C29.6237 27.8457 31.1104 29.3762 31.1104 31.3232Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M46.6651 31.3232C46.6651 33.2703 45.1784 34.8008 43.4019 34.8008C41.6254 34.8008 40.1387 33.2703 40.1387 31.3232C40.1387 29.3762 41.6254 27.8457 43.4019 27.8457C45.1784 27.8457 46.6651 29.3762 46.6651 31.3232Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M49.1751 30.8257C49.1751 33.8403 46.7075 36.2919 43.654 36.2919C40.6004 36.2919 38.1328 33.8403 38.1328 30.8257C38.1328 27.811 40.6004 25.3594 43.654 25.3594C46.7075 25.3594 49.1751 27.811 49.1751 30.8257Z"
      stroke={color}
    />
    <path
      d="M33.6204 30.8257C33.6204 33.8403 31.1528 36.2919 28.0993 36.2919C25.0457 36.2919 22.5781 33.8403 22.5781 30.8257C22.5781 27.811 25.0457 25.3594 28.0993 25.3594C31.1528 25.3594 33.6204 27.811 33.6204 30.8257Z"
      stroke={color}
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="5.5948"
      y2="-0.5"
      transform="matrix(0.912742 -0.408535 0.414793 0.909916 22.3066 23.1211)"
      stroke={color}
      strokeLinecap="round"
    />
    <line
      x1="0.5"
      y1="-0.5"
      x2="6.67684"
      y2="-0.5"
      transform="matrix(0.943351 0.331796 -0.337233 0.941421 39.7656 19.9399)"
      stroke={color}
      strokeLinecap="round"
    />
  </svg>
);
