import { Text, useMantineTheme } from '@mantine/core';
import Image from 'next/legacy/image';
import { GreenRectangle } from '@icons';
import classes from './HomepagePartnerWithUs.module.scss';
import { PebbleButtonSet } from 'components/ui';
import { PebbleButtonsEnum } from 'enums';
import { useMediaQuery } from '@mantine/hooks';

const HomepagePartnerWithUs: React.FC = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <div className={classes.wrapper}>
      <div>
        <Text component="h2" className={classes.heading}>
          One platform for all your activities
        </Text>
        <div className={classes.greenRectangle}>
          <GreenRectangle size={30} />
        </div>
      </div>
      <div className={classes.flexRow}>
        <div className={classes.emptyDiv}></div>
        <div className={classes.textWrapper}>
          <Text component="h3" className={classes.subheading}>
            Are you an activity provider? <br /> List with us
          </Text>
          <Text className={classes.textCopy}>
            Get more visibility, manage your listings and business all on one simple platform.
          </Text>
          <PebbleButtonSet
            btnVariant={PebbleButtonsEnum.PRIMARY}
            size="lg"
            component="a"
            data-testid="link"
            href="https://www.bookpebble.co.uk/activity-suppliers"
            target="_blank"
            w={isMobile ? '85%' : 211}
            mb="xl"
          >
            Partner With Us
          </PebbleButtonSet>
        </div>
        <div className={classes.imageWrapper}>
          <div className={classes.imageContainer}>
            <Image
              src="/provisional_img.png"
              alt="Laptop and phone with Pebble app"
              objectPosition="bottom"
              width={1125}
              height={705}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepagePartnerWithUs;
