import { gql } from '@apollo/client';

const GetSupplierBySlug = gql`
  query getSupplierBySlug($supplierSlug: String!) {
    marketplaceSupplierBySlug(supplierSlug: $supplierSlug) {
      id
      name
      slug
      cloudinaryImageId
      additionalImageIds
      logoImageId
      aboutUs
      website
      ageRange
      location {
        addressLine1
        addressLine2
        city
        postCode
        country
        latitude
        longitude
      }
      activitiesLocation
      facebook
      instagram
      twitter
      reviewsEnabled
      categories {
        name
        id
        slug
      }
      averageRating
      numberOfRatings
      seoPageTitle
      seoPageDescription
    }
  }
`;

export default GetSupplierBySlug;
