import { Accordion, Affix, Button, Flex, Indicator, Text, useMantineTheme } from '@mantine/core';
import { CaretDown, CaretUp, Lightning, ToteSimple } from '@phosphor-icons/react';
import classes from './ScheduleBasket.module.scss';
import ScheduleBasketItem from './ScheduleBasketItem/ScheduleBasketItem';
import { useBasket } from 'context/BasketContext';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import { Actions, trackAction } from 'utils/amplitude';
import { PricingPolicyEnum } from 'enums';
import { formatPenceToPounds } from 'utils/formatPrice';
interface IScheduleBasket {
  handleCheckoutButton: () => Promise<void>;
}

const ScheduleBasket: React.FC<IScheduleBasket> = ({ handleCheckoutButton }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const { basketSchedules, basketAccordionValue, setBasketAccordionValue } = useBasket();

  const hasBasketSchedules = basketSchedules.length > 0;

  const minimiseAndViewBasketButtons = () => (
    <Button
      rightSection={basketAccordionValue ? <CaretDown weight="bold" /> : <CaretUp weight="bold" />}
      variant="transparent"
      radius={6}
      className={classes.minimiseCta}
    >
      {!basketAccordionValue ? 'View basket' : 'Minimise'}
    </Button>
  );

  const getPrice = () => {
    const price = basketSchedules.reduce((acc, basketSchedule) => {
      const { ticket, selectedSessions } = basketSchedule;

      const numberOfAvailableSessions =
        selectedSessions.length > 1
          ? selectedSessions.length
          : selectedSessions[0].numberOfSessionsAvailable;

      if (ticket.pricingPolicy === PricingPolicyEnum.BLOCK_FULL_PRICE) {
        return acc + ticket.ticketPrice;
      } else {
        return acc + ticket.ticketPrice * numberOfAvailableSessions;
      }
    }, 0);

    return price;
  };

  const checkoutButton = () => {
    return (
      <Button
        data-cy="checkout-button"
        onClick={handleCheckoutButton}
        classNames={{ root: classes.checkoutButton, inner: classes.checkoutButtonInner }}
        leftSection={
          <Lightning
            weight="fill"
            color={basketSchedules.length !== 0 ? theme.colors.blue[8] : theme.colors.gray[4]}
          />
        }
        rightSection={<Text fw={400}>{getPrice() > 0 ? formatPenceToPounds(getPrice()) : ''}</Text>}
        disabled={basketSchedules.length === 0}
      >
        Check out{getPrice() > 0 && ':'}
      </Button>
    );
  };

  return (
    <Affix position={{ bottom: 0, right: 0, left: 0 }} className={classes.basketWrapper} p={0}>
      <Accordion
        data-testid="basket-popup"
        value={basketAccordionValue}
        onChange={(val) => {
          setBasketAccordionValue(val);
          if (!val) {
            trackAction(Actions.BASKET_COLLAPSE_V2);
          } else {
            trackAction(Actions.BASKET_EXPAND_V2);
          }
        }}
        classNames={{
          content: classes.accordionContent,
          root: classNames(classes.accordionRoot, {
            [classes.accordionMobileRoot]: basketAccordionValue,
          }),
          chevron: classes.accordionChevron,
          control: classNames(classes.accordionControl, {
            [classes.disableControlClick]: !hasBasketSchedules,
          }),
          panel: classes.accordionPanel,
        }}
        chevron={<CaretUp />}
      >
        <Accordion.Item value="info" className={classes.accordionItem}>
          <Accordion.Control p="4px 0px">
            <Flex>
              <Flex justify="start" w="100%" align="center">
                <Text fw={700} size="16px" c={theme.colors.blue[8]} mr="sm">
                  Basket
                </Text>
                <Indicator
                  classNames={{
                    root: classes.basketScheduleAmountRoot,
                    indicator: classes.basketScheduleAmount,
                  }}
                  color={theme.colors.red[6]}
                  size={20}
                  label={basketSchedules.length}
                  data-testid="amount-of-basket-items"
                  offset={6}
                >
                  <ToteSimple size={36} color={theme.colors.blue[8]} />
                </Indicator>
                {!isMobile && hasBasketSchedules && minimiseAndViewBasketButtons()}
              </Flex>
              {!isMobile && !basketAccordionValue && checkoutButton()}
            </Flex>
          </Accordion.Control>
          <Accordion.Panel>
            <Flex justify="space-between" align="end">
              <Flex direction="row" gap="12px" wrap={'wrap'}>
                {basketSchedules.map((basketSchedule, index) => {
                  return (
                    <ScheduleBasketItem
                      key={`${basketSchedule.ticket.ticketId}-${index}}`}
                      basketSchedule={basketSchedule}
                      index={index}
                      ticketInfo={basketSchedule.ticket}
                    />
                  );
                })}
                {isMobile && checkoutButton()}
              </Flex>
              {!isMobile && basketAccordionValue && hasBasketSchedules && checkoutButton()}
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Affix>
  );
};

export default ScheduleBasket;
