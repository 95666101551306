import classNames from 'classnames';

export const Play = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.9008 11.7401H16.099C16.3855 11.7401 16.6199 11.5051 16.6199 11.2191V3.02092C16.6199 2.73493 16.3855 2.5 16.099 2.5H7.9008C7.6143 2.5 7.37988 2.73493 7.37988 3.02092V11.2191C7.37988 11.5051 7.6143 11.7401 7.9008 11.7401ZM11.143 6.21936L11.9999 4.4847L12.8568 6.21936L14.7722 6.49806L13.3856 7.84828L13.7127 9.75588L11.9999 8.85469L10.2871 9.75588L10.6143 7.84828L9.2281 6.49806L11.143 6.21936Z"
      fill="white"
    />
    <path
      d="M11.2186 12.2607H3.02092C2.73441 12.2607 2.5 12.4946 2.5 12.7817V20.9788C2.5 21.2659 2.73441 21.4998 3.02092 21.4998H11.2186C11.5051 21.4998 11.7395 21.2659 11.7395 20.9788V12.7817C11.7395 12.4946 11.5051 12.2607 11.2186 12.2607ZM7.11951 19.0712C5.90994 19.0712 4.92853 18.0904 4.92853 16.8797C4.92853 15.6702 5.90994 14.6882 7.11951 14.6882C8.33013 14.6882 9.31102 15.6702 9.31102 16.8797C9.31102 18.0904 8.33013 19.0712 7.11951 19.0712Z"
      fill="white"
    />
    <path
      d="M20.9791 12.2607H12.7814C12.4949 12.2607 12.2605 12.4946 12.2605 12.7817V20.9788C12.2605 21.2659 12.4949 21.4998 12.7814 21.4998H20.9791C21.2656 21.4998 21.5 21.2659 21.5 20.9788V12.7817C21.5 12.4946 21.2656 12.2607 20.9791 12.2607ZM18.0922 18.9796H15.6689L14.4572 16.8797L15.6689 14.782H18.0922L19.3038 16.8797L18.0922 18.9796Z"
      fill="white"
    />
  </svg>
);
