import { gql } from '@apollo/client';

const getPromotion = gql`
  query GetPromotion(
    $code: String!
    $bookingType: BookingTypeEnum!
    $bookingDate: Date!
    $amount: Int!
    $supplier: UUID
    $isSingleSession: Boolean
  ) {
    promotionForGuardians(
      code: $code
      bookingType: $bookingType
      bookingDate: $bookingDate
      amount: $amount
      supplier: $supplier
      isSingleSession: $isSingleSession
    ) {
      id
      finalAmount
      discount
      originalAmount
    }
  }
`;

export default getPromotion;
