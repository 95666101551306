import {
  ActionIcon,
  Button,
  Flex,
  Modal,
  Rating,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './ReviewSection.module.scss';
import { setCookie } from 'cookies-next';
import { XCircle, Star, X, CheckCircle } from '@phosphor-icons/react';

interface ReviewSectionProps {
  fullName: string;
  email: string;
  activityId: string;
}

const ReviewSection: React.FC<ReviewSectionProps> = (props) => {
  const theme = useMantineTheme();
  const [opened, { open, close }] = useDisclosure(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState<string>('');
  const [reviewActionText, setReviewActionText] = useState<string | null>(null);
  const [showReviewError, setShowReviewError] = useState(false);
  const [isLowReview, setIsLowReview] = useState(false);
  const [isAnonymousSubmission, setIsAnonymousSubmission] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const { fullName, email, activityId } = props;
  const firstName = fullName && fullName.split(' ')[0];
  const successColor = '#148867';

  const cookieOptions = {
    maxAge: 7890000, // 3 months
    path: '/',
  };

  enum ReviewTextOptions {
    SUCCESS = 'Review submitted, thanks!',
    FAIL = 'Rating not submitted',
    EMAIL = 'Please wait for an e-mail window to appear. Alternatively, please email us at support@bookpebble.co.uk',
  }

  const showError = useMemo(() => {
    return review.length < 10
      ? 'For a review to be completed you need to leave a comment about your Pebble experience.'
      : false;
  }, [review]);

  const submitReview = async (isAnonymous: boolean) => {
    const response = await fetch('/api/company_review/add_review', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        name: isAnonymous ? 'anonymous' : firstName,
        email: email,
        rating,
        order_id: activityId,
        comments: review,
        tag: isAnonymous ? 'anonymous' : '',
      }),
    });
    return response;
  };

  const reviewSubmitted = useMemo(
    () =>
      reviewActionText === ReviewTextOptions.SUCCESS ||
      reviewActionText === ReviewTextOptions.EMAIL,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewActionText],
  );

  const getEmptyIcon = (value: number) => {
    return <Star color={theme.colors.gray[2]} data-testid={`star-empty-${value}`} weight="fill" />;
  };

  const getFullIcon = (value: number) => {
    return <Star weight="fill" color="#EFC830" data-testid={`star-${value}`} />;
  };

  return (
    <>
      <Stack mt={24} gap="xs" align="center">
        <Text component="h4">Rate your Pebble booking experience</Text>
        <Rating
          classNames={{
            root: classes.ratingContainer,
            symbolGroup: classes.symbolGroup,
            symbolBody: classes.starSymbol,
          }}
          emptySymbol={getEmptyIcon}
          fullSymbol={getFullIcon}
          data-testid={'review-component'}
          onChange={(e) => {
            if (!reviewSubmitted) {
              open();
              setRating(e);
              setReview('');
              trackAction(Actions.REVIEW_OPEN_CHECKOUT_MODAL);
            }
          }}
          value={reviewSubmitted ? rating : 0}
          readOnly={reviewSubmitted}
        />
        {!!reviewActionText && (
          <Flex align={'center'} mt={24} data-testid="helper-text">
            {reviewActionText === ReviewTextOptions.SUCCESS && (
              <CheckCircle size={28} color={successColor} weight="fill" />
            )}
            {reviewActionText === ReviewTextOptions.FAIL && (
              <XCircle size={28} color={theme.colors.gray[5]} weight="fill" />
            )}
            <Text
              className={classes.helperText}
              ml={8}
              c={
                reviewActionText === ReviewTextOptions.SUCCESS ? successColor : theme.colors.gray[5]
              }
            >
              {reviewActionText}
            </Text>
          </Flex>
        )}
      </Stack>
      <Modal
        opened={opened}
        onClose={() => {
          close();
          setRating(0);
          setReviewActionText(ReviewTextOptions.FAIL);
          setShowReviewError(false);
          trackAction(Actions.REVIEW_CLOSE_CHECKOUT);
        }}
        fullScreen={isMobile}
        centered
        withCloseButton={false}
        classNames={{
          content: classes.modal,
          body: classes.modalBody,
        }}
        size={540}
      >
        <ActionIcon
          onClick={() => {
            close();
            setReviewActionText(ReviewTextOptions.FAIL);
          }}
          className={classes.closeButton}
          variant="filled"
          role="close-button"
        >
          <X size={16} weight="bold" color="white" />
        </ActionIcon>
        <Flex direction={'column'} className={classes.modalContents} data-testid="review-modal">
          {!isLowReview ? (
            <>
              <Text className={classes.headerText}>
                How would you rate your Pebble booking experience?
              </Text>
              <Rating
                classNames={{
                  root: classes.ratingContainerModal,
                  symbolGroup: classes.symbolGroup,
                  symbolBody: classes.starSymbol,
                }}
                mt={24}
                value={rating}
                emptySymbol={getEmptyIcon}
                fullSymbol={getFullIcon}
                onChange={(e) => {
                  setRating(e);
                  trackAction(Actions.REVIEW_EDIT_CHECKOUT);
                }}
              />
              <Textarea
                placeholder="Tell us more... (min characters 10)"
                autosize
                minRows={7}
                mt={32}
                classNames={{ input: classes.reviewText, error: classes.error }}
                error={showReviewError ? showError : false}
                w={'100%'}
                required={true}
                value={review}
                onChange={(event) => setReview(event.currentTarget.value)}
                onBlur={() => trackAction(Actions.REVIEW_COMMENT_CHECKOUT)}
              />
              <Stack gap={24} mt={32} w={'100%'}>
                {fullName && (
                  <Button
                    fullWidth={true}
                    className={classes.primaryButton}
                    data-testid="name-button"
                    onClick={() => {
                      if (review.length < 10) return setShowReviewError(true);
                      if (rating <= 3) return setIsLowReview(true);
                      setIsAnonymousSubmission(false);
                      setReviewActionText(ReviewTextOptions.SUCCESS);
                      submitReview(false);
                      setShowReviewError(false);
                      close();
                      trackAction(Actions.REVIEW_SUBMIT_CHECKOUT);
                    }}
                  >
                    Submit as: <Text fs="italic">"{firstName}"</Text>
                  </Button>
                )}
                <Button
                  className={classes.secondaryButton}
                  onClick={() => {
                    if (review.length < 10) return setShowReviewError(true);
                    if (rating <= 3) return setIsLowReview(true);

                    if (review.length >= 10 && rating > 3) {
                      setReviewActionText(ReviewTextOptions.SUCCESS);
                      submitReview(true);
                      setShowReviewError(false);
                      close();
                      trackAction(Actions.REVIEW_SUBMIT_CHECKOUT);
                      setIsAnonymousSubmission(true);
                    }
                  }}
                >
                  Submit anonymously
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Text className={classes.headerText}>
                We're here to help! You can send your feedback straight to us.
              </Text>
              <Stack mt={24} w={'80%'}>
                <Button
                  component="a"
                  href={`mailto:support@bookpebble.co.uk?subject=Pebble%20feedback&body=${review}`}
                  className={classes.primaryButton}
                  onClick={() => {
                    close();
                    setReviewActionText(ReviewTextOptions.EMAIL);
                    setCookie('review-submitted', 'true', cookieOptions);
                    trackAction(Actions.NEGATIVE_REVIEW_EMAIL);
                  }}
                >
                  E-mail
                </Button>
                <Button
                  className={classes.secondaryButton}
                  onClick={() => {
                    submitReview(isAnonymousSubmission);
                    close();
                    trackAction(Actions.NEGATIVE_REVIEW_SUBMIT);
                    setReviewActionText(ReviewTextOptions.SUCCESS);
                  }}
                >
                  No, leave a review
                </Button>
              </Stack>
            </>
          )}
        </Flex>
      </Modal>
    </>
  );
};

export default ReviewSection;
