import classNames from 'classnames';

export const ParentAndBaby = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1142 6.11484C16.1142 8.38744 14.272 10.2297 11.9994 10.2297C9.72676 10.2297 7.88452 8.38748 7.88452 6.11484C7.88452 3.8422 9.72672 2 11.9994 2C14.2711 2 16.1142 3.8422 16.1142 6.11484Z"
      fill="white"
    />
    <path
      d="M8.7472 22.8308C9.06862 22.8088 9.38432 22.6811 9.63006 22.4354C10.1695 21.8959 10.1695 21.022 9.63006 20.4817L7.23539 18.087C7.21912 18.0708 7.19959 18.0594 7.18169 18.0439L5.75529 16.6175C5.39563 16.2578 5.39563 15.6753 5.7561 15.3156C6.11576 14.9559 6.69834 14.9559 7.058 15.3156L9.86365 18.1212C10.2567 18.5134 10.7302 18.7738 11.2315 18.9065H12.8043C13.3055 18.773 13.7799 18.5134 14.1721 18.1212L16.9769 15.3156C17.3366 14.9559 17.9192 14.9559 18.2788 15.3148C18.6385 15.6744 18.6385 16.257 18.2797 16.6175L16.8956 18.0024C16.8614 18.0309 16.8232 18.0553 16.7906 18.087L14.3959 20.4817C13.8565 21.0211 13.8565 21.8959 14.3959 22.4353C14.6417 22.6811 14.9574 22.8096 15.2788 22.8308H21.3759L20.209 17.1439C19.5532 13.9468 16.4823 11.4424 13.2186 11.4424H10.7808C7.51706 11.4424 4.44707 13.9468 3.79029 17.1439L2.62427 22.8308L8.7472 22.8308ZM12.0174 12.571C13.4592 12.571 14.6285 13.7395 14.6285 15.1821C14.6285 16.6247 13.4592 17.7932 12.0174 17.7932C10.5747 17.7932 9.40628 16.6247 9.40628 15.1821C9.40628 13.7395 10.5747 12.571 12.0174 12.571Z"
      fill="white"
    />
  </svg>
);
