import { Button, ButtonProps } from '@mantine/core';
import { useContext } from 'react';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import { getIframeBtnStyles } from 'utils/iframeBtnStyles';
import classes from './GetTicketsButton.module.scss';
import { Ticket } from '@phosphor-icons/react';
import classNames from 'classnames';

interface IGetTicketsButton extends ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  onClick(): void;
  isSubscription?: boolean;
}

const GetTicketsButton: React.FC<IGetTicketsButton> = ({
  children,
  radius = 'xs',
  isSubscription = false,
  ...rest
}) => {
  const { iframeTheme } = useContext(EmbedContext) as EmbedContextType;
  const colours = getIframeBtnStyles(iframeTheme);

  return (
    <Button
      {...rest}
      className={classNames(classes.button, { [classes.subscriptionButton]: isSubscription })}
      radius={radius}
      leftSection={
        <Ticket size={isSubscription ? '20px' : '16px'} className={classes.ticketIcon} />
      }
      style={colours}
    >
      {children}
    </Button>
  );
};

export default GetTicketsButton;
