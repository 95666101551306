import classNames from 'classnames';

export const Settings = ({
  className = '',
  size = 24,
  color = 'white',
  strokeColour = '#4378FF',
  withBorder = true,
  borderStrokeWidth = '2.07143',
}) => (
  <svg className={classNames('icon', className)} width={size} height={size} viewBox="0 0 32 32">
    {withBorder && (
      <path
        d="M29.4643 16C29.4643 23.4361 23.4361 29.4643 16 29.4643C8.56388 29.4643 2.53571 23.4361 2.53571 16C2.53571 8.56388 8.56388 2.53571 16 2.53571C23.4361 2.53571 29.4643 8.56388 29.4643 16Z"
        stroke={strokeColour}
        strokeWidth={borderStrokeWidth}
        fill={color}
      />
    )}
    <path
      d="M14.0341 11.4732C14.0341 12.4281 14.8082 13.2022 15.7631 13.2022C16.7181 13.2022 17.4922 12.4281 17.4922 11.4732C17.4922 10.5183 16.7181 9.74414 15.7631 9.74414C14.8082 9.74414 14.0341 10.5183 14.0341 11.4732Z"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
    <path
      d="M17.4931 16.6626C17.4931 17.6176 18.2672 18.3917 19.2221 18.3917C20.177 18.3917 20.9512 17.6176 20.9512 16.6626C20.9512 15.7077 20.177 14.9336 19.2221 14.9336C18.2672 14.9336 17.4931 15.7077 17.4931 16.6626Z"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
    <path
      d="M11.7294 21.8482C11.7294 22.8031 12.5035 23.5772 13.4584 23.5772C14.4134 23.5772 15.1875 22.8031 15.1875 21.8482C15.1875 20.8933 14.4134 20.1191 13.4584 20.1191C12.5035 20.1191 11.7294 20.8933 11.7294 21.8482Z"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={color}
    />
    <path
      d="M22.6797 11.4746L17.4925 11.4746"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6797 21.8477L15.1871 21.8477"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6797 16.6621L20.9506 16.6621"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0352 11.4746L10.0007 11.4746"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7285 21.8477L9.99946 21.8477"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4922 16.6621L9.99963 16.6621"
      stroke={strokeColour}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
