import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

const dateFormat = 'YYYY-MM-DD';

export const formatDate = (date: string) => {
  return dayjs(date, dateFormat).format('dddd Do MMMM');
};

export const formatDateWithDayAndMonth = (date: string) => {
  return dayjs(date, dateFormat).format('Do MMM');
};
