import { Group, Text, useMantineTheme } from '@mantine/core';
import { getHref } from 'utils/getHref';
import classes from './SocialLinksBanner.module.scss';
import { FacebookLogo, InstagramLogo, XLogo } from '@phosphor-icons/react';
interface ISocialLinksBannerProps {
  supplierName: string;
  facebookLink?: string | null;
  instagramLink?: string | null;
  twitterLink?: string | null;
  supplierPage?: boolean;
  trackEvent: () => void;
}

const SocialLinksBanner: React.FC<ISocialLinksBannerProps> = ({
  supplierName,
  facebookLink,
  instagramLink,
  twitterLink,
  supplierPage = false,
  trackEvent,
}: ISocialLinksBannerProps) => {
  const theme = useMantineTheme();

  const hasSocials = facebookLink || instagramLink || twitterLink;

  return (
    <>
      {supplierPage && hasSocials && (
        <Text className={classes.copy} mb="md" fw={600}>
          Follow {supplierName}
        </Text>
      )}
      {!supplierPage && (
        <>
          <Text className={classes.copy} fw={700}>
            Activity provided by
          </Text>
          <Text className={classes.copy} mb={hasSocials ? 24 : 12} fw={600}>
            {supplierName}
          </Text>
        </>
      )}
      <Group pb={theme.spacing.md}>
        {instagramLink && (
          <a
            title="instagram"
            href={getHref(instagramLink)}
            target="_blank"
            onClick={() => trackEvent()}
          >
            <InstagramLogo size={36} color={theme.colors.blue[8]} weight="fill" />
          </a>
        )}
        {facebookLink && (
          <a
            title="facebook"
            href={getHref(facebookLink)}
            target="_blank"
            onClick={() => trackEvent()}
          >
            <FacebookLogo size={36} color={theme.colors.blue[8]} weight="fill" />
          </a>
        )}
        {twitterLink && (
          <a
            title="twitter"
            href={getHref(twitterLink)}
            target="_blank"
            onClick={() => trackEvent()}
          >
            <XLogo size={36} color={theme.colors.blue[8]} weight="fill" />
          </a>
        )}
      </Group>
    </>
  );
};

export default SocialLinksBanner;
