import { createFormContext } from '@mantine/form';
import { ICheckoutAttendeesFormValues, IReviewPayFormValues } from 'interfaces';

export const [
  CheckoutAttendeesFormProvider,
  useCheckoutAttendeesFormContext,
  useCheckoutAttendeesForm,
] = createFormContext<ICheckoutAttendeesFormValues>();

export const [ReviewPayFormProvider, useReviewPayFormContext, useReviewPayForm] =
  createFormContext<IReviewPayFormValues>();
