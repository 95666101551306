import { gql } from '@apollo/client';

const CaptureAdditionalInfoForBooking = gql`
  mutation captureAdditionalInfoForBooking($input: AdditionalInfoInputType!) {
    captureAdditionalInfoForBooking(input: $input) {
      activityBookingId
      attendees {
        id
        fullName
      }
      supplierMarketingConsentGiven
    }
  }
`;

export default CaptureAdditionalInfoForBooking;
