import { Stack, Text, Button, Box } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';
import { ActivityTypeEnum } from 'enums';
import classes from './BookContainer.module.scss';
import { ListPlus } from '@phosphor-icons/react';

interface IBookingContainerProps {
  selectedSessionSpotsLeft?: number;
  waitlistLink: string;
  activityType: string;
}

const BookContainer: React.FC<IBookingContainerProps> = ({ waitlistLink, activityType }) => {
  return (
    <Box>
      <Stack align="center">
        <Button
          onClick={() => trackAction(Actions.REQUEST_WAITLIST)}
          component="a"
          href={waitlistLink}
          className={classes.enquireButton}
          radius="xl"
          size="md"
          mt="md"
          fullWidth
          leftSection={<ListPlus size={24} />}
          rel="noopener noreferrer"
        >
          Join Waitlist
        </Button>
      </Stack>

      {activityType === ActivityTypeEnum.SUBSCRIPTION && (
        <Stack
          mt="md"
          mb={8}
          className={classes.cancelWrapper}
          gap={0}
          align="center"
          justify="center"
        >
          <Text className={classes.cancelCopyBold}>Cancel anytime with provider.</Text>
          <Text className={classes.cancelCopy}>Subject to provider T&Cs</Text>
        </Stack>
      )}
    </Box>
  );
};

export default BookContainer;
