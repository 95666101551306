import classNames from 'classnames';

export const NewMapMarker = ({
  className = '',
  color = 'currentColor',
  width = 30,
  height = 41,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 44 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {props.title && <title>{props.title}</title>}
    <mask
      id="path-2-outside-1_59_5515"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={width}
      height={height}
      fill="black"
    >
      <rect fill="white" width={width} height={height} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 53C23.5 53 42 33.0457 42 22C42 10.9543 33.0457 2 22 2C10.9543 2 2 10.9543 2 22C2 33.0457 20.5 53 22 53ZM22 28C25.866 28 29 24.866 29 21C29 17.134 25.866 14 22 14C18.134 14 15 17.134 15 21C15 24.866 18.134 28 22 28Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 53C23.5 53 42 33.0457 42 22C42 10.9543 33.0457 2 22 2C10.9543 2 2 10.9543 2 22C2 33.0457 20.5 53 22 53ZM22 28C25.866 28 29 24.866 29 21C29 17.134 25.866 14 22 14C18.134 14 15 17.134 15 21C15 24.866 18.134 28 22 28Z"
      fill={color}
    />
    <path
      d="M40 22C40 24.2792 39.0201 27.2526 37.3067 30.5902C35.6189 33.8779 33.331 37.3074 30.9668 40.4364C28.606 43.5609 26.202 46.3424 24.3093 48.3305C23.36 49.3276 22.5607 50.1026 21.9744 50.6147C21.6769 50.8745 21.4684 51.0365 21.3437 51.1217C21.2793 51.1657 21.2759 51.1625 21.3206 51.1409C21.3445 51.1294 21.4067 51.1007 21.4995 51.0724C21.5837 51.0467 21.7617 51 22 51V55C22.512 55 22.9107 54.8149 23.0581 54.7439C23.2608 54.6461 23.4464 54.5296 23.5999 54.4247C23.911 54.2122 24.2538 53.9347 24.6057 53.6274C25.3182 53.0051 26.2103 52.1348 27.2063 51.0886C29.2042 48.9901 31.7065 46.0927 34.1582 42.8478C36.6065 39.6076 39.0374 35.9774 40.8652 32.4171C42.6674 28.9066 44 25.2437 44 22H40ZM22 4C31.9411 4 40 12.0589 40 22H44C44 9.84974 34.1503 0 22 0V4ZM4 22C4 12.0589 12.0589 4 22 4V0C9.84974 0 0 9.84974 0 22H4ZM22 51C22.2383 51 22.4163 51.0467 22.5005 51.0724C22.5933 51.1007 22.6555 51.1294 22.6794 51.1409C22.7241 51.1625 22.7207 51.1657 22.6563 51.1217C22.5316 51.0365 22.3231 50.8745 22.0256 50.6147C21.4393 50.1026 20.64 49.3276 19.6907 48.3305C17.798 46.3424 15.394 43.5609 13.0332 40.4364C10.669 37.3074 8.3811 33.8779 6.6933 30.5902C4.97985 27.2526 4 24.2792 4 22H0C0 25.2437 1.33265 28.9066 3.13483 32.4171C4.96265 35.9774 7.39352 39.6076 9.84178 42.8478C12.2935 46.0927 14.7958 48.9901 16.7937 51.0886C17.7897 52.1348 18.6818 53.0051 19.3943 53.6274C19.7462 53.9347 20.089 54.2122 20.4001 54.4247C20.5536 54.5296 20.7392 54.6461 20.9419 54.7439C21.0893 54.8149 21.488 55 22 55V51ZM27 21C27 23.7614 24.7614 26 22 26V30C26.9706 30 31 25.9706 31 21H27ZM22 16C24.7614 16 27 18.2386 27 21H31C31 16.0294 26.9706 12 22 12V16ZM17 21C17 18.2386 19.2386 16 22 16V12C17.0294 12 13 16.0294 13 21H17ZM22 26C19.2386 26 17 23.7614 17 21H13C13 25.9706 17.0294 30 22 30V26Z"
      fill={color}
      fillOpacity="0.2"
      mask="url(#path-2-outside-1_59_5515)"
    />
  </svg>
);
