import { Box, Image, Spoiler, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './PebblePromiseBanner.module.scss';
import classNames from 'classnames';

interface IPebblePromiseBannerProps {
  supplierPage?: boolean;
}

const PebblePromiseBanner: React.FC<IPebblePromiseBannerProps> = ({ supplierPage }) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <Box
      mt={supplierPage ? 70 : 0}
      className={classNames({ [classes.bannerContainer]: !supplierPage })}
    >
      <div className={classes.imageContainer}>
        <Image
          alt="pebble promise badge"
          src={isMobile ? '/pebble_promise_small.png' : '/pebble_promise_large.png'}
          className={classes.promiseImage}
        />
        <div
          className={classNames(classes.bannerWrapper, { [classes.supplierBanner]: supplierPage })}
        >
          <Text className={classes.heading}>Providers on Pebble promise to:</Text>
          <Spoiler
            maxHeight={isMobile ? 120 : 50}
            showLabel="Read more"
            hideLabel="Read less"
            classNames={{ control: classes.control }}
            onClick={(e) => {
              if (e.currentTarget.lastElementChild?.textContent === 'Read more') {
                trackAction(Actions.PEBBLE_PROMISE_MORE);
              }
            }}
          >
            <ul className={classes.list}>
              <li className={classes.listItem}>Make everyone feel welcome, safe and included</li>
              <li className={classes.listItem}>
                Create exciting and captivating experiences that support children’s development and
                well-being
              </li>
              <li className={classes.listItem}>
                Commit to best in class customer service, communicating clearly with attendees and
                do their best to accommodate each child’s needs
              </li>
              <li className={classes.listItem}>
                Ensure their team have all the necessary checks and qualifications
              </li>

              <li className={classes.listItem}>
                Select clean and accessible venues, with family-friendly facilities
              </li>
            </ul>
          </Spoiler>
        </div>
      </div>
    </Box>
  );
};

export default PebblePromiseBanner;
