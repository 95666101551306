import { ParsedUrlQuery } from 'querystring';

export function getCanonicalURL(query: ParsedUrlQuery) {
  const siteUrl = process.env.NEXT_PUBLIC_CANONICAL_BASE_URL;

  const { location, category, page } = query;

  const url = `${siteUrl}/activities/${category}/${location}`;

  if (!page || page === '1') {
    return url;
  }

  return `${url}?page=${page}`;
}
