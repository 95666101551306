import { Text, Title } from '@mantine/core';
import { GreenRectangle } from '@icons';
import CategoriesCarousel from './CategoryCarousel/CategoriesCarousel';
import classes from './HomepageCarousel.module.scss';

const HomepageCarousel: React.FC = () => {
  return (
    <>
      <div>
        <Title order={2} className={classes.pinkHeader}>
          Fun all year round
        </Title>
        <div className={classes.greenRectangle}>
          <GreenRectangle size={30} />
        </div>
        <Text className={classes.subheading}>Find activities your children will love</Text>
      </div>
      <CategoriesCarousel />
    </>
  );
};

export default HomepageCarousel;
