import { Flex, Grid, Rating, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from './SupplierMainInfoBanner.module.scss';
import classNames from 'classnames';
import CloudinaryLogo from 'components/ui/CloudinaryLogo/CloudinaryLogo';
import { useState } from 'react';

interface ISupplierMainInfoBannerProps {
  allActivitiesOnline: boolean;
  ageRange: string | undefined;
  supplierStarRating: number | null;
  supplierReviewsTotal: number | null;
  activitiesWithLocation: number;
  scrollToReviews: (options: { alignment: 'start' }) => void;
  logoImageId: string | null;
  supplierName: string;
  reviewsEnabled?: boolean;
}

const SupplierMainInfoBanner: React.FC<ISupplierMainInfoBannerProps> = ({
  allActivitiesOnline,
  ageRange,
  supplierStarRating,
  supplierReviewsTotal,
  activitiesWithLocation,
  scrollToReviews,
  logoImageId,
  supplierName,
  reviewsEnabled,
}: ISupplierMainInfoBannerProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)', true);

  const [logoError, setLogoError] = useState(false);

  const logoInTheGrid = Boolean(logoImageId && !isMobile && !logoError);

  const isFourColumnGrid = reviewsEnabled && logoInTheGrid;
  const isTwoColumnGrid = !reviewsEnabled && !logoInTheGrid;

  const getSupplierReviewText = () => {
    if (!supplierReviewsTotal) {
      return isMobile ? 'NO REVIEWS' : 'NO REVIEWS YET';
    } else {
      return `${supplierReviewsTotal} RATING${
        supplierReviewsTotal && supplierReviewsTotal > 1 ? 'S' : ''
      }`;
    }
  };

  return (
    <Grid className={classes.grid} justify={isFourColumnGrid ? 'space-between' : 'center'}>
      {logoInTheGrid && (
        <Grid.Col
          span={reviewsEnabled ? 2 : 4}
          pt={0}
          data-testid="cloudinary-logo-column"
          className={classNames({
            [classNames(classes.logoWithoutReviews, classes.applyRightBorder)]: !reviewsEnabled,
          })}
        >
          <CloudinaryLogo
            id={logoImageId || ''}
            alt={`${supplierName} provider logo`}
            setLogoError={setLogoError}
          />
        </Grid.Col>
      )}
      {reviewsEnabled && (
        <Grid.Col
          span={isFourColumnGrid ? 3 : 4}
          className={classNames(classes.gridColumn, {
            [classes.applyRightBorder]: ageRange !== '' || !allActivitiesOnline,
          })}
        >
          <Flex direction="column" align="center">
            <Text c={theme.colors.gray[6]} className={classes.header}>
              {getSupplierReviewText()}
            </Text>
            {supplierStarRating ? (
              <Text className={classes.middleSection}>{supplierStarRating.toFixed(1)}</Text>
            ) : (
              <Text c={theme.colors.gray[5]} size="md" fw={700} my={4}>
                -
              </Text>
            )}
            <Rating
              mt={2}
              size="xs"
              value={supplierStarRating || 0}
              readOnly
              fractions={4}
              onClick={() => {
                if (supplierReviewsTotal) {
                  scrollToReviews({ alignment: 'start' });
                }
              }}
            />
          </Flex>
        </Grid.Col>
      )}
      <Grid.Col
        span={isTwoColumnGrid ? 6 : isFourColumnGrid ? 3 : 4}
        className={classNames(classes.gridColumn, {
          [classes.applyRightBorder]: !allActivitiesOnline,
        })}
      >
        {ageRange && (
          <>
            <Flex direction="column" align="center">
              <Text color={theme.colors.gray[6]} className={classes.header}>
                SUITABLE FOR
              </Text>
              <Text className={classes.middleSection}>{ageRange}</Text>
              <Text className={classes.bottomSection}>Age Range</Text>
            </Flex>
          </>
        )}
      </Grid.Col>
      <Grid.Col
        span={isTwoColumnGrid ? 6 : isFourColumnGrid ? 3 : 4}
        className={classes.gridColumn}
      >
        {!allActivitiesOnline && (
          <>
            <Flex direction="column" align="center">
              <Text color={theme.colors.gray[6]} className={classes.header}>
                VENUES
              </Text>
              <Text
                color={theme.colors.blue[8]}
                size="md"
                fw={700}
                className={classes.middleSection}
              >
                {activitiesWithLocation}
              </Text>
              <Text className={classes.bottomSection}>In Total</Text>
            </Flex>
          </>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default SupplierMainInfoBanner;
