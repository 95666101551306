import { useEffect, useRef, useState } from 'react';
import Supercluster, { PointFeature, ClusterFeature, AnyProps } from 'supercluster';
import { BBox, Feature, Point, Properties } from '@turf/helpers';
import { useDebouncedState } from '@mantine/hooks';

interface IUseSupercluster {
  points: Feature<Point, Properties>[];
  bounds?: BBox;
  zoom: number;
}

export const useSupercluster = ({ points, bounds, zoom }: IUseSupercluster) => {
  const [clusters, setClusters] = useState<PointFeature<AnyProps>[] | ClusterFeature<AnyProps>[]>(
    [],
  );
  const [debouncedZoom, setDebouncedZoom] = useDebouncedState<number>(zoom, 200);
  const SuperclusterRef = useRef<Supercluster>(new Supercluster({ radius: 60, maxZoom: 20 }));

  useEffect(() => {
    if (zoom) {
      setDebouncedZoom(zoom);
    }
  }, [zoom, setDebouncedZoom]);

  useEffect(() => {
    if (points && SuperclusterRef.current) {
      SuperclusterRef.current.load(points as PointFeature<AnyProps>[]);
    }
  }, [points]);

  useEffect(() => {
    if (bounds && debouncedZoom && SuperclusterRef.current) {
      const c = SuperclusterRef.current.getClusters(bounds, debouncedZoom);
      setClusters(c);
    }
  }, [bounds, debouncedZoom]);

  return { clusters, supercluster: SuperclusterRef.current };
};
