import { gql } from '@apollo/client';

const initiatePonchoPay = gql`
  mutation initiatePonchoPay($basketId: UUID!) {
    initiatePonchoPayCheckout(basketId: $basketId) {
      redirectUrl
    }
  }
`;

export default initiatePonchoPay;
