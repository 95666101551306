import { gql } from '@apollo/client';

const ActivitiesQuery = gql`
  query (
    $postcode: String
    $bounds: GeoBoundsInput
    $ageStart: Int
    $ageEnd: Int
    $dayOfTheWeek: [ActivityDayOfWeekFilterEnum]
    $suppliers: [UUID]
    $text: String
    $distance: Int
    $offset: Int
    $limit: Int
    $time: ActivityTimeEnum
    $categories: [UUID!]
    $startDate: Date
    $endDate: Date
    $searchTerms: [String]
    $isOnline: Boolean
    $bookingType: [BookingTypesEnum]
    $latitude: String
    $longitude: String
  ) {
    marketplaceActivities(
      postcode: $postcode
      bounds: $bounds
      ageStart: $ageStart
      ageEnd: $ageEnd
      dayOfTheWeek: $dayOfTheWeek
      suppliers: $suppliers
      text: $text
      distance: $distance
      offset: $offset
      limit: $limit
      time: $time
      categories: $categories
      startDate: $startDate
      endDate: $endDate
      searchTerms: $searchTerms
      isOnline: $isOnline
      bookingType: $bookingType
      latitude: $latitude
      longitude: $longitude
    ) {
      total
      latitude
      longitude
      items {
        id
        slug
        name
        cloudinaryImageId
        callToAction
        supplierName
        supplierId
        location
        shortLocation
        postcode
        ageRange
        activityType
        bookingType
        times
        weekdays
        dateRange
        supplierId
        isOnline
        categories {
          id
          name
        }
        anySpotsLeft
        isInPast
        eligibleChildren {
          id
          fullName
        }
        longitude
        latitude
        socialProof {
          limitedSpots
          popular
          justAdded
        }
        reviewsEnabled
        distanceInMiles
        priceRange
        nextSessionDate
        subscriptionStart
      }
    }
  }
`;

export default ActivitiesQuery;
