import { ActionIconProps, Flex, Text, ThemeIcon } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Category } from 'types';
import { getCategoryIcon } from 'utils/categoryIcons/CategoryIcons';
import classes from './CategoryIcon.module.scss';
import classNames from 'classnames';

interface ICategoryIcon extends ActionIconProps {
  category: Category;
  categoryCarousel?: boolean;
  selectCategory?: boolean;
  mediumSizeText?: boolean;
}

const CategoryIcon: React.FC<ICategoryIcon> = ({
  category,
  categoryCarousel = false,
  selectCategory = false,
  mediumSizeText = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)', true);
  return (
    <Flex direction={categoryCarousel ? 'column' : 'row'} align="center" justify="center">
      <ThemeIcon
        title={category.name}
        size={'32px'}
        mr={categoryCarousel ? 0 : 8}
        className={classNames(classes.background, {
          [classes.deselectedState]: categoryCarousel,
          [classes.selectedState]: selectCategory,
        })}
      >
        {getCategoryIcon(category)}
      </ThemeIcon>
      <Text
        className={classNames(classes.categoryName, {
          [classes.alignText]: categoryCarousel,
          [classes.selectedState]: selectCategory,
          [classes.mediumSizeText]: mediumSizeText && !isMobile,
        })}
      >
        {category.name}
      </Text>
    </Flex>
  );
};

export default CategoryIcon;
