import classNames from 'classnames';

export const GreenSuccessCheck = ({
  className = 'icon',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="32" cy="32" r="32" fill="#C6F991" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.0978 22.5858C45.8788 23.3668 45.8788 24.6332 45.0978 25.4142L29.5028 41.0091C28.7218 41.7902 27.4554 41.7902 26.6744 41.0091L19.5858 33.9205C18.8047 33.1395 18.8047 31.8732 19.5858 31.0921C20.3668 30.3111 21.6332 30.3111 22.4142 31.0921L28.0886 36.7665L42.2693 22.5858C43.0504 21.8047 44.3167 21.8047 45.0978 22.5858Z"
      fill="#0E206F"
    />
  </svg>
);
