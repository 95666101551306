import classNames from 'classnames';

export const DanceAndDrama = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.97328 5.92645C8.70034 6.10756 8.48725 6.36555 8.36095 6.66779C8.23466 6.97002 8.20083 7.30292 8.26375 7.62438C8.32667 7.94585 8.48351 8.24143 8.71444 8.47374C8.94537 8.70606 9.24 8.86467 9.56108 8.92951C9.88216 8.99436 10.2153 8.96252 10.5182 8.83804C10.8212 8.71355 11.0805 8.50201 11.2632 8.23016C11.446 7.95832 11.544 7.63838 11.5449 7.31082C11.5458 6.98326 11.4495 6.66279 11.2683 6.38995C11.1482 6.20863 10.9935 6.05278 10.8131 5.93133C10.6327 5.80987 10.4301 5.7252 10.2169 5.68214C10.0037 5.63909 9.78417 5.63851 9.57077 5.68043C9.35737 5.72235 9.15433 5.80595 8.97328 5.92645Z"
      fill="white"
    />
    <path
      d="M7.99813 13.7595C6.48838 14.16 4.50838 15.4605 4.50838 15.4605V20.7615L5.54563 21L6.28138 16.8488C6.28138 16.8488 8.71588 15.2737 10.8106 15.8137C11.7151 16.05 20.2134 21 20.2134 21L21.2326 20.0707C21.2326 20.0707 13.4994 15.1725 12.6916 14.0655C11.9784 13.089 11.4384 11.55 11.7444 10.5825C11.9266 9.9975 17.5246 6.68775 17.5246 6.68775L17.0994 6.04875C17.0994 6.04875 13.9134 7.725 11.2134 8.8095C10.1716 9.228 9.11638 8.9175 8.34913 8.71275C7.08688 8.37975 3.78838 3 3.78838 3L3.33838 3.53325C3.96613 5.10825 4.33288 6.0375 7.75963 10.6905L7.99813 13.7595Z"
      fill="white"
    />
  </svg>
);
