import classNames from 'classnames';

export const Subscriptions = ({
  className = '',
  color = 'currentColor',
  size = 24,
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 63 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <path
      d="M45.1645 48.0682C41.2345 51.4183 36.145 53.2866 30.991 53.2866C18.8791 53.2866 8.95763 43.4296 8.95763 31.2532V29.2561H12.6299C13.2097 29.2561 13.7895 28.9339 14.0472 28.4185C14.3049 27.9031 14.3049 27.2589 13.9828 26.7435L8.6355 18.4327C8.05568 17.4663 6.50948 17.4663 5.86523 18.4327L0.646804 26.6791C0.324679 27.1945 0.324679 27.8387 0.582379 28.3541C0.840079 28.8695 1.4199 29.1916 1.99973 29.1916H5.67195V31.2532C5.67195 45.169 17.0108 56.5078 30.9265 56.5078C36.9181 56.5078 42.7163 54.3818 47.2261 50.5163C47.9347 49.9365 47.9992 48.9057 47.4193 48.197C46.9039 47.5528 45.8731 47.4883 45.1645 48.0682Z"
      fill={color}
    />
    <path
      d="M59.9809 33.314C60.5607 33.314 61.1406 33.6361 61.3983 34.1515C61.656 34.6669 61.656 35.3112 61.4627 35.8266L56.1154 44.1374C55.8577 44.6528 55.2779 44.9105 54.7625 44.9105C54.2471 44.9105 53.7317 44.5884 53.4096 44.1374L48.0623 35.8266C47.7402 35.3112 47.7402 34.6669 47.9979 34.1515C48.2556 33.6361 48.8354 33.314 49.4152 33.314H53.0874V31.3168C53.0874 19.1405 43.166 9.28348 31.0541 9.28348C25.9001 9.28348 20.8105 11.1518 16.8806 14.5019C16.1719 15.0817 15.1411 15.0173 14.5613 14.3086C13.9815 13.6 14.0459 12.5692 14.7546 11.9893C19.2643 8.12383 25.0626 5.9978 31.0541 5.9978C44.9699 5.9978 56.3087 17.3366 56.3087 31.2524V33.314H59.9809Z"
      fill={color}
    />
    <path
      d="M39.296 29.0442C40.2948 28.0454 40.2948 26.4473 39.296 25.4486C38.2972 24.4498 36.6991 24.4498 35.7003 25.4486L29.408 31.7409L26.2119 28.5448C25.2131 27.546 23.6151 27.546 22.6163 28.5448C21.6175 29.5436 21.6175 31.1416 22.6163 32.1404L27.6102 37.1343C28.1096 37.6337 28.7089 37.8335 29.408 37.8335C30.1072 37.8335 30.7064 37.5338 31.2058 37.1343L39.296 29.0442Z"
      fill={color}
    />
  </svg>
);
