import { Text, Title } from '@mantine/core';
import { PebbleTextInput } from 'components/ui';
import { Actions } from 'utils/amplitude';
import classes from './AboutYouSection.module.scss';
import { ICheckoutAttendeesFormValues } from 'interfaces';
import { UseFormReturnType } from '@mantine/form';

interface IAboutYouSectionProps {
  form: UseFormReturnType<ICheckoutAttendeesFormValues>;
}

const AboutYouSection: React.FC<IAboutYouSectionProps> = ({ form }: IAboutYouSectionProps) => {
  return (
    <>
      <Title order={3} className={classes.title}>
        A little bit about you
      </Title>

      <PebbleTextInput
        required
        label="Full name"
        placeholder="Full name"
        action={Actions.GUEST_CHECKOUT_NAME}
        blueRequired
        pt="sm"
        {...form.getInputProps('fullName')}
      />

      <PebbleTextInput
        required
        label="E-mail address"
        placeholder="your@email.com"
        action={Actions.GUEST_CHECKOUT_EMAIL}
        blueRequired
        type="email"
        pt="sm"
        {...form.getInputProps('email')}
      />
      <PebbleTextInput
        leftSection="+44"
        required
        label="Phone number"
        placeholder="Mobile number"
        action={Actions.GUEST_CHECKOUT_TELEPHONE}
        classNames={{
          section: classes.phonePrefix,
        }}
        pt="sm"
        withLeftIcon
        blueRequired
        type="tel"
        {...form.getInputProps('telephone')}
      />
      <Text size="xs" className={classes.descriptionText}>
        <b>In case Pebble / the supplier need to contact you about your booking. </b>
      </Text>
    </>
  );
};

export default AboutYouSection;
