import { Checkbox, CheckboxGroupProps, CheckboxProps } from '@mantine/core';
import { forwardRef } from 'react';
import classes from './PebbleCheckbox.module.scss';
import classNames from 'classnames';

type IPebbleCheckboxGroupProps = CheckboxGroupProps;
interface IPebbleCheckboxProps extends CheckboxProps {
  fullWidth?: boolean;
  checkboxLabelOriginal?: boolean;
  onlineBanner?: boolean;
  labelSizeMd?: boolean;
  dayOfWeekSelector?: boolean;
  isList?: boolean;
}

export const PebbleCheckboxGroup: React.FC<IPebbleCheckboxGroupProps> = (props) => {
  return <Checkbox.Group {...props}>{props.children}</Checkbox.Group>;
};

export const PebbleCheckbox: React.FC<IPebbleCheckboxProps> = forwardRef<
  HTMLInputElement,
  IPebbleCheckboxProps
>(
  (
    {
      fullWidth,
      checkboxLabelOriginal,
      onlineBanner,
      labelSizeMd,
      dayOfWeekSelector,
      isList,
      ...props
    },
    ref,
  ) => {
    return (
      <Checkbox
        ref={ref}
        {...props}
        labelPosition={props.labelPosition || 'right'}
        indeterminate={props.indeterminate || false}
        classNames={{
          root: classNames({
            [classes.checkboxRoot]: isList,
          }),
          input: classNames(classes.checkboxInput, {
            [classes.checkboxInputIndeterminate]: props.indeterminate,
            //styles for selected Online banner component
            [classes.onlineCheckboxInput]: onlineBanner,
          }),
          icon: classNames(classes.checkboxIcon, {
            [classes.checkboxIconIndeterminate]: props.indeterminate,
            [classes.disabledIcon]: props.disabled,
          }),
          label: classNames(classes.checkboxLabel, {
            [classes.disabledLabel]: props.disabled,
            [classes.checkboxLabelOriginal]: checkboxLabelOriginal,
            [classes.checkboxLabelSizeMd]: labelSizeMd,
            [classes.checkboxLabelNeat]: onlineBanner,
          }),
          labelWrapper: classNames(classes.checkboxLabelWrapper, {
            [classes.fullWidthLabel]: onlineBanner || dayOfWeekSelector,
          }),
          body: classNames(classes.checkboxBody, {
            [classes.fullWidth]: fullWidth,
          }),
          ...props.classNames,
        }}
      />
    );
  },
);
