import { ActionIcon, Box, Flex, Popover, Text, useMantineTheme } from '@mantine/core';
import { Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import TrialsOnBlocksBadge from 'components/TrialsOnBlocksBadge/TrialsOnBlocksBadge';
import { PebbleCheckbox } from 'components/ui';
import { useBasket } from 'context/BasketContext';
import { BlockTrialTypeEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './TrialsOnBlocksBanner.module.scss';

interface ITrialsOnBlocksBannerProps {
  blockTrialType: BlockTrialTypeEnum;
  blockTrialSessionCount: number;
  blockTrialPrice: number;
  blockBookingTabActive: boolean;
  supplierName: string;
  activityName: string;
}

const TrialsOnBlocksBanner: React.FC<ITrialsOnBlocksBannerProps> = ({
  blockTrialType,
  blockTrialSessionCount,
  blockTrialPrice,
  blockBookingTabActive,
  supplierName,
  activityName,
}) => {
  const theme = useMantineTheme();

  const { basketSchedules, setTrialsOnBlocksSelected, trialsOnBlocksSelected } = useBasket();

  const basketIsEmpty = basketSchedules.length === 0;
  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  } as Intl.NumberFormatOptions;

  const isFreeTrial = blockTrialType === BlockTrialTypeEnum.FREE_TRIAL;
  const isPaidTrial = blockTrialType === BlockTrialTypeEnum.PAID_TRIAL;

  const getPriceString = () => {
    if (isFreeTrial) {
      return '';
    } else {
      return ` for ${Number(blockTrialPrice / 100).toLocaleString('en-GB', opts)} per attendee`;
    }
  };

  const InfoPopover: React.FC = () => {
    return (
      <Popover
        width={260}
        position="top-end"
        withArrow
        styles={{
          dropdown: {
            backgroundColor: theme.colors.blue[8],
            color: '#fff',
            borderRadius: 4,
          },
          arrow: { backgroundColor: theme.colors.blue[8] },
        }}
      >
        <Popover.Target>
          <ActionIcon
            variant="subtle"
            size="sm"
            aria-label="Show popover"
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <Info size={18} color={theme.colors.blue[8]} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <div style={{ backgroundColor: theme.colors.blue[8], color: '#fff' }}>
            <Text>
              {supplierName} offers {blockTrialSessionCount} {isFreeTrial ? 'FREE' : 'PAID'} trial
              session
              {blockTrialSessionCount > 1 ? 's' : ''} per attendee on block bookings.
            </Text>
            <br />
            <Text>
              Payment details are collected at checkout, but{' '}
              {isFreeTrial
                ? 'no charge is made immediately.'
                : 'you only pay for the trial session immediately.'}
            </Text>
            <br />
            <Text>Upon booking, you’ll receive an email with a cancellation option.</Text>
            <br />
            <Text>
              If you do not wish to continue after the trial, cancel by 23:30 the day before the
              first paid session, or your card will be charged.
            </Text>
          </div>
        </Popover.Dropdown>
      </Popover>
    );
  };

  return (
    <>
      <Box
        className={classNames(classes.trialBanner, {
          [classes.freeTrialBanner]: isFreeTrial,
          [classes.paidTrialBanner]: isPaidTrial,
        })}
      >
        <Text lh={'18px'} size="sm" fw={600}>
          {supplierName} offer {blockTrialSessionCount}{' '}
          <span className={classes.highlightedText}>{isFreeTrial ? 'FREE' : 'PAID'}</span> Trial
          session{blockTrialSessionCount > 1 ? 's' : ''} on{' '}
          <span className={classes.highlightedText}>block bookings {getPriceString()}</span>. You
          can only book one trial for {activityName} per attendee.
        </Text>
        <TrialsOnBlocksBadge
          cornerBadge
          blockTrialType={blockTrialType}
          blockTrialSessionCount={blockTrialSessionCount}
        />
      </Box>
      {!basketIsEmpty ? (
        <Text className={classes.infoText}>
          Trials must be booked separately. Please complete your current checkout, then return to
          book a trial.
        </Text>
      ) : !blockBookingTabActive ? (
        <Text className={classes.infoText}>Trials are only available on blocks</Text>
      ) : (
        <Flex justify="space-between" mb="xl">
          <PebbleCheckbox
            checked={trialsOnBlocksSelected}
            onChange={(e) => {
              setTrialsOnBlocksSelected(e.currentTarget.checked);
              if (e.currentTarget.checked) {
                trackAction(Actions.TRIAL_CHECKBOX);
              }
            }}
            data-testid="block-trial-selection-checkbox"
            label={
              <>
                <Flex>
                  <Text size="sm" fw={600} mr={'4px'}>
                    Not been before? Tick for{' '}
                  </Text>
                  <TrialsOnBlocksBadge
                    hideIcon
                    blockTrialType={blockTrialType}
                    blockTrialSessionCount={blockTrialSessionCount}
                  />
                </Flex>
              </>
            }
            classNames={{
              label: classes.radioLabel,
            }}
          />
          <InfoPopover />
        </Flex>
      )}
    </>
  );
};

export default TrialsOnBlocksBanner;
