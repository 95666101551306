import { Category } from 'types';

type CategoryStyles = {
  backgroundColor: string;
  color: string;
};

const ColorMap: Record<string, CategoryStyles> = {
  ['Sport']: {
    backgroundColor: '#FFEBEE',
    color: '#CC1313',
  },
  ['Dance & Drama']: {
    backgroundColor: '#E8EAF6',
    color: '#1A237E',
  },
  ['Play']: {
    backgroundColor: '#FBEEE7',
    color: '#AF4A00',
  },
  ['Parent & Baby/Toddler']: {
    backgroundColor: '#E0F7FA',
    color: '#006064',
  },
  ['Music']: {
    backgroundColor: '#F3E5F5',
    color: '#4A148C',
  },
  ['Health & Wellbeing']: {
    backgroundColor: '#E8F5E9',
    color: '#1B5E20',
  },
  ['Arts & Crafts']: {
    backgroundColor: '#FAEBF6',
    color: '#A50077',
  },
  ['Educational']: {
    backgroundColor: '#FFFBEF',
    color: '#8E6F00',
  },
  ['Online']: {
    backgroundColor: '#ECECEC',
    color: '#1B223C',
  },
  ['Yoga']: {
    backgroundColor: '#E3F2FD',
    color: '#0D47A1',
  },
  ['Holiday Camp']: {
    backgroundColor: '#FFFFFF',
    color: '#3D3D3D',
  },
  ['Language']: {
    backgroundColor: '#F7F8CD',
    color: '#565801',
  },
  ['Prenatal']: {
    backgroundColor: '#E3F2FD',
    color: '#005D72',
  },
  ['Tutoring']: {
    backgroundColor: '#EFF8DC',
    color: '#4F7500',
  },
  ['First Aid']: {
    backgroundColor: '#FCD1DE',
    color: '#730022',
  },
  ['STEM']: {
    backgroundColor: '#DFD1FC',
    color: '#23006A',
  },
  //   TODO:
  ['Childcare']: {
    backgroundColor: '#FCE4EC',
    color: '#880E4F',
  },
};

export const getCategoryStyles = (category: Category): CategoryStyles => {
  return ColorMap[category.name];
};

export const getCategoryStylesByName = (categoryName: string): CategoryStyles => {
  return ColorMap[categoryName];
};
