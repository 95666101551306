import classNames from 'classnames';

export const ChildFace = ({ className = '', color = '#0E206F', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2998 14.6C11.2998 15.7555 9.56616 15.7555 9.56616 14.6C9.56616 13.4445 11.2998 13.4445 11.2998 14.6Z"
      fill={color}
    />
    <path
      d="M14.4336 14.6C14.4336 15.7555 12.7 15.7555 12.7 14.6C12.7 13.4445 14.4336 13.4445 14.4336 14.6Z"
      fill={color}
    />
    <path
      d="M11.9999 3.63379C7.38674 3.63379 3.63354 7.38699 3.63354 12.0002C3.63354 16.6134 7.38674 20.3666 11.9999 20.3666C16.6131 20.3666 20.3663 16.6134 20.3663 12.0002C20.3663 7.38699 16.6131 3.63379 11.9999 3.63379ZM11.9999 18.8338C8.23354 18.8338 5.16634 15.7674 5.16634 12.0002C5.16634 11.1072 5.33978 10.2471 5.65306 9.46659C7.03978 10.7666 8.91326 11.5666 10.9663 11.5666C13.0733 11.5666 14.9867 10.7267 16.3867 9.35959C16.2937 9.65959 16.2468 9.97287 16.2468 10.2994C16.2468 11.7994 17.2937 13.0596 18.6936 13.379C18.053 16.493 15.2928 18.8336 11.9998 18.8336L11.9999 18.8338Z"
      fill={color}
    />
    <path
      d="M12.9334 17.6004H11.067C10.7741 17.6004 10.5334 17.3606 10.5334 17.0668C10.5334 16.7738 10.7733 16.5332 11.067 16.5332H12.9334C13.2264 16.5332 13.467 16.773 13.467 17.0668C13.4663 17.3606 13.2264 17.6004 12.9334 17.6004Z"
      fill={color}
    />
  </svg>
);
