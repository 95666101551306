/* eslint-disable @typescript-eslint/no-explicit-any */
import { Map } from 'components';
import { ActivitySearchResult } from 'types';
import { useMemo } from 'react';
import { Actions, trackAction } from 'utils/amplitude';

const DEFAULT_MAX_ZOOM = 12;

interface IActivitiesMapProps {
  activities: ActivitySearchResult[];
  supplierId: string;
}

const ActivitiesMap: React.FC<IActivitiesMapProps> = (props) => {
  const { activities, supplierId } = props;

  const defaultCoordinates = useMemo(() => {
    return {
      centre: {
        lat: Number(activities[0]?.latitude),
        lng: Number(activities[0]?.longitude),
      },
    };
  }, [activities]);

  const markers = useMemo<Record<string, ActivitySearchResult[]>>(() => {
    return activities.reduce((acc: Record<string, ActivitySearchResult[]>, curr) => {
      const key = `${Number(curr.latitude)}:${Number(curr.longitude)}`;
      if (acc[key]) {
        acc[key].push(curr);
      } else {
        acc[key] = [curr];
      }
      return acc;
    }, {});
  }, [activities]);

  return (
    <Map
      centerLat={defaultCoordinates.centre.lat}
      centerLng={defaultCoordinates.centre.lng}
      zoom={DEFAULT_MAX_ZOOM}
      markers={markers}
      onDragEnd={() => trackAction(Actions.SUPPLIER_MAP, { supplierId: supplierId })}
    />
  );
};

export default ActivitiesMap;
