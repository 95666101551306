import { gql } from '@apollo/client';

const getGuestPromotion = gql`
  query GetGuestPromotion(
    $code: String!
    $phoneNumber: String!
    $bookingDate: Date!
    $amount: Int!
    $supplier: UUID
    $isSingleSession: Boolean
  ) {
    promotionForGuests(
      code: $code
      phoneNumber: $phoneNumber
      bookingDate: $bookingDate
      amount: $amount
      supplier: $supplier
      isSingleSession: $isSingleSession
    ) {
      id
      finalAmount
      discount
      originalAmount
    }
  }
`;

export default getGuestPromotion;
