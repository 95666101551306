import { gql } from '@apollo/client';

const GetActivitySlugByIdQuery = gql`
  query marketplaceActivity($activityId: UUID!) {
    marketplaceActivity(activityId: $activityId) {
      activity {
        id
        slug
      }
    }
  }
`;

// allBlockDates will have time and spotsLeft field

export default GetActivitySlugByIdQuery;
