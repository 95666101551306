import { DefaultSeoProps } from 'next-seo';

const config: DefaultSeoProps = {
  defaultTitle: 'Pebble Activities',
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://activities.bookpebble.co.uk/',
    site_name: 'Pebble Activities',
  },
  twitter: {
    handle: '@Bookpebbleuk',
    site: '@Bookpebbleuk',
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no',
    },
    {
      name: 'theme-color',
      content: '#FFF',
    },
  ],
};

export default config;
