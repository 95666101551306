import { ActionIcon, Flex, Modal, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import classes from './VerifiedModal.module.scss';
import { ShieldCheck, X } from '@phosphor-icons/react';

interface IVerifiedModalProps {
  opened: boolean;
  onClose(): void;
}

export const VerifiedModal: React.FC<IVerifiedModalProps> = ({
  opened,
  onClose,
}: IVerifiedModalProps) => {
  const theme = useMantineTheme();
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        centered
        size={875}
        classNames={{
          content: classes.modal,
          body: classes.modalBody,
        }}
        padding={0}
      >
        <ActionIcon onClick={onClose} className={classes.closeButton} variant="filled">
          <X size={16} color={theme.colors.gray[7]} weight="bold" />
        </ActionIcon>
        <Stack align="center">
          <Flex align="center" direction="column">
            <ShieldCheck size={40} color={theme.colors.lime[8]} weight="fill" />
            <Title order={1} className={classes.title}>
              Verified by Pebble
            </Title>
          </Flex>
          <Text className={classes.description}>
            Our team has checked and approved all of the details for this business and the
            activities they run to ensure that they are accurate and trustworthy. The provider has
            been fully verified by Pebble, so you can now rest assured and book with confidence!
          </Text>
        </Stack>
      </Modal>
    </>
  );
};
