import classNames from 'classnames';

export const Childcare = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2851 0.64919C12.0808 0.955581 12.1289 1.37832 12.4135 1.64076C6.04726 2.35054 1.08613 7.78554 1.08613 14.3374C1.08613 21.3856 6.83966 27.1391 13.8878 27.1391C20.936 27.1391 26.6895 21.3856 26.6895 14.3374C26.6895 13.9018 26.3561 13.5683 25.9204 13.5683C25.5181 13.5683 25.1513 13.8956 25.1513 14.3374C25.1513 20.5685 20.0918 25.628 13.8607 25.628C7.6303 25.628 2.59725 20.5691 2.59725 14.3374C2.59725 8.61724 6.85896 3.90019 12.3765 3.14785C12.1024 3.41054 12.0492 3.84774 12.2936 4.16207L12.2936 4.16208L12.2953 4.16422C12.4388 4.3435 12.6572 4.45608 12.8851 4.45608C13.0086 4.45608 13.2036 4.42834 13.3409 4.29961L15.1746 2.89733L15.1747 2.89737L15.1781 2.89463C15.3615 2.74792 15.4699 2.52764 15.4699 2.27775C15.4699 2.01457 15.3224 1.79113 15.134 1.67572L13.3047 0.438254C12.969 0.155668 12.5178 0.31661 12.2877 0.645438L12.2876 0.645408L12.2851 0.64919Z"
      fill="white"
      stroke="white"
      strokeWidth="0.4"
    />
    <path
      d="M25.3191 11.7501C25.7761 11.7501 26.1466 11.3796 26.1466 10.9226C26.1466 10.4656 25.7761 10.0951 25.3191 10.0951C24.8621 10.0951 24.4916 10.4656 24.4916 10.9226C24.4916 11.3796 24.8621 11.7501 25.3191 11.7501Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
    <path
      d="M23.9636 8.24721C24.4207 8.24721 24.7912 7.87672 24.7912 7.4197C24.7912 6.96268 24.4207 6.59219 23.9636 6.59219C23.5066 6.59219 23.1361 6.96268 23.1361 7.4197C23.1361 7.87672 23.5066 8.24721 23.9636 8.24721Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
    <path
      d="M21.409 5.39076C21.866 5.39076 22.2365 5.02027 22.2365 4.56325C22.2365 4.10623 21.866 3.73574 21.409 3.73574C20.9519 3.73574 20.5814 4.10623 20.5814 4.56325C20.5814 5.02027 20.9519 5.39076 21.409 5.39076Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
    <path
      d="M18.0056 3.58315C18.4627 3.58315 18.8331 3.21266 18.8331 2.75564C18.8331 2.29861 18.4627 1.92812 18.0056 1.92812C17.5486 1.92812 17.1781 2.29861 17.1781 2.75564C17.1781 3.21266 17.5486 3.58315 18.0056 3.58315Z"
      fill="white"
      stroke="white"
      strokeWidth="0.3"
    />
    <path
      d="M11.366 11.8983C12.6981 11.8983 13.778 10.8427 13.778 9.5406C13.778 8.23846 12.6981 7.18286 11.366 7.18286C10.034 7.18286 8.9541 8.23846 8.9541 9.5406C8.9541 10.8427 10.034 11.8983 11.366 11.8983Z"
      fill="white"
    />
    <path
      d="M18.7388 11.6002C19.4872 11.6002 20.0938 11.0056 20.0938 10.2723C20.0938 9.53887 19.4872 8.94434 18.7388 8.94434C17.9905 8.94434 17.3838 9.53887 17.3838 10.2723C17.3838 11.0056 17.9905 11.6002 18.7388 11.6002Z"
      fill="white"
    />
    <path
      d="M20.4177 14.7437C20.1467 14.2017 19.4963 14.012 18.9543 14.283L15.4583 16.0987C15.0789 15.5296 11.9895 11.302 9.14392 12.928C7.32819 13.9578 8.087 18.4565 8.60191 20.6787H14.8079L14.9976 19.1069C14.8892 19.0256 10.1737 15.4483 10.255 15.3399C10.3363 15.2315 10.526 15.2044 10.6344 15.2857L15.1331 18.7004L19.957 16.2071C20.4719 15.909 20.6887 15.2586 20.4177 14.7437Z"
      fill="white"
    />
    <path
      d="M15.9465 15.2044C15.6213 15.2044 15.3232 15.0418 15.1606 14.7437C14.9167 14.3101 15.0793 13.7952 15.5129 13.5513L18.3042 12.0336C18.7379 11.7897 19.2528 11.9523 19.4967 12.3859C19.7406 12.8196 19.578 13.3345 19.1444 13.5784L16.353 15.096C16.2175 15.1773 16.082 15.2044 15.9465 15.2044Z"
      fill="white"
    />
  </svg>
);
