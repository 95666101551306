const getURLpathnameParam = (routerPathname: string) => {
  const pathname = routerPathname.split('/')[1];
  if (pathname === '') {
    return 'homepage';
  } else {
    return pathname;
  }
};

export default getURLpathnameParam;
