import { DaysOfWeekEnum } from 'enums';

export const sortSelectedDaysOfWeek = (weekdays: string[]): string[] => {
  const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

  const sortDays = (a: string, b: string) => {
    return days.indexOf(a) - days.indexOf(b);
  };

  return weekdays.sort(sortDays);
};

export const getWeekdayBtnLabel = (item: string): string => {
  return item.charAt(0).toUpperCase() + item.slice(1, 2).toLowerCase();
};

export const getDaysOfWeekBetweenDates = (startDate: Date | null, endDate: Date | null) => {
  if (startDate && endDate) {
    // TO DO look at refactoring, need to create a new Date copy -  why?
    const startDateCopy = new Date(startDate);
    const endDateCopy = new Date(endDate);

    endDateCopy.setDate(endDateCopy.getDate() + 1);

    const includedDaysOfWeek = [];

    let i = 0;

    while (i < 7 && startDateCopy < endDateCopy) {
      includedDaysOfWeek.push(startDateCopy.getDay());
      startDateCopy.setDate(startDateCopy.getDate() + 1);
      i++;
    }
    return includedDaysOfWeek;
  }
  if (startDate && !endDate) {
    return [startDate.getDay()];
  }
  return [];
};

export const weekdays: DaysOfWeekEnum[] = [
  DaysOfWeekEnum.Mondays,
  DaysOfWeekEnum.Tuesdays,
  DaysOfWeekEnum.Wednesdays,
  DaysOfWeekEnum.Thursdays,
  DaysOfWeekEnum.Fridays,
];

export const weekends: DaysOfWeekEnum[] = [DaysOfWeekEnum.Saturdays, DaysOfWeekEnum.Sundays];
