type IframeBtnStyles = {
  backgroundColor: string;
  '&:disabled': {
    backgroundColor: string;
    color: string;
  };
};

const ColorMap: Record<string, IframeBtnStyles> = {
  ['BDCEFC']: {
    backgroundColor: '#BDCEFC',
    '&:disabled': {
      backgroundColor: '#BDCEFC',
      color: '#5565A9',
    },
  },
  ['FFB5DD']: {
    backgroundColor: '#FFB5DD',
    '&:disabled': {
      backgroundColor: '#FFB5DD',
      color: '#5565A9',
    },
  },
  ['FBE58F']: {
    backgroundColor: '#FBE58F',
    '&:disabled': {
      backgroundColor: '#FBE58F',
      color: '#5565A9',
    },
  },
  ['FFC88E']: {
    backgroundColor: '#FFC88E',
    '&:disabled': {
      backgroundColor: '#FFC88E',
      color: '#5565A9',
    },
  },
  ['FF8787']: {
    backgroundColor: '#FF8787',
    '&:disabled': {
      backgroundColor: '#FF8787',
      color: '#5565A9',
    },
  },
};

export const getIframeBtnStyles = (iframeTheme: string): IframeBtnStyles => {
  return ColorMap[iframeTheme];
};
