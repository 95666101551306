import classNames from 'classnames';

export const MobileTabTicket = ({
  className = '',
  color = 'currentColor',
  size = 32,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.2412 9.24323L26.1759 7.1779C24.8355 8.5172 22.6629 8.5172 21.3233 7.1779C19.983 5.83808 19.983 3.66573 21.3226 2.32591L19.2575 0.260576C18.9171 -0.0804952 18.3707 -0.0878126 18.0382 0.244934L14.0924 4.19077L24.3099 14.4083L28.2557 10.4625C28.5882 10.1297 28.5817 9.58357 28.2411 9.24322L28.2412 9.24323ZM0.745365 17.5382C0.412618 17.8701 0.419174 18.4163 0.759745 18.7574L2.82508 20.8227C4.16542 19.4841 6.33725 19.4849 7.67603 20.8243C9.01534 22.1641 9.01534 24.3342 7.67831 25.6755L9.74364 27.7401C10.0837 28.0804 10.6302 28.0877 10.9629 27.755L23.7075 15.011L13.4906 4.79346L0.745365 17.5382ZM17.9733 13.6799C18.1852 13.8918 18.1852 14.2352 17.9733 14.4471L12.0169 20.404C11.9109 20.5099 11.7719 20.5627 11.6332 20.5627C11.4942 20.5627 11.3557 20.5099 11.2497 20.404C11.0378 20.1921 11.0378 19.8487 11.2497 19.6368L17.2061 13.6799C17.4178 13.468 17.7614 13.468 17.9733 13.6799H17.9733ZM16.3968 12.1034C16.6087 12.3154 16.6087 12.6587 16.3968 12.8706L10.4399 18.8275C10.334 18.9335 10.195 18.9862 10.0562 18.9862C9.9172 18.9862 9.77845 18.9335 9.67249 18.8275C9.46059 18.6156 9.46059 18.2723 9.67249 18.0604L15.6294 12.1034C15.8416 11.8915 16.1849 11.8915 16.3968 12.1034H16.3968ZM14.8204 10.5267C15.0323 10.7386 15.0323 11.082 14.8204 11.2939L8.86372 17.2513C8.75777 17.3573 8.61876 17.41 8.48001 17.41C8.34101 17.41 8.20226 17.3573 8.09631 17.2513C7.8844 17.0394 7.8844 16.6961 8.09631 16.4842L14.0527 10.5265C14.2651 10.3151 14.6085 10.3151 14.8204 10.5267L14.8204 10.5267Z"
      fill={color}
    />
  </svg>
);
