import { Accordion, Button, Center, Flex, Grid, Stack, useMantineTheme } from '@mantine/core';
import { ActivitySearchResult } from 'types';
import { ProviderSearchVenue } from 'interfaces';
import { ActivityCardVariantEnum } from 'enums';
import Link from 'next/link';
import NewActivityCard from 'components/NewActivityCard/NewActivityCard';
import { useState } from 'react';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './ProviderVenues.module.scss';
import { CaretDown, MapPin, VideoCamera } from '@phosphor-icons/react';

interface IProviderVenuesProps {
  venues: ProviderSearchVenue[];
  online: {
    items: ActivitySearchResult[];
    totalActivities: number;
  } | null;
  supplierSlug: string;
  supplierId: string | undefined;
}

const ProviderVenues: React.FC<IProviderVenuesProps> = ({
  venues,
  online,
  supplierSlug,
  supplierId,
}: IProviderVenuesProps) => {
  const theme = useMantineTheme();

  const [accordionOpen, setAccordionOpen] = useState<string | null>(
    venues.length > 0
      ? `0:${venues[0].location}:${venues[0].latitude}:${venues[0].longitude}`
      : 'online',
  );

  const handleAccordionChange = (val: string | null) => {
    if (val && accordionOpen) {
      trackAction(Actions.PROVIDER_CARD_ACCORDION_EXPAND, { supplierId: supplierId });
    } else {
      const event = accordionOpen
        ? Actions.PROVIDER_CARD_ACCORDION_COLLAPSE
        : Actions.PROVIDER_CARD_ACCORDION_EXPAND;
      trackAction(event, { supplierId });
    }

    setAccordionOpen(val);
  };

  return (
    <Stack w="full" gap={0}>
      <Accordion
        value={accordionOpen}
        onChange={handleAccordionChange}
        chevron={<CaretDown size={18} weight="bold" color={theme.colors.blue[8]} />}
        chevronPosition="right"
        variant="default"
        classNames={{
          control: classes.control,
          icon: classes.icon,
          chevron: classes.chevron,
          content: classes.content,
          item: classes.item,
        }}
      >
        {venues.map((venue, venueIndex) => {
          return (
            <Accordion.Item
              key={`${venueIndex}:${venue.location}:${venue.latitude}:${venue.longitude}`}
              value={`${venueIndex}:${venue.location}:${venue.latitude}:${venue.longitude}`}
              data-cy="provider-venue-accordion"
            >
              <Accordion.Control
                onClick={() => {}}
                icon={<MapPin color={theme.colors.pink[5]} size={18} weight="fill" />}
              >
                <span className={classes.distanceInfo}>{venue.distanceInMiles}m</span>
                {' • '}
                <span style={{ fontWeight: 600 }}>{venue.location}</span>
              </Accordion.Control>
              <Accordion.Panel>
                {
                  <Grid columns={12}>
                    {venue.items.map((item, index) => {
                      if (index <= 3) {
                        return (
                          <Grid.Col
                            span={{ xs: 12, md: 6 }}
                            key={item.id}
                            data-testid="activity-card"
                          >
                            <NewActivityCard
                              activity={item}
                              variant={ActivityCardVariantEnum.MIN}
                            />
                          </Grid.Col>
                        );
                      }
                    })}
                  </Grid>
                }
                {venue.totalActivities > 4 && (
                  <Flex justify="end">
                    <Link className={classes.moreSubheader} href={`/supplier/${supplierSlug}`}>
                      +{venue.totalActivities - 4} more matching your criteria
                    </Link>
                  </Flex>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
        {online && (
          <Accordion.Item key={'online'} value={'online'}>
            <Accordion.Control
              icon={<VideoCamera color={theme.colors.blue[8]} weight="fill" size={18} />}
            >
              Online
            </Accordion.Control>
            <Accordion.Panel>
              {
                <Grid columns={12}>
                  {online.items.map((item, index) => {
                    if (index <= 3) {
                      return (
                        <Grid.Col
                          span={{ xs: 12, md: 6 }}
                          key={item.id}
                          data-testid="activity-card"
                          onClick={() =>
                            trackAction(Actions.PROVIDER_CARD_ACTIVITY, {
                              activityId: item.id,
                              supplierId: item.supplierId,
                            })
                          }
                        >
                          <NewActivityCard activity={item} variant={ActivityCardVariantEnum.MIN} />
                        </Grid.Col>
                      );
                    }
                  })}
                </Grid>
              }
              {online.totalActivities > 4 && (
                <Flex justify="end">
                  <Link className={classes.moreSubheader} href={`/supplier/${supplierSlug}`}>
                    +{online.totalActivities - 4} more matching your criteria
                  </Link>
                </Flex>
              )}
            </Accordion.Panel>
          </Accordion.Item>
        )}
      </Accordion>
      <Center>
        <Link href={`/supplier/${supplierSlug}`} passHref legacyBehavior>
          <Button fullWidth className={classes.supplierPageButton}>
            See more from this provider
          </Button>
        </Link>
      </Center>
    </Stack>
  );
};

export default ProviderVenues;
