import { gql } from '@apollo/client';

const ActivityDetailsQuery = gql`
  query marketplaceActivity($activityId: UUID!) {
    marketplaceActivity(activityId: $activityId) {
      activity {
        id
        slug
        name
        locationName
        description
        ageRange
        email
        ageMonthsStart
        ageMonthsEnd
        activityType
        bookingType
        supplierToProvide
        guardianToProvide
        cloudinaryImageId
        additionalImageIds
        allowBlockBookings
        allowIndividualBookings
        blockSubtype
        hideEndTimes
        categories {
          id
          name
        }
        locationName
        isOnline
        onlinePublicInfo
        location {
          addressLine1
          addressLine2
          city
          postCode
          country
          latitude
          longitude
        }
        supplier {
          id
          name
          website
          phoneNumber
          isClaimed
          aboutUs
          ageRange
          ageMonthsStart
          ageMonthsEnd
          cloudinaryImageId
          facebook
          instagram
          twitter
          reviewsEnabled
          links {
            name
            url
          }
          activitiesLocation
          location {
            city
            postCode
          }
        }
        subscriptionOffDates
        subscriptionTrialType
        subscriptionTrialSessionCount
        subscriptionTrialPrice
        subscriptionInfo
      }
      eligibleChildren {
        id
      }
      dateRange
      weekdays
      classes {
        id
        name
        dateRange
        weekdays
        tickets {
          block {
            id
            name
            description
            price
            capacity
            order
            isAvailable
            isDeleted
          }
          individual {
            id
            name
            description
            price
            capacity
            order
            isAvailable
            isDeleted
          }
        }
        addons {
          name
          description
          price
          isPublished
          addonType
        }
        sessions {
          date
          products {
            dateRange
            weekdays
            startTime
            endTime
            timeGroup
            spotsLeft
            numberOfSessionsAvailable
            checkoutKey
            checkoutValue
            singleSessionDate
          }
        }
        blocks {
          dateRange
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          numberOfSessionsAvailable
          checkoutKey
          checkoutValue
          allBlockDates {
            date
            isInPast
            startTime
            endTime
            spotsLeft
          }
        }
      }
      subscriptions {
        products {
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          checkoutKey
          checkoutValue
          subscriptionNextSessionDate
        }
        tickets {
          id
          name
          description
          price
          ticketType
          capacity
          isAvailable
          pricingPolicy
        }
      }
      totalActivityBookings
      lastBookingTime
      anySpotsLeft
    }
  }
`;

export default ActivityDetailsQuery;
