import amplitude from 'amplitude-js';

const API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '';

export const initializeAmplitude = (moduleName: string, deviceId?: string) => {
  amplitude.getInstance().init(API_KEY, '', {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    logAttributionCapturedEvent: true,
    deviceId: deviceId || '',
  });

  amplitude.getInstance().logEvent(`${moduleName}_app_launch}`);
};

const getPathName = (path: string[]) => {
  if (path[1] === 'checkout') {
    return `${path[1]}/${path[2]}`;
  }

  if (path[1] === 'iframe') {
    return 'widget';
  }

  return path[1];
};

export const trackPageView = (pageUrl: string, data?: any, isIframe?: boolean) => {
  const splitPath = pageUrl.split('/');

  const pageName = getPathName(splitPath);

  // initialize amplitude again for iframe route to allow log event
  if (splitPath[1] === 'iframe') {
    initializeAmplitude('marketplace');
  }

  if (data?.integrationTab) {
    amplitude.getInstance().logEvent(`page_view/${data.integrationTab}`, data);
    return;
  }

  if (splitPath[1] === 'discount-codes' && data?.discountPage) {
    amplitude.getInstance().logEvent(`page_view/${data.discountPage}`, data);
    return;
  }

  if (isIframe) {
    amplitude.getInstance().logEvent(`page_view/${pageName}_iframe`, data);
  } else {
    amplitude.getInstance().logEvent(`page_view/${pageName}`, data);
  }
};

export const trackAction = <T>(action: T, data: any = {}) => {
  amplitude.getInstance().logEvent(`action/${action}`, data);
};

export const getDeviceId = () => {
  return amplitude.getInstance().options.deviceId;
};
