import { useRouter } from 'next/router';
import { createContext, FC, useEffect, useState } from 'react';
import { initializeAmplitude } from '../../../../shared/utils/amplitude';

export interface AmplitudeContextType {
  ready: boolean;
}

export const AmplitudeContext = createContext<AmplitudeContextType | null>(null);

interface IAmplitudeProvider {
  children?: React.ReactNode;
}

export const AmplitudeContextProvider: FC<IAmplitudeProvider> = ({ children }) => {
  const router = useRouter();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const amplitudeDevId = router?.query?.deviceId;

    if (typeof amplitudeDevId === 'string') {
      initializeAmplitude('marketplace', amplitudeDevId);
      setReady(true);
      return () => console.log('amplitude initialised with device id');
    }

    initializeAmplitude('marketplace');
    setReady(true);
    return () => console.log('amplitude initialised without device id');
  }, [router.query?.deviceId]);

  return <AmplitudeContext.Provider value={{ ready }}>{children}</AmplitudeContext.Provider>;
};
