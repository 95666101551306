import classNames from 'classnames';

export const ComboBooking = ({
  className = '',
  size = 16,
  color = '#4378FF',
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="comboBookingIcon"
    {...props}
  >
    {title && <title>{title}</title>}
    <rect
      x="13.5898"
      y="2.47728"
      width="2.5"
      height="11.1441"
      rx="0.5"
      transform="rotate(90 13.5898 2.47728)"
      fill={color}
    />
    <path
      d="M13.09 6.64999C13.3661 6.64999 13.59 6.87385 13.59 7.14999L13.59 13C13.59 13.2761 13.3661 13.5 13.09 13.5L2.94546 13.5C2.66932 13.5 2.44546 13.2761 2.44546 13L2.44547 7.14999C2.44547 6.87385 2.66932 6.64999 2.94547 6.64999L13.09 6.64999Z"
      fill={color}
    />
    <rect x="0.6" y="0.6" width="14.8" height="14.8" rx="1.4" stroke={color} strokeWidth="1.2" />
  </svg>
);
