import { GoogleAutocompleteInput } from 'components/MainFilterContent/LocationFilterContent/GoogleAutocompleteInput';
import { PebbleRadio, PebbleRadioGroup } from 'components/ui';
import { Flex, Text, useMantineTheme } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { filterOptionsWithAllDaysAndTime } from 'utils/filters/filters';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { useRouter } from 'next/router';

const LocationFilterContent = () => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const {
    searchFilterState: { selectedDistance },
    setSearchFilterState,
    setModifiedFilter,
  } = useSavedSearchValues();

  const { distance } = filterOptionsWithAllDaysAndTime;

  const URLpathname = getURLpathnameParam(useRouter().pathname);

  return (
    <>
      {!isMobile && (
        <Text mb="sm" color={theme.colors.blue[8]} size="md" fw={700}>
          Location
        </Text>
      )}
      <GoogleAutocompleteInput />
      <PebbleRadioGroup
        name="distance filter option"
        label={'Within'}
        onChange={(val) => {
          setSearchFilterState((prevState) => ({
            ...prevState,
            selectedDistance: val,
          }));

          setModifiedFilter(true);
          trackAction(Actions.DISTANCE_SELECTED, { distance: val, URLpathname });
        }}
        value={selectedDistance}
        apply_filter_styling={'true'}
        mt={isMobile ? 0 : 'sm'}
        mb={'sm'}
      >
        <Flex direction={isMobile ? 'column' : 'row'}>
          {distance.map(({ label, value }) => (
            <PebbleRadio
              key={value}
              labelPosition="left"
              width={'100%'}
              value={value}
              label={label}
              pb={'sm'}
              py={isMobile ? 'xs' : 0}
              applySearchBarStyling={!isMobile}
              boldLabelFont={!isMobile}
            />
          ))}
        </Flex>
      </PebbleRadioGroup>
    </>
  );
};

export default LocationFilterContent;
