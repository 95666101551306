import { CategoriesCarouselContent } from 'types';

const categoriesCarouselContent: CategoriesCarouselContent[] = [
  {
    categoryName: 'music',
    displayName: 'Music',
    categoryImageAlt: 'guitar lesson photo',
    description: "Develop your children's talent and musical abilities",
  },
  {
    categoryName: 'arts-and-crafts',
    displayName: 'Arts & Crafts',
    categoryImageAlt: 'crafts classes photo',
    description: 'Allow your children to express themselves creatively',
  },
  {
    categoryName: 'sport',
    displayName: 'Sport',
    categoryImageAlt: 'toddlers playing football photo',
    description: 'Help your child stay active and find a sport they love',
  },
  {
    categoryName: 'dance-and-drama',
    displayName: 'Dance & Drama',
    categoryImageAlt: 'child ballet',
    description: 'Find the right classes for your child who is a natural performer.',
  },
  {
    categoryName: 'parent-and-baby-toddler',
    displayName: 'Parent & Baby/Toddler',
    categoryImageAlt: 'parent and baby photo',
    description: 'Spend quality time and create a valuable bond with your child.',
  },
  {
    categoryName: 'health-and-wellbeing',
    displayName: 'Health & Wellbeing',
    categoryImageAlt: 'baby massage photo',
    description: ' Keep your children pampered, motivated and happy',
  },
  {
    categoryName: 'educational',
    displayName: 'Educational',
    categoryImageAlt: 'children in the classroom photo',
    description: ' Give your child the opportunity to learn more while having fun.',
  },
  {
    categoryName: 'play',
    displayName: 'Play',
    categoryImageAlt: 'playing baby photo',
    description: 'Let your child have fun in a secure and safe environment.',
  },
  {
    categoryName: 'yoga',
    displayName: 'Yoga',
    categoryImageAlt: 'baby foot photo',
    description: ' Bond with your baby or benefit from postpartum exercises.',
  },
];

export default categoriesCarouselContent;
