import { gql } from '@apollo/client';

const createChild = gql`
  mutation CreateChild($child: ChildrenInput!) {
    childCreate(child: $child) {
      child {
        id
        fullName
        birthDate
        isAdult
      }
    }
  }
`;

export default createChild;
