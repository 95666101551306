import { useDisclosure, useSessionStorage } from '@mantine/hooks';
import { FiltersQuery } from 'types';
import { SearchBarDropdownEnum, DaysOfWeekEnum } from 'enums';
import {
  getWeekdayBtnLabel,
  weekdays,
} from 'components/MainFilterContent/ScheduleFilterContent/utils';
import dayjs from 'dayjs';
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import queryString from 'query-string';
import { useRouter } from 'next/router';
import { getHashQuery, getValidFiltersFromQuery } from 'utils/filters/filters';
import { GoogleContext, GoogleContextType } from './GoogleContext';

type GeolocationType = {
  lat: number;
  lng: number;
};

export interface SearchFilterState {
  coordinates: GeolocationType | null;
  selectedDistance: string;
  selectedAgeStart: string;
  selectedAgeEnd: string;
  selectedTime: string | null;
  selectedCategory: string;
  selectedSuppliers: string | string[];
  isOnline: string | undefined;
  isInstantBookOnly: string | undefined;
  daysOfWeek?: string | string[];
  startDate?: string;
  endDate?: string;
  searchTerm?: string[];
}

export interface SavedSearchFilterValuesType {
  showFiltersDrawer: boolean;
  toggle(): void;
  setActiveDesktopFilter: Dispatch<SetStateAction<boolean>>;
  activeDesktopFilter: boolean;
  setActiveSearchBarDropDown: Dispatch<SetStateAction<SearchBarDropdownEnum | null>>;
  activeSearchBarDropDown: SearchBarDropdownEnum | null;
  setActivitySearchTerms: Dispatch<SetStateAction<string[] | null>>;
  activitySearchTerms: string[] | null;
  searchFilterTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  locationString: string | undefined;
  setLocationString(value: string): void;
  startFilterDate: Date | null;
  endFilterDate: Date | null;
  setDateRange: Dispatch<SetStateAction<[Date | null, Date | null]>>;
  daysOfWeek: DaysOfWeekEnum[];
  selectedDays: string[];
  setSelectedDays: Dispatch<SetStateAction<string[]>>;
  selectedDatesLabel(): string;
  selectedDaysLabels(): string;
  redirectUrl: string;
  resetAllFilters(): void;
  isHomepage: boolean;
  setModifiedFilter: Dispatch<SetStateAction<boolean>>;
  modifiedFilter: boolean;
  showUpdateButton: boolean;
  fetching: boolean;
  setFetching: Dispatch<SetStateAction<boolean>>;
  setUserDeniedGeolocation: Dispatch<SetStateAction<boolean>>;
  userDeniedGeolocation: boolean;
  searchFilterState: SearchFilterState;
  setSearchFilterState: Dispatch<SetStateAction<SearchFilterState>>;
}

export const SavedSearchFilterValues = createContext<SavedSearchFilterValuesType | null>(null);

interface MainSearchContextProviderProps {
  children: ReactNode;
}

const getCoordinates = (filters: FiltersQuery, googleReady: boolean) => {
  if (filters.latitude && filters.longitude) {
    return { lat: Number(filters.latitude), lng: Number(filters.longitude) };
  }

  if (!googleReady) {
    return null;
  }

  if (filters.seLat && filters.seLng && filters.nwLat && filters.nwLng) {
    const seLatLng = new google.maps.LatLng(Number(filters.seLat), Number(filters.seLng));
    const nwLatLng = new google.maps.LatLng(Number(filters.nwLat), Number(filters.nwLng));
    const centre = new google.maps.LatLngBounds(seLatLng, nwLatLng).getCenter();
    return { lat: centre.lat(), lng: centre.lng() };
  }
  return null;
};

export const SavedSearchFilterValuesProvider: FC<MainSearchContextProviderProps> = ({
  children,
}) => {
  const router = useRouter();
  const { ready } = useContext(GoogleContext) as GoogleContextType;

  const { postcode, location, category } = router.query;

  const hash = getHashQuery(router.asPath);

  const { distance, latitude, longitude, seLat, seLng, nwLat, nwLng, ...rest } = hash;

  const filtersApplied = useMemo(() => {
    return getValidFiltersFromQuery(router.query, router.asPath, '5');
  }, [router.query, router.asPath]);

  const isHomepage = router.pathname === '/';

  const [showFiltersDrawer, { toggle }] = useDisclosure(false);

  const [activeDesktopFilter, setActiveDesktopFilter] = useState(isHomepage ? false : true);

  const [locationString, setLocationString] = useState<string>('');

  const [searchFilterState, setSearchFilterState] = useState<SearchFilterState>({
    coordinates:
      { lat: Number(filtersApplied.latitude), lng: Number(filtersApplied.longitude) } || null,
    selectedDistance: filtersApplied.distance || '5',
    selectedAgeStart: filtersApplied.ageStart || '',
    selectedAgeEnd: filtersApplied.ageEnd || '',
    selectedTime: filtersApplied.time || null,
    selectedCategory: filtersApplied.category || '',
    selectedSuppliers: filtersApplied.suppliers || [],
    isOnline: filtersApplied.isOnline || '',
    isInstantBookOnly: filtersApplied.isInstantBookOnly || '',
    daysOfWeek: filtersApplied.dayOfTheWeek || [],
    startDate: filtersApplied.startDate || '',
    endDate: filtersApplied.endDate || '',
    searchTerm: filtersApplied.searchTerm || [],
  });

  const { coordinates, selectedDistance, selectedAgeStart, selectedAgeEnd } = searchFilterState;

  const [activeSearchBarDropDown, setActiveSearchBarDropDown] =
    useState<SearchBarDropdownEnum | null>(isHomepage ? SearchBarDropdownEnum.location : null);

  const [searchFilterTerm, setSearchTerm] = useState('');

  const [userDeniedGeolocation, setUserDeniedGeolocation] = useState(false);

  const appliedSearchTerms = useMemo<string[] | null>(() => {
    if (!filtersApplied.searchTerm) {
      return null;
    }
    if (Array.isArray(filtersApplied.searchTerm)) {
      return filtersApplied.searchTerm;
    }
    return [filtersApplied.searchTerm];
  }, [filtersApplied.searchTerm]);

  const [activitySearchTerms, setActivitySearchTerms] = useState<string[] | null>(
    appliedSearchTerms,
  );

  const [modifiedFilter, setModifiedFilter] = useState(false);

  const [showUpdateButton, setShowUpdateButton] = useState(false);

  useEffect(() => {
    setModifiedFilter(false);

    const routePath = router.pathname.split('/')[1];

    if (routePath === 'activities') {
      setShowUpdateButton(true);
    } else {
      setShowUpdateButton(false);
    }
  }, [router]);

  const getAppliedStartDateFilter = () => {
    if (filtersApplied.startDate) {
      return new Date(filtersApplied.startDate);
    } else {
      return null;
    }
  };
  const getAppliedEndDateFilter = () => {
    if (filtersApplied.endDate) {
      return new Date(filtersApplied.endDate);
    } else {
      return null;
    }
  };

  const [[startFilterDate, endFilterDate], setDateRange] = useState<[Date | null, Date | null]>([
    getAppliedStartDateFilter(),
    getAppliedEndDateFilter(),
  ]);
  const daysOfWeek: DaysOfWeekEnum[] = [
    DaysOfWeekEnum.Mondays,
    DaysOfWeekEnum.Tuesdays,
    DaysOfWeekEnum.Wednesdays,
    DaysOfWeekEnum.Thursdays,
    DaysOfWeekEnum.Fridays,
    DaysOfWeekEnum.Saturdays,
    DaysOfWeekEnum.Sundays,
  ];

  const appliedDaysFilter = useMemo<string[] | null>(() => {
    if (!filtersApplied.dayOfTheWeek) {
      return null;
    }
    if (Array.isArray(filtersApplied.dayOfTheWeek)) {
      return filtersApplied.dayOfTheWeek;
    }
    return [filtersApplied.dayOfTheWeek];
  }, [filtersApplied.dayOfTheWeek]);

  const [selectedDays, setSelectedDays] = useState<string[]>(appliedDaysFilter || daysOfWeek);

  const [fetching, setFetching] = useState<boolean>(false);

  const [utmParams] = useSessionStorage<Record<string, string>>({
    key: 'pebble_utm_params',
  });

  const stringParams = useMemo(() => {
    const getLocationParams = () => {
      if (isHomepage) {
        return {
          distance: selectedDistance,
          latitude: coordinates?.lat,
          longitude: coordinates?.lng,
          isOnline: true,
        };
      }
      //Map search resets central coordinates
      else if (coordinates?.lat && coordinates?.lng) {
        return {
          latitude: coordinates?.lat,
          longitude: coordinates?.lng,
          distance: selectedDistance,
        };
      } else if (seLat) {
        return { seLat, seLng, nwLat, nwLng };
      } else if (postcode) {
        return { postcode, distance: selectedDistance };
      }
    };

    const { ageStart, ageEnd, startDate, endDate, dayOfTheWeek, searchTerm, ...filteredRest } =
      rest;
    const params = {
      ...filteredRest,
      ...getLocationParams(),
      ...(selectedAgeStart && {
        ageStart: selectedAgeStart,
      }),
      ...(selectedAgeEnd && {
        ageEnd: selectedAgeEnd,
      }),
      ...(startFilterDate && {
        startDate: dayjs(startFilterDate).format('YYYY-MM-DD'),
      }),
      ...(endFilterDate && {
        endDate: dayjs(endFilterDate).format('YYYY-MM-DD'),
      }),
      ...(selectedDays.length < 7 && {
        dayOfTheWeek: selectedDays,
      }),
      ...(activitySearchTerms && {
        searchTerm: activitySearchTerms,
      }),
      ...utmParams,
    };

    return queryString.stringify(params);
  }, [
    activitySearchTerms,
    coordinates?.lat,
    coordinates?.lng,
    startFilterDate,
    endFilterDate,
    isHomepage,
    nwLat,
    nwLng,
    postcode,
    rest,
    seLat,
    seLng,
    selectedAgeEnd,
    selectedAgeStart,
    selectedDays,
    selectedDistance,
    utmParams,
  ]);

  const locationKeyword = useMemo<string | string[] | undefined>(() => {
    if (coordinates) {
      return 'search';
    } else {
      return location;
    }
  }, [coordinates, location]);

  const redirectUrl = useMemo(() => {
    return `/activities/${category || 'all-activities'}/${locationKeyword}#${stringParams}`;
  }, [category, locationKeyword, stringParams]);

  useEffect(() => {
    if (seLat || (!locationString && coordinates?.lat)) {
      setLocationString('Map area');
    }
  }, [seLat, locationString, coordinates?.lat]);

  const selectedDatesLabel = (): string => {
    if (!startFilterDate && !endFilterDate) {
      if (selectedDays.length !== 7) {
        return '';
      }

      return `All Dates`;
    } else if (startFilterDate && !endFilterDate) {
      return `${dayjs(startFilterDate).format('DD MMM YYYY')} ${String.fromCharCode(0x2192)}`;
    } else {
      return `${dayjs(startFilterDate).format('DD MMM')} - ${dayjs(endFilterDate).format(
        'DD MMM YYYY',
      )}`;
    }
  };

  const selectedDaysLabels = (): string => {
    if (selectedDays.length === 7 || selectedDays.length === 0) {
      if (startFilterDate) return '';

      return '& All Days';
    } else if (selectedDays.length === 1) {
      // single day string
      return selectedDays[0].charAt(0).toUpperCase() + selectedDays[0].slice(1).toLowerCase() + 's';
    } else if (selectedDays.length === 5 && weekdays.every((day) => selectedDays.includes(day))) {
      return 'Weekdays';
    } else {
      const label = selectedDays.map((day) => {
        return getWeekdayBtnLabel(day);
      });
      return `(${label})`;
    }
  };

  const resetAllFilters = () => {
    setSearchFilterState((prevState) => ({
      ...prevState,
      selectedDistance: '5',
      selectedAgeStart: '',
      selectedAgeEnd: '',
    }));

    setActivitySearchTerms(null);
    setDateRange([null, null]);
    setSelectedDays(daysOfWeek);
  };

  const value: SavedSearchFilterValuesType = {
    showFiltersDrawer,
    toggle,
    activeDesktopFilter,
    setActiveDesktopFilter,
    setActiveSearchBarDropDown,
    activeSearchBarDropDown,
    setActivitySearchTerms,
    activitySearchTerms,
    searchFilterTerm,
    setSearchTerm,
    locationString,
    setLocationString,
    startFilterDate,
    endFilterDate,
    setDateRange,
    daysOfWeek,
    selectedDays,
    setSelectedDays,
    selectedDatesLabel,
    selectedDaysLabels,
    redirectUrl,
    resetAllFilters,
    isHomepage,
    setModifiedFilter,
    modifiedFilter,
    showUpdateButton,
    fetching,
    setFetching,
    setUserDeniedGeolocation,
    userDeniedGeolocation,
    searchFilterState,
    setSearchFilterState,
  };

  // handle updating searchFilterState when filtersApplied changes
  useEffect(() => {
    const { slug } = router.query;

    // return early if slug is detected in the URL, as this will reset the values in searchFilterState since there will be no search params present in the URL
    if (slug) return;

    setSearchFilterState({
      coordinates: getCoordinates(filtersApplied, ready),
      selectedDistance:
        filtersApplied.distance === '500' || !filtersApplied.distance
          ? '5'
          : filtersApplied.distance,
      selectedAgeStart: filtersApplied.ageStart || '',
      selectedAgeEnd: filtersApplied.ageEnd || '',
      selectedTime: filtersApplied.time || null,
      selectedCategory: filtersApplied.category || '',
      selectedSuppliers: filtersApplied.suppliers || [],
      isOnline: filtersApplied.isOnline || '',
      isInstantBookOnly: filtersApplied.isInstantBookOnly || '',
      daysOfWeek: filtersApplied.dayOfTheWeek || [],
      startDate: filtersApplied.startDate || '',
      endDate: filtersApplied.endDate || '',
      searchTerm: filtersApplied.searchTerm || [],
    });
  }, [router.query, filtersApplied, ready]);

  return (
    <SavedSearchFilterValues.Provider value={value}>{children}</SavedSearchFilterValues.Provider>
  );
};

export const useSavedSearchValues = () => {
  const context = useContext(SavedSearchFilterValues);
  if (!context) {
    throw new Error('useSavedSearchValues must be used within a SavedSearchFilterValuesProvider');
  }
  return context;
};
