import { LocationType } from 'interfaces';

export type AddressFieldsType = Array<keyof LocationType>;

const buildFullAddressFromLocation = (
  addressFields: AddressFieldsType,
  location?: LocationType,
): string => {
  if (!location) {
    return 'Unknown';
  }

  return addressFields
    .reduce<string>((address: string, field: keyof LocationType) => {
      if (location[field]) {
        return address + `${location[field]}, `;
      }
      return address;
    }, '')
    .slice(0, -2); // Remove last space and comma
};

export default buildFullAddressFromLocation;
