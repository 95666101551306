import { Select, SelectProps } from '@mantine/core';
import classes from './PebbleSelect.module.scss';

type IPebbleSelectProps = SelectProps;

const PebbleSelect: React.FC<IPebbleSelectProps> = (props) => {
  return (
    <Select
      {...props}
      withCheckIcon={false}
      allowDeselect={false}
      classNames={{
        input: classes.input,
        option: classes.item,
        label: classes.label,
        ...props.classNames,
      }}
    />
  );
};

export default PebbleSelect;
