import classNames from 'classnames';

export const RedStopSign = ({
  className = 'icon',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="32" cy="32" r="32" fill="#E74522" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7998 32.7998C12.7998 30.5907 14.5907 28.7998 16.7998 28.7998H47.1998C49.4089 28.7998 51.1998 30.5907 51.1998 32.7998C51.1998 35.0089 49.4089 36.7998 47.1998 36.7998H16.7998C14.5907 36.7998 12.7998 35.0089 12.7998 32.7998Z"
      fill="white"
    />
  </svg>
);
