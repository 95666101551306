import classNames from 'classnames';

export const SadClipboard = ({ className = '', color = 'currentColor', size = 91, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 91 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M71.0938 11.375H58.2045C56.6066 9.58599 54.6489 8.15463 52.4596 7.17461C50.2703 6.19458 47.8987 5.68799 45.5 5.68799C43.1013 5.68799 40.7297 6.19458 38.5404 7.17461C36.3511 8.15463 34.3934 9.58599 32.7955 11.375H19.9062C18.3978 11.375 16.9512 11.9742 15.8846 13.0408C14.818 14.1074 14.2188 15.5541 14.2188 17.0625V76.7812C14.2188 78.2896 14.818 79.7363 15.8846 80.8029C16.9512 81.8695 18.3978 82.4687 19.9062 82.4687H71.0938C72.6022 82.4687 74.0488 81.8695 75.1154 80.8029C76.182 79.7363 76.7812 78.2896 76.7812 76.7812V17.0625C76.7812 15.5541 76.182 14.1074 75.1154 13.0408C74.0488 11.9742 72.6022 11.375 71.0938 11.375ZM45.5 11.375C48.5168 11.375 51.4101 12.5734 53.5433 14.7066C55.6766 16.8399 56.875 19.7331 56.875 22.75H34.125C34.125 19.7331 35.3234 16.8399 37.4567 14.7066C39.5899 12.5734 42.4832 11.375 45.5 11.375Z"
      fill="#0E206F"
    />
    <g filter="url(#filter0_f_650_17464)">
      <path
        d="M58 55C58 55 52.5 52 44.6433 52C36.7867 52 32 55 32 55"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <g filter="url(#filter1_f_650_17464)">
      <circle cx="27" cy="42" r="2" fill="white" />
    </g>
    <g filter="url(#filter2_f_650_17464)">
      <circle cx="62" cy="42" r="2" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_f_650_17464"
        x="30.4999"
        y="50.5"
        width="29.0003"
        height="6.00024"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.25" result="effect1_foregroundBlur_650_17464" />
      </filter>
      <filter
        id="filter1_f_650_17464"
        x="24"
        y="39"
        width="6"
        height="6"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_650_17464" />
      </filter>
      <filter
        id="filter2_f_650_17464"
        x="59"
        y="39"
        width="6"
        height="6"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_650_17464" />
      </filter>
    </defs>
  </svg>
);
