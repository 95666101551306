import { Box, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { Warning, Info } from '@phosphor-icons/react';
import classNames from 'classnames';
import { BlockTrialTypeEnum } from 'enums';
import classes from './BlockTrialsInfoSummary.module.scss';

interface IBlockTrialsInfoSummaryProps {
  blockTrialType: BlockTrialTypeEnum;
  ticketCapacity: number;
  blockTrialPriceSum: string;
  trialCancellationDate?: string;
}

const BlockTrialsInfoSummary: React.FC<IBlockTrialsInfoSummaryProps> = ({
  blockTrialType,
  ticketCapacity,
  blockTrialPriceSum,
  trialCancellationDate,
}) => {
  const theme = useMantineTheme();

  const isPaidTrial = blockTrialType === BlockTrialTypeEnum.PAID_TRIAL;

  return (
    <Group
      className={classNames(classes.trialInfoBox, {
        [classes.paidTrialBox]: isPaidTrial,
        [classes.freeTrialBox]: !isPaidTrial,
      })}
      wrap="nowrap"
      align="flex-start"
      gap="sm"
    >
      <Box>
        {isPaidTrial ? (
          <Info color={theme.colors.blue[8]} size={24} />
        ) : (
          <Warning color={theme.colors.yellow[6]} weight="fill" size={24} />
        )}
      </Box>

      <Stack gap="xs">
        {isPaidTrial && (
          <Text size="xs" fw={700}>
            Total to pay now = {blockTrialPriceSum} (Paid trial for {ticketCapacity} attendee
            {ticketCapacity > 1 ? 's' : ''}).
          </Text>
        )}
        <Text size="xs" fw={400}>
          You will <b>NOT</b> be charged the cost of the paid sessions immediately.
        </Text>
        <Text size="xs" fw={400}>
          Upon booking, you’ll receive an email with a cancellation option.
        </Text>
        <Text size="xs" fw={400}>
          If you do not wish to continue after the trial, cancel by 23:30 {trialCancellationDate},
          or your card will be charged.
        </Text>
      </Stack>
    </Group>
  );
};

export default BlockTrialsInfoSummary;
