import { Text } from '@mantine/core';
import classes from './HomepageQuote.module.scss';

const HomepageQuote: React.FC = () => {
  return (
    <div className={classes.center}>
      <Text className={classes.boldQuote}>
        <span className={classes.top}>“</span> I feel that literally all the activities <br />I need
        are here. <span className={classes.bottom}>”</span>
      </Text>
      <Text className={classes.quoteOwner}>Sarah, Parent from South London</Text>
    </div>
  );
};

export default HomepageQuote;
