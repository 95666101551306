import classNames from 'classnames';

export const GreyMedicalConsiderations = ({
  className = '',
  color = 'currentColor',
  size = 24,
  strokeWidth = 2,
  height = 23,
  width = 25,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 25 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0204 8.06085C10.4461 8.06085 10.7912 8.40595 10.7912 8.83165V10.7586H12.7181C13.1438 10.7586 13.4889 11.1037 13.4889 11.5294C13.4889 11.9551 13.1438 12.3002 12.7181 12.3002H10.7912V14.2272C10.7912 14.6529 10.4461 14.998 10.0204 14.998C9.59466 14.998 9.24956 14.6529 9.24956 14.2272V12.3002H7.32257C6.89687 12.3002 6.55177 11.9551 6.55177 11.5294C6.55177 11.1037 6.89687 10.7586 7.32257 10.7586H9.24956V8.83165C9.24956 8.40595 9.59466 8.06085 10.0204 8.06085Z"
      fill="#7F839B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.70797 0.738281C7.09468 0.738281 6.50652 0.981907 6.07286 1.41556C5.6392 1.84922 5.39558 2.43739 5.39558 3.05067V3.82147H1.54159C0.690195 3.82147 0 4.51166 0 5.36306V17.6958C0 18.5472 0.690195 19.2374 1.54159 19.2374H18.4991C19.3505 19.2374 20.0407 18.5472 20.0407 17.6958V5.36306C20.0407 4.51166 19.3505 3.82147 18.4991 3.82147H14.6451V3.05067C14.6451 2.43739 14.4015 1.84922 13.9679 1.41556C13.5342 0.981907 12.946 0.738281 12.3327 0.738281H7.70797ZM13.1035 3.82147V3.05067C13.1035 2.84624 13.0223 2.65019 12.8778 2.50564C12.7332 2.36108 12.5372 2.27987 12.3327 2.27987H7.70797C7.50354 2.27987 7.30749 2.36108 7.16293 2.50564C7.01838 2.65019 6.93717 2.84624 6.93717 3.05067V3.82147H13.1035ZM1.54159 5.36306V17.6958H18.4991V5.36306H1.54159Z"
      fill="#7F839B"
    />
    <ellipse cx="18.2957" cy="16.579" rx="4.04668" ry="4.23914" fill="white" />
    <path
      d="M18.5908 10.8516C16.9995 10.8516 15.4734 11.4837 14.3482 12.6089C13.223 13.7342 12.5908 15.2601 12.5908 16.8516C12.5908 18.443 13.223 19.969 14.3482 21.0942C15.4734 22.2194 16.9994 22.8516 18.5908 22.8516C20.1822 22.8516 21.7082 22.2194 22.8334 21.0942C23.9587 19.969 24.5908 18.443 24.5908 16.8516C24.5908 15.2601 23.9587 13.7342 22.8334 12.6089C21.7082 11.4837 20.1822 10.8516 18.5908 10.8516ZM18.5091 20.7988C18.327 20.7988 18.1523 20.7264 18.0235 20.5976C17.8946 20.4687 17.8223 20.2941 17.8223 20.1119C17.8223 19.9298 17.8946 19.7551 18.0235 19.6262C18.1523 19.4975 18.327 19.4251 18.5091 19.4251C18.6913 19.4251 18.866 19.4975 18.9947 19.6262C19.1236 19.7551 19.1959 19.9298 19.1959 20.1119C19.1956 20.294 19.1232 20.4685 18.9944 20.5972C18.8656 20.7259 18.6912 20.7984 18.5091 20.7988ZM19.2598 17.4643C19.2598 17.4643 19.1921 17.4885 19.1921 17.5282V18.0733C19.1921 18.3175 19.0618 18.5432 18.8503 18.6653C18.6388 18.7874 18.3782 18.7874 18.1667 18.6653C17.9552 18.5432 17.8248 18.3175 17.8248 18.0733V17.5282C17.8279 17.2258 17.9257 16.9318 18.1046 16.6879C18.2835 16.444 18.5343 16.2624 18.8219 16.1686C19.0202 16.1024 19.1919 15.974 19.3113 15.8024C19.4306 15.6308 19.4914 15.4252 19.4845 15.2163C19.4712 14.9637 19.3625 14.7255 19.1802 14.5502C18.9979 14.3747 18.7557 14.2752 18.5028 14.2716C18.3207 14.2724 18.1424 14.3246 17.9886 14.4221C17.8348 14.5197 17.7117 14.6587 17.6334 14.8231C17.5273 15.0431 17.3119 15.19 17.0684 15.2081C16.8247 15.2263 16.59 15.1132 16.4524 14.9113C16.3149 14.7095 16.2954 14.4495 16.4015 14.2295C16.5899 13.8345 16.886 13.5005 17.2557 13.2662C17.6253 13.0318 18.0536 12.9064 18.4913 12.9044H18.5054H18.5053C19.1134 12.9079 19.6966 13.146 20.1333 13.5692C20.57 13.9923 20.8265 14.5678 20.8491 15.1754C20.8661 15.6769 20.7207 16.1705 20.4345 16.5827C20.1484 16.9949 19.7368 17.3036 19.261 17.4631L19.2598 17.4643Z"
      fill="#7F839B"
    />
  </svg>
);
