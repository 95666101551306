import { gql } from '@apollo/client';

const CreateBasketMutation = gql`
  mutation createBasket($input: CreateBasketInputType!) {
    createBasket(input: $input) {
      id
      activity {
        id
        name
      }
      createdAt
      tickets {
        ticket
        ticketName
        ticketPrice
        block
        schedule
        checksum
        attendees {
          id
        }
      }
      originalAmount
    }
  }
`;

export default CreateBasketMutation;
