import dayjs from 'dayjs';

const ageInRange = (
  childDateOfBirth?: string | Date | null,
  ageMonthsStart?: number,
  ageMonthsEnd?: number,
): boolean | undefined => {
  const today = new Date();
  const formattedDate = dayjs(today);
  const formattedBirthday = dayjs(childDateOfBirth);

  const ageInMonths = formattedDate.diff(formattedBirthday, 'month');

  if (ageMonthsStart === undefined || ageMonthsEnd === undefined) {
    return;
  }

  if (
    (ageInMonths > ageMonthsStart && ageInMonths < ageMonthsEnd) ||
    ageInMonths === ageMonthsStart ||
    ageInMonths === ageMonthsEnd
  ) {
    return true;
  }
  return false;
};

export default ageInRange;
