import { PreCheckoutBasketTicket } from 'interfaces';
import React from 'react';
import classes from './BookedSubscriptionInfo.module.scss';
import { Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { Ticket } from '@phosphor-icons/react';
import { formatPenceToPounds } from 'utils/formatPrice';
import dayjs from 'dayjs';

interface IBookedSubscriptionInfoProps {
  filteredTickets: PreCheckoutBasketTicket[];
  tickets: PreCheckoutBasketTicket[];
  subscriptionStart: string | null;
  trialEndDate?: string;
}

const BookedSubscriptionInfo: React.FC<IBookedSubscriptionInfoProps> = ({
  filteredTickets,
  tickets,
  trialEndDate,
  subscriptionStart,
}) => {
  const theme = useMantineTheme();

  const getFirstFullPaymentMonth = () => {
    if (trialEndDate) {
      const parsedDate = dayjs(trialEndDate, 'DD MM YYYY');
      return parsedDate.add(1, 'M').format('MMMM');
    } else if (subscriptionStart) {
      return dayjs(subscriptionStart).add(1, 'M').format('MMMM');
    } else {
      return dayjs(new Date()).add(1, 'M').format('MMMM');
    }
  };

  const costSummary = (ticketPrice: number): string => {
    const price = ticketPrice === 0 ? 'Free' : formatPenceToPounds(ticketPrice);
    return `${price} / MONTH (first full payment taken on the 1st ${getFirstFullPaymentMonth()})`;
  };

  return (
    <>
      {filteredTickets.map((ticket) => {
        const singleTicketPrice = ticket.ticketPrice;
        const numberOfTickets = tickets.filter(
          (selectedTicket) => selectedTicket.ticket === ticket.ticket,
        ).length;

        return (
          <div className={classes.sessionCostSection} key={ticket.id}>
            <Stack gap={4}>
              <Group gap="xs" align="flex-start" wrap="nowrap">
                <Ticket
                  weight="fill"
                  size={18}
                  color={theme.colors.blue[8]}
                  className={classes.icon}
                />
                <Stack gap="4px">
                  <Text className={classes.priceHeader} fw={700}>
                    {numberOfTickets} x {ticket.ticketName}
                  </Text>

                  <Text size="xs" c={theme.colors.gray[6]}>
                    {costSummary(singleTicketPrice)}
                  </Text>
                </Stack>
              </Group>
            </Stack>
            {singleTicketPrice === 0 ? (
              <Text c={theme.colors.pink[6]} fw={700}>
                Free
              </Text>
            ) : (
              <Text className={classes.price} fw={700}>
                {formatPenceToPounds(ticket.ticketPrice)}
              </Text>
            )}
          </div>
        );
      })}
    </>
  );
};

export default BookedSubscriptionInfo;
