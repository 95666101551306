import { gql } from '@apollo/client';

const BasketConfirmAttendees = gql`
  mutation basketConfirmAttendees($input: BasketConfirmAttendeesInputType!) {
    basketConfirmAttendees(input: $input) {
      id
      status
    }
  }
`;

export default BasketConfirmAttendees;
