import { PreCheckoutBasketTicket } from 'interfaces';

// filters repetitive instances of multiple quantity tickets
const getFilteredTickets = (tickets: PreCheckoutBasketTicket[]): PreCheckoutBasketTicket[] => {
  return tickets.filter(
    (ticket, index, self) => index === self.findIndex((t) => t.ticket === ticket.ticket),
  );
};

export default getFilteredTickets;
