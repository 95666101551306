import { Activity } from 'types';
import { Franchise, Supplier } from 'interfaces';

interface SeoBreadcrumbItems {
  title: string;
  href?: string;
  nofollow?: boolean;
}

interface BreadcrumbItemsParams {
  activity: Activity;
  supplier?: Supplier;
  franchise?: Franchise;
}

export const getBreadcrumbItems = ({ activity, supplier, franchise }: BreadcrumbItemsParams) => {
  const items: Array<SeoBreadcrumbItems> = [{ title: 'Home', href: '/' }];

  if (franchise) {
    items.push({
      title: `${franchise.name}`,
      href: `/franchise/${franchise.slug}`,
    });
  }

  if (supplier) {
    items.push({
      title: `${supplier.seoPageTitle}`,
      href: `/supplier/${supplier.slug}`,
    });
  }

  if (activity) {
    items.push({
      title: `${activity.seoPageTitle}`,
    });
  }

  return items;
};
