import { DEFAULT_THEME, createTheme, mergeMantineTheme } from '@mantine/core';

const $blue800 = '#0e206f';
const $blue600 = '#2d5fe8';
const $blue500 = '#4378ff';
const $blue200 = '#d5e1ff';
const $blue100 = '#f0f4ff';
const $blueHover = '#5565A9';

const $gray100 = '#f6f6f6';
const $gray200 = '#f1f1f2';
const $gray400 = '#C6C7D2';
const $gray500 = '#7f839b';
const $gray600 = '#4c5471';
const $gray800 = '#1f2744';

const $green100 = '#E7F7F3';
const $green200 = '#CFF0E7';
const $green500 = '#37AD8B';
const $green600 = '#148867';
const $green800 = '#0F634A';

const $pink100 = '#FFEFF8';
const $pink200 = '#FED0E9';
const $pink500 = '#FB54AE';
const $pink600 = '#E5489D';
const $pink800 = '#AE005E';
const $pinkHover = '#D13F8E';

const $lime100 = '#F7FFEF';
const $lime200 = '#ECFDDA';
const $lime500 = '#C6F991';
const $lime600 = '#9DE551';
const $lime800 = '#7ECB01';

const $red100 = '#FCEAE6';
const $red200 = '#FFCBBF';
const $red500 = '#E74522';
const $red600 = '#D93E1C';
const $red800 = '#971B00';

const $yellow100 = '#FEFAEB';
const $yellow200 = '#FCF0C2';
const $yellow500 = '#F7D347';
const $yellow600 = '#EFC830';
const $yellow800 = '#CDA300';

const {
  colors: { blue, gray, green, pink, lime, red, yellow },
} = DEFAULT_THEME;

const theme = createTheme({
  fontFamily: 'Mulish',
  spacing: {
    xs: '0.5rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.5rem',
  },
  radius: {
    xs: '0.75rem',
    sm: '1rem',
    md: '1.625rem',
    lg: '2rem',
  },
  colors: {
    blue: [
      blue[0],
      $blue100,
      $blue200,
      blue[3],
      blue[4],
      $blue500,
      $blue600,
      blue[7],
      $blue800,
      blue[9],
    ],
    gray: [
      gray[0],
      $gray100,
      $gray200,
      gray[3],
      $gray400,
      $gray500,
      $gray600,
      gray[7],
      $gray800,
      gray[9],
    ],
    green: [
      green[0],
      $green100,
      $green200,
      green[3],
      green[4],
      $green500,
      $green600,
      green[7],
      $green800,
      green[9],
    ],
    pink: [
      pink[0],
      $pink100,
      $pink200,
      pink[3],
      pink[4],
      $pink500,
      $pink600,
      pink[7],
      $pink800,
      pink[9],
    ],
    lime: [
      lime[0],
      $lime100,
      $lime200,
      lime[3],
      lime[4],
      $lime500,
      $lime600,
      lime[7],
      $lime800,
      lime[9],
    ],
    red: [red[0], $red100, $red200, red[3], red[4], $red500, $red600, red[7], $red800, red[9]],
    yellow: [
      yellow[0],
      $yellow100,
      $yellow200,
      yellow[3],
      yellow[4],
      $yellow500,
      $yellow600,
      yellow[7],
      $yellow800,
      yellow[0],
    ],
  },
  other: {
    pinkHover: $pinkHover,
    blueHover: $blueHover,
  },
  black: '#1f2744',
  primaryColor: 'blue',
  headings: {
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    sizes: {
      h1: {
        fontSize: '48px',
        lineHeight: '54px',
      },
      h2: {
        fontSize: '32px',
        lineHeight: '40px',
      },
      h3: {
        fontSize: '24px',
        lineHeight: '36px',
      },
      h4: {
        fontSize: '22px',
        lineHeight: '28px',
      },
      h5: {
        fontSize: '20px',
        lineHeight: '24px',
      },
      h6: {
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
  },
});

const PebbleTheme = mergeMantineTheme(DEFAULT_THEME, theme);

export default PebbleTheme;
