import { withSSRContext, Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
const getUserSSR = async (context) => {
    const { Auth: SSRAuth } = withSSRContext(context);
    try {
        const user = await SSRAuth.currentAuthenticatedUser();
        return user;
    }
    catch (err) {
        return null;
    }
};
const getJWTSSR = async (context) => {
    const { Auth: SSRAuth } = withSSRContext(context);
    try {
        const session = await SSRAuth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token;
    }
    catch (err) {
        return null;
    }
};
const useGetJWT = () => {
    const [token, setToken] = useState(null);
    useEffect(() => {
        const getTokenAsync = async () => {
            try {
                const currentSession = await Auth.currentSession();
                const jwt = currentSession.getIdToken().getJwtToken();
                if (jwt) {
                    setToken(jwt);
                }
                else {
                    setToken(null);
                }
            }
            catch (error) {
                setToken(null);
            }
        };
        getTokenAsync();
    }, []);
    return token;
};
const requireAuthentication = (gssp) => {
    return async (context) => {
        const redirectValue = context.resolvedUrl;
        const destination = redirectValue ? `/login?redirect=${redirectValue}` : '/login';
        const token = await getJWTSSR(context);
        if (!token) {
            return {
                redirect: {
                    destination,
                    statusCode: 302,
                },
            };
        }
        return gssp(context, token);
    };
};
const login = async (email, password) => {
    try {
        await Auth.signIn(email.trim(), password);
        console.log('[Auth] Login Success');
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Login Failed: ', error);
        throw error;
    }
};
const logout = async (context) => {
    const { Auth: SSRAuth } = withSSRContext(context);
    try {
        await SSRAuth.signOut();
        console.log('[Auth] Logout Success');
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Logout Failed: ', error);
        throw error;
    }
};
const signUp = async (email, password) => {
    try {
        await Auth.signUp({
            username: email.trim(),
            password: password,
        });
        console.log('[Auth] Signup Success');
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Signup Failed:', error);
        throw error;
    }
};
const verifyEmail = async (email, code) => {
    try {
        await Auth.confirmSignUp(email.trim(), code.trim());
        console.log('[Auth] Confirm Signup Success');
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Confirm Signup Failed', error);
        throw error;
    }
};
const resendVerificationCode = async (email) => {
    try {
        await Auth.resendSignUp(email.trim());
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Resend Code Failed:', error);
        throw error;
    }
};
const getUserAttributeValue = async (user, attribute) => {
    return new Promise((res, rej) => {
        user.getUserAttributes((err, data) => {
            if (err) {
                rej(err);
            }
            const selectedAttribute = data?.find((attr) => attr.Name === attribute);
            res(selectedAttribute?.Value);
        });
    });
};
const forgotPasswordInitiate = async (email) => {
    try {
        await Auth.forgotPassword(email.trim());
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Forgot Password Initiate Error: ', error);
        throw error;
    }
};
const forgotPasswordSubmit = async (code, email, newPassword) => {
    try {
        await Auth.forgotPasswordSubmit(email.trim(), code.trim(), newPassword);
        return {
            success: true,
        };
    }
    catch (error) {
        console.log('[Auth] Forgot Password Submit Error: ', error);
        throw error;
    }
};
export default {
    getUserSSR,
    getJWTSSR,
    useGetJWT,
    requireAuthentication,
    login,
    logout,
    signUp,
    verifyEmail,
    getUserAttributeValue,
    resendVerificationCode,
    forgotPasswordInitiate,
    forgotPasswordSubmit,
};
