// Import the Cloudinary class
import { Cloudinary } from '@cloudinary/url-gen';

// Create your instance
const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
  },
  url: {
    secure: true, // force https, set to false to force http
  },
});

export const getImageUrl = (id?: string): string | null => {
  if (!id) {
    return null;
  }

  return cld.image(id).toURL();
};
