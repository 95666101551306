import { gql } from '@apollo/client';

const BasketAnswerQuestions = gql`
  mutation basketAnswerQuestions($input: BasketAnswerQuestionsInputType!) {
    basketAnswerQuestions(input: $input) {
      id
      status
    }
  }
`;

export default BasketAnswerQuestions;
