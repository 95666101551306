import { Button, ButtonProps } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { SearchBarDropdownEnum } from 'enums';
import { Dispatch, SetStateAction } from 'react';
import classes from './ResetSearchFilterButton.module.scss';
interface ResetActivityFilterButtonProps extends ButtonProps {
  filterType: SearchBarDropdownEnum;
  setAccordionValue?: Dispatch<SetStateAction<string | null>>;
}

const ResetSearchFilterButton: React.FC<ResetActivityFilterButtonProps> = ({
  filterType,
  setAccordionValue,
  ...props
}) => {
  const {
    //age filter
    searchFilterState: { selectedAgeStart, selectedAgeEnd },
    setSearchFilterState,
    //when filter
    setDateRange,
    setSelectedDays,
    daysOfWeek,
    selectedDays,
    startFilterDate,
    endFilterDate,
    //activity filter
    activitySearchTerms,
    setActivitySearchTerms,
    //
    setModifiedFilter,
  } = useSavedSearchValues();

  const isDisabled = () => {
    switch (filterType) {
      case SearchBarDropdownEnum.age:
        return Boolean(!selectedAgeStart && !selectedAgeEnd);
      case SearchBarDropdownEnum.when:
        return Boolean(selectedDays.length === 7 && !startFilterDate && !endFilterDate);
      case SearchBarDropdownEnum.activity:
        return Boolean(!activitySearchTerms);
      default:
        return false;
    }
  };

  const handleClick = () => {
    if (filterType === SearchBarDropdownEnum.age) {
      setSearchFilterState((prevState) => ({
        ...prevState,
        selectedAgeStart: '',
        selectedAgeEnd: '',
      }));
    } else if (filterType === SearchBarDropdownEnum.when) {
      setDateRange([null, null]);
      setSelectedDays(daysOfWeek);
    } else if (filterType === SearchBarDropdownEnum.activity) {
      setActivitySearchTerms(null);
    }
    setModifiedFilter(true);
  };
  return (
    <Button
      {...props}
      variant="white"
      disabled={isDisabled()}
      onClick={() => {
        handleClick();
        if (setAccordionValue) {
          setAccordionValue((filterType as string) || null);
        }
      }}
      className={classes.resetBtn}
    >
      Reset
    </Button>
  );
};

export default ResetSearchFilterButton;
