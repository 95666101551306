import { gql } from '@apollo/client';

const GetBookingByBasketIdToCancelTrial = gql`
  query getBookingByBasketIdToCancelTrial($basketId: UUID!) {
    activityBookingByBasketId(basketId: $basketId) {
      id
      basketId
      activity {
        id
        name
        email
        supplier {
          id
          name
          slug
        }
      }
      finalAmount
      bookedClasses {
        id
        name
        bookedTickets {
          id
          attendees {
            attendeeId
            attendeeName
          }
          blockTrialType
          blockTrialSessionCount
          blockTrialPrice
          blockFirstPaidSessionDate
          paymentDate
          sessions {
            id
          }
        }
      }
    }
  }
`;

export default GetBookingByBasketIdToCancelTrial;
