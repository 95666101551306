import {
  Accordion,
  Badge,
  Box,
  Card,
  Flex,
  Group,
  Image,
  Space,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import classNames from 'classnames';
import { ProviderSearchResult } from 'interfaces';
import { SocialProofLabelEnum } from 'enums';
import classes from './ProviderCard.module.scss';
import { RefObject, useMemo, useRef, useState } from 'react';
import ImageCollage from 'components/ImageCollage/ImageCollage';
import ProviderVenues from 'components/ProviderVenues/ProviderVenues';
import DaysOfWeekDisplayLine from 'components/ui/DaysOfWeekDisplayLine/DaysOfWeekDisplayLine';
import { ChildFace } from '@icons';
import RatingsBar from 'components/Reviews/RatingsBar';
import { useMediaQuery } from '@mantine/hooks';
import Link from 'next/link';
import getSocialProofBadge from 'utils/getSocialProofBadge';
import { Actions, trackAction } from 'utils/amplitude';
import { CaretDown, MapPin, X, VideoCamera, Star } from '@phosphor-icons/react';

interface IProviderCardProps {}

interface IProviderCardProps {
  provider: ProviderSearchResult;
  containerRef?: RefObject<HTMLDivElement>;
}

const ProviderCard: React.FC<IProviderCardProps> = ({
  provider,
  containerRef,
}: IProviderCardProps) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const [accordionOpen, setAccordionOpen] = useState<string | null>(null);

  const cardRef = useRef<HTMLDivElement>(null);
  const accordionRef = useRef<HTMLDivElement>(null);

  const { supplier, venues, online } = provider;

  const SUPPLIER_LINK = `/supplier/${supplier.slug}`;

  const supplierCloudinaryImages = useMemo(() => {
    if (!supplier?.cloudinaryImageId) return [];

    return [supplier?.cloudinaryImageId].concat(...(supplier?.additionalImageIds || []));
  }, [supplier]);

  const hasSocialProof = supplier.justAdded || provider.limitedSpots || provider.popular;

  const socialProofBadge = useMemo(() => {
    if (hasSocialProof) {
      return getSocialProofBadge(
        {
          justAdded: supplier.justAdded || false,
          popular: provider.popular || false,
          limitedSpots: provider.limitedSpots || false,
        },
        false,
      );
    }
  }, [supplier.justAdded, hasSocialProof, provider.limitedSpots, provider.popular]);

  const scrollIntoView = () => {
    if (containerRef?.current && cardRef.current && accordionRef.current) {
      const target = cardRef.current.offsetTop + accordionRef.current.offsetTop;
      containerRef.current.scrollTo({
        top: target,
        behavior: 'smooth',
      });
    }
  };

  const getReviewsComponent = () => {
    const hasReviews = Boolean(supplier?.averageRating && supplier.averageRating > 0) || false;

    if (hasReviews) {
      return (
        <div data-testid={'ratings-bar'}>
          <RatingsBar supplierId={supplier.id || ''} mode="minimal" hideText />
        </div>
      );
    } else {
      return (
        <Flex align="center">
          <Star size={16} color={theme.colors.gray[4]} weight="fill" />
          <Text size="sm" color={theme.colors.gray[5]} style={{ lineHeight: '18px' }} pl={2}>
            No reviews yet
          </Text>
        </Flex>
      );
    }
  };

  const sanitiseHtml = () => {
    if (!supplier.aboutUs) return '';

    // Add space after closing tags, then remove HTML tags
    return supplier.aboutUs
      .replace(/<\/[^>]*>/gm, ' ')
      .replace(/<[^>]*>?/gm, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&amp;/g, '&');
  };

  return (
    <Box pos="relative" ref={cardRef}>
      {socialProofBadge && (
        <Badge
          className={classNames(classes.badge, {
            [classes.limitedSpotsBadge]: socialProofBadge === SocialProofLabelEnum.limitedSpots,
            [classes.popularBadge]: socialProofBadge === SocialProofLabelEnum.popular,
            [classes.justAddedBadge]: socialProofBadge === SocialProofLabelEnum.justAdded,
          })}
        >
          {socialProofBadge}
        </Badge>
      )}
      <Card
        w="100%"
        className={classNames(`${classes.card}`, {
          [classes.defaultCard]: !supplier?.cloudinaryImageId,
        })}
        p={0}
        data-cy="provider-card"
      >
        <Box
          onClick={() =>
            trackAction(Actions.PROVIDER_CARD_CLICK, {
              supplierId: supplier.id,
              ...(socialProofBadge && {
                socialProofBadge,
              }),
            })
          }
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? theme.spacing.sm : theme.spacing.lg,
            padding: '0.75rem 0.75rem 0',
          }}
        >
          <Link href={SUPPLIER_LINK} passHref legacyBehavior>
            <Box
              style={{
                cursor: 'pointer',
                width: !isMobile ? 351 : '100%',
              }}
              data-testid="supplier-link"
            >
              {supplierCloudinaryImages.length > 0 ? (
                <ImageCollage imageIds={supplierCloudinaryImages} altText={supplier.name || ''} />
              ) : (
                <Image
                  width={!isMobile ? 301 : '100%'}
                  src="/pebble_placeholder_image.png"
                  height={186}
                  fit="cover"
                  alt="Pattern with shapes and lines"
                  radius={12}
                />
              )}
            </Box>
          </Link>
          <Stack w={isMobile ? '100%' : '55%'} justify={!isMobile ? 'space-between' : 'flex-start'}>
            <Stack gap={theme.spacing.xs}>
              {/* Ratings and location */}
              <Flex justify="space-between" align="center">
                {supplier.reviewsEnabled ? getReviewsComponent() : <Space />}

                <Flex align="center" gap={4}>
                  {provider.nearestActivity ? (
                    <>
                      <MapPin color={theme.colors.pink[5]} size={18} weight="fill" />
                      <Text
                        size="sm"
                        style={{
                          textAlign: 'center',
                          color: theme.colors.gray[6],
                        }}
                        data-testid="supplier-activity-distance"
                      >
                        {provider.nearestActivity.toFixed(1).toString() + 'm'}
                      </Text>
                    </>
                  ) : (
                    <>
                      <VideoCamera color={theme.colors.blue[8]} size={18} weight="fill" />
                      <Text
                        size="sm"
                        style={{
                          textAlign: 'center',
                          color: theme.colors.gray[6],
                        }}
                        data-testid="supplier-activity-online"
                      >
                        Online
                      </Text>
                    </>
                  )}
                </Flex>
              </Flex>
              {/* Supplier name */}
              <Link href={SUPPLIER_LINK} passHref legacyBehavior>
                <Text
                  style={{
                    fontSize: 20,
                    color: theme.colors.blue[8],
                    fontWeight: 700,
                    cursor: 'pointer',
                    lineHeight: '22px',
                  }}
                  data-cy="provider-name"
                >
                  {supplier.name}
                </Text>
              </Link>
              {/* About us */}
              <Link href={SUPPLIER_LINK} passHref legacyBehavior>
                <Text
                  lineClamp={isMobile ? 2 : 3}
                  style={{
                    fontSize: 14,
                    color: theme.colors.gray[6],
                    cursor: 'pointer',
                  }}
                  data-cy="provider-description"
                >
                  {sanitiseHtml()}
                </Text>
              </Link>
            </Stack>
            {/* Age range & day of week */}
            <Group
              w="full"
              align="center"
              gap={4}
              style={{ justifyContent: 'space-between' }}
              data-cy="provider-ageRange-and-schedule"
            >
              <Badge
                variant="light"
                radius={4}
                h={isMobile ? 'auto' : 29}
                px={2}
                style={{
                  backgroundColor: theme.colors.blue[1],
                  border: `1px solid ${theme.colors.blue[2]}`,
                  textTransform: 'none',
                }}
              >
                <Flex align="center">
                  <ChildFace color={theme.colors.blue[8]} />
                  <Text
                    size={isMobile ? 'xs' : 'sm'}
                    lineClamp={1}
                    style={{
                      fontWeight: 'bold',
                      color: theme.colors.blue[8],
                    }}
                  >
                    {supplier.ageRange}
                  </Text>
                </Flex>
              </Badge>
              <Box w={isMobile ? 185 : 227} h="100%">
                <DaysOfWeekDisplayLine
                  weekdays={provider.weekdays || []}
                  selectedLimeBg
                  fullWidth
                  enlarged={!isMobile}
                />
              </Box>
            </Group>
          </Stack>
        </Box>
        <Accordion
          chevronPosition="right"
          variant="default"
          classNames={{
            item: classNames(classes.item, {
              [classes.itemActive]: accordionOpen,
            }),
            content: classes.content,
            chevron: classes.chevron,
            control: classNames(classes.control, {
              [classes.controlActive]: accordionOpen,
            }),
            label: classes.label,
          }}
          value={accordionOpen}
          onChange={(val) => {
            setAccordionOpen(val);
            if (val && isMobile) {
              scrollIntoView();
            }
          }}
          chevron={
            accordionOpen ? (
              <Group gap="xs" mt={isMobile ? 'md' : 0}>
                {!isMobile && (
                  <Text style={{ fontSize: 14, fontWeight: 700, color: theme.colors.blue[8] }}>
                    Hide
                  </Text>
                )}
                <X size={14} color={theme.colors.blue[8]} weight="bold" />
              </Group>
            ) : (
              <CaretDown size={24} weight="bold" />
            )
          }
        >
          <Accordion.Item value={supplier.slug} key={supplier.slug} ref={accordionRef}>
            <Accordion.Control
              onClick={() => {
                const event = accordionOpen ? Actions.HIDE_CLASSES : Actions.SEE_CLASSES;
                trackAction(event, { supplierId: supplier.id });
              }}
            >
              {!accordionOpen && (
                <Text style={{ fontSize: 14, fontWeight: 'bold', color: theme.colors.blue[8] }}>
                  See classes
                </Text>
              )}
            </Accordion.Control>
            <Accordion.Panel>
              <ProviderVenues
                venues={venues}
                online={online}
                supplierSlug={supplier.slug}
                supplierId={supplier.id}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Card>
    </Box>
  );
};

export default ProviderCard;
