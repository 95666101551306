import { ActionIcon, Button, Flex, Popover, Text, useMantineTheme } from '@mantine/core';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { SearchBarDropdownEnum } from 'enums';
import { getAgeRangeLabel } from 'utils/filters/filters';
import LocationFilterContent from 'components/MainFilterContent/LocationFilterContent/LocationFilterContent';
import ActivityFilterContent from 'components/MainFilterContent/ActivityFilterContent/ActivityFilterContent';
import AgeFilterContent from 'components/MainFilterContent/AgeFilterContent/AgeFilterContent';
import ScheduleFilterContent from 'components/MainFilterContent/ScheduleFilterContent/ScheduleFilterContent';
import { useRouter } from 'next/router';
import { KeyboardEvent } from 'react';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';
import { Actions, trackAction } from 'utils/amplitude';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import classes from './ActiveMainSearchBarDesktop.module.scss';
import classNames from 'classnames';
import { MagnifyingGlass } from '@phosphor-icons/react';

const ActiveMainSearchBarDesktop = () => {
  const theme = useMantineTheme();

  const router = useRouter();

  const {
    setActiveSearchBarDropDown,
    activeSearchBarDropDown,
    locationString,
    selectedDatesLabel,
    selectedDaysLabels,
    redirectUrl,
    setActiveDesktopFilter,
    activitySearchTerms,
    isHomepage,
    modifiedFilter,
    showUpdateButton,
    setFetching,
    selectedDays,
    startFilterDate,
    searchFilterState: { selectedAgeStart, selectedAgeEnd },
  } = useSavedSearchValues();

  const ageRangeLabel = getAgeRangeLabel(true, selectedAgeStart, selectedAgeEnd);

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      router.push(redirectUrl);
      setActiveSearchBarDropDown(null);
    }
  };

  const URLpathname = getURLpathnameParam(router.pathname);

  const whenFilterSelected = startFilterDate || selectedDays.length !== 7;

  return (
    <Flex className={classes.wrapper} data-cy="active-search-bar">
      <Popover
        classNames={{
          dropdown: classes.locationDropdown,
        }}
        width="target"
        position="bottom-start"
        shadow="md"
        defaultOpened={isHomepage}
        trapFocus
        onOpen={() => {
          if (activeSearchBarDropDown === null) {
            setActiveSearchBarDropDown(SearchBarDropdownEnum.location);
            trackAction(Actions.SEARCH_LOCATION_SELECT, {
              URLpathname,
            });
          } else {
            setActiveSearchBarDropDown(null);
          }
        }}
        onClose={() => {
          if (!locationString && isHomepage) {
            setActiveSearchBarDropDown(SearchBarDropdownEnum.location);
            setActiveDesktopFilter(false);
          } else {
            setActiveSearchBarDropDown(null);
          }
        }}
      >
        <Popover.Target>
          <Button
            className={classNames(classes.locationFilter, {
              [classes.activeLocationFilter]:
                activeSearchBarDropDown === SearchBarDropdownEnum.location,
              [classes.noRightBorder]: activeSearchBarDropDown === SearchBarDropdownEnum.age,
            })}
            classNames={{ inner: classes.filterButton }}
            w={265}
          >
            <Flex direction="column">
              <Text c={theme.colors.blue[8]} size="sm" fw="600">
                Location
              </Text>
              <Text
                c={locationString ? theme.colors.blue[8] : theme.colors.gray[5]}
                size="md"
                fw="600"
                maw={200}
                truncate="end"
              >
                {locationString || 'Search activities near you'}
              </Text>
            </Flex>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <LocationFilterContent />
        </Popover.Dropdown>
      </Popover>

      <Popover
        width="target"
        position="bottom-start"
        shadow="md"
        onOpen={() => {
          if (activeSearchBarDropDown === null) {
            setActiveSearchBarDropDown(SearchBarDropdownEnum.age);
            trackAction(Actions.SEARCH_AGE_SELECT, {
              URLpathname,
            });
          } else {
            setActiveSearchBarDropDown(null);
          }
        }}
        onClose={() => setActiveSearchBarDropDown(null)}
        classNames={{
          dropdown: classes.ageDropdown,
        }}
      >
        <Popover.Target>
          <Button
            className={classNames(classes.middleFilters, {
              [classes.activeMiddleFilter]: activeSearchBarDropDown === SearchBarDropdownEnum.age,
              [classes.noRightBorder]: activeSearchBarDropDown === SearchBarDropdownEnum.when,
            })}
            classNames={{ inner: classes.filterButton }}
            miw={200}
            disabled={!locationString}
          >
            <Flex direction="column">
              <Text c={theme.colors.blue[8]} size="sm" fw="600">
                Ages
              </Text>
              <Text
                c={ageRangeLabel ? theme.colors.blue[8] : theme.colors.gray[5]}
                size="md"
                fw="600"
                maw={200}
                truncate="end"
              >
                {ageRangeLabel || 'All ages'}
              </Text>
            </Flex>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <AgeFilterContent />
        </Popover.Dropdown>
      </Popover>

      <Popover
        width="target"
        position="bottom-start"
        shadow="md"
        middlewares={{ flip: false, shift: true }}
        onOpen={() => {
          if (activeSearchBarDropDown === null) {
            setActiveSearchBarDropDown(SearchBarDropdownEnum.when);
            trackAction(Actions.SEARCH_WHEN_SELECT, {
              URLpathname,
            });
          } else {
            setActiveSearchBarDropDown(null);
          }
        }}
        classNames={{
          dropdown: classes.whenDropdown,
        }}
        onClose={() => setActiveSearchBarDropDown(null)}
      >
        <Popover.Target>
          <Button
            className={classNames(classes.middleFilters, {
              [classes.activeMiddleFilter]: activeSearchBarDropDown === SearchBarDropdownEnum.when,
              [classes.noRightBorder]: activeSearchBarDropDown === SearchBarDropdownEnum.activity,
            })}
            classNames={{ inner: classes.filterButton }}
            miw={220}
            disabled={!locationString}
          >
            <Flex direction="column">
              <Text c={theme.colors.blue[8]} size="sm" fw="600">
                When
              </Text>
              <Text
                c={whenFilterSelected ? theme.colors.blue[8] : theme.colors.gray[5]}
                size="md"
                fw="600"
                maw={230}
                truncate="end"
              >
                {selectedDatesLabel()} {selectedDaysLabels()}
              </Text>
            </Flex>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <ScheduleFilterContent />
        </Popover.Dropdown>
      </Popover>

      <Popover
        width="target"
        position="bottom-start"
        shadow="md"
        onOpen={() => {
          if (activeSearchBarDropDown === null) {
            setActiveSearchBarDropDown(SearchBarDropdownEnum.activity);
            trackAction(Actions.SEARCH_ACTIVITY_SELECT, {
              URLpathname,
            });
          } else {
            setActiveSearchBarDropDown(null);
          }
        }}
        onClose={() => setActiveSearchBarDropDown(null)}
        classNames={{
          dropdown: classes.activityDropdown,
        }}
      >
        <Popover.Target>
          <Button
            className={classNames(classes.activityFilter, {
              [classes.activeActivityFilter]:
                activeSearchBarDropDown === SearchBarDropdownEnum.activity,
              [classes.noLeftBorder]: activeSearchBarDropDown !== SearchBarDropdownEnum.activity,
            })}
            classNames={{ inner: classes.filterButton }}
            miw={200}
            disabled={!locationString}
          >
            <Flex direction="column">
              <Text c={theme.colors.blue[8]} size="sm" fw="600">
                Activity
              </Text>
              <Text
                c={!activitySearchTerms ? theme.colors.gray[5] : theme.colors.blue[8]}
                size="md"
                fw="600"
                maw={200}
                truncate="end"
              >
                {!activitySearchTerms
                  ? 'All activities'
                  : activitySearchTerms
                      .map((searchTerm) => capitalizeFirstLetter(searchTerm))
                      .join(', ')}
              </Text>
            </Flex>
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <ActivityFilterContent />
        </Popover.Dropdown>
      </Popover>
      <Flex align="center">
        {modifiedFilter && showUpdateButton ? (
          <ActionIcon
            mr="sm"
            ml="xs"
            variant="filled"
            radius="xl"
            size="lg"
            title="Search"
            onClick={() => {
              setFetching(true);
              router.push(redirectUrl);
              setActiveSearchBarDropDown(null);
              trackAction(Actions.SEARCH_DESKTOP_UPDATE, {
                URLpathname,
              });
            }}
            className={classes.update}
            disabled={!locationString}
            onKeyDown={handleKeyDown}
          >
            <Text className={classes.updateText} fw={600}>
              UPDATE
            </Text>

            <MagnifyingGlass size={20} weight="bold" />
          </ActionIcon>
        ) : (
          <ActionIcon
            mx="xs"
            variant="filled"
            radius="xl"
            size={'xl'}
            title="Search"
            onClick={() => {
              setFetching(true);
              router.push(redirectUrl);
              setActiveSearchBarDropDown(null);
              trackAction(Actions.SEARCH_DESKTOP_BUTTON, {
                URLpathname,
              });
            }}
            className={classNames(classes.search, {
              [classes.searchText]: locationString && isHomepage,
            })}
            disabled={!locationString}
            onKeyDown={handleKeyDown}
          >
            {locationString && isHomepage ? 'Search' : ''}
            <MagnifyingGlass size={20} weight="bold" />
          </ActionIcon>
        )}
      </Flex>
    </Flex>
  );
};

export default ActiveMainSearchBarDesktop;
