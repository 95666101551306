import { useEffect, useRef, useState } from 'react';
import { Carousel, CarouselProps } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { Card, Text, useMantineTheme } from '@mantine/core';
import Image from 'next/legacy/image';
import categoriesCarouselContent from './categoryCarouselContent';
import { useMediaQuery } from '@mantine/hooks';
import { getCategoryStylesByName } from 'utils/categoryStyles';
import classes from './CategoriesCarousel.module.scss';
import Link from 'next/link';

const CategoriesCarousel = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  const [align, setAlign] = useState<CarouselProps['align']>(undefined);

  useEffect(() => {
    if (isMobile) {
      setAlign('start');
    } else {
      setAlign(0.3);
    }
  }, [isMobile]);

  return (
    <div>
      <Carousel
        id="categories-carousel"
        withIndicators
        slideSize={{ sm: '30%', md: '20%', lg: '15%' }}
        slideGap="xs"
        loop
        align={align}
        classNames={{
          root: classes.root,
          controls: classes.controls,
          indicator: classes.indicator,
          indicators: classes.indicators,
        }}
        plugins={[autoplay.current]}
        onMouseEnter={autoplay.current.stop}
        onMouseLeave={autoplay.current.reset}
        nextControlProps={{
          title: 'next slide',
        }}
        previousControlProps={{
          title: 'previous slide',
        }}
      >
        {categoriesCarouselContent.map(
          ({ categoryName, displayName, categoryImageAlt, description }, index) => (
            <Carousel.Slide key={index}>
              <Link
                className={classes.linkWrapper}
                href={`/activities/${categoryName}/near-uk#distance=500`}
                passHref
              >
                <Card
                  p={0}
                  className={classes.carouselCard}
                  style={getCategoryStylesByName(displayName)}
                >
                  <Image
                    src={`/carouselPictures/${categoryName}.png`}
                    alt={categoryImageAlt}
                    width={255}
                    height={190}
                    // Onclick needed to prevent "TypeError: _a2.clickAllowed is not a function" when clicking on carousel item due to image
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onClick={(e: any) => {
                      e.stopPropagation();
                    }}
                  />
                  <Text className={classes.cardHeading}>{displayName}</Text>
                  <Text className={classes.cardDescription}>{description}</Text>
                </Card>
              </Link>
            </Carousel.Slide>
          ),
        )}
      </Carousel>
    </div>
  );
};

export default CategoriesCarousel;
