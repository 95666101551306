const getUTMParamsFromURL = (): Record<string, string> => {
  let urlSearchParams: URLSearchParams;

  if (typeof window !== 'undefined') {
    urlSearchParams = new URLSearchParams(window.location.search);
  }

  const utmParams: Record<string, string> = {};

  const utmParamsList = ['utm_source', 'utm_medium', 'utm_campaign'];

  utmParamsList.forEach((param) => {
    const value = urlSearchParams?.get(param);

    if (value) {
      utmParams[param] = value;
    }
  });

  return utmParams;
};

export default getUTMParamsFromURL;
