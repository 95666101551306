import { FC } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';

const viewboxCorrection = {
  translateX: 40,
  translateY: 40,
};

interface IPebblesProps {
  className?: string;
  duration?: number;
  fillSpace?: boolean;
  size?: number;
}

const Pebbles: FC<IPebblesProps> = ({ className, duration = 2, size = 80 }) => (
  <div
    className={classNames(className)}
    data-testid="pebbles"
    aria-busy
    role="alert"
    aria-label="Loading"
  >
    <svg
      width={size}
      height={size}
      viewBox="0 0 302 348"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <motion.path
        animate={{
          rotate: [0, 360, 360],
        }}
        transition={{
          duration,
          ease: 'easeInOut',
          times: [0, 0.3, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}
        style={viewboxCorrection}
        d="M55.66 10.64C66.5311 3.58365 79.2392 -0.11591 92.1992 0.00276829C113.504 0.378377 120.43 4.61525 120.43 4.61525C120.43 4.61525 134.763 13.5247 138.624 14.6665C142.486 15.8084 149.277 18.1672 151.666 25.0934C154.054 32.0196 157.435 39.8173 152.086 44.4448C146.738 49.0723 145.04 51.5663 132.975 54.7815C122.844 57.2992 112.379 58.2067 101.965 57.4709C93.8969 57.0953 74.846 55.1421 68.2203 55.1271C61.5946 55.1121 49.2746 47.9755 47.7421 39.5769C46.2097 31.1783 46.4801 14.6064 55.66 10.64Z"
        fill="#9DE551"
      />

      <motion.path
        animate={{
          rotate: [0, 360, 360],
        }}
        transition={{
          duration,
          ease: 'easeInOut',
          times: [0, 0.3, 1],
          repeat: Infinity,
          repeatDelay: 1,
          delay: 0.5,
        }}
        style={viewboxCorrection}
        d="M26.6271 93.3499C26.6271 93.3499 43.3191 78.7011 81.6161 78.1002C119.913 77.4992 161.876 83.6892 176.961 99.7803C192.045 115.871 182.55 129.438 182.55 129.438C182.55 129.438 175.473 141.563 161.756 144.718C148.039 147.873 132.263 151.329 110.673 151.464C89.0833 151.599 43.5294 149.12 33.2077 140.181C22.886 131.241 15.9898 123.894 16.0349 113.888C16.08 103.882 26.6271 93.3499 26.6271 93.3499Z"
        fill="#FB54A2"
      />

      <motion.path
        animate={{
          rotate: [0, 360, 360],
        }}
        transition={{
          duration,
          ease: 'easeInOut',
          times: [0, 0.3, 1],
          repeat: Infinity,
          repeatDelay: 1,
          delay: 0.9,
        }}
        style={viewboxCorrection}
        d="M2.51467 203.056C2.51467 203.056 10.0269 187.13 37.3862 177.965C59.465 170.724 83.0145 169.173 105.852 173.458C122.379 176.387 147.004 180.068 171.193 188.662C195.382 197.256 201.918 202.455 201.753 214.204C201.587 225.953 189.628 235.238 185.947 236.74C172.231 243.19 157.305 246.661 142.151 246.927C117.18 247.678 60.8242 248.429 51.2386 247.708C41.6531 246.987 18.1851 246.206 8.79486 233.78C-0.595356 221.355 -2.08278 210.568 2.51467 203.056Z"
        fill="#4378FF"
      />
    </svg>
  </div>
);

export default Pebbles;
