import { ActivitySearchResult } from 'types';
import { ActivityTypeEnum } from 'enums';

export const getCalendarText = (
  dateRange: ActivitySearchResult['dateRange'],
  weekdays: ActivitySearchResult['weekdays'],
  activityType: ActivitySearchResult['activityType'],
) => {
  if (!dateRange) {
    return activityType === ActivityTypeEnum.SUBSCRIPTION ? 'Weekly' : weekdays[0];
  } else {
    return activityType === ActivityTypeEnum.SUBSCRIPTION ? 'Weekly' : dateRange;
  }
};
