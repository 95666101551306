import classNames from 'classnames';

export const CustomComms = ({
  className = 'icon',
  color = 'currentColor',
  stroke = 'none',
  viewBox="0 0 32 32",
  size = 32,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30 21.773a7 7 0 0 1-10.29 6.18l-2.292.764a1.078 1.078 0 0 1-1.362-1.363l.764-2.291A7 7 0 1 1 30 21.773Z"
    />
    <path
      d="m12.948 3.361 9.693 6.462a.808.808 0 0 1 .359.672v3.058a7.89 7.89 0 0 0-1.615.202v-1.692l-5.727 4.086 1.07.754c-.31.442-.577.916-.792 1.418l-1.93-1.364h-3.011l-5.718 4.038h10.035a7.976 7.976 0 0 0 .077 1.616H3.615A1.616 1.616 0 0 1 2 20.995v-10.5a.807.807 0 0 1 .36-.672l9.692-6.462a.808.808 0 0 1 .896 0ZM3.615 20.188l5.727-4.039-5.727-4.086v8.125Z"
    />
  </svg>
);
