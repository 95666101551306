import { Category } from 'types';
import { ParentAndBaby, Play, DanceAndDrama, Prenatal, Yoga, Childcare } from '@icons';
import {
  Backpack,
  ChalkboardTeacher,
  DribbbleLogo,
  FirstAidKit,
  HeartStraight,
  MathOperations,
  MusicNotes,
  Palette,
  Student,
  Tent,
  Translate,
} from '@phosphor-icons/react';

export const getCategoryIcon = (category: Category): JSX.Element | null => {
  switch (category.name) {
    case 'Sport':
      return <DribbbleLogo size={24} weight="fill" />;
    case 'Dance & Drama':
      return <DanceAndDrama />;
    case 'Play':
      return <Play />;
    case 'Parent & Baby/Toddler':
      return <ParentAndBaby />;
    case 'Music':
      return <MusicNotes size={24} weight="fill" />;
    case 'Health & Wellbeing':
      return <HeartStraight size={24} weight="fill" />;
    case 'Arts & Crafts':
      return <Palette size={24} weight="fill" />;
    case 'Educational':
      return <Student size={24} weight="fill" />;
    case 'Yoga':
      return <Yoga />;
    case 'Holiday Camp':
      return <Tent size={24} weight="fill" />;
    case 'First Aid':
      return <FirstAidKit size={24} weight="fill" />;
    case 'Prenatal':
      return <Prenatal />;
    case 'Language':
      return <Translate size={24} weight="fill" />;
    case 'STEM':
      return <MathOperations size={24} weight="fill" />;
    case 'Tutoring':
      return <ChalkboardTeacher size={24} weight="fill" />;
    case 'Childcare':
      return <Childcare />;
    case 'Wraparound Childcare':
      return <Backpack size={24} weight="fill" />;
    default:
      return null;
  }
};
