import {
  ActionIcon,
  Card,
  Divider,
  Flex,
  Group,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './AddOnsStep.module.scss';
import { formatPenceToPounds } from 'utils/formatPrice';
import { Fragment } from 'react';
import { UseFormReturnType } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { AddOnFormValues, Addons, PerBookingAddonList } from 'interfaces';
import { Plus, CheckCircle, Minus } from '@phosphor-icons/react';

interface IAddonQuantityPriceProps {
  attendeeAddon: PerBookingAddonList;
  quantity: number;
  addItem: (item: PerBookingAddonList) => void;
  removeItem: (item: PerBookingAddonList) => void;
}

export const AddonQuantityPrice: React.FC<IAddonQuantityPriceProps> = ({
  attendeeAddon,
  quantity,
  addItem,
  removeItem,
}) => {
  const itemPrice = formatPenceToPounds(attendeeAddon.addonOption.price);
  const totalPrice = formatPenceToPounds(attendeeAddon.addonOption.price * quantity);

  return (
    <Flex align="center" className={classes.addRemoveContainer}>
      <span className={classes.buttonWrapper}>
        <ActionIcon
          title="minus-button"
          disabled={quantity <= 0}
          size={36}
          variant="default"
          onClick={() => {
            removeItem(attendeeAddon);
            trackAction(Actions.DELETE_ADDON, { addon: 'Booking addon' });
          }}
          className={classes.minusItemButton}
        >
          <Minus size={18} weight="bold" />
        </ActionIcon>
        <ActionIcon
          title="add-button"
          size={36}
          variant="default"
          className={classes.addItemButton}
          onClick={() => {
            addItem(attendeeAddon);
            trackAction(Actions.ADD_ADDON, { addon: 'Booking addon' });
          }}
        >
          <Plus size={18} weight="bold" />
        </ActionIcon>
      </span>
      <Flex className={classes.priceLabel} align="center" justify="space-between">
        <Text fw={600} size="14px">
          {quantity} x {itemPrice}
        </Text>
        <Text fw={600} size="18px">
          {totalPrice}
        </Text>
      </Flex>
    </Flex>
  );
};

interface IBookingAddonsProps {
  addOnIndex: number;
  addOn: Addons;
  addOnsForm: UseFormReturnType<AddOnFormValues, (values: AddOnFormValues) => AddOnFormValues>;
}

export const BookingAddons: React.FC<IBookingAddonsProps> = ({ addOnIndex, addOn, addOnsForm }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 1148px)', true);

  const updateBookingAddonQuantity = async (index: number, delta: number) => {
    const newValues = [...(addOnsForm.values.addOns ?? [])];
    const newQuantity = (newValues[addOnIndex]?.perBooking[index]?.quantity ?? 0) + delta;

    addOnsForm.setFieldValue(`addOns.${addOnIndex}.perBooking.${index}.quantity`, newQuantity);

    newValues[addOnIndex].perBooking[index] = {
      ...newValues[addOnIndex].perBooking[index],
      quantity: newQuantity,
    };
  };

  const circleIcon = <CheckCircle size={22} weight="fill" color={theme.colors.lime[8]} />;
  return (
    <>
      {addOn.perBooking.map((attendeeAddon, bookingAddonIndex) => {
        const quantity =
          addOnsForm?.values?.addOns?.[addOnIndex]?.perBooking[bookingAddonIndex].quantity ?? 0;
        return (
          <Fragment key={`${attendeeAddon.addonOption.id}-${bookingAddonIndex}`}>
            <Card className={classes.addOnCard} style={{ paddingTop: '8px' }}>
              <Group justify="space-between" wrap={isMobile ? 'wrap' : 'nowrap'}>
                <Stack justify="center" gap="xs">
                  <Group>
                    <Text size="14px" fw={700} c={theme.colors.blue[8]}>
                      {attendeeAddon.addonOption.name}
                    </Text>
                    {quantity > 0 ? circleIcon : null}
                  </Group>
                  <Text className={classes.description}>
                    {attendeeAddon.addonOption.description}
                  </Text>
                </Stack>

                <AddonQuantityPrice
                  attendeeAddon={attendeeAddon}
                  quantity={quantity}
                  addItem={() => updateBookingAddonQuantity(bookingAddonIndex, 1)}
                  removeItem={() => updateBookingAddonQuantity(bookingAddonIndex, -1)}
                />
              </Group>

              {quantity > 0 && (
                <>
                  <Divider mt="md" variant="dashed" />
                  <Textarea
                    classNames={{ input: classes.textAreaInput }}
                    radius="8px"
                    mt="sm"
                    mb="4px"
                    autosize={true}
                    maxRows={2}
                    maxLength={65}
                    onBlur={() => {
                      trackAction(Actions.ADDON_ORDER_NOTES, { addon: 'Booking addon' });
                    }}
                    placeholder="Order notes (optional)"
                    {...addOnsForm.getInputProps(
                      `addOns.${addOnIndex}.perBooking.${bookingAddonIndex}.note`,
                    )}
                  />
                </>
              )}
            </Card>
          </Fragment>
        );
      })}
    </>
  );
};
