import { DateAvailabilityType, ProductsByDate } from 'types';
import { Session } from 'interfaces';
import { AvailabilityEnum } from 'enums';
import dayjs from 'dayjs';
import { formatDate } from 'utils/formatDate/index';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { Text } from '@mantine/core';

const dateFormat = 'YYYY-MM-DD';

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

interface RemainingSessionsObject {
  total: number;
  sessions: (Session & { isInPast: boolean; formattedDate: string })[];
}

export const getActivityRemainingSessions = (sessions: Session[]): RemainingSessionsObject => {
  return sessions.reduce(
    (current: RemainingSessionsObject, nextSession: Session) => {
      const formattedDate = dayjs(nextSession.date, dateFormat);

      if (formattedDate.isBefore(dayjs())) {
        return {
          total: current.total,
          sessions: current.sessions.concat({
            ...nextSession,
            formattedDate: formatDate(nextSession.date),
            isInPast: true,
          }),
        };
      }

      return {
        total: current.total + 1,
        sessions: current.sessions.concat({
          ...nextSession,
          formattedDate: formatDate(nextSession.date),
          isInPast: false,
        }),
      };
    },
    { total: 0, sessions: [] },
  );
};

export const sessionsAreSoldOut = (sessions: Session[]): boolean => {
  return sessions.every((session) => session.spotsLeft === 0);
};

export const getMonthAndYear = (date: string): string => dayjs(date).format('MMMM YYYY');

export const groupSessionsByMonth = (
  sessions: ProductsByDate[],
): Partial<Record<string, ProductsByDate[]>> => {
  return sessions.reduce((accum: Partial<Record<string, ProductsByDate[]>>, current) => {
    const dateYearLabel = getMonthAndYear(current.date);
    const sessionsByCurrentMonth = accum[dateYearLabel] || [];
    return {
      ...accum,
      [dateYearLabel]: [...sessionsByCurrentMonth, current],
    };
  }, {});
};

export const getFormattedDateString = (date: string): string => dayjs(date).format('ddd Do MMMM');

export const getDayOfMonth = (date: string): string => dayjs(date).format('DD');

export const getDayAndMonth = (date: string, format = 'ddd D MMMM'): string =>
  dayjs(date).format(format);

export const getSpotsLeftText = (spotsLeft: number): string | JSX.Element => {
  if (spotsLeft === 0) {
    return (
      <Text
        c="#971B00"
        fw={700}
        style={{
          fontSize: '0.75rem',
        }}
      >
        Waitlist
      </Text>
    );
  }

  if (spotsLeft === 1) {
    return (
      <Text>
        1 spot left!{' '}
        <Text c={'#FB54AE'} span>
          •
        </Text>
      </Text>
    );
  }

  if (spotsLeft <= 3) {
    return `${spotsLeft} spots left!`;
  }

  return '';
};

export function getFilteredDatesWithAvailability(
  dates: DateAvailabilityType[],
): DateAvailabilityType[] {
  const getAvailability = (isInPast: boolean, spotsLeft: number): AvailabilityEnum => {
    if (isInPast) {
      return AvailabilityEnum.IN_PAST;
    }

    if (spotsLeft === 0) {
      return AvailabilityEnum.SOLD_OUT;
    }

    return AvailabilityEnum.AVAILABLE;
  };

  return dates.map((date) => ({
    ...date,
    availability: getAvailability(date.isInPast, date.spotsLeft),
  }));
}

export const showTimesVaryBanner = (
  startTime: string,
  endTime: string,
  allBlockDates: DateAvailabilityType[] | undefined,
) => {
  if (!allBlockDates) return false;

  const allMatchCoreTime = allBlockDates.every(
    (date) => date.startTime === startTime && date.endTime === endTime,
  );

  return !allMatchCoreTime;
};

export const groupDatesByWeek = (dates: DateAvailabilityType[]): DateAvailabilityType[][] => {
  const groupedDates: Record<string, DateAvailabilityType[]> = dates.reduce(
    (accum: Record<string, DateAvailabilityType[]>, val) => {
      const dayJsObject = dayjs(val.date);
      const year = dayJsObject.year();
      const week = dayjs(val.date).week();
      const key = `${year}-${week}`;
      const existing = accum[key] || [];
      return {
        ...accum,
        [key]: [...existing, val],
      };
    },
    {},
  );
  return Object.values(groupedDates);
};
