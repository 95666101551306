import classNames from 'classnames';

export const MoveArrowsCircle = ({
  className = '',
  color = '#0E206F',
  size = 20,
  title = '',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {title && <title>{title}</title>}
    <rect
      x="0.741667"
      y="0.741667"
      width="18.5167"
      height="18.5167"
      rx="9.25833"
      stroke={color}
      strokeWidth="1.48333"
    />
    <path
      d="M4.65991 7.36193C4.61355 7.31562 4.57677 7.26063 4.55168 7.2001C4.52659 7.13956 4.51367 7.07468 4.51367 7.00915C4.51367 6.94362 4.52659 6.87874 4.55168 6.81821C4.57677 6.75768 4.61355 6.70268 4.65991 6.65637L6.65442 4.66187C6.72416 4.59205 6.81304 4.5445 6.90981 4.52523C7.00659 4.50596 7.10691 4.51583 7.19807 4.5536C7.28923 4.59138 7.36713 4.65535 7.42192 4.73742C7.4767 4.81949 7.5059 4.91597 7.50583 5.01464V6.51052H14.9852C15.1175 6.51052 15.2443 6.56306 15.3378 6.65657C15.4313 6.75008 15.4839 6.87691 15.4839 7.00915C15.4839 7.1414 15.4313 7.26822 15.3378 7.36173C15.2443 7.45524 15.1175 7.50778 14.9852 7.50778H7.50583V9.00366C7.5059 9.10233 7.4767 9.19882 7.42192 9.28089C7.36713 9.36296 7.28923 9.42693 7.19807 9.4647C7.10691 9.50247 7.00659 9.51235 6.90981 9.49308C6.81304 9.4738 6.72416 9.42625 6.65442 9.35644L4.65991 7.36193ZM15.338 12.6399L13.3435 10.6454C13.2738 10.5756 13.1849 10.528 13.0881 10.5087C12.9913 10.4895 12.891 10.4994 12.7999 10.5371C12.7087 10.5749 12.6308 10.6389 12.576 10.7209C12.5212 10.803 12.492 10.8995 12.4921 10.9982V12.494H5.01269C4.88045 12.494 4.75362 12.5466 4.66011 12.6401C4.5666 12.7336 4.51406 12.8604 4.51406 12.9927C4.51406 13.1249 4.5666 13.2517 4.66011 13.3453C4.75362 13.4388 4.88045 13.4913 5.01269 13.4913H12.4921V14.9872C12.492 15.0859 12.5212 15.1823 12.576 15.2644C12.6308 15.3465 12.7087 15.4104 12.7999 15.4482C12.891 15.486 12.9913 15.4959 13.0881 15.4766C13.1849 15.4573 13.2738 15.4098 13.3435 15.34L15.338 13.3455C15.3844 13.2991 15.4211 13.2442 15.4462 13.1836C15.4713 13.1231 15.4842 13.0582 15.4842 12.9927C15.4842 12.9271 15.4713 12.8623 15.4462 12.8017C15.4211 12.7412 15.3844 12.6862 15.338 12.6399Z"
      fill={color}
    />
  </svg>
);
