import { ActivitySearchResult } from 'types';
import { MarketplaceProvidersSearchResult } from 'interfaces';
import { Text, Center, Group, Flex, Stack, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Pebbles } from '@ui';
import { RefObject } from 'react';
import ProviderCard from 'components/ProviderCard/ProviderCard';
import classes from './ActivityListings.module.scss';
import { LightbulbFilament } from '@phosphor-icons/react';
interface IActivityListingsProps {
  activities: ActivitySearchResult[];
  error: boolean;
  postcode?: string;
  place?: string;
  mapSelectedActivities?: ActivitySearchResult[];
  loading?: boolean;
  providerActivitiesData?: MarketplaceProvidersSearchResult;
  containerRef?: RefObject<HTMLDivElement>;
}

const ActivityListings: React.FC<IActivityListingsProps> = (props) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { activities, error, loading, providerActivitiesData, containerRef } = props;

  if (error) {
    return (
      <div role="alert" aria-label="Error retrieving activities">
        Something went wrong retrieving activities. Please try again.
      </div>
    );
  }

  if (loading || !providerActivitiesData?.dataLoaded) {
    return (
      <Center style={{ height: '50vh' }}>
        <Pebbles data-testid="pebbles" />
      </Center>
    );
  }

  return (
    <div>
      {activities.length === 0 && providerActivitiesData?.dataLoaded ? (
        <Flex direction="column" justify="space-between" className={classes.noActivitiesWrapper}>
          <Group className={classes.tipBox} gap="xs">
            <LightbulbFilament
              size={isMobile ? 55 : 32}
              color={theme.colors.blue[6]}
              weight="regular"
              width={isMobile ? 'fit-content' : '32px'}
            />
            <div className={classes.flexColumn}>
              <Text className={classes.noActivitiesText} pb="sm">
                {' '}
                Unfortunately we can't find any activities near this location with the current
                filter settings.
              </Text>
              <Text size="md" py="xs">
                <span style={{ fontWeight: 'bold' }}>Tip!</span> Try expanding the search radius
              </Text>
            </div>
          </Group>
          <Text className={classes.registerLink} mt="xl">
            Click this{' '}
            <a href="https://forms.gle/cS3mqNnYrj5w5nQHA" target="_blank">
              link
            </a>{' '}
            to register your interest and we will do our best to bring Pebble Activities to your
            area soon!
          </Text>
        </Flex>
      ) : (
        <Stack gap={20} px={isMobile ? 20 : 0}>
          {providerActivitiesData?.providers.map((provider) => (
            <ProviderCard
              provider={provider}
              containerRef={containerRef}
              key={provider.supplier.id}
            />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default ActivityListings;
