import classNames from 'classnames';

export const CardholderWithGradient = ({
  className = '',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.5829 17.6085L21.5831 17.6075C21.6736 17.1628 21.9149 16.763 22.2663 16.4758C22.6176 16.1885 23.0574 16.0315 23.5112 16.0312H30.375H30.6562V15.75V13.5V13.2188H30.375H5.625H5.34375V13.5V15.75V16.0312H5.625L12.4889 16.0313L12.4895 16.0312C12.9441 16.0304 13.3851 16.1869 13.7374 16.4742C14.0898 16.7615 14.3319 17.1619 14.4225 17.6075L14.4227 17.6085C14.5935 18.4318 15.0427 19.1711 15.6948 19.7018C16.3469 20.2326 17.162 20.5223 18.0028 20.5223C18.8436 20.5223 19.6587 20.2326 20.3108 19.7018C20.9629 19.1711 21.4122 18.4318 21.5829 17.6085ZM30.375 11.5312H30.6562V11.25V10.125C30.6562 9.75204 30.5081 9.39435 30.2444 9.13063C29.9806 8.86691 29.623 8.71875 29.25 8.71875H6.75C6.37704 8.71875 6.01935 8.86691 5.75563 9.13063C5.49191 9.39435 5.34375 9.75204 5.34375 10.125V11.25V11.5312H5.625H30.375ZM6.75 7.03125H29.25C30.0705 7.03125 30.8574 7.3572 31.4376 7.93739C32.0178 8.51758 32.3438 9.30449 32.3438 10.125V25.875C32.3438 26.6955 32.0178 27.4824 31.4376 28.0626C30.8574 28.6428 30.0705 28.9688 29.25 28.9688H6.75C5.92949 28.9688 5.14258 28.6428 4.56239 28.0626C3.9822 27.4824 3.65625 26.6955 3.65625 25.875V10.125C3.65625 9.30449 3.9822 8.51758 4.56239 7.93739C5.14258 7.3572 5.92949 7.03125 6.75 7.03125Z"
      fill="url(#paint0_radial_2717_792)"
      stroke="#CDA300"
      strokeWidth="0.5625"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2717_792"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18 18) rotate(90) scale(11.25 14.625)"
      >
        <stop offset="0.438323" stopColor="#FFCD27" />
        <stop offset="1" stopColor="#FFE48A" />
      </radialGradient>
    </defs>
  </svg>
);
