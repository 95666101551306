export const Scribbles = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={62}
    height={138}
    fill="none"
  >
    <path
      stroke="#8EB6F3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M45.599 134.882c.344-4.64-3.232-6.871-5.014-6.501-1.707.353-2.222 3.128.14 3.824 2.36.695 4.646-3.585 4.806-6.175.363-5.904-7.295-12.387-14.373-10.296M18.614 14.831c-4.309.696-6.297 7.225-5.647 9.292.623 1.981 3.245 1.777 3.47-1.247.224-3.023-4.368-4.585-6.768-4.157C4.199 19.694-.4 30.512 2.75 38.576M47.948 46.424s-6.396 5.415-7.873 6.909M53.205 53.523s-11.87 5.752-12.81 6.329"
    />
    <path
      fill="#7ECB01"
      d="M16.285 4.544c.113.16.114.152 0-.027.062.143.073.154.033.035-.035-.12-.033-.106.007.04a10.195 10.195 0 0 1-.007-.104.24.24 0 0 1-.036.18c.074-.182.077-.198.009-.049-.096.025-.163.13-.281.147a.577.577 0 0 1-.452-.143.803.803 0 0 1-.292-.386c-.03-.158-.034-.128-.008.086l.003-.09c0 .018-.018.553-.007.281-.085.214-.086.22-.005.014.455-.541.978-.389 1.566.456-.343-.898-1.188-1.46-2.005-1.157-.751.28-1.244 1.337-.9 2.237.747 1.95 2.995 2.279 3.951.536.727-1.327.736-3.504-.417-4.671-1.31-1.323-3.12-.666-3.88.843-.762 1.509-.26 3.258.773 4.422.596.673 1.712.576 2.185-.13.529-.789.404-1.794-.235-2.515l-.002-.005ZM58.766 120.024c-.331.109-.697.015-.993-.206-.706-.211-1.299-.906-1.394-1.911-.13-1.368.577-2.988 1.767-3.348 1.37-.414 2.607 1.077 2.575 2.621-.028 1.373-.848 2.479-1.955 2.844Z"
    />
    <path
      stroke="#8EB6F3"
      d="M58.766 120.024c-.331.109-.697.015-.993-.206-.706-.211-1.299-.906-1.394-1.911-.13-1.368.577-2.988 1.767-3.348 1.37-.414 2.607 1.077 2.575 2.621-.028 1.373-.848 2.479-1.955 2.844Z"
    />
    <path
      stroke="#8EB6F3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M31.494 109.758c3.032-1.143 9.569.272 12.604 2.59"
    />
  </svg>
);
