import classNames from 'classnames';

export const Clone = ({ className = '', color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2457 1.66675H6.87724C6.28078 1.66675 5.78955 2.15798 5.78955 2.75444V4.8948H13.1229C14.2282 4.8948 15.1229 5.7895 15.1229 6.8948V14.2106H17.2458C17.8422 14.2106 18.3335 13.7194 18.3335 13.1229V2.75447C18.3335 2.15801 17.8422 1.66678 17.2458 1.66678L17.2457 1.66675Z"
      fill={color}
    />
    <path
      d="M13.1228 5.80713H2.75432C2.15786 5.80713 1.66663 6.28085 1.66663 6.87731V17.2458C1.66663 17.8422 2.15786 18.3334 2.75432 18.3334H13.1228C13.7192 18.3334 14.2105 17.8422 14.2105 17.2458V6.87731C14.2105 6.28085 13.7192 5.80713 13.1228 5.80713ZM11.1929 12.5264H8.38591V15.3159C8.38591 15.5615 8.17542 15.772 7.9298 15.772C7.68418 15.772 7.47369 15.5615 7.47369 15.3159L7.47357 12.5264H4.68412C4.43851 12.5264 4.22801 12.316 4.22801 12.0703C4.22801 11.8247 4.4385 11.6142 4.68412 11.6142H7.47357V8.80714C7.47357 8.56153 7.68406 8.35103 7.92968 8.35103C8.17529 8.35103 8.38579 8.56152 8.38579 8.80714V11.5966H11.1752C11.4208 11.5966 11.6313 11.8071 11.6313 12.0527C11.6315 12.2983 11.4386 12.5264 11.1929 12.5264L11.1929 12.5264Z"
      fill={color}
    />
  </svg>
);
