import { gql } from '@apollo/client';

const getBookingList = gql`
  query GetAllBookings($bookingFilter: BookingType!, $startDate: Date) {
    allBookingsForGuardian(bookingFilter: $bookingFilter, startDate: $startDate) {
      bookingId
      sessionId
      bookingType
      subscriptionStartTime
      subscriptionEndTime
      subscriptionDayOfWeek
      status
      date
      sessionType
      marketplaceActivity {
        id
        name
        activityType
        cloudinaryImageId
        supplier {
          name
        }
        isOnline
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
      }
      startTime
      endTime
      child {
        id
        fullName
      }
      activityClass {
        name
      }
    }
  }
`;

export default getBookingList;
