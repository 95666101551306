import classNames from 'classnames';

export const PonchoPay = ({
  className = '',
  color = 'currentColor',
  size = 24,
  strokeWidth = '3',
  width = '107',
  height = '19',
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 107 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_73_1475)">
      <path
        d="M84.5911 8.95309C85.0908 8.95309 85.5478 8.83298 85.9621 8.59276C86.3765 8.35253 86.6996 8.02822 86.931 7.61984C87.1748 7.19945 87.2967 6.74302 87.2967 6.25055C87.2967 5.7581 87.1748 5.30767 86.931 4.89929C86.6996 4.4789 86.3765 4.15459 85.9621 3.92636C85.5478 3.68615 85.0908 3.56604 84.5911 3.56604H80.4409V8.95309H84.5911ZM79.6365 2.80933H84.5911C85.2492 2.80933 85.8463 2.95946 86.3826 3.25974C86.9188 3.56002 87.3395 3.97442 87.6441 4.50292C87.9609 5.03141 88.1198 5.61397 88.1198 6.25055C88.1198 6.88715 87.9609 7.46971 87.6441 7.9982C87.3395 8.5267 86.9188 8.94709 86.3826 9.25939C85.8463 9.55965 85.2492 9.7098 84.5911 9.7098H80.4409V15.4212H79.6365V2.80933ZM97.4747 15.4212H96.6881V12.9709C96.3718 13.7877 95.8659 14.4302 95.1713 14.8987C94.4883 15.3671 93.6781 15.6013 92.7394 15.6013C91.9111 15.6013 91.1491 15.3971 90.4544 14.9888C89.7717 14.5804 89.2292 14.0278 88.827 13.3312C88.4248 12.6346 88.224 11.8718 88.224 11.0431C88.224 10.2143 88.4248 9.45757 88.827 8.77294C89.2292 8.07627 89.7717 7.52975 90.4544 7.13339C91.1491 6.72501 91.9111 6.52081 92.7394 6.52081C93.6781 6.52081 94.4883 6.75503 95.1713 7.22347C95.8659 7.6799 96.3718 8.31649 96.6881 9.13326V6.70098H97.4747V15.4212ZM89.0099 11.0431C89.0099 11.7518 89.1746 12.4004 89.5038 12.9889C89.8448 13.5654 90.3082 14.0219 90.8931 14.3582C91.4783 14.6945 92.1236 14.8627 92.8311 14.8627C93.5742 14.8627 94.2385 14.7005 94.8234 14.3762C95.4085 14.0399 95.8659 13.5835 96.1949 13.0069C96.5241 12.4304 96.6881 11.7757 96.6881 11.0431C96.6881 10.3224 96.5241 9.67378 96.1949 9.09722C95.8659 8.50867 95.4085 8.05224 94.8234 7.72796C94.2385 7.40365 93.5742 7.24148 92.8311 7.24148C92.1236 7.24148 91.4783 7.40964 90.8931 7.74597C90.3082 8.08229 89.8448 8.54471 89.5038 9.13326C89.1746 9.7098 89.0099 10.3464 89.0099 11.0431ZM102.353 15.4752L98.569 6.70098H99.4278L102.737 14.5564L106.064 6.70098H106.923L101.713 18.9165H100.872L102.353 15.4752Z"
        fill="#1A2535"
      />
      <path
        d="M27.9106 6.77786C27.9106 5.9452 27.2466 5.32522 26.3312 5.32522H24.1956V8.28388H26.3312C27.2466 8.28388 27.9106 7.64599 27.9106 6.77786ZM30.5846 6.77786C30.5846 9.0988 28.7897 10.7996 26.3312 10.7996H24.1956V15.2112H21.5393V2.80933H26.3312C28.7897 2.80933 30.5846 4.47483 30.5846 6.77786Z"
        fill="#1A2535"
      />
      <path
        d="M33.2772 10.8707C33.2772 12.0753 34.1743 12.9967 35.3769 12.9967C36.5612 12.9967 37.4764 12.0753 37.4764 10.8707C37.4764 9.63037 36.5612 8.72692 35.3769 8.72692C34.1743 8.72692 33.2772 9.63037 33.2772 10.8707ZM39.9531 10.8707C39.9531 13.4573 37.9611 15.3886 35.3769 15.3886C32.7747 15.3886 30.8002 13.4573 30.8002 10.8707C30.8002 8.28399 32.7747 6.33521 35.3769 6.33521C37.9611 6.33521 39.9531 8.28399 39.9531 10.8707Z"
        fill="#1A2535"
      />
      <path
        d="M49.1602 10.286V15.2113H46.6295V10.6049C46.6295 9.47106 45.9298 8.72692 44.889 8.72692C43.8301 8.72692 43.1483 9.47106 43.1483 10.6049V15.2113H40.6176V6.5124H43.1483V7.64627C43.7045 6.81361 44.5302 6.33521 45.535 6.33521C47.7963 6.33521 49.1602 7.82349 49.1602 10.286Z"
        fill="#1A2535"
      />
      <path
        d="M49.807 10.8707C49.807 8.28399 51.7811 6.33521 54.3832 6.33521C56.5908 6.33521 58.4572 7.82332 58.6905 9.82549H56.124C55.9446 9.18761 55.2267 8.72692 54.3832 8.72692C53.1809 8.72692 52.2835 9.63037 52.2835 10.8707C52.2835 12.0753 53.1809 12.9967 54.3832 12.9967C55.2267 12.9967 55.9624 12.5005 56.1418 11.8273H58.7084C58.5111 13.9003 56.6265 15.3886 54.3832 15.3886C51.7811 15.3886 49.807 13.4573 49.807 10.8707Z"
        fill="#1A2535"
      />
      <path
        d="M67.9152 10.2859V15.2113H65.3848V10.6049C65.3848 9.47103 64.6851 8.72689 63.6438 8.72689C62.5851 8.72689 61.9033 9.47103 61.9033 10.6049V15.2113H59.3726V2.80957H61.9033V7.64624C62.4597 6.81356 63.2853 6.33515 64.29 6.33515C66.5515 6.33515 67.9152 7.82344 67.9152 10.2859Z"
        fill="#1A2535"
      />
      <path
        d="M71.0388 10.8707C71.0388 12.0753 71.9359 12.9967 73.1383 12.9967C74.3228 12.9967 75.238 12.0753 75.238 10.8707C75.238 9.63037 74.3228 8.72692 73.1383 8.72692C71.9359 8.72692 71.0388 9.63037 71.0388 10.8707ZM77.7147 10.8707C77.7147 13.4573 75.7226 15.3886 73.1383 15.3886C70.5361 15.3886 68.562 13.4573 68.562 10.8707C68.562 8.28399 70.5361 6.33521 73.1383 6.33521C75.7226 6.33521 77.7147 8.28399 77.7147 10.8707Z"
        fill="#1A2535"
      />
      <path
        d="M17.0853 8.93517C17.0853 13.5848 13.2606 17.354 8.54263 17.354C3.82466 17.354 0 13.5848 0 8.93517C0 4.28558 3.82466 0.516357 8.54263 0.516357C13.2606 0.516357 17.0853 4.28558 17.0853 8.93517Z"
        fill="#00CCA7"
      />
      <path
        d="M9.42577 8.02265C8.6385 8.69312 7.4486 8.60816 6.76825 7.83257C6.08764 7.05671 6.17385 5.88405 6.96112 5.21356C7.74812 4.54282 8.93802 4.62778 9.61864 5.40364C10.299 6.17923 10.2128 7.35189 9.42577 8.02265Z"
        fill="#126DFF"
      />
      <mask id="mask0_73_1475" maskUnits="userSpaceOnUse" x="9" y="8" width="5" height="5">
        <path
          d="M10.5888 8.9679L9.30585 10.0613C8.96105 10.3551 8.92339 10.8686 9.2213 11.2084L10.3307 12.4727C10.6292 12.8125 11.1502 12.8499 11.4947 12.5561L12.7779 11.463C13.1227 11.1691 13.1606 10.6551 12.8625 10.3156L11.7528 9.05097C11.5896 8.8652 11.3597 8.76978 11.1284 8.76978C10.9371 8.76978 10.7451 8.83482 10.5888 8.9679Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_73_1475)">
        <path
          d="M10.5888 8.9679L9.30585 10.0613C8.96105 10.3551 8.92339 10.8686 9.2213 11.2084L10.3307 12.4727C10.6292 12.8125 11.1502 12.8499 11.4947 12.5561L12.7779 11.463C13.1227 11.1691 13.1606 10.6551 12.8625 10.3156L11.7528 9.05097C11.5896 8.8652 11.3597 8.76978 11.1284 8.76978C10.9371 8.76978 10.7451 8.83482 10.5888 8.9679Z"
          fill="url(#paint0_linear_73_1475)"
        />
      </g>
      <path
        d="M6.96876 9.50306L4.66642 9.93778C4.06493 10.0515 3.81424 10.7636 4.21442 11.2201L5.74777 12.9678C6.14822 13.424 6.89976 13.2821 7.10025 12.7124L7.86952 10.5297C8.0703 9.95982 7.57 9.38961 6.96876 9.50306Z"
        fill="#FAC80F"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_73_1475"
        x1="9.94745"
        y1="9.51464"
        x2="12.0998"
        y2="12.0406"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EB3B50" />
        <stop offset="1" stopColor="#E6505B" />
      </linearGradient>
      <clipPath id="clip0_73_1475">
        <rect width="107" height="18.6087" fill="white" transform="translate(0 0.33667)" />
      </clipPath>
    </defs>
  </svg>
);
