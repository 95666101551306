import { gql } from '@apollo/client';

const createFreeActivityBooking = gql`
  mutation createFreeActivityBooking($input: CreateFreeActivityBookingInputType!) {
    createFreeActivityBooking(input: $input) {
      booking {
        id
      }
    }
  }
`;

export default createFreeActivityBooking;
