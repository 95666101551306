import { gql } from '@apollo/client';

const BasketFinalize = gql`
  mutation basketFinalize($input: BasketFinalizeInputType!) {
    basketFinalize(input: $input) {
      id
      status
    }
  }
`;

export default BasketFinalize;
