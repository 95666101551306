import React from 'react';
import classNames from 'classnames';

export const IframeLogo = ({ className = '', color = 'currentColor', size = 98, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8127 13.5781V84.9399H84.4132V13.5781H12.8127ZM82.1857 82.235H15.0403V16.3626H82.1857V82.235Z"
      fill="#4C5471"
    />
    <path d="M35.9635 53.0391H18.3816V54.9484H35.9635V53.0391Z" fill="#4C5471" />
    <path d="M24.1096 73.168H18.3816V73.884H24.1096V73.168Z" fill="#4C5471" />
    <path d="M45.5901 73.168H39.8621V73.884H45.5901V73.168Z" fill="#4C5471" />
    <path d="M66.9905 73.168H61.2625V73.884H66.9905V73.168Z" fill="#4C5471" />
    <path d="M57.3641 20.6602H18.3816V22.7286H57.3641V20.6602Z" fill="#4C5471" />
    <path d="M33.4973 24.4766H18.3816V25.6699H33.4973V24.4766Z" fill="#4C5471" />
    <path d="M35.9635 56.9375H18.3816V58.8468H35.9635V56.9375Z" fill="#4C5471" />
    <path d="M30.0763 64.7344H18.3816V66.6437H30.0763V64.7344Z" fill="#4C5471" />
    <path d="M51.3974 64.7344H39.7026V66.6437H51.3974V64.7344Z" fill="#4C5471" />
    <path d="M72.7983 64.7344H61.1035V66.6437H72.7983V64.7344Z" fill="#4C5471" />
    <path d="M35.9635 60.8359H18.3816V62.7453H35.9635V60.8359Z" fill="#4C5471" />
    <path d="M57.444 53.0391H39.8621V54.9484H57.444V53.0391Z" fill="#4C5471" />
    <path d="M57.444 56.9375H39.8621V58.8468H57.444V56.9375Z" fill="#4C5471" />
    <path d="M57.444 60.8359H39.8621V62.7453H57.444V60.8359Z" fill="#4C5471" />
    <path d="M78.8443 53.0391H61.2625V54.9484H78.8443V53.0391Z" fill="#4C5471" />
    <path d="M78.8443 56.9375H61.2625V58.8468H78.8443V56.9375Z" fill="#4C5471" />
    <path d="M78.8443 60.8359H61.2625V62.7453H78.8443V60.8359Z" fill="#4C5471" />
    <path
      d="M25.9394 47.7094C30.1135 47.7094 33.4973 44.3257 33.4973 40.1516C33.4973 35.9775 30.1135 32.5938 25.9394 32.5938C21.7653 32.5938 18.3816 35.9775 18.3816 40.1516C18.3816 44.3257 21.7653 47.7094 25.9394 47.7094Z"
      fill="#4C5471"
    />
    <path
      d="M47.4199 47.7094C51.594 47.7094 54.9777 44.3257 54.9777 40.1516C54.9777 35.9775 51.594 32.5938 47.4199 32.5938C43.2458 32.5938 39.8621 35.9775 39.8621 40.1516C39.8621 44.3257 43.2458 47.7094 47.4199 47.7094Z"
      fill="#4C5471"
    />
    <path
      d="M68.8203 47.7094C72.9944 47.7094 76.3781 44.3257 76.3781 40.1516C76.3781 35.9775 72.9944 32.5938 68.8203 32.5938C64.6462 32.5938 61.2625 35.9775 61.2625 40.1516C61.2625 44.3257 64.6462 47.7094 68.8203 47.7094Z"
      fill="#4C5471"
    />
  </svg>
);
