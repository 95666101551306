import classNames from 'classnames';

export const ImageUpload = ({
  className = '',
  color = 'currentColor',
  width = 126,
  height = 108,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 126 108"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.588383"
      y="41.8375"
      width="93"
      height="66.68"
      rx="1.5"
      transform="rotate(-11.315 0.588383 41.8375)"
      fill="white"
      stroke="#0E206F"
    />
    <circle
      cx="25.8102"
      cy="55.4531"
      r="7.02"
      transform="rotate(-11.315 25.8102 55.4531)"
      fill="#ECFDDA"
      stroke="#0E206F"
    />
    <mask id="path-3-inside-1_9_12113" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.353 68.876L104.864 86.424C105.189 88.0486 104.136 89.6292 102.511 89.9543L19.1268 106.639L59.6078 45.822C60.8319 43.983 63.3138 43.4864 65.1512 44.7128L101.353 68.876Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.353 68.876L104.864 86.424C105.189 88.0486 104.136 89.6292 102.511 89.9543L19.1268 106.639L59.6078 45.822C60.8319 43.983 63.3138 43.4864 65.1512 44.7128L101.353 68.876Z"
      fill="#FFEFF8"
    />
    <path
      d="M101.353 68.876L102.334 68.6798L102.252 68.274L101.908 68.0442L101.353 68.876ZM104.864 86.424L105.845 86.2278L104.864 86.424ZM102.511 89.9543L102.315 88.9737L102.511 89.9543ZM19.1268 106.639L18.2944 106.085L16.9578 108.093L19.323 107.619L19.1268 106.639ZM59.6078 45.822L60.4403 46.3761L60.4403 46.3761L59.6078 45.822ZM65.1512 44.7128L64.5961 45.5446L64.5961 45.5446L65.1512 44.7128ZM100.372 69.0722L103.884 86.6202L105.845 86.2278L102.334 68.6798L100.372 69.0722ZM103.884 86.6202C104.1 87.7033 103.398 88.757 102.315 88.9737L102.707 90.9349C104.873 90.5014 106.278 88.394 105.845 86.2278L103.884 86.6202ZM102.315 88.9737L18.9306 105.658L19.323 107.619L102.707 90.9349L102.315 88.9737ZM19.9593 107.193L60.4403 46.3761L58.7754 45.2679L18.2944 106.085L19.9593 107.193ZM60.4403 46.3761C61.3581 44.9973 63.2184 44.6251 64.5961 45.5446L65.7064 43.8811C63.4091 42.3478 60.3057 42.9688 58.7754 45.2679L60.4403 46.3761ZM64.5961 45.5446L100.798 69.7077L101.908 68.0442L65.7064 43.8811L64.5961 45.5446Z"
      fill="#0E206F"
      mask="url(#path-3-inside-1_9_12113)"
    />
    <mask id="path-5-inside-2_9_12113" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.861 76.3999L104.865 86.4146C105.19 88.0392 104.136 89.6198 102.512 89.9449L38.4042 102.772L70.5619 63.7434C71.9667 62.0384 74.6884 61.4938 76.6411 62.527L102.861 76.3999Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.861 76.3999L104.865 86.4146C105.19 88.0392 104.136 89.6198 102.512 89.9449L38.4042 102.772L70.5619 63.7434C71.9667 62.0384 74.6884 61.4938 76.6411 62.527L102.861 76.3999Z"
      fill="#F0F4FF"
    />
    <path
      d="M102.861 76.3999L103.841 76.2037L103.748 75.7381L103.328 75.516L102.861 76.3999ZM38.4042 102.772L37.6325 102.136L35.8466 104.304L38.6004 103.753L38.4042 102.772ZM70.5619 63.7434L71.3336 64.3792L71.3336 64.3792L70.5619 63.7434ZM76.6411 62.527L76.1734 63.4109L76.1734 63.4109L76.6411 62.527ZM101.88 76.5961L103.884 86.6108L105.845 86.2184L103.841 76.2037L101.88 76.5961ZM103.884 86.6108C104.101 87.6939 103.398 88.7476 102.315 88.9643L102.708 90.9254C104.874 90.492 106.279 88.3846 105.845 86.2184L103.884 86.6108ZM102.315 88.9643L38.208 101.792L38.6004 103.753L102.708 90.9254L102.315 88.9643ZM39.176 103.408L71.3336 64.3792L69.7901 63.1075L37.6325 102.136L39.176 103.408ZM71.3336 64.3792C72.4365 63.0407 74.6404 62.5997 76.1734 63.4109L77.1087 61.6431C74.7364 60.3879 71.4968 61.0361 69.7901 63.1075L71.3336 64.3792ZM76.1734 63.4109L102.393 77.2838L103.328 75.516L77.1087 61.6431L76.1734 63.4109Z"
      fill="#0E206F"
      mask="url(#path-5-inside-2_9_12113)"
    />
    <rect x="6.875" y="32.4609" width="93" height="66.68" rx="1.5" fill="white" stroke="#0E206F" />
    <circle cx="28.9341" cy="50.7622" r="7.02" fill="#ECFDDA" stroke="#0E206F" />
    <mask id="path-9-inside-3_9_12113" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.377 78.7556V96.6441C100.377 98.301 99.0338 99.6441 97.377 99.6441H12.3392L63.9624 47.9553C65.5235 46.3922 68.0546 46.3922 69.6157 47.9553L100.377 78.7556Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.377 78.7556V96.6441C100.377 98.301 99.0338 99.6441 97.377 99.6441H12.3392L63.9624 47.9553C65.5235 46.3922 68.0546 46.3922 69.6157 47.9553L100.377 78.7556Z"
      fill="#FFEFF8"
    />
    <path
      d="M100.377 78.7556H101.377V78.3418L101.085 78.049L100.377 78.7556ZM12.3392 99.6441L11.6316 98.9375L9.92714 100.644H12.3392V99.6441ZM63.9624 47.9553L64.67 48.6619V48.6619L63.9624 47.9553ZM69.6157 47.9553L68.9081 48.6619V48.6619L69.6157 47.9553ZM99.377 78.7556V96.6441H101.377V78.7556H99.377ZM99.377 96.6441C99.377 97.7487 98.4815 98.6441 97.377 98.6441V100.644C99.5861 100.644 101.377 98.8533 101.377 96.6441H99.377ZM97.377 98.6441H12.3392V100.644H97.377V98.6441ZM13.0467 100.351L64.67 48.6619L63.2549 47.2486L11.6316 98.9375L13.0467 100.351ZM64.67 48.6619C65.8405 47.49 67.7377 47.49 68.9081 48.6619L70.3232 47.2486C68.3715 45.2944 65.2066 45.2944 63.2549 47.2486L64.67 48.6619ZM68.9081 48.6619L99.6694 79.4623L101.085 78.049L70.3232 47.2486L68.9081 48.6619Z"
      fill="#0E206F"
      mask="url(#path-9-inside-3_9_12113)"
    />
    <mask id="path-11-inside-4_9_12113" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.376 86.4203V96.6364C100.376 98.2932 99.0332 99.6364 97.3763 99.6364H32.0042L71.1931 67.6765C72.9051 66.2803 75.6808 66.2803 77.3928 67.6765L100.376 86.4203Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.376 86.4203V96.6364C100.376 98.2932 99.0332 99.6364 97.3763 99.6364H32.0042L71.1931 67.6765C72.9051 66.2803 75.6808 66.2803 77.3928 67.6765L100.376 86.4203Z"
      fill="#F0F4FF"
    />
    <path
      d="M100.376 86.4203H101.376V85.9455L101.008 85.6454L100.376 86.4203ZM32.0042 99.6364L31.3722 98.8614L29.1958 100.636H32.0042V99.6364ZM71.1931 67.6765L71.8251 68.4515V68.4515L71.1931 67.6765ZM77.3928 67.6765L76.7608 68.4515V68.4515L77.3928 67.6765ZM99.3763 86.4203V96.6364H101.376V86.4203H99.3763ZM99.3763 96.6364C99.3763 97.7409 98.4809 98.6364 97.3763 98.6364V100.636C99.5854 100.636 101.376 98.8455 101.376 96.6364H99.3763ZM97.3763 98.6364H32.0042V100.636H97.3763V98.6364ZM32.6362 100.411L71.8251 68.4515L70.5611 66.9016L31.3722 98.8614L32.6362 100.411ZM71.8251 68.4515C73.1692 67.3554 75.4167 67.3554 76.7608 68.4515L78.0248 66.9016C75.9449 65.2053 72.641 65.2053 70.5611 66.9016L71.8251 68.4515ZM76.7608 68.4515L99.7443 87.1953L101.008 85.6454L78.0248 66.9016L76.7608 68.4515Z"
      fill="#0E206F"
      mask="url(#path-11-inside-4_9_12113)"
    />
    <rect
      x="102.629"
      width="32"
      height="32"
      rx="16"
      transform="rotate(45 102.629 0)"
      fill="#4378FF"
    />
    <g clipPath="url(#clip0_9_12113)">
      <g clipPath="url(#clip1_9_12113)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111 22.6276C111 23.3455 110.418 23.9276 109.7 23.9276H95.5578C94.8399 23.9276 94.2578 23.3455 94.2578 22.6276C94.2578 21.9096 94.8399 21.3276 95.5578 21.3276H109.7C110.418 21.3276 111 21.9096 111 22.6276Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.629 14.2565C103.347 14.2565 103.929 14.8385 103.929 15.5565L103.929 29.6986C103.929 30.4166 103.347 30.9986 102.629 30.9986C101.911 30.9986 101.329 30.4166 101.329 29.6986L101.329 15.5565C101.329 14.8385 101.911 14.2565 102.629 14.2565Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9_12113">
        <rect
          width="21.3333"
          height="21.3333"
          fill="white"
          transform="translate(102.629 8.48535) rotate(45)"
        />
      </clipPath>
      <clipPath id="clip1_9_12113">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(102.629 5.65698) rotate(45)"
        />
      </clipPath>
    </defs>
  </svg>
);
