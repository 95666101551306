import { Modal, Button, Text } from '@mantine/core';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import classes from './SoldOutModal.module.scss';

interface ISoldOutModal {
  onBack(): void;
}

const SoldOutModal: React.FC<ISoldOutModal> = ({ onBack }) => {
  const { soldOut, setSoldOut } = useCheckoutDetailsContext();
  return (
    <Modal onClose={() => setSoldOut(false)} opened={soldOut}>
      <Text className={classes.text}>
        Sorry, the availability of this activity has changed. Please go back and try again.
      </Text>
      <Button className={classes.button} onClick={onBack} size="md" fullWidth radius="lg">
        Back
      </Button>
    </Modal>
  );
};

export default SoldOutModal;
