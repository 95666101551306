import classNames from 'classnames';

export const RedMedicalConsiderations = ({
  className = '',
  color = 'currentColor',
  size = 24,
  strokeWidth = 2,
  height = 22,
  width = 26,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={width}
    height={height}
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90576 7.56695C10.3266 7.56695 10.6677 7.9081 10.6677 8.32893V10.2339H12.5727C12.9935 10.2339 13.3347 10.575 13.3347 10.9959C13.3347 11.4167 12.9935 11.7578 12.5727 11.7578H10.6677V13.6628C10.6677 14.0836 10.3266 14.4248 9.90576 14.4248C9.48493 14.4248 9.14378 14.0836 9.14378 13.6628V11.7578H7.23882C6.81799 11.7578 6.47684 11.4167 6.47684 10.9959C6.47684 10.575 6.81799 10.2339 7.23882 10.2339H9.14378V8.32893C9.14378 7.9081 9.48493 7.56695 9.90576 7.56695Z"
      fill="#0E206F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.61981 0.328125C7.01354 0.328125 6.4321 0.568965 6.00341 0.997662C5.57471 1.42636 5.33387 2.0078 5.33387 2.61407V3.37605H1.52396C0.682301 3.37605 0 4.05835 0 4.90001V17.0917C0 17.9334 0.682301 18.6157 1.52396 18.6157H18.2876C19.1292 18.6157 19.8115 17.9334 19.8115 17.0917V4.90001C19.8115 4.05835 19.1292 3.37605 18.2876 3.37605H14.4776V2.61407C14.4776 2.0078 14.2368 1.42636 13.8081 0.997662C13.3794 0.568965 12.798 0.328125 12.1917 0.328125H7.61981ZM12.9537 3.37605V2.61407C12.9537 2.41198 12.8734 2.21817 12.7305 2.07527C12.5876 1.93237 12.3938 1.85209 12.1917 1.85209H7.61981C7.41772 1.85209 7.22391 1.93237 7.08101 2.07527C6.93811 2.21817 6.85783 2.41198 6.85783 2.61407V3.37605H12.9537ZM1.52396 4.90001V17.0917H18.2876V4.90001H1.52396Z"
      fill="#0E206F"
    />
    <circle cx="19.2803" cy="15.5215" r="6" fill="#E74522" />
    <path
      d="M19.0124 16.9405C19.1906 17.5051 19.0421 18.1289 18.5964 18.6044C17.9428 19.258 16.8732 19.258 16.2196 18.6044C15.5362 17.921 15.5362 16.8811 16.2196 16.1978C16.6653 15.7521 17.3189 15.6036 17.8834 15.7819L21.5378 12.1275C21.7458 11.8898 22.1023 11.8898 22.34 12.1275L22.6965 12.484C22.9045 12.692 22.9045 13.0485 22.6965 13.2565L19.0124 16.9405Z"
      stroke="white"
      strokeWidth="0.45567"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3883 16.7613L21.0919 14.0577L20.7354 13.7012L18.0318 16.4048L17.7347 16.3156C17.3188 16.1968 16.9028 16.3156 16.6057 16.6127C16.1601 17.0286 16.1601 17.7714 16.6057 18.1873C17.0514 18.633 17.7645 18.633 18.2101 18.1873C18.5072 17.9199 18.5964 17.4743 18.4776 17.088L18.3884 16.7612L18.3883 16.7613Z"
      fill="white"
    />
  </svg>
);
