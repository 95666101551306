import { Button, useMantineTheme } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';
import { useContext } from 'react';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import classes from './VerifiedModal.module.scss';
import classNames from 'classnames';
import { ShieldCheck } from '@phosphor-icons/react';

interface IVerifiedButtonProps {
  onClick(): void;
  activityDetailsPage?: boolean;
}

export const VerifiedButton: React.FC<IVerifiedButtonProps> = ({
  onClick,
  activityDetailsPage = false,
}: IVerifiedButtonProps) => {
  const { isEmbed, iframeLocation } = useContext(EmbedContext) as EmbedContextType;
  const theme = useMantineTheme();

  return (
    <Button
      leftSection={<ShieldCheck size={20} color={theme.colors.lime[8]} weight="fill" />}
      variant="subtle"
      onClick={() => {
        onClick();
        if (isEmbed) {
          trackAction(Actions.VERIFIED_PEBBLE_IFRAME, {
            iframeLocation,
          });
        } else {
          trackAction(Actions.VERIFIED_PEBBLE);
        }
      }}
      classNames={{
        section: classes.verifyButtonIcon,
        root: classNames(classes.verifyButton, {
          [classes.activityDetailsPage]: activityDetailsPage,
        }),
      }}
    >
      Verified by Pebble
    </Button>
  );
};
