import { categoryGroups, getAgeRangeLabel } from 'utils/filters/filters';

const capitalize = (string: string) => {
  const words = string.split(' ');

  return words.map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()).join(' ');
};

// Don't index search pages with dynamic postcode in the url
// set no-index, no-follow for all listings with /all-activities/around-E170GF
export const getMetaRobots = (place?: string, noIndexNoFollow?: boolean) => {
  if (!place) {
    return {
      nofollow: true,
      noindex: true,
    };
  }

  return {
    nofollow: noIndexNoFollow,
    noindex: noIndexNoFollow,
  };
};

interface SeoTitleParams {
  categoryName?: string;
  postcode?: string;
  place?: string;
  ageStart?: string;
  ageEnd?: string;
  activePage?: number;
  locationString?: string;
}

export const categoryNameAndSuffix = (category: string) => {
  for (const [groupName, categories] of Object.entries(categoryGroups)) {
    if (categories.includes(category)) {
      switch (groupName) {
        case 'group1':
          return `${category} for kids`;
        case 'group2':
          return `${category} classes for kids`;
        case 'group3':
          return `${category} activities for kids`;
        case 'group4':
          return `${category} classes`;
        default:
          return `${category} activities`;
      }
    }
  }
};

export const getSeoTitle = ({
  categoryName,
  place,
  postcode,
  ageStart,
  ageEnd,
  activePage,
  locationString,
}: SeoTitleParams) => {
  const buildTitle = (title: string) => {
    if (activePage && activePage > 1) {
      return `${title} | Page ${activePage}`;
    }

    return `${title}`;
  };

  const ageRangeLabel = getAgeRangeLabel(false, ageStart || '', ageEnd || '');

  const ageRangeLabelWithCategoryApplied = getAgeRangeLabel(
    false,
    ageStart || '',
    ageEnd || '',
    categoryName,
  );

  if (categoryName && place) {
    return `${categoryNameAndSuffix(categoryName)} ${capitalize(place)}`;
  }

  if (categoryName && ageStart && !ageEnd) {
    if (categoryName === 'Parent & Baby/Toddler') {
      return buildTitle(
        `${categoryNameAndSuffix(categoryName)} for kids ${ageRangeLabelWithCategoryApplied}`,
      );
    }
    return buildTitle(`${categoryNameAndSuffix(categoryName)} ${ageRangeLabelWithCategoryApplied}`);
  }
  if (categoryName && !ageStart && ageEnd) {
    if (categoryName === 'Parent & Baby/Toddler') {
      return buildTitle(
        `${categoryNameAndSuffix(categoryName)} for kids ${ageRangeLabelWithCategoryApplied}`,
      );
    }
    return buildTitle(`${categoryNameAndSuffix(categoryName)} ${ageRangeLabelWithCategoryApplied}`);
  }

  if (categoryName && ageRangeLabel) {
    if (categoryName === 'Parent & Baby/Toddler') {
      return buildTitle(
        `${categoryNameAndSuffix(categoryName)} for kids ${ageRangeLabelWithCategoryApplied}`,
      );
    }
    return buildTitle(`${categoryNameAndSuffix(categoryName)} ${ageRangeLabelWithCategoryApplied}`);
  }

  if (categoryName && postcode) {
    return buildTitle(`${categoryNameAndSuffix(categoryName)} ${postcode}`);
  }

  if (categoryName) {
    return buildTitle(`${categoryNameAndSuffix(categoryName)}`);
  }

  if (place) {
    return buildTitle(`Activities for kids in ${capitalize(place)}`);
  }

  if (ageRangeLabel) {
    return buildTitle(`Activities for kids ${ageRangeLabel}`);
  }

  // default
  if (postcode || locationString || !ageRangeLabel) {
    return buildTitle(`Activities for kids around ${postcode || locationString}`);
  }
};

interface SeoDescriptionParams {
  postcode?: string;
  place?: string;
}

export const getSeoDescription = ({ place, postcode }: SeoDescriptionParams) => {
  let location = 'near you';

  if (place) {
    location = `near ${capitalize(place)}`;
  }

  if (postcode) {
    location = `around ${postcode}`;
  }

  return `Discover exciting activities for children in your area! From fun-filled workshops to engaging classes, explore a wide range of options to keep your little ones entertained and inspired. Find the perfect classes ${location} and create unforgettable experiences for your kids. Click here to start exploring!`;
};

interface SeoBreadcrumbItemsParams {
  categoryName?: string;
  postcode?: string;
  place?: string;
}

interface SeoBreadcrumbItems {
  title: string;
  href?: string;
  nofollow?: boolean;
}

export const getBreadcrumbItems = ({
  categoryName,
  place,
  postcode,
}: SeoBreadcrumbItemsParams): Array<SeoBreadcrumbItems> => {
  const items: Array<SeoBreadcrumbItems> = [{ title: 'Home', href: '/' }];

  if (categoryName && place) {
    return [
      ...items,
      {
        title: `Near ${capitalize(place)}`,
        href: `/activities/all-activities/near-${place}#distance=5`,
      },
      { title: `${categoryName} Near ${capitalize(place)}` },
    ];
  }

  if (categoryName && postcode) {
    return [
      ...items,
      {
        title: `Around ${postcode}`,
        href: `/activities/all-activities/around-${postcode}#distance=5`,
        nofollow: true,
      },
      { title: `${categoryName} Around ${postcode}` },
    ];
  }

  if (place) {
    return [...items, { title: `Near ${capitalize(place)}` }];
  }

  if (postcode) {
    return [...items, { title: `Around ${postcode}` }];
  }

  return items;
};
