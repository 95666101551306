import { FC, useEffect, useState, useContext } from 'react';
import { Text, Group, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { trackAction, Actions } from 'utils/amplitude';
import { PebblePopover } from '../PebblePopover/PebblePopover';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import classes from './ShareButton.module.scss';
import { Share } from '@phosphor-icons/react';

interface ISharedButton {
  activityName: string;
}

const ShareButton: FC<ISharedButton> = ({ activityName }) => {
  const routerPath = useRouter()?.asPath;
  const theme = useMantineTheme();
  const clipboard = useClipboard({ timeout: 1000 });
  const [copied, setCopied] = useState(false);
  const origin =
    typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';

  const shareData = {
    title: `${activityName}`,
    url: `${origin}${routerPath}`,
  };

  const getShareData = async () => {
    try {
      if (navigator.canShare(shareData)) {
        await navigator.share(shareData);
      } else {
        clipboard.copy(shareData.url);
        setCopied(true);
        console.log(`Your system doesn't support sharing these files.`);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      clipboard.copy(shareData.url);
      if (error.message !== 'Abort due to cancellation of share.') {
        setCopied(true);
      }
      console.log(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => setCopied(false), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);

  const { isEmbed, iframeLocation } = useContext(EmbedContext) as EmbedContextType;

  // no clipboard events allowed in iframe
  if (isEmbed) {
    return null;
  }

  return (
    <div className={classes.shareButton}>
      <PebblePopover
        popoverOpened={copied}
        onClose={() => setCopied(false)}
        position="bottom"
        popoverTarget={
          <UnstyledButton
            title="Share this activity"
            onClick={() => {
              getShareData();
              if (isEmbed) {
                trackAction(Actions.ACTIVITY_SHARE_IFRAME, {
                  iframeLocation,
                });
              } else {
                trackAction(Actions.SHARE_DETAILS);
              }
            }}
          >
            <Group justify="right" gap="xs">
              <Text className={classes.label}>Share</Text>
              <Share size={28} weight="fill" color={theme.colors.blue[6]} />
            </Group>
          </UnstyledButton>
        }
      >
        {<Text size="sm">Activity link copied!</Text>}
      </PebblePopover>
    </div>
  );
};

export default ShareButton;
