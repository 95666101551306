import { Text, Center, Stack } from '@mantine/core';
import { Pebbles } from '@ui';
import classes from '../index.module.scss';

export const Loading = () => {
  return (
    <Center className={classes.fullPageContainer}>
      <Stack>
        <Pebbles size={120} />
        <Text size="xl" fw="bold">
          We are just confirming your booking, hold tight...
        </Text>
      </Stack>
    </Center>
  );
};
