import { Flex, Text, useMantineTheme } from '@mantine/core';
import { PebbleSelect } from 'components/ui';
import { useSavedSearchValues } from 'context/SavedSearchFilterValuesContext';
import { getAgeFilters, getDisabledAgeOption } from 'utils/filters/filters';
import { FilterOption } from 'interfaces';
import { AgeFilterTypeEnum, MaxAgeRangeEnum, MinAgeRangeEnum, SearchBarDropdownEnum } from 'enums';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import ResetSearchFilterButton from 'components/ui/ResetSearchFilterButtons/ResetSearchFilterButton';
import getURLpathnameParam from 'utils/getURLpathnameParam';
import { useRouter } from 'next/router';
import getEnumKeyByEnumValue from 'utils/getEnumKeyByEnumValue';
import classes from './AgeFilterContent.module.scss';
import { CaretDown } from '@phosphor-icons/react';

const AgeFilterContent = () => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const URLpathname = getURLpathnameParam(useRouter().pathname);

  const {
    searchFilterState: { selectedAgeStart, selectedAgeEnd },
    setSearchFilterState,
    setModifiedFilter,
  } = useSavedSearchValues();

  const minAgeOptions = getAgeFilters(AgeFilterTypeEnum.MIN);
  const maxAgeOptions = getAgeFilters(AgeFilterTypeEnum.MAX);

  const getUpdatedOptions = (ageOptions: FilterOption[], type: AgeFilterTypeEnum) => {
    const selectedOppositeAge = type === AgeFilterTypeEnum.MIN ? selectedAgeEnd : selectedAgeStart;
    return ageOptions.map((value: FilterOption) => {
      return {
        label: value.label,
        value: value.value,
        disabled: getDisabledAgeOption(value.value, type, selectedOppositeAge),
      };
    });
  };

  return (
    <>
      {!isMobile && (
        <Flex justify="space-between" align="center">
          <Text
            c={theme.colors.blue[8]}
            style={{
              fontWeight: 700,
            }}
          >
            Ages
          </Text>
          <ResetSearchFilterButton filterType={SearchBarDropdownEnum.age} />
        </Flex>
      )}

      <Flex direction={isMobile ? 'row' : 'column'}>
        <PebbleSelect
          mt="sm"
          data={getUpdatedOptions(minAgeOptions, AgeFilterTypeEnum.MIN)}
          placeholder="Min age"
          onChange={(value: string | null) => {
            setSearchFilterState((prevState) => ({
              ...prevState,
              selectedAgeStart: value || '',
              selectedAgeEnd: selectedAgeEnd || '1201',
            }));

            trackAction(Actions.FILTER_MIN_AGE_SELECTED, {
              minAge: `${getEnumKeyByEnumValue(MinAgeRangeEnum, value || '')}`,
              URLpathname,
            });

            setModifiedFilter(true);
          }}
          value={selectedAgeStart}
          classNames={{
            dropdown: classes.dropdown,
            input: classes.input,
            section: classes.rightSection,
            wrapper: classes.wrapper,
            option: classes.item,
          }}
          rightSection={<CaretDown size={16} color={theme.colors.blue[8]} weight="bold" />}
          comboboxProps={{ withinPortal: false }}
        />
        <PebbleSelect
          mt="sm"
          mb="sm"
          data={getUpdatedOptions(maxAgeOptions, AgeFilterTypeEnum.MAX)}
          placeholder="Max age"
          onChange={(value: string | null) => {
            setSearchFilterState((prevState) => ({
              ...prevState,
              selectedAgeEnd: value || '',
              selectedAgeStart: selectedAgeStart || '0',
            }));

            trackAction(Actions.FILTER_MAX_AGE_SELECTED, {
              maxAge: `${getEnumKeyByEnumValue(MaxAgeRangeEnum, value || '')}`,
              URLpathname,
            });

            setModifiedFilter(true);
          }}
          value={selectedAgeEnd}
          classNames={{
            dropdown: classes.dropdown,
            input: classes.input,
            section: classes.rightSection,
            option: classes.item,
          }}
          rightSection={<CaretDown size={16} color={theme.colors.blue[8]} weight="bold" />}
          comboboxProps={{ withinPortal: false }}
        />
      </Flex>
    </>
  );
};

export default AgeFilterContent;
