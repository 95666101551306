import classNames from 'classnames';

const Logo = ({ height = '50', className = '' }) => (
  <svg
    className={classNames('logo', className)}
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 50 50"
  >
    <path
      d="M5.38,40.74s1.5-3.29,7-5.18A26.72,26.72,0,0,1,26,34.63a77.31,77.31,0,0,1,13,3.14c4.82,1.77,6.12,2.84,6.09,5.26S42.7,47.37,42,47.68a20.92,20.92,0,0,1-8.74,2.1c-5,.15-16.22.31-18.13.16s-6.59-.31-8.47-2.87S4.46,42.28,5.38,40.74Z"
      fill="#4378ff"
    />
    <path
      d="M9.45,17.94s3.36-3.17,11.08-3.3,16.17,1.21,19.21,4.68,1.13,6.41,1.13,6.41A7.29,7.29,0,0,1,36.68,29a41.22,41.22,0,0,1-10.29,1.46c-4.35,0-13.53-.51-15.61-2.44s-3.47-3.52-3.46-5.68S9.45,17.94,9.45,17.94Z"
      fill="#fb54a2"
    />
    <path
      d="M15.17,2.25A13.11,13.11,0,0,1,22.6,0c4.34.08,5.75,1,5.75,1a32.23,32.23,0,0,0,3.7,2.12A3.68,3.68,0,0,1,34.7,5.3c.49,1.47,1.17,3.11.09,4.09a7.06,7.06,0,0,1-3.89,2.18,19.18,19.18,0,0,1-6.31.57c-1.64-.08-5.52-.49-6.87-.49s-3.85-1.51-4.16-3.29S13.3,3.09,15.17,2.25Z"
      fill="#9de551"
    />
  </svg>
);

export default Logo;
