import { Burger, Menu, useMantineTheme } from '@mantine/core';
import { trackAction, Actions } from 'utils/amplitude';
import { useRouter } from 'next/router';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import classes from './NavMobile.module.scss';

interface IAdHocNavMobile {
  userToken: string;
}

enum NavBarRoutes {
  Activities = 'Activities',
  AdHocChildcare = 'AdHocChildcare',
  Account = 'Account',
}

const NavMobile: React.FC<IAdHocNavMobile> = ({ userToken }) => {
  const router = useRouter();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery('(max-width: 897px)', false);

  const [opened, { toggle }] = useDisclosure();
  // const [opened, setOpened] = useState(false);

  const handleClick = (route: string) => {
    if (route === NavBarRoutes.Activities) {
      router.push('/');
    } else if (route === NavBarRoutes.AdHocChildcare) {
      router.push('https://bookpebble.co.uk/childcare');
    } else {
      router.push(
        userToken
          ? `${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}profile`
          : `${process.env.NEXT_PUBLIC_PEBBLE_APP_ENDPOINT}welcome`,
      );
    }
  };

  return (
    <Menu
      classNames={{
        dropdown: classes.dropdown,
        item: classes.item,
      }}
      opened={opened}
      onChange={toggle}
      shadow="md"
      width={isMobile ? '105%' : '250px'}
    >
      <Menu.Target>
        <Burger
          opened={opened}
          onClick={() => {
            toggle();
            trackAction(Actions.ACTIVITIES_MENU);
          }}
          aria-label="Toggle navigation"
          mr="sm"
          color={theme.colors.blue[8]}
        />
        {/* <Button size="xs" className={classes.toggle}>
          {opened ? 'Close' : 'Menu'}
        </Button> */}
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          color={theme.colors.blue[8]}
          onClick={() => {
            trackAction(Actions.ACTIVITIES_NAV);
            handleClick(NavBarRoutes.Activities);
          }}
        >
          Activities
        </Menu.Item>
        <Menu.Item
          color={theme.colors.gray[5]}
          onClick={() => {
            trackAction(Actions.AD_HOC_NAV);
            handleClick(NavBarRoutes.AdHocChildcare);
          }}
        >
          Ad-hoc Childcare
        </Menu.Item>
        <Menu.Item
          color={theme.colors.gray[5]}
          onClick={() => {
            trackAction(Actions.ACCOUNT_NAV);
            handleClick(NavBarRoutes.Account);
          }}
        >
          Account
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default NavMobile;
