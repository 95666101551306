import { FC, forwardRef } from 'react';
import PebbleColour from './pebbleColour.svg';
import PebbleWhite from './pebbleWhite.svg';
import PebbleAllWhite from './pebbleAllWhite.svg';

interface IPebbleLogoProps {
  className?: string;
  variant: 'white' | 'default' | 'all-white';
  height?: number;
  onClick?(): void;
  href?: string;
}

const PebbleLogo: FC<IPebbleLogoProps> = forwardRef(
  ({ onClick, href, className, variant, height = 60 }, ref) => {
    const logoProps = {
      className,
      height,
      href,
      onClick,
      ...((href || onClick) && {
        ref,
      }),
    };
    switch (variant) {
      case 'white':
        return <PebbleWhite {...logoProps} />;
      case 'all-white':
        return <PebbleAllWhite {...logoProps} />;
      default:
        return <PebbleColour {...logoProps} />;
    }
  },
);

export default PebbleLogo;
