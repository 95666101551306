import { Button, ButtonProps } from '@mantine/core';
import { forwardRef, HTMLAttributeAnchorTarget, useContext } from 'react';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import { getIframeBtnStyles } from 'utils/iframeBtnStyles';
import classes from './BookNowButton.module.scss';
import classNames from 'classnames';
import { Lightning } from '@phosphor-icons/react';

interface IBookNowButton extends ButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  id?: string;
  target?: HTMLAttributeAnchorTarget;
  subPriceButton?: boolean;
  hideLightning?: boolean;
}

const BookNowButton: React.FC<IBookNowButton> = forwardRef(
  (
    { children, radius = 'xs', id, subPriceButton = false, hideLightning = false, ...rest },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => {
    const { iframeTheme } = useContext(EmbedContext) as EmbedContextType;
    const colours = getIframeBtnStyles(iframeTheme);

    return (
      <Button
        {...rest}
        className={classNames(classes.button, { [classes.subsPriceButton]: subPriceButton })}
        data-testid="book-now-button"
        data-cy="book-now-button"
        radius={radius}
        leftSection={!hideLightning ? <Lightning size={18} weight="fill" /> : null}
        id={id}
        style={colours}
      >
        {children}
      </Button>
    );
  },
);

export default BookNowButton;
