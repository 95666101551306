import classNames from 'classnames';

export const UpcomingDatesCalendar = ({
  className = '',
  color = '#0E206F',
  size = 21,
  strokeWidth = 1.5,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    color={color}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83838 2C8.39066 2 8.83838 2.44772 8.83838 3V4H16.8384V3C16.8384 2.44772 17.2861 2 17.8384 2C18.3907 2 18.8384 2.44772 18.8384 3V4H19.8384C21.4953 4 22.8384 5.34315 22.8384 7V14C22.8384 14.5523 22.3907 15 21.8384 15C21.2861 15 20.8384 14.5523 20.8384 14V10H4.83838V19C4.83838 19.5523 5.2861 20 5.83838 20H10.8384C11.3907 20 11.8384 20.4477 11.8384 21C11.8384 21.5523 11.3907 22 10.8384 22H5.83838C4.18153 22 2.83838 20.6569 2.83838 19V7C2.83838 5.34315 4.18153 4 5.83838 4H6.83838V3C6.83838 2.44772 7.2861 2 7.83838 2ZM5.83838 6C5.2861 6 4.83838 6.44772 4.83838 7V8H20.8384V7C20.8384 6.44772 20.3907 6 19.8384 6H5.83838Z"
      fill={color}
    />
    <path
      d="M19.1313 17.7071C18.7408 17.3166 18.7408 16.6834 19.1313 16.2929C19.5218 15.9024 20.155 15.9024 20.5455 16.2929L22.5455 18.2929C22.936 18.6834 22.936 19.3166 22.5455 19.7071L20.5455 21.7071C20.155 22.0976 19.5218 22.0976 19.1313 21.7071C18.7408 21.3166 18.7408 20.6834 19.1313 20.2929L20.4242 19L19.1313 17.7071Z"
      fill={color}
    />
    <path
      d="M14.1313 16.2929C13.7408 16.6834 13.7408 17.3166 14.1313 17.7071L15.4242 19L14.1313 20.2929C13.7408 20.6834 13.7408 21.3166 14.1313 21.7071C14.5218 22.0976 15.155 22.0976 15.5455 21.7071L17.5455 19.7071C17.936 19.3166 17.936 18.6834 17.5455 18.2929L15.5455 16.2929C15.155 15.9024 14.5218 15.9024 14.1313 16.2929Z"
      fill={color}
    />
  </svg>
);
