import { gql } from '@apollo/client';

const GetActivitySitemapInfo = gql`
  query getActivitySitemapInfo {
    marketplaceActivitySitemapInfo {
      totalEntries
      pageSize
      numberOfPages
    }
  }
`;

export default GetActivitySitemapInfo;
