import { createContext, FC, useEffect, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

export interface GoogleContextType {
  ready: boolean;
}

export const GoogleContext = createContext<GoogleContextType | null>(null);

interface IGoogleProvider {
  children?: React.ReactNode;
}

export const GoogleContextProvider: FC<IGoogleProvider> = ({ children }) => {
  const loader = new Loader({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '',
    version: 'weekly',
  });

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const loadLibs = async () => {
      try {
        await loader.importLibrary('core');
        await loader.importLibrary('places');
        setReady(true);
      } catch (e) {
        console.log(e);
      }
    };
    loadLibs();
  });

  return <GoogleContext.Provider value={{ ready }}>{children}</GoogleContext.Provider>;
};
