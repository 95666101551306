import { gql } from '@apollo/client';

const ActivityAvailabilityQuery = gql`
  query marketplaceActivity($activityId: UUID!) {
    marketplaceActivity(activityId: $activityId) {
      singleSessions {
        date
        products {
          spotsLeft
          checkoutKey
          checkoutValue
          checksum
        }
      }
      blockBookings {
        checkoutKey
        checkoutValue
        spotsLeft
        checksum
      }
    }
  }
`;

export default ActivityAvailabilityQuery;
