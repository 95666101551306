import { Anchor, Button, Modal, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { GreenSuccessCheck } from '@icons';
import classes from './FurtherInformationSuccessModal.module.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IFurtherInformationSuccessModalProps {
  opened: boolean;
  onClose: () => void;
}

const FurtherInformationSuccessModal: React.FC<IFurtherInformationSuccessModalProps> = ({
  opened,
  onClose,
}) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      centered
      classNames={{
        content: classes.modalContent,
      }}
      w={isMobile ? '90%' : 600}
      padding={isMobile ? 24 : 40}
      fullScreen
      radius={0}
    >
      <Stack align="center" maw={400} style={{ margin: 'auto' }}>
        <GreenSuccessCheck size={64} />

        <Text
          fw={700}
          style={{
            fontSize: '20px',
          }}
        >
          Information submitted!
        </Text>
        <Text
          style={{
            textAlign: 'center',
            fontSize: theme.fontSizes.sm,
          }}
        >
          We'll pass on these details to the provider. If there is any information you would like to
          update then please contact the activity provider or{' '}
          <Anchor
            style={{
              textDecoration: 'underline',
            }}
            href="mailto: support@bookpebble.co.uk"
          >
            support@bookpebble.co.uk
          </Anchor>
        </Text>
        <Anchor
          href="https://activities.bookpebble.co.uk/"
          style={{ color: 'white', textDecoration: 'none' }}
        >
          <Button className={classes.submitButton} radius={60} px={24} h={48} mt={theme.spacing.lg}>
            Explore more activities
          </Button>
        </Anchor>
      </Stack>
    </Modal>
  );
};

export default FurtherInformationSuccessModal;
