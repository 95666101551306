import classNames from 'classnames';

export const Schedules = ({ className = '', color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.459 3V6.53363"
      stroke={color}
      strokeWidth="2.25269"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0947 3V6.53363"
      stroke={color}
      strokeWidth="2.25269"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.21767 4.47461H8.45763V6.54686C8.45763 7.99834 9.63428 9.17499 11.0858 9.17499C12.5372 9.17499 13.7139 7.99834 13.7139 6.54686V4.47461H18.8339V6.54686C18.8339 7.99834 20.0106 9.17499 21.462 9.17499C22.9135 9.17499 24.0902 7.99834 24.0902 6.54686V4.47461H25.357C27.4102 4.47461 29.0746 6.13907 29.0746 8.19228V26.3316C29.0746 28.3848 27.4102 30.0493 25.357 30.0493H7.21767C5.16446 30.0493 3.5 28.3848 3.5 26.3316V8.19228C3.5 6.13907 5.16446 4.47461 7.21767 4.47461ZM3.55409 15.3254H29.0006V13.0727H3.55409V15.3254Z"
      fill={color}
    />
    <path
      d="M12.8162 27.2617V26.0993C13.1857 25.9642 13.4695 25.7975 13.6677 25.5992C13.875 25.392 13.9786 25.1217 13.9786 24.7883V23.0041H12.8027V21.9634H13.9786V20.5442C13.9786 19.9405 14.0958 19.4179 14.33 18.9763C14.5733 18.5348 14.9338 18.1969 15.4113 17.9626C15.8889 17.7193 16.4926 17.5977 17.2225 17.5977C17.7812 17.5977 18.2993 17.6833 18.7768 17.8545C19.2634 18.0257 19.6869 18.269 20.0474 18.5844L19.4391 19.8414C19.1147 19.544 18.7768 19.3277 18.4254 19.1926C18.074 19.0574 17.7001 18.9898 17.3036 18.9898C16.7629 18.9898 16.362 19.116 16.1007 19.3683C15.8483 19.6206 15.7222 19.9945 15.7222 20.4901V21.9634H18.0064V23.0041H15.7357V24.3828C15.7357 24.7883 15.6501 25.1172 15.4789 25.3695C15.3167 25.6128 15.0824 25.793 14.7761 25.9101V25.8425H19.9122V27.2617H12.8162Z"
      fill="white"
    />
  </svg>
);
