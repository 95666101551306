import { Text, useMantineTheme } from '@mantine/core';
import { GreenRectangle } from '@icons';
import classes from './HomepageStartBooking.module.scss';
import { PebbleButtonsEnum } from 'enums';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleButtonSet } from 'components/ui';

interface IHomepageStartBookingProps {
  onClick?: () => void;
}

const HomepageStartBooking: React.FC<IHomepageStartBookingProps> = ({ onClick }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  return (
    <div className={classes.wrapper}>
      <div className={classes.greenRectangle}>
        <GreenRectangle size={30} />
      </div>
      <div>
        <Text component="h3" className={classes.subheading}>
          Try it now
        </Text>
        <Text className={classes.textCopy}>Book an activity for your child within seconds.</Text>
        <PebbleButtonSet
          btnVariant={PebbleButtonsEnum.PRIMARY}
          size="lg"
          onClick={onClick}
          w={isMobile ? '85%' : 211}
        >
          Search Nearby
        </PebbleButtonSet>
      </div>
    </div>
  );
};

export default HomepageStartBooking;
