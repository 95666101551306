import { gql } from '@apollo/client';

const CreateWaitListRequest = gql`
  mutation createWaitListRequest($input: CreateWaitListRequestInputType!) {
    createWaitListRequest(input: $input) {
      created
    }
  }
`;

export default CreateWaitListRequest;
