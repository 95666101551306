import { Box, Group, Stack, Text } from '@mantine/core';
import { Info } from '@phosphor-icons/react';
import { BlockTrialTypeEnum } from 'enums';
import classes from './TrialsOnBlocksInfoBanner.module.scss';

interface ITrialsOnBlocksInfoBannerProps {
  blockTrialType: BlockTrialTypeEnum;
}

const TrialsOnBlocksInfoBanner: React.FC<ITrialsOnBlocksInfoBannerProps> = ({ blockTrialType }) => {
  const isPaidTrial = blockTrialType === BlockTrialTypeEnum.PAID_TRIAL;

  return (
    <Box className={classes.box}>
      <Group wrap="nowrap" gap={8} align="flex-start">
        <Info size={26} className={classes.icon} />
        <Stack>
          <Text fw={600} size="xs">
            Payment details are collected at checkout, but{' '}
            {isPaidTrial
              ? 'only the charge for the trial is collected immediately.'
              : 'no charge is made immediately.'}
          </Text>
          <Text fw={600} size="xs">
            Upon booking, you’ll receive an email with a cancellation option.
          </Text>
          <Text fw={600} size="xs">
            If you don’t wish to continue after the trial, cancel by 23:30 the day before the first
            paid session, or your card will be charged for the paid sessions.
          </Text>
        </Stack>
      </Group>
    </Box>
  );
};

export default TrialsOnBlocksInfoBanner;
