import { Flex, FlexProps, MantineSize, Text, ThemeIcon } from '@mantine/core';
import { AriaRole, MouseEventHandler, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import classes from './PebbleIconButtonSet.module.scss';
import { PebbleButtonsEnum } from 'enums';

interface PebbleIconButtonSetBaseProps {
  onClick?: MouseEventHandler<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>;
  btnVariant: PebbleButtonsEnum;
  icon: ReactNode;
  size?: MantineSize;
  label?: string | ReactNode;
  disabled?: boolean;
  role?: AriaRole;
  href?: string;
  target?: string;
}

type PebbleIconButtonSetProps = PebbleIconButtonSetBaseProps & FlexProps;

export const PebbleIconButtonSet = forwardRef<
  HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
  PebbleIconButtonSetProps
>(({ onClick, btnVariant, icon, size, label, disabled, role, href, target, ...props }, ref) => {
  const getButtonSize = () => {
    switch (size) {
      case 'lg':
        return '48px';
      case 'md':
        return '40px';
      case 'sm':
        return '32px';
      default:
        return 'auto';
    }
  };

  const isLink = href !== undefined && role === 'link';
  const component = isLink ? 'a' : 'button';

  return (
    <Flex
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ref={ref as any}
      {...props}
      component={component}
      href={isLink ? href : undefined}
      target={isLink ? target : undefined}
      role={role || (isLink ? 'link' : 'button')}
      onClick={onClick}
      direction="column"
      align="center"
      gap={2}
      className={classNames(classes.btnWrapper, {
        [classes.primaryBtnWrapper]: btnVariant === PebbleButtonsEnum.PRIMARY && !disabled,
        [classes.secondaryBtnWrapper]: btnVariant === PebbleButtonsEnum.SECONDARY && !disabled,
        [classes.tertiaryBtnWrapper]: btnVariant === PebbleButtonsEnum.TERTIARY && !disabled,
      })}
    >
      <ThemeIcon
        size={getButtonSize()}
        classNames={{
          root: classNames(classes.iconRoot, {
            [classes.primaryBtn]: btnVariant === PebbleButtonsEnum.PRIMARY,
            [classes.secondaryBtn]: btnVariant === PebbleButtonsEnum.SECONDARY,
            [classes.tertiaryBtn]: btnVariant === PebbleButtonsEnum.TERTIARY,
            [classes.lgBtn]: size === 'lg',
            [classes.mdBtn]: size === 'md',
            [classes.smBtn]: size === 'sm',
            [classes.disabled]: disabled,
          }),
        }}
      >
        {icon}
      </ThemeIcon>
      {label && (
        <Text
          className={classNames(classes.btnLabel, {
            [classes.lgBtn]: size === 'lg',
            [classes.mdBtn]: size === 'md',
            [classes.smBtn]: size === 'sm',
            [classes.disabledLabel]: disabled,
          })}
        >
          {label}
        </Text>
      )}
    </Flex>
  );
});
