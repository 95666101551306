// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Image, Transformation } from 'cloudinary-react';
import classes from './CloudinaryLogo.module.scss';

interface ICloudinaryLogo {
  id: string;
  alt?: string;
  width?: number;
  height?: number;
  setLogoError(value: boolean): void;
  logoError?: boolean;
}

const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME || '';

const CloudinaryLogo: React.FC<ICloudinaryLogo> = ({
  id,
  width = 250,
  height = 250,
  alt,
  setLogoError,
}) => {
  if (!cloudName) {
    return null;
  }

  return (
    <div className={classes.logoWrapper}>
      <Image
        onError={() => {
          setLogoError(true);
        }}
        cloudName={cloudName}
        secure
        publicId={id}
        alt={alt}
      >
        {(width || height) && (
          <Transformation width={width} height={height} crop="fill" quality="auto:best" />
        )}
      </Image>
    </div>
  );
};

export default CloudinaryLogo;
