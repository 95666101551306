import { useMemo } from 'react';
import { Activity } from 'types';
import { Map } from 'components';

interface IActivityMapProps {
  activity: Activity;
}

const ActivityMap: React.FC<IActivityMapProps> = (props) => {
  const { activity } = props;

  const defaultCoordinates = useMemo(() => {
    return {
      centre: {
        lat: Number(activity.location?.latitude),
        lng: Number(activity.location?.longitude),
      },
      zoom: 14,
    };
  }, [activity]);

  const marker = {
    [`${Number(activity.location?.latitude)}:${Number(activity.location?.longitude)}`]: [activity],
  };

  return (
    <Map
      centerLat={defaultCoordinates.centre.lat}
      centerLng={defaultCoordinates.centre.lng}
      zoom={defaultCoordinates.zoom}
      markers={marker}
    />
  );
};

export default ActivityMap;
