import classNames from 'classnames';

export const CheckBadge = ({ className = '', color = 'currentColor', size = 40, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M37.1519 15.4173L37.4271 10.4741C37.4438 10.1407 37.2686 9.83227 36.9684 9.6822L32.5647 7.45651L30.3379 3.05514C30.1877 2.76339 29.8791 2.57999 29.5455 2.59667L24.5914 2.86342L20.4629 0.13754C20.1877 -0.0458467 19.8207 -0.0458467 19.5455 0.13754L15.417 2.86342L10.4713 2.58833C10.1376 2.57166 9.82906 2.74671 9.6789 3.0468L7.45206 7.44817L3.04839 9.67386C2.75649 9.82391 2.573 10.1323 2.58968 10.4658L2.86491 15.409L0.137612 19.5353C-0.0458706 19.8104 -0.0458706 20.1772 0.137612 20.4522L2.85652 24.5868L2.58129 29.53C2.56461 29.8635 2.73975 30.1719 3.04 30.322L7.44367 32.5476L9.67052 36.949C9.82064 37.2408 10.1292 37.4242 10.4629 37.4075L15.4086 37.1324L19.5371 39.8583C19.6789 39.95 19.8373 40 19.9958 40C20.1543 40 20.3127 39.95 20.4545 39.8583L24.583 37.1324L29.5287 37.4075C29.8624 37.4242 30.1709 37.2491 30.3211 36.949L32.5479 32.5476L36.9516 30.322C37.2435 30.1719 37.427 29.8635 37.4103 29.53L37.1351 24.5868L39.8624 20.4605C40.0459 20.1854 40.0459 19.8187 39.8624 19.5436L37.1519 15.4173ZM35.6005 23.903C35.5005 24.0531 35.4504 24.2281 35.4671 24.4115L35.7256 29.0879L31.5639 31.1885C31.4054 31.2719 31.2719 31.3969 31.1969 31.5553L29.0952 35.7149L24.4163 35.4565C24.2412 35.4482 24.0577 35.4982 23.9075 35.5899L20.0043 38.1657L16.101 35.5899C15.9675 35.4982 15.8007 35.4482 15.6423 35.4482H15.6006L10.9217 35.7066L8.81997 31.547C8.73657 31.3886 8.61147 31.2552 8.453 31.1802L4.28282 29.0879L4.54137 24.4115C4.54971 24.2364 4.49967 24.053 4.40793 23.903L1.83077 20.0017L4.40793 16.1005C4.50801 15.9504 4.55805 15.7754 4.54137 15.592L4.28282 10.9155L8.44461 8.81493C8.60308 8.73157 8.73652 8.60653 8.81159 8.44815L10.9133 4.28853L15.5922 4.54694C15.7673 4.55528 15.9508 4.50526 16.1009 4.41357L20.0042 1.83776L23.9075 4.41357C24.0576 4.5136 24.2328 4.56362 24.4163 4.54694L29.0951 4.28853L31.1968 8.44815C31.2802 8.60653 31.4053 8.73991 31.5638 8.81493L35.7256 10.9155L35.4671 15.592C35.4587 15.767 35.5088 15.9504 35.6005 16.1005L38.1777 20.0017L35.6005 23.903Z"
      fill="#4378FF"
    />
    <path
      d="M33.25 20C33.25 12.6822 27.3178 6.75 20 6.75C12.6822 6.75 6.75 12.6822 6.75 20C6.75 27.3178 12.6822 33.25 20 33.25C27.3178 33.25 33.25 27.3178 33.25 20Z"
      stroke="#9DE551"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9963 15.9469C26.5088 16.4595 26.5088 17.2905 25.9963 17.8031L18.9963 24.8031C18.7501 25.0492 18.4163 25.1875 18.0682 25.1875C17.7201 25.1875 17.3862 25.0492 17.1401 24.8031L13.3219 20.9849C12.8094 20.4723 12.8094 19.6413 13.3219 19.1287C13.8345 18.6162 14.6655 18.6162 15.1781 19.1287L18.0682 22.0188L24.1401 15.9469C24.6527 15.4344 25.4837 15.4344 25.9963 15.9469Z"
      fill="#4378FF"
    />
  </svg>
);
