import { SocialProofType } from 'types';
import { SocialProofLabelEnum } from 'enums';

const getSocialProofBadge = (socialProof: SocialProofType, isSoldOut: boolean) => {
  if (isSoldOut) {
    return SocialProofLabelEnum.soldOut;
  } else if (socialProof.limitedSpots) {
    return SocialProofLabelEnum.limitedSpots;
  } else if (socialProof.popular) {
    return SocialProofLabelEnum.popular;
  } else {
    return SocialProofLabelEnum.justAdded;
  }
};

export default getSocialProofBadge;
