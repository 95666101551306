import classNames from 'classnames';

export const CloneRegular = ({ className = '', color = 'currentColor', size = 32, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.6825 1.52368H9.13001C8.67664 1.52368 8.33662 1.90149 8.33662 2.31707V22.8696C8.33662 23.323 8.71442 23.663 9.13001 23.663H29.6825C30.1359 23.663 30.4759 23.2852 30.4759 22.8696V2.31707C30.4759 1.86371 30.1359 1.52368 29.6825 1.52368ZM28.8892 22.0762H9.9234V3.11046H28.8514V22.0762H28.8892ZM25.1867 28.1589C25.1867 28.6122 24.8089 28.9523 24.3933 28.9523H3.84075C3.38739 28.9523 3.04736 28.5744 3.04736 28.1589V7.60633C3.04736 7.15296 3.42517 6.81294 3.84075 6.81294C4.25634 6.81294 4.63414 7.19074 4.63414 7.60633V27.3655H24.3933C24.8467 27.3655 25.1867 27.7055 25.1867 28.1589ZM14.1548 12.5933C14.1548 12.14 14.5326 11.8 14.9482 11.8H18.5751V8.17303C18.5751 7.71967 18.9529 7.37964 19.3685 7.37964C19.8219 7.37964 20.1619 7.75745 20.1619 8.17303V11.8H23.7888C24.2422 11.8 24.5822 12.1778 24.5822 12.5933C24.5822 13.0467 24.2044 13.3867 23.7888 13.3867H20.1619V17.0136C20.1619 17.467 19.7841 17.807 19.3685 17.807C18.9151 17.807 18.5751 17.4292 18.5751 17.0136V13.3867H14.9482C14.5326 13.3867 14.1548 13.0467 14.1548 12.5933Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
    />
  </svg>
);
