import classNames from 'classnames';

export const IconActivity = ({ className = '', color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    {...props}
  >
    <path
      d="M23.4061 24.6621C21.3514 26.4136 18.6904 27.3905 15.9957 27.3905C9.66327 27.3905 4.47603 22.2369 4.47603 15.8707V14.8266H6.39598C6.69913 14.8266 7.00228 14.6581 7.13701 14.3887C7.27175 14.1192 7.27175 13.7824 7.10333 13.5129L4.30761 9.16776C4.00446 8.66251 3.19606 8.66251 2.85923 9.16776L0.130875 13.4792C-0.0375422 13.7487 -0.0375422 14.0855 0.0971912 14.355C0.231925 14.6245 0.535075 14.7929 0.838225 14.7929H2.75818V15.8707C2.75818 23.1464 8.68645 29.0746 15.9621 29.0746C19.0946 29.0746 22.1261 27.9631 24.4839 25.9421C24.8545 25.6389 24.8881 25.1 24.585 24.7295C24.3155 24.3926 23.7766 24.359 23.4061 24.6621Z"
      fill={color}
    />
    <path
      d="M31.1531 16.9485C31.4562 16.9485 31.7594 17.1169 31.8941 17.3864C32.0288 17.6558 32.0288 17.9927 31.9278 18.2621L29.1321 22.6073C28.9973 22.8768 28.6942 23.0115 28.4247 23.0115C28.1552 23.0115 27.8858 22.8431 27.7174 22.6073L24.9216 18.2621C24.7532 17.9927 24.7532 17.6558 24.888 17.3864C25.0227 17.1169 25.3258 16.9485 25.629 16.9485H27.5489V15.9043C27.5489 9.53815 22.3617 4.3846 16.0292 4.3846C13.3346 4.3846 10.6736 5.36142 8.61889 7.11295C8.24837 7.4161 7.70944 7.38242 7.40629 7.0119C7.10314 6.64139 7.13682 6.10245 7.50734 5.7993C9.86517 3.7783 12.8967 2.66675 16.0292 2.66675C23.3048 2.66675 29.2331 8.59502 29.2331 15.8706V16.9485H31.1531Z"
      fill={color}
    />
    <path
      d="M22.9347 15.8707C22.9347 12.0308 19.8358 8.93188 15.9959 8.93188C12.156 8.93188 9.05713 12.0308 9.05713 15.8707C9.05713 19.7106 12.156 22.8094 15.9959 22.8094C19.8358 22.8094 22.9347 19.7106 22.9347 15.8707ZM10.775 15.8707C10.775 12.9739 13.1328 10.6497 15.9959 10.6497C18.859 10.6497 21.2168 13.0076 21.2168 15.8707C21.2168 18.7337 18.859 21.0916 15.9959 21.0916C13.1328 21.0916 10.775 18.7674 10.775 15.8707Z"
      fill={color}
    />
    <path
      d="M18.7913 15.1295C19.1282 14.7927 19.1282 14.2538 18.7913 13.9169C18.4545 13.5801 17.9156 13.5801 17.5787 13.9169L15.4567 16.039L14.3788 14.9611C14.042 14.6243 13.503 14.6243 13.1662 14.9611C12.8294 15.2979 12.8294 15.8369 13.1662 16.1737L14.8504 17.8579C15.0188 18.0263 15.2209 18.0937 15.4567 18.0937C15.6925 18.0937 15.8946 17.9926 16.063 17.8579L18.7913 15.1295Z"
      fill={color}
    />
  </svg>
);
