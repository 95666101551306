import classNames from 'classnames';

export const AttendanceConfirmed = ({
  className = '',
  color = 'currentColor',
  size = 24,
  ...props
}) => (
  <svg
    className={classNames('icon', className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.8149 11.4867C9.21771 11.4867 10.3549 10.3704 10.3549 8.99333C10.3549 7.6163 9.21771 6.5 7.8149 6.5C6.4121 6.5 5.2749 7.6163 5.2749 8.99333C5.2749 10.3704 6.4121 11.4867 7.8149 11.4867Z"
      fill="#0E206F"
    />
    <path
      d="M13.295 14.8799L11.015 12.5799C10.595 12.1599 10.015 11.9199 9.415 11.9199H6.235C5.635 11.9199 5.055 12.1599 4.635 12.5799L2.315 14.8799C1.895 15.2999 1.895 16.0199 2.315 16.4199C2.735 16.8799 3.435 16.8599 3.875 16.4199L5.275 15.0199V21.3999C5.275 21.9999 5.755 22.4999 6.375 22.4999C6.975 22.4999 7.475 21.9999 7.475 21.3999V18.9999C7.475 18.8199 7.635 18.6599 7.815 18.6599C7.995 18.6599 8.155 18.8199 8.155 18.9999V21.3999C8.155 21.9999 8.635 22.4999 9.255 22.4999C9.855 22.4999 10.355 21.9999 10.355 21.3999V15.0399L11.755 16.4399C12.175 16.8599 12.875 16.8799 13.315 16.4399C13.735 16.0199 13.735 15.2999 13.295 14.8799Z"
      fill="#0E206F"
    />
    <path
      d="M20.1001 5.5L16.8242 8.77586L15.1001 7.05172"
      stroke="#7ECB01"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
