export const LookAgain = () => (
  <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 44.5C35.0457 44.5 44 35.5457 44 24.5C44 13.4543 35.0457 4.5 24 4.5C12.9543 4.5 4 13.4543 4 24.5C4 35.5457 12.9543 44.5 24 44.5Z"
      fill="white"
    />
    <path
      d="M16.0627 24.7018C15.9692 24.8933 15.9692 25.1134 16.0627 25.3049L16.0627 25.3049L31.2224 24.7018C31.129 24.5101 28.954 20.1859 23.6426 20.1859C18.3301 20.1859 16.1555 24.5116 16.0627 24.7018ZM16.0627 24.7018L16.1975 24.7676L16.0627 24.7018C16.0627 24.7018 16.0627 24.7018 16.0627 24.7018ZM31.2225 25.3049C31.3159 25.1134 31.316 24.8934 31.2225 24.7019L31.0876 25.2391L31.2224 25.3049C31.2224 25.3049 31.2224 25.3049 31.2225 25.3049ZM29.642 25.0032C29.0964 25.8926 27.2597 28.3587 23.6683 28.3705H23.6426C20.033 28.3705 18.1889 25.8935 17.6432 25.0033C18.1897 24.112 20.0338 21.6359 23.6426 21.6359C27.2511 21.6359 29.0951 24.1118 29.642 25.0032Z"
      fill="#4378FF"
      stroke="#4378FF"
      strokeWidth="0.3"
    />
    <path
      d="M23.6445 22.5531C22.1952 22.5531 20.9945 23.6404 20.9945 25.003C20.9945 26.3655 22.1952 27.4528 23.6445 27.4528C25.0939 27.4528 26.2945 26.3655 26.2945 25.003C26.2945 23.6404 25.0939 22.5531 23.6445 22.5531ZM23.6445 26.0029C23.0261 26.0029 22.5445 25.5426 22.5445 25.003C22.5445 24.4633 23.0261 24.003 23.6445 24.003C24.2629 24.003 24.7445 24.4633 24.7445 25.003C24.7445 25.5426 24.2629 26.0029 23.6445 26.0029Z"
      fill="#4378FF"
      stroke="#4378FF"
      strokeWidth="0.3"
    />
    <path
      d="M27.7675 13.1294L30.1895 16.2939L26.0902 16.742L27.7675 13.1294Z"
      fill="#4378FF"
      stroke="#4378FF"
    />
    <mask
      id="path-5-outside-1_1_9070"
      maskUnits="userSpaceOnUse"
      x="11"
      y="12.5"
      width="25"
      height="25"
      fill="black"
    >
      <rect fill="white" x="11" y="12.5" width="25" height="25" />
      <path d="M28.036 15.1668C28.1912 14.8303 28.0449 14.4297 27.6999 14.2943C25.6468 13.4889 23.4004 13.2891 21.2292 13.7264C18.8382 14.208 16.6623 15.4374 15.0158 17.2367C13.3693 19.0361 12.3375 21.3124 12.0696 23.7366C11.8017 26.1609 12.3114 28.6075 13.5252 30.7231C14.739 32.8386 16.594 34.5134 18.822 35.5056C21.0501 36.4977 23.536 36.7557 25.9203 36.2424C28.3047 35.7291 30.4641 34.471 32.0866 32.6499C33.5599 30.9962 34.5211 28.956 34.8619 26.7771C34.9191 26.4109 34.6471 26.0824 34.2783 26.0466V26.0466C33.9094 26.0108 33.5836 26.2813 33.5235 26.647C33.2121 28.5426 32.3685 30.316 31.0845 31.7571C29.6514 33.3657 27.744 34.477 25.6379 34.9304C23.5318 35.3838 21.336 35.1559 19.368 34.2796C17.3999 33.4032 15.7614 31.9238 14.6893 30.0552C13.6171 28.1865 13.1669 26.0254 13.4035 23.8841C13.6402 21.7428 14.5516 19.7321 16.0059 18.1427C17.4602 16.5533 19.3822 15.4675 21.4942 15.0421C23.3863 14.6609 25.3431 14.8276 27.1367 15.5154C27.4828 15.6481 27.8808 15.5033 28.036 15.1668V15.1668Z" />
    </mask>
    <path
      d="M28.036 15.1668C28.1912 14.8303 28.0449 14.4297 27.6999 14.2943C25.6468 13.4889 23.4004 13.2891 21.2292 13.7264C18.8382 14.208 16.6623 15.4374 15.0158 17.2367C13.3693 19.0361 12.3375 21.3124 12.0696 23.7366C11.8017 26.1609 12.3114 28.6075 13.5252 30.7231C14.739 32.8386 16.594 34.5134 18.822 35.5056C21.0501 36.4977 23.536 36.7557 25.9203 36.2424C28.3047 35.7291 30.4641 34.471 32.0866 32.6499C33.5599 30.9962 34.5211 28.956 34.8619 26.7771C34.9191 26.4109 34.6471 26.0824 34.2783 26.0466V26.0466C33.9094 26.0108 33.5836 26.2813 33.5235 26.647C33.2121 28.5426 32.3685 30.316 31.0845 31.7571C29.6514 33.3657 27.744 34.477 25.6379 34.9304C23.5318 35.3838 21.336 35.1559 19.368 34.2796C17.3999 33.4032 15.7614 31.9238 14.6893 30.0552C13.6171 28.1865 13.1669 26.0254 13.4035 23.8841C13.6402 21.7428 14.5516 19.7321 16.0059 18.1427C17.4602 16.5533 19.3822 15.4675 21.4942 15.0421C23.3863 14.6609 25.3431 14.8276 27.1367 15.5154C27.4828 15.6481 27.8808 15.5033 28.036 15.1668V15.1668Z"
      fill="#4378FF"
    />
    <path
      d="M28.036 15.1668C28.1912 14.8303 28.0449 14.4297 27.6999 14.2943C25.6468 13.4889 23.4004 13.2891 21.2292 13.7264C18.8382 14.208 16.6623 15.4374 15.0158 17.2367C13.3693 19.0361 12.3375 21.3124 12.0696 23.7366C11.8017 26.1609 12.3114 28.6075 13.5252 30.7231C14.739 32.8386 16.594 34.5134 18.822 35.5056C21.0501 36.4977 23.536 36.7557 25.9203 36.2424C28.3047 35.7291 30.4641 34.471 32.0866 32.6499C33.5599 30.9962 34.5211 28.956 34.8619 26.7771C34.9191 26.4109 34.6471 26.0824 34.2783 26.0466V26.0466C33.9094 26.0108 33.5836 26.2813 33.5235 26.647C33.2121 28.5426 32.3685 30.316 31.0845 31.7571C29.6514 33.3657 27.744 34.477 25.6379 34.9304C23.5318 35.3838 21.336 35.1559 19.368 34.2796C17.3999 33.4032 15.7614 31.9238 14.6893 30.0552C13.6171 28.1865 13.1669 26.0254 13.4035 23.8841C13.6402 21.7428 14.5516 19.7321 16.0059 18.1427C17.4602 16.5533 19.3822 15.4675 21.4942 15.0421C23.3863 14.6609 25.3431 14.8276 27.1367 15.5154C27.4828 15.6481 27.8808 15.5033 28.036 15.1668V15.1668Z"
      stroke="#4378FF"
      strokeWidth="0.6"
      mask="url(#path-5-outside-1_1_9070)"
    />
  </svg>
);
