import { useContext, useMemo } from 'react';
import { Auth } from '@pebble/common';
import { useRouter } from 'next/router';
import { ApolloError, useMutation } from '@apollo/client';
import { Center, Container, Stepper } from '@mantine/core';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { BackButton, SoldOutModal } from 'components';
import { CreateFreeActivityBooking, initiatePonchoPay } from 'graphql/mutations';
import { CheckoutBody, CommonMetaData } from 'types';
import { PreCheckoutBasketAttendee, IReviewPayFormValues } from 'interfaces';
import {
  ActivityTypeEnum,
  BasketAvailabilityErrorsEnum,
  CheckoutPaymentMethodEnum,
  StepperEnum,
} from 'enums';
import { trackAction, Actions } from 'utils/amplitude';
import { getImageUrl } from 'utils/getImageUrl';
import { EmbedContext, EmbedContextType } from 'context/EmbedContext';
import LeavingModal from 'components/ActivityDetails/LeavingModal/LeavingModal';
import getSocialProofBadge from 'utils/getSocialProofBadge';
import { Pebbles } from '@ui';
import getFilteredTickets from 'utils/getFilteredTickets';
import classes from './CheckoutDetails.module.scss';
import CheckoutAttendees from './CheckoutAttendees/CheckoutAttendees';
import { getPreviousLabel } from './CheckoutDetailsUtils';
import ReviewPayStep from './ReviewPayStep/ReviewPayStep';
import AddOnsStep from './AddOnsStep/AddOnsStep';
import { useCheckoutDetailsContext } from 'context/CheckoutDetailsContext';
import PreCheckoutInfoForm from 'components/CheckoutDetails/PreCheckoutInfoForm/PreCheckoutInfoForm';

dayjs.extend(advancedFormat);
interface ICheckoutDetails {
  goBack: () => void;
  guardianEmail?: string;
  guardianName?: string;
}

export interface GetBasketTicketInput {
  ticket: string;
  attendees: PreCheckoutBasketAttendee[];
  session?: string;
  block?: string;
}

const CheckoutDetails: React.FC<ICheckoutDetails> = ({ guardianEmail, guardianName }) => {
  const Router = useRouter();
  const userToken = Auth.useGetJWT();

  const {
    hasAddOns,
    isBlockTrialCheckout,
    steps,
    activeStep,
    setActiveStep,
    checkoutLoading,
    setCheckoutLoading,
    soldOut,
    setSoldOut,
    openLeavingModal,
    setOpenLeavingModal,
    basket,
    isLoggedIn,
    basketFinalizeMutation,
    preCheckoutFormRequired,
    scrollRef,
  } = useCheckoutDetailsContext();

  const { activity, tickets, supplierMarketingConsentGiven } = basket;

  const { hideEndTimes } = activity;

  const [getPonchoPatRedirectUrl] = useMutation(initiatePonchoPay, {
    variables: {
      basketId: basket.id,
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [createFreeBooking, { loading: freeBookingLoading }] = useMutation(
    CreateFreeActivityBooking,
    {
      variables: {
        input: {
          basketId: basket.id,
        },
      },
      onError: (error) => {
        console.log(error);
      },
      onCompleted: () => {
        Router.push(`/checkout/result?basketId=${basket.id}`);
      },
    },
  );

  const { iframeLocation } = useContext(EmbedContext) as EmbedContextType;

  const initiateStripeCheckout = async (body: CheckoutBody) => {
    setCheckoutLoading(true);

    const response = await fetch('/api/checkout_sessions', {
      method: 'POST',
      body: JSON.stringify(body),
    });

    const dataInfo = await response.json();

    if (dataInfo.statusCode === 500) {
      console.error(dataInfo.message);
      return;
    }

    if (dataInfo.url) {
      window.location.href = dataInfo.url;
      return;
    }

    throw new Error('Error initializing stripe checkout');
  };

  const initiatePonchoPayCheckout = async () => {
    setCheckoutLoading(true);

    const { data } = await getPonchoPatRedirectUrl();

    const { redirectUrl } = data?.initiatePonchoPayCheckout;

    window.location.href = redirectUrl;
  };

  const commonMetaData: CommonMetaData | null = useMemo(() => {
    const activityUrl = `${window.location.origin}/activity/${activity.id}`;

    return {
      basketId: basket.id,
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
      ...(iframeLocation && {
        iframeLocation,
      }),
      url: activityUrl,
    };
  }, [basket, iframeLocation, activity]);

  const handleCheckout = async (
    paymentType: CheckoutPaymentMethodEnum,
    reviewPayFormValues: IReviewPayFormValues,
  ) => {
    const { isMarketingConsentGiven, supplierLinksAccepted } = reviewPayFormValues;
    trackAction(
      isLoggedIn ? Actions.PROCEED_TO_PAYMENT_LOGGED_IN : Actions.PROCEED_TO_PAYMENT_GUEST,
      {
        activityLocation: activity.isOnline ? 'online' : 'in-person',
        activityType: activity.activityType,
      },
    );

    try {
      await basketFinalizeMutation({
        variables: {
          input: {
            id: basket.id,
            isMarketingConsentGiven,
            supplierLinksAccepted,
          },
        },
      });

      const { guest } = basket;

      // Set session storage of name and email for reviews post-checkout
      if (window) {
        if (isLoggedIn) {
          window.sessionStorage.setItem('fullname', JSON.stringify(guardianName));
          window.sessionStorage.setItem('email', JSON.stringify(guardianEmail));
        } else {
          window.sessionStorage.setItem('fullname', JSON.stringify(guest?.fullName));
          window.sessionStorage.setItem('email', JSON.stringify(guest?.email));
        }
      }

      const subscriptionTicket =
        activity.activityType === ActivityTypeEnum.SUBSCRIPTION
          ? basket.tickets.find((ticket) => Boolean(ticket.subscriptionOption?.stripePriceId))
          : null;

      const body: CheckoutBody = {
        name: activity.name,
        amount: basket.finalAmount,
        email: isLoggedIn ? guardianEmail : basket.guest?.email,
        imageUrl: getImageUrl(activity.cloudinaryImageId),
        activityType: activity.activityType,
        subscriptionStart: activity.subscriptionStart,
        ...(subscriptionTicket && {
          priceId: subscriptionTicket.subscriptionOption?.stripePriceId,
        }),
        ...(basket.tickets[0].subscriptionTrialSelected && {
          trialSelected: true,
          trialCost: activity.subscriptionTrialPrice,
          trialType: activity.subscriptionTrialType,
        }),
        ...(isBlockTrialCheckout && {
          isBlockTrial: true,
          blockTrialType: tickets[0].blockTrialType,
          trialCost: Number((tickets[0]?.blockTrialPrice || 0) * tickets[0].ticketCapacity),
        }),
        metaData: commonMetaData,
      };

      if (paymentType === CheckoutPaymentMethodEnum.PONCHOPAY)
        return await initiatePonchoPayCheckout();
      if (paymentType === CheckoutPaymentMethodEnum.STRIPE)
        return await initiateStripeCheckout(body);
      if (paymentType === CheckoutPaymentMethodEnum.FREE_BOOKING) {
        return await createFreeBooking({
          variables: {
            input: {
              basketId: basket.id,
            },
          },
          context: {
            ...(isLoggedIn && {
              headers: {
                Authorization: `${userToken}`,
              },
            }),
          },
        });
      }
    } catch (error) {
      const apolloError = error as ApolloError;

      const availabilityErrors = Object.values(BasketAvailabilityErrorsEnum);

      if (availabilityErrors.includes(apolloError.message as BasketAvailabilityErrorsEnum)) {
        setSoldOut(true);
      }

      setCheckoutLoading(false);
    }
  };

  const goBack = () => {
    Router.push(`/activity/${activity.slug}`);
    const action = userToken ? Actions.CHECKOUT_DETAILS_BACK : Actions.GUEST_CHECKOUT_DETAILS_BACK;
    trackAction(action);
  };

  // repetitive instances of multiple quantity tickets filtered
  const filteredTickets = getFilteredTickets(tickets);

  const handlePreviousStep = () => {
    if (activeStep === StepperEnum.CONFIRM_ATTENDEES) {
      trackAction(Actions.CHECKOUT_BACK_ACTIVITY_DETAILS);
      if (iframeLocation) {
        goBack();
      } else {
        setOpenLeavingModal(true);
      }
    } else if (activeStep === StepperEnum.ANSWER_QUESTIONS) {
      setActiveStep(steps[steps.indexOf(activeStep) - 1]);
      trackAction(Actions.CHECKOUT_BACK_ATTENDEES);
    } else if (activeStep === StepperEnum.SELECT_ADDONS) {
      setActiveStep(steps[steps.indexOf(activeStep) - 1]);
      trackAction(Actions.CHECKOUT_BACK_ANSWER_QUESTIONS);
    } else if (activeStep === StepperEnum.REVIEW) {
      trackAction(hasAddOns ? Actions.CHECKOUT_BACK_ADDONS : Actions.CHECKOUT_BACK_ATTENDEES);
      setActiveStep(steps[steps.indexOf(activeStep) - 1]);
    }
  };

  const handlePaymentButton = async (
    paymentType: CheckoutPaymentMethodEnum,
    reviewFormValues: IReviewPayFormValues,
  ) => {
    await handleCheckout(paymentType, reviewFormValues);
  };

  if (checkoutLoading) {
    return (
      <Center style={{ height: '60vh' }}>
        <Pebbles />
      </Center>
    );
  }

  return (
    <Container className={classes.container} ref={scrollRef}>
      <BackButton
        onClick={handlePreviousStep}
        label={getPreviousLabel(
          activeStep,
          hasAddOns,
          preCheckoutFormRequired,
          isBlockTrialCheckout,
        )}
      />
      <Stepper
        iconSize="16px"
        active={steps.indexOf(activeStep)}
        allowNextStepsSelect={false}
        classNames={{
          stepIcon: classes.stepIcon,
          step: classes.step,
          separator: classes.separator,
          root: classes.root,
          content: classes.stepperContent,
        }}
      >
        {/* Attendees step */}
        <Stepper.Step
          label="Attendee(s)"
          completedIcon={<div className={classes.hiddenIcon}></div>}
          progressIcon={<div className={classes.hiddenIcon}></div>}
          icon={<div className={classes.hiddenIcon}></div>}
        >
          <CheckoutAttendees
            userToken={userToken}
            supplierMarketingConsentGiven={supplierMarketingConsentGiven}
          />
        </Stepper.Step>
        {/* Pre-Checkout Questions step */}
        {preCheckoutFormRequired && (
          <Stepper.Step
            label="Questions"
            progressIcon={<div className={classes.hiddenIcon} />}
            completedIcon={<div className={classes.hiddenIcon} />}
            icon={<div className={classes.hiddenIcon}></div>}
          >
            <PreCheckoutInfoForm
              userToken={userToken}
              supplierMarketingConsentGiven={supplierMarketingConsentGiven}
            />
          </Stepper.Step>
        )}
        {hasAddOns && !isBlockTrialCheckout && (
          <Stepper.Step
            label="Add-Ons"
            progressIcon={<div className={classes.hiddenIcon} />}
            completedIcon={<div className={classes.hiddenIcon} />}
            icon={<div className={classes.hiddenIcon}></div>}
          >
            <AddOnsStep hideEndTimes={hideEndTimes} />
          </Stepper.Step>
        )}
        {/* Review and pay step */}
        <Stepper.Step
          label="Review"
          progressIcon={<div className={classes.hiddenIcon}></div>}
          completedIcon={<div className={classes.hiddenIcon}></div>}
          icon={<div className={classes.hiddenIcon}></div>}
        >
          {freeBookingLoading ? (
            <Center style={{ height: '60vh' }}>
              <Pebbles />
            </Center>
          ) : (
            <ReviewPayStep
              filteredTickets={filteredTickets}
              userToken={userToken}
              freeBookingLoading={freeBookingLoading}
              handleCheckout={handlePaymentButton}
            />
          )}
        </Stepper.Step>
      </Stepper>

      <SoldOutModal onBack={goBack} />

      {openLeavingModal && (
        <LeavingModal
          opened={openLeavingModal}
          onClose={() => setOpenLeavingModal(!openLeavingModal)}
          onLeaveClick={() => goBack()}
          preCheckoutPage
          socialProofBadge={getSocialProofBadge(activity.socialProof, soldOut)}
          hasOneSpotLeft={basket.tickets.some((ticket) => ticket.product?.spotsLeft === 1)}
        />
      )}
    </Container>
  );
};

export default CheckoutDetails;
