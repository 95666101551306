import { ActivitySearchResult, Category } from 'types';
import { ActivityBookingTypeEnum } from 'enums';
import { SearchFilterState } from 'context/SavedSearchFilterValuesContext';
import { getCategoryParams } from 'utils/categories';

export const defaultSearchFilterState: SearchFilterState = {
  coordinates: {
    lat: NaN,
    lng: NaN,
  },
  selectedDistance: '5',
  selectedAgeStart: '',
  selectedAgeEnd: '',
  selectedTime: null,
  selectedCategory: '',
  selectedSuppliers: [],
  isOnline: '',
  isInstantBookOnly: '',
  daysOfWeek: [],
  startDate: '',
  endDate: '',
  searchTerm: [],
};

export const hasSearchCriteria = (searchFilterState: SearchFilterState) =>
  JSON.stringify(searchFilterState) !== JSON.stringify(defaultSearchFilterState);

export const getMatchingSearchVariables = (
  searchFilterState: SearchFilterState,
  supplierId: string,
  categories: Category[],
) => {
  return {
    suppliers: [supplierId],
    distance: Number(searchFilterState.selectedDistance) || 5,
    latitude: searchFilterState.coordinates?.lat.toString() || '',
    longitude: searchFilterState.coordinates?.lng.toString() || '',
    //   pass in additional filters only if they are set
    ...(searchFilterState.startDate ? { startDate: searchFilterState.startDate } : {}),
    ...(searchFilterState.endDate ? { endDate: searchFilterState.endDate } : {}),
    ...(Boolean(searchFilterState.isOnline) && {
      isOnline: true,
    }),
    ...(searchFilterState.selectedTime && {
      time: searchFilterState.selectedTime,
    }),
    ...(searchFilterState.selectedAgeStart
      ? { ageStart: Number(searchFilterState.selectedAgeStart) }
      : {}),
    ...(searchFilterState.selectedAgeEnd
      ? { ageEnd: Number(searchFilterState.selectedAgeEnd) }
      : {}),
    ...(searchFilterState.searchTerm && searchFilterState.searchTerm.length > 0
      ? { searchTerm: searchFilterState.searchTerm }
      : {}),
    ...(searchFilterState.daysOfWeek && searchFilterState.daysOfWeek.length > 0
      ? { dayOfTheWeek: searchFilterState.daysOfWeek }
      : {}),
    ...(searchFilterState.isInstantBookOnly === 'true' && {
      bookingType: [ActivityBookingTypeEnum.INSTANT, ActivityBookingTypeEnum.SUBSCRIPTION],
    }),
    ...getCategoryParams(searchFilterState.selectedCategory, categories),
  };
};

export const getAllActivitiesDistanceVariables = (
  coordinates: SearchFilterState['coordinates'],
) => {
  if (coordinates?.lat && coordinates?.lng) {
    return {
      latitude: coordinates.lat.toString(),
      longitude: coordinates.lng.toString(),
      distance: 500,
    };
  }
  return {};
};

export const getNumberOfVenues = (activities: ActivitySearchResult[]): number => {
  const uniqueKeys = new Set(
    activities
      .filter((activity) => !activity.isOnline)
      .map((activity) => `${activity.latitude}:${activity.longitude}`),
  );
  return uniqueKeys.size;
};
