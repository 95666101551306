import { Button } from '@mantine/core';
import { CaretLeft } from '@phosphor-icons/react';

interface IBackButton {
  onClick: () => void;
  label?: string;
}

const BackButton: React.FC<IBackButton> = ({ onClick, label }) => {
  return (
    <Button
      variant="subtle"
      onClick={onClick}
      size="md"
      leftSection={<CaretLeft size={20} weight="bold" />}
      style={{
        paddingLeft: 0,
      }}
    >
      {label || 'Back'}
    </Button>
  );
};

export default BackButton;
