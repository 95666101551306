export const getHref = (website: string | undefined) => {
  if (!website) {
    return '';
  }
  const checkPrefix = website?.split('')?.slice(0, 4)?.join('');
  if (checkPrefix !== 'http') {
    const formattedWebsite = `//${website}`;
    return formattedWebsite;
  }
  return website;
};
