export const validateEmail = (input) => {
    const emailMatcher = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return emailMatcher.test(input);
};
export const isntEmpty = (input) => {
    return input?.trim().length > 0;
};
export const hasFirstAndSecondName = (input) => {
    if (!isntEmpty(input)) {
        return false;
    }
    const separateStrings = input.split(' ');
    return separateStrings.length > 1;
};
export const validateFullName = (input) => {
    if (!isntEmpty(input)) {
        return false;
    }
    const fullNameRegex = new RegExp(/^([a-zA-Z ]{1,}'?-?[a-zA-Z]{1,}\s[a-zA-Z]{1,}'?-?[a-zA-Z]\s?([a-zA-Z]{1,})?)/);
    return fullNameRegex.test(input);
};
