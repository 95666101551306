import { gql } from '@apollo/client';

const ChildListQuery = gql`
  query {
    guardian {
      id
      fullName
      email
      children {
        id
        fullName
        birthDate
        isAdult
      }
    }
  }
`;

export default ChildListQuery;
