import { useRouter } from 'next/router';
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  setUser,
} from './intercom';
import { useEffect } from 'react';

interface IntercomProviderProps {
  children: React.ReactNode;
  supplierId?: string;
  loggedInGuardian?: {
    name: string;
    email: string;
  };
}

const IntercomProvider = (props: IntercomProviderProps): JSX.Element => {
  const router = useRouter();
  const isWindowReady = typeof window !== 'undefined';
  const isEmbed = isWindowReady && (window.location !== window.parent.location);

  if (isWindowReady && !isEmbed) {
    loadIntercom();
    bootIntercom();
  }

  useEffect(() => {
    const handleRouteChange = () => {
      if (isWindowReady) {
        updateIntercom();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (props.supplierId) {
      setUser(props.supplierId);
    }
  });

  return props.children as JSX.Element;
};

export default IntercomProvider;
